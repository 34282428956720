import React from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import NewsList from "./../../Components/NewsList";

const EMList = [
    'https://imagegroup.in/images/news-events/icreas-2018-st-patricks-academy/1.jpg',
    'https://imagegroup.in/images/news-events/icreas-2018-st-patricks-academy/2.jpg',
    'https://imagegroup.in/images/news-events/icreas-2018-st-patricks-academy/3.jpg',
    'https://imagegroup.in/images/news-events/icreas-2018-st-patricks-academy/4.jpg',
    'https://imagegroup.in/images/news-events/icreas-2018-st-patricks-academy/5.jpg',
    'https://imagegroup.in/images/news-events/icreas-2018-st-patricks-academy/6.jpg',
    'https://imagegroup.in/images/news-events/icreas-2018-st-patricks-academy/7.jpg',
    'https://imagegroup.in/images/news-events/icreas-2018-st-patricks-academy/8.jpg',
]

export default function Icreas2018AtStPatricksAcademy() {
    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };

    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 mb-3'>
                        <Slider className="news-slider" {...settings}>
                            {EMList.map((iMG, index) => {
                                return (
                                    <div key={index}><img src={iMG} alt="Icreas 2018 at St. Patrick's Academy" /></div>
                                )
                            })}
                        </Slider>
                    </div>
                    <h2 className="h4 mb-3">Icreas 2018 at St. Patrick's Academy</h2>
                    <p className='fs-italic mb-2'>Place: St. Patrick's Academy, Dehradun, Date: 26th-January-2018</p>
                    <p>Icreas 2018 at St. Patrick's Academy Dehradun showcased on 26th of January, displayed the heights of creative and artistic talents in the young Patricians. The event was inaugurated by Col. Sanjay Washington, the Chief Guest for the event, The show was witnessed by the parents and other special invitees. The Fashion Show and the exhibits of a year long preparation received huge appreciation from the audience.</p>
                    <div className='news-arrows'>
                        <Link to='/10th-convocation-ceremony-2019-chennai' className='prev'>New Post</Link>
                        <Link to='/CSR-activity-help-kerala-flood-victims' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}
