import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import NewsList from "./../../Components/NewsList";

const EMList = [
	'https://lh3.googleusercontent.com/oaJLtDA5erPca8ZQqJ8JVguechK1Ufvx9YFCuzNzdlQ-qjJ9-QAB0sgZxDpSo9u2Psce10Cn00ow7o7PMcla1kV17WktkT1xPfL7bmh3GujEkQO7JqGQPEn1ANYgsiuslSyQeME8Rg=w1920-h1080',
	'https://lh3.googleusercontent.com/LYjKcZDWELw6DtgG7CeG1RxgCsWEhmQt3fXRy7AGhBprSY7FzQv71gbdB_mkT6nYCWXBc3STL3XwXQUEMjxJrS9Ex8A6ce3i1p8ToIlTHBhZBowo756X4Kn8BjAuQAD3X9rfKywdGQ=w1920-h1080',
	'https://lh3.googleusercontent.com/Dumk-fqN7GuHHyczv6RfTFzvMsMAkDqv9Ttbwh3ljcBNjy3b0ytsDV2N15NGkMbsIxJBrIItVpE-1yKLa7ETj9pXttuBy4A4zkxw8xDvGdoLkQp_SNFKQI5v7mzDi6DVT1JQANTR4g=w1920-h1080',
	'https://lh3.googleusercontent.com/ICEKIjAFyRG_2umYQdfpb4NUHofhiKrtx4bcFlV95UthwNdmU37HolVzHfVpfcS8G3sJuzeB0jK3cNkSlpjMtmONzYmeGPQgvmepgYUuSqrWUB9i7v9lVsWvbmh1fA5fWjNqjXVC9A=w1920-h1080',
	'https://lh3.googleusercontent.com/Qfp0f2sp_902dmtBuU4Ya06FY_fV_fvLaoRSqGgHJf_lHiKy-tz473K14d9GS1RWWlOVzxDPGfwQbpiAM1S7VIvGY9wdfdUCEflD6M2dt-qZ345JqZS5JfYMHtuHomt_g9by-EFGjw=w1920-h1080',
	'https://lh3.googleusercontent.com/Gcl2zCk0P_36HL91GX3BqlyP_9GMziT2DUb6ozqmDp7ibSnPDPOeq4e1qcTdiv5qz23WEBnbleoNnYvdFccbvMUXD8a1324INgk9o7rW4Rnt2BOBASDx_wMIyKSPC7W5oEI40p7a_w=w1920-h1080',
	'https://lh3.googleusercontent.com/2Btp28hdShuSpNK5FGdlLjot4TAAK4tG_mQjHfVZjMXvEViWYeHyWm_QyCrnQLAOn47j5CTpqVZpMHNamSgBN_3ty2qsl0sBqYUbT-R7HVODh-QNINXeXDY8YJK8UbTei5f90U34Dg=w1920-h1080',
	'https://lh3.googleusercontent.com/0nAyprZTgta0bnNISzgFQXqhahjTF0phCyDh0HYwI8-rf4NJw51Zuip76fNPYKQE8LbpQahGovi30DXdRfFfH_VyCl4g0Db4NrDHeXUEY844_07-73mChpibvmANV1ifO5U81uEJMQ=w1920-h1080',
	'https://lh3.googleusercontent.com/iAHewgX_UCDWzIhfFV5t5_cr5yqqFo5o5f8vQA6bTwKfCCn5fSBYo82phzgPkhtMsDDsGMbq42TVoDdqwgLpDU-ADedWqQfp-SEIXfxOTrQIXC2YFCvpFNFZ3JosLcNcfNORcPhcXg=w1920-h1080',
	'https://lh3.googleusercontent.com/4TD5P6jpA1XLYJ4b3NvwP9wFq_v2pzMbyTtb3XeP0ryicK1XnWTT5Bn5Hs9eGIBWp0_J9rzFBHRLtt0Zi_8RVCKff2vyOwq2eCGUcSRVJ_Vy5yxCJ_HZ3UOaXTHDs65a4HP--jv8Jg=w1920-h1080',
	'https://lh3.googleusercontent.com/CWOQyq-XjHNObX8U2cnjZ0F0pbAGLE1vxkfaffoFRzCPxp6lFZqrIn62SaxRDqWHQbfp_xRdh7GD69QHYbsNyqD4H6JddGxXl79w7ei3jps93KEdhvsCn-abGJLMA52YpmxoUYI_jQ=w1920-h1080',
	'https://lh3.googleusercontent.com/gxZHUGkqJWoSAWqAQLea-tmAZDazNRqpLnC62i8hb97MUtOqk7Q1v9VrgXY6_mBCBC9llUBBXKnXclSllmku7Jo4QP-EMcjeNvOK-3Q4odglKcqAT8FqN98_eUm4WjvQbWE9uACtWQ=w1920-h1080',
	'https://lh3.googleusercontent.com/-IAfnuxmOBbeg4WCeIh6gkGIx3xw8FbXed6Ppxnb01Wm-CgXw0H9aWhDHXRS9RLf7OXQQ4aZ7y5ZKSYZPRekO8UHiOBDreUbRiXzt3L0lLu5DAFMmSgODocxdU3fA6cQs3Lrn0FwDQ=w1920-h1080',
	'https://lh3.googleusercontent.com/F3kVBgPesa7aY2_JfZR4PcR7K6ttiSQjoce9wenZwitmqR5jasd4nEtb6aq0THZGuYFx5VLgqWmFnx5Nl2Zpg5TJzwxRt1VGmVsxB5V7w93C5wzTIxCKJilrIVeUd6lykUgMuSL-zQ=w1920-h1080',
	'https://lh3.googleusercontent.com/xDBVAXjMGfuoL_wlQ1Kkgz5rM1tNBna_tfkW_CGqcxuoQzMhJZvlJFrcKAk7I7sOT4X172NRVcMQLRmolCSL2FioP6ZGLSSEg2bCWIvdbg3-ysoHg79bAHXaBCsL7lfnSmx5ftY3EQ=w1920-h1080',
	'https://lh3.googleusercontent.com/pIlQYn9obeiSmdGvieXtcMd038NgwdotyQLMUmg5GxfiHcNXnUcMAQ8hfcbfo_aibeeuFPa4Txb0nAFgXzyHDZnm1N2hzl_eHfDsO2Cj48iz-NL_J5YhP3H8tC0Tcr6la7rICxj3QQ=w1920-h1080',
	'https://lh3.googleusercontent.com/z1SJ5kB4FtXx4F8XRO7hNR0VwrbstkD3_2dXaIXREVZ0OOXtH32p2MpcSv0hPzdPiBgrmC12mAvRBcUDS4A-0YaYoYrURW7Eo1C_KP4luJTJUwdxBNKrHaLJW6Zv8qMvnbMpS_yw4g=w1920-h1080',
	'https://lh3.googleusercontent.com/RqJANhBO2JeJbT2qlVc_SCNZQQDC-3SL0offGE33rYbGkWcUgKEbEBT-RigzvolnWTrSrf4Tt9vy_5sGgJDF9hDuP4HOlvcMzPnFBJJNuZY3VrDaQDeIPN_XOGidvSWjmnQz-vpf4g=w1920-h1080',
	'https://lh3.googleusercontent.com/Qd5qw2jW_KnCG_1USou-pl2eHIXlHtGTSDsfEj8IB8nDgoboNPgdACtMDRPK1TrfHevjOO7DJvWN9b7K5Tt_xCkErD0cmj0Zm09JJENqiS4Opb-kygPSCGLV1MjPIleAjectF3FSYA=w1920-h1080',
	'https://lh3.googleusercontent.com/YUR_Mpc_7rkfxOJZeSsNKxq11v15K76QW-EdYkmy99v4_ogJs0f3zZrm0nZ0qOZB-_23VsmvMfW-gRNXYfBrpzzx1xXY-xorTy7Do3aaZVR9kRZHhHsDKptGdbPcrdm_onu9NJzm3A=w1920-h1080',
	'https://lh3.googleusercontent.com/AA_HVFLxWNxupOzongOx3pXe0vh34OluO69ADTLkASvk3leRXF0yfspXmaApheOTZVZd7gI0OzY8urKXQRNVURncK_QiDFbwde38H3DugzHRABlD9IcORPgt2NoovNlS_L-TW5bh0g=w1920-h1080',
	'https://lh3.googleusercontent.com/kMUTzzowKvNWAKSiHakAQd2EcJAb9Dj_4L9Fseta3pK9op-WebhuaLN46OcZ7DW9-p609l4nmsxvdxOBbhrEQr6ODO4ASqqdjoVV9XTPuhl-LBcWAbQXCStDxrhg-tC6fe7z3iXstQ=w1920-h1080',
	'https://lh3.googleusercontent.com/NcjCTe1hq9WhatumxPDozmhP81QdfX-Fxah27AsClZokBbXro9Q8LU9RPtLSW_WT-rxJ1U2BocWVdFKyIAk_tCoMRgaEM_bJ5f-n5N7gx7PrMAz9yh0lqLiuDbWlSfe3CMVZ6rGPxA=w1920-h1080',
	'https://lh3.googleusercontent.com/63VjZe2obLsUGwyYyymqRd6B6D_gHTG3wrGlC5U4IPEzP8OgYf5m4BoEf947r2YLTwfYziA-eqZZebPjCN-z4mJQOOKO8bnpcUkW2OHGhNxjYpIoDLFsVPtA5d250xMdU4C-FgyICw=w1920-h1080',
	'https://lh3.googleusercontent.com/_wz39H4dE2cByln5DYdlRDQxyxNcoYSDHrFAs0bxYMebQmEv7N8eYsYJqYc16QqEtvHIfjXKFGlscp3AVnH9GtWzD6GuCCotR53poz0keKR5xpKNYQY99F49U7XYAivMEpwsVSJIUQ=w1920-h1080',
	'https://lh3.googleusercontent.com/eF5GcHxZDXbM6KqWqKLST3yDhooF1T6FH1084vXXA4bA-ixDIksWIbpfDqk0gA-mXH1WYhA17Z7h9WP7__25GC5WCzNNzwr0NU5mBf-K_PyS-x4FsbZrnhSlv1aSffUT0rVkjo0AwQ=w1920-h1080',
	'https://lh3.googleusercontent.com/TiLfh_3rzc7V7r0BhX7gNxxfn46VoVuteYbhrO6I63wjZJV2Hxm-gNiG0_1TsIN1MHSAOt7rntw1aZxtHL6EPFx4pgPmaoGBiVoK3CTuPlhLq2Z3m4SxdQWvFENJOiZ5RcaCwutjaA=w1920-h1080',
	'https://lh3.googleusercontent.com/obOYb3KDVlf_G9n0SwfhwOrnk3AAMysjvta6Ei5T6oI7iqpDAo51Fpv-ubquqRfVe9WDJflww0YxZrx3g7doAU56JLMEaHJFOoSVw7s23y7zB7eGLvVOcZcwC4hJRokT7SXkxNjkjA=w1920-h1080',
	'https://lh3.googleusercontent.com/t2btZq_UwKqIF8jmWgC2hc9nz3nOdOk85T7KZdHtyHYrfduUVen7z0q_P9g3K6BtOdyZxA11erBuF1eKriUWWA7jPw6-VJTVLd054jUmE6A6ZGXRczOFOAxBFUl4pHt8GSbB62_LoA=w1920-h1080',
	'https://lh3.googleusercontent.com/37l8E_i_c2y5BmyPEyhwSC39FCcr1DZg5P6k03CsH1Q1FhtZVaRam5pEQmsDopTgGtTtdtezY_pOit-lquIYVRSbq3f_n-71Q9gaG-UrVsfGjF_yIv-LJyttPPcHgEFjGkzDgUUXiA=w1920-h1080',
	'https://lh3.googleusercontent.com/QrSpi5KUiN1YeGX7WSR1xyNan-0GXgEMK07gXT7zN9rkzCln8ikuuJQdzRfizexoaIWXRLDMlvy8isH7vIRsydJ9h1oDoWVdS7D1ztapPrwBl94aXDsx24bU3qOONzPoL3jEwOLEoA=w1920-h1080',
	'https://lh3.googleusercontent.com/cAO4U73yUNmcr1ZUvJP81JzMnhlMCS0eHnqp24yPvxwttYGf_r94WDyrH61vPBOGdjnEeh2-D66lVNzQCQAGXl7bnlFeEUoelICVIoOha3ximhPi4yd_asPVMR1rj2Ckn5laU8NAww=w1920-h1080',
	'https://lh3.googleusercontent.com/UOdhwRsxE6CD0Cm8c-r2u5gdcMQEdR64PwW81wi6mWlxkBDINuo4qGLOXy5Yp5AzK7XG9WKw6_CMFOi6o5llDujeidze-jgq34n07mYB-LiHBslLo4SiKoNuOJESmQz_eGO-XmGNZA=w1920-h1080',
	'https://lh3.googleusercontent.com/r0r4Xy7x_J-tpGp9YasKnCk95712ZJIXhRozc9AeszYP4ckDJIFAFjT5pRBUFyTYXB1PF7m6KHOo5_gaBQPTHi2uGAUSpokH0xXNF-oleDa3uGhjQTP1D6rUrWKItVn0Pi2IkZ3UOA=w1920-h1080',
	'https://lh3.googleusercontent.com/UV-HuK6VLFoWL0ngWHDHWvDYZWq6wzoEFeZjm8u90wIGOkDWIMhwpA69QUt2cTnegoPJ8B1r0JKVmlUbqnmNYyViVJ6yvtjTdzLhaKRUQ0pN1zz02I9-zVZhLJcAGthzWhLOk3duew=w1920-h1080',
	'https://lh3.googleusercontent.com/vUzHLfJOaWJN4k06sZET0o_HTqWhcy1KvHV0AF11HlT5MnVmJQh9OyYGdpCh1Kazt3RJcJKGnINI00aNEHB7SqJcjsfjOL0-AQyQNhTZOzDpR92cLoPyn62SIbrixgGHfWZ9RAf8kA=w1920-h1080',
	'https://lh3.googleusercontent.com/M9mazQketuBvyPcg2QXDaHPb-5NnnkpS4DE0tggc4bGyMaqptRmF8SBEwKzyaFixJ3Xf0wKe3qwOvwpmFnVr2w2seK9MZSzs8INMaPTthXe4owYD92bJfOcQtvMZqgG80mUoeAeBww=w1920-h1080',
	'https://lh3.googleusercontent.com/5IGrk_Fw2Ud1tQpw3tbPxICvptWcklt7nY1Npi6yAIuvt-bZn6jqwdD_1k9BHW-Lpd9v-QM5tTm9ik6FTZlrhSqgYVRm8ikL8bX-pxrsQLCeRdrmKpERNeoeJL69bgBh8EXTk4iTCQ=w1920-h1080',
	'https://lh3.googleusercontent.com/Ij6DkDIoShj93heGmJuJsct1jOmQabujN5DyQwalHx0W1HA7NrGuI8z0p_bbRilk3geh3d_RgRY2yALuuk0xm-S3uigoXfJlELG5tM-24B1MytwBoiLLu4O-mukCeBmDAAfi0AesaA=w1920-h1080',
	'https://lh3.googleusercontent.com/TJm36LVKnb_Q0bmDjVbt7pRVo4o4kJDeiNFJNmNVEWj9r43wjT0QwCtIt7Ifht0SfQyEJxAmuSRM4p5cExuiYOj4hpv8CgpVbauw_zfPHd7FjZiuxUa2-wtyegv4lGtaVCKDMDbWEw=w1920-h1080',
	'https://lh3.googleusercontent.com/moz19YmzMiccx_YM2J_o1ZCnGYDotD0VHkcWZX_GpPLeripGpTMWvDcj4WORDxM8LEA_rLNNMTy4BHTsSDo12kIG3un-FdcheYEptcvO2-iImVmAWaUOUFM-objlQnM5Eo0RkgW2PA=w1920-h1080',
	'https://lh3.googleusercontent.com/DKZWUMoTwlC_XmpJG7kiNLQHNenL4CVulgPOjoIjOAkVLCmllhHNzzyu5_uFLL2fCYbZvhnYbq6mGEXwo3U1DeHZfGDWADnmoJZAdZ0y7U2FjpXYr3qYR6i0Sc9xFddG0yEPoQ9zbw=w1920-h1080',
	'https://lh3.googleusercontent.com/kVlxY43m5wtjxhTZxyT1SAIR3LkehEzcCwnK7C-8gc3F7asVLgbLGIlztONvZQXd01vAuW_SJGAKBubdgH9wmA7Eamtp0P9xz_NIMMJmEizsK21TGcfHBB6-ZpZtEjuwyZtbu4RY9A=w1920-h1080',
	'https://lh3.googleusercontent.com/FlFOWF8U5zT7s76VeqK79fO_Df2IzWVq14oqaaqhQk7Utbxzvt2iOSkPQHK0zPMGK4SNwTjH9_mK7SCcDMoMoy-_zBxSGNM9ALA4OTj04a9qzPqviQ37a1C3FNZTwKFTSu34m-GxQg=w1920-h1080',
	'https://lh3.googleusercontent.com/mG9wiE-Eyf0DjsUeW1cchQfyNyok4iT5w7TXzyeMkYSdtXz_TnbTWMf4YaLL6FSGR5YbQpTpztYKLmXSqxhcTmZkSl1B5f9DI1VwkEMXAGWicEE0rJw4ULhl_zCnbyr2n5-kJ0QMlw=w1920-h1080',
	'https://lh3.googleusercontent.com/7evAfRv_5i1crud1bMj4_nc-25T4XD1FdLbGWjzAjI9EDcEX9z2dSBSDEbvDTcoUzAoI3tEIhlhDfql1WU5dKdYGCarHwc5E96YUz752d--i4_28bURBThzg3yaKdbIDquIGcbYpiA=w1920-h1080',
	'https://lh3.googleusercontent.com/91QLx9H_ZFd3zEZ5ulEGdFe5FAgXd9PjsoeVAldnYAYK2CnJba0BVPiAynr1uIK-qeFipeKS9G93J8n03o17P4Wn1_Z3fVbvdDyrXlli28VmzI6_UH_HjC1oR9QSYxQk1yLZwGCbyg=w1920-h1080',
	'https://lh3.googleusercontent.com/aDl3uvatB8vBf9ZNa1iPNxfcT6REQA4JR2CJoOyC1jWMxtrgtSzIpCfFPJpnUFNm1bCj3crjTbHBrNBtnFtImVFIq6OMefD75rMDTnJ9ebMxzwXHsr6Rx2ojBV66pMiKA-IzXeWnBw=w1920-h1080',
	'https://lh3.googleusercontent.com/6k7DqzOnHYb8gNTSJwQnRxh6rnBJ2C2t-_uNdAFcdeGXv5PifPubSegaDfV6ruGQDMNXy8hvp106lnosdl2IBkRT4zbp5IRmhdvfhFnvuCrUJN0RV7-V0l-i-gYDoFhTBD9Iq5bRXA=w1920-h1080',
	'https://lh3.googleusercontent.com/RczKlBhxQ4mcjOW2l1x9mLJqbSG6MIhjjX3ZgFrfOxtsyHeQIns70fLrEEKq_DQsflp2SkdOs7wvzmpJO_-UssgKBeYX7RtfSnih24oeBCMZm0rLXdnO4C59H3Y0HpTW2MlThbdHkA=w1920-h1080',
	'https://lh3.googleusercontent.com/X2Kiw4XyiwDBm-RyJGFSNgz_mtpzPBt49rgYt0w3XfjisFgtiuRDq2PxJZgZ3aB6ay3pKoTG-SnzciXjPjbEFe8w2pZem3ogopRafvpapvDu5tsJgdvVJH6h7D0JDpj2scFRwm2HKA=w1920-h1080',
	'https://lh3.googleusercontent.com/61WVIlfmvVPy5cCSTtK-i-u-d8YgsLF0SPXekkH2VfdeDY9cw9_eVO3YvQNKgW2E_oUbvjdSd9BgtegTKv7yqlx4kmUI3xMl8bETHWS6SwjxgHnpjAWoF2Nh4C0MJn4O_v8zzm4fPA=w1920-h1080',
	'https://lh3.googleusercontent.com/xRO9TWV5UPXgFcQ1GrTyaeQM4kaJcM5a9KnIPKVzmt3d-TiDmteQ4Z1Y-_A2EnfWMpTL684ObtsptDjlzaFAZpR-ZAgLWrxaykHvZ577tWnjb8EvKulMJwj53_5zkiGRqaxUH32SVA=w1920-h1080',
	'https://lh3.googleusercontent.com/fbclC_IneZqGMqWh0ZpWDHrDA--Ljgs_kpLGhW025RL7EzgX4hgPmxvwzVdOusgswU3l3gXPUXG6kNPt2JmoM0p5ZCfCZ_ZC-bHgSlH9ozj_M7MRGbeewxqR_evD0N7ssJqoBAI_1Q=w1920-h1080',
	'https://lh3.googleusercontent.com/7d15jtXqnIOAWmhEZd6CKD4iphaebAmZGnETzlp6cCLx4Sdx1ABjggyDUAuTFcUUWWkHhrWixEibZvmv2fQltYBYUK_okloKVFhsOAQ3PKv8dkWdTyeruNyKwGVMh2p2Ar6M8G3adQ=w1920-h1080',
	'https://lh3.googleusercontent.com/zTh-_WaTRedHsGe09cPiIiFoKSx6ntNPexxm3YUEjfEPZX1pEpTd0RiBjTwZ78ipnxcoAGRPhGq10fmZFvO0gFZfS0Fqx4afOxWNjMJ606acJotlKZu1uUuTvGzSXOyF7LtGYsI8dQ=w1920-h1080',
	'https://lh3.googleusercontent.com/tDZ3Gne-3mzwtzwJbLIun3KI1kqUqhH-zFJ_26SS6PyVLLZDGvPhG3sY9HMAhqPG-nFsja2dQjr4tr_VEjvWxNTvkYccubXRd9gbELh9eyca-TkKMWmKLtHU2osieIKmtOR8i68OiQ=w1920-h1080',
	'https://lh3.googleusercontent.com/b9R7iqtvrtOFzue-g7HEOcg_sBzElfejWkZ1nLQb-PF1e-yK-Pt0pvrMDiR-iq0IcqKuk3XKOoR-ar0hZnxcjH7ufgG7bccqbEpxc2uEMq7Gr3JAwAMhpPMXfSNMAezX_1MTHS8C4A=w1920-h1080',
	'https://lh3.googleusercontent.com/wPe-2qxEuwefxVUZlecvxpayIQMrXgwCmOQtdD38aQ1rFLUAn3YpF1v_enzgi7MloOIB-PhtiOH73wGb7zPNllOMq0RHZOb2eXYP0MSnMVIUPQTXS2sHnmqGnvOvTA1REVZk0GeU7g=w1920-h1080',
	'https://lh3.googleusercontent.com/zZwlhs7UGCWAIjR6m__RrxnQTvH8oBFBVZzSzD9oqD0-00LsLSlhL11VGGxqVYQxEbQA6XtdhRSHXWT5ODgMHHnbJx1kGqNkiJdCe4GDyNWuiHkgEerqD9E2_Dtkwoq9q2hI80NF3A=w1920-h1080',
	'https://lh3.googleusercontent.com/5yx-inr6wLckSnzLXQ_uu3phlcANc5GBVEPLuSg1i3QBI7MWvLD0n1fV4IFop7W1imtkNUa9urHeP145C6tGjSdOwWQROaCE8aN_oPXb1rq89jx3-dz4HURXwkzPaTDrueg1EoYaOA=w1920-h1080',
	'https://lh3.googleusercontent.com/8IQenLAvS2-hw7NHoRSozh8C0ySg6uVOe0N7o5Lnc_XkYLNbdL_r9CHUrcXGCsJSboyRSv99vTQeZgdZOrXtwbDW62SQbrG8e6_DJtwqDSnp2Rbpw2icPz1h39eXuKpe6UdmAf6JJQ=w1920-h1080',
	'https://lh3.googleusercontent.com/qHIXpnIyYXNkN2cFhgJbI4unTGS24RdbWGjW5XNTD3_tnYWcZJSW6SLHIqiMqnWqYsvsZ5WAIj5ngX-0WRs0qd7wKmyOf4_oi5Hn4FdHiTX8xRyYBXvGfwLyHdoF0Dwa55nS7sMblA=w1920-h1080',
	'https://lh3.googleusercontent.com/NUPZ4cnuYAbU2bo5ip3avlJl8Cg8ex4nT5vovtLRhDQfPEPF69TR3z4yeWn0ens_3msfnfJh_yx-GAuQ5_2145yHfT9O6chGYR9HeiJsWHbOzQ8pjtMYdE7sASD0O8ZOgQ6MhbhACw=w1920-h1080',
	'https://lh3.googleusercontent.com/yfobK2YJQ9yHgiYOtGbx83KrEXhmzHl8pgVVunO2Is8CslWnitwqai3o7jQs5pibToraNFQ8SqtcfXL1bXbaN0zCH5xt_WmOdRxO6ZglTXDjmdqJBog5YHTY8ktvhiWNQmzBEFjUQg=w1920-h1080',
	'https://lh3.googleusercontent.com/MKmK4ubKXOuWHPeL6Bb4VMRFvlaLkmn7kupxmH5ljwGNa_tSRdlnD2OB5U8oU1rYWmuB3uat4nwE1Ir8TLMpzuWm1aSmYLJw4xYYgUSzDS2k1FuSwSRjHdMwNBOeGNRF6Xo9vNwkEw=w1920-h1080',
	'https://lh3.googleusercontent.com/t5tE0cX0zUTI8_fukEnKYdJPziuwFIEWtybGcdk-cQywEwvf9n2ACMDbOPF7Svb0t4-QoK-zIZnQ40lJEpUPIGnBqPgQOuOb3dqqRlr-pFFKS10a46CS9UXDieYU9jC9rVHx9IllBQ=w1920-h1080',
	'https://lh3.googleusercontent.com/HM5XzsQFZfE4180w-p4_5lgBvhz2AatZ8TR-MCpcikr_HJInqw-RjLP2hq6EprkpSgWV0DTBpaTwejs8hzrRcpROOL70UQDcykdhgvQBjk8FHfIzlLbgjKBhxAR3ovzdvjkiKtKN8g=w1920-h1080',
	'https://lh3.googleusercontent.com/mkI5NWin2N2flmkYfFf_qSMEABilN6XCrDmn8rXxprqZMO18NMfeMtPUaiayYLL-mnr1P9paCYLDezdkDqMadHuSv8VgAJBEG1QAbUnCRy_EDuw0noe9Ga-GA_gexVxQGP3gXWIPDg=w1920-h1080',
	'https://lh3.googleusercontent.com/AVVT58Skz2C3ms8YosW3IlqoVutO32o1gVBi4vVzLb8eOG1PPm4f_p4HitlqyyURL6O6AZ9oGPTnOQ_mrYcj7di8rmCk-EtV7OwcrVroBfUM2xcFSHep6ki81a21Eru1NhLvCpSBPg=w1920-h1080',
	'https://lh3.googleusercontent.com/Ux3vadTXY_Mkrrk7u3Tk-NxoohZGp5EExYRIMPAZsqrHqHgLh4f0xf_Buy5wLpOZjEyZV0NjiR3r1no14xJe926s4TZDWoqObfDWQibW4eT2EBzUypR8Mfh3yJ5fQqmA2qFudhQh7A=w1920-h1080',
	'https://lh3.googleusercontent.com/87H4XXjep4RzqaV82-UA_de87Xhwn1DVAyBUweFzSWp-OGHwkYfqCUkCZQbViIE3X7FFH7jNEkegwhqo-5nWrXXbKZM8yzN0RaxoOMUFGdt2dSdp5uupg29Xp96rCBiyL6gTCp7Z1g=w1920-h1080',
	'https://lh3.googleusercontent.com/HMCh7wdy9XAGI2vi47kq1YaBcqYR24luSijTK501emQHunu9yd0sEO2X54GA0bUsI7VL_zQ3mxe0Hi_4tufSJeJ2zCxcqDUMibvoS31VfH98Dm___1cGOEJNTsFviddaxwygKXL-jg=w1920-h1080',
	'https://lh3.googleusercontent.com/Uc6-_hDJH-i-Ndgirs6W2Z9EsAnf91ap8WhVGQwSGxPMauAYsQjfjboTlTb5lfMdB8FxgJOPCuS2rZ7-JGk4TN_TIDnc93tobWftyMuZjaecaXxvnrxpKbumnqhh7Pqgu9dkJ4kSKw=w1920-h1080',
	'https://lh3.googleusercontent.com/ViY63b41wrXYqiV6CuWMl9bkDAQWgH_Ztg9WL0kdDxnH-p-McfN9VUsYgfMA1Ma5jqxt-9UtYPGKN8Hl34iiD8AXEcU8Bo-6wVd7wE6xMD_N_8vnuPmYlWgMRBf_3k3OVjhq7bbveQ=w1920-h1080',
	'https://lh3.googleusercontent.com/slUkg1N4e2WZERN3EMhZ4FNzVnonCtTYh_K_aQL5aJNzpiUKokAHB2tBKmqQZgNwxaYAZXyA9eOyxx1tlYWey1sWtN90H0_zGPhDxj_USvjU722GcYkuvZ7CnPk5M4G9wnv_80ahtQ=w1920-h1080',
	'https://lh3.googleusercontent.com/oraBsdY9i3CrR-H_SLs1fWXj8u3cgZXSlV3oGEYPziAP0D56c5xFLSf5F5ldPG4d4fxBPxRLTmkteNrYwqfoqqR0GLyUYV-y3pcAVdMsMtByfhbjaid-xOjDZ4ASYPlgcMrA8yXOqQ=w1920-h1080',
	'https://lh3.googleusercontent.com/PIdyFkqcEj9vvt6CGfdPaX_AfxqEwaY08WJNK7OwadAk2UukyZgJ2mj55rWmIO6XuvKVAW6odB0xkeUFOiGl-Ib_amE_nuPs0PQr8rG9fMItTtTM3JRDBSyIe4g7EHcE-ct6xcNPnA=w1920-h1080',
	'https://lh3.googleusercontent.com/lseifSWeadt3BBbLIAycOdc2JP3RFg_zzxyIzK9DmjKvDxk03onXYaG8_W3V5gtbSxoFBDS7tD5Zry2UCnVgDe24TXxr1H0oDKuHqL9cf5oFp6vVGGdOJCgkR9t2p22fEkGkF86TSA=w1920-h1080',
	'https://lh3.googleusercontent.com/9wXggmYADD4bY-Q_7OWju8M8S9-glEpMI8TCEimbbR9sv8-QooZdBal4GO6kOtHBqkoBTa4wrl5qtskDk5aPbozhu4XF2ZbvUoN0JLLcsxS7WDVqbCN5kpHpAsfnpWbM2lCy1A6oEA=w1920-h1080',
	'https://lh3.googleusercontent.com/BwKovJV2GnzP2Q265hBOX9p3lc823eQXQ6x3hB6roq2XqqVo29l09rBm5kAGEGXUaImBe-9OgUP7HdMiWpMjgH5DJl5-MR8IiMtPTwYQET2_tPUoxhBG4ZTbU0dgV0AQyqi9XiO9-w=w1920-h1080',
	'https://lh3.googleusercontent.com/vZ0YxuNxejFVdif5d0mxtquopGKrgCWDyQcQ6FwMBGJyLkV38mhPEGwVdZjV8lyZ7FtC00IURAjmTmbkAEcQgMSAcW957awqIRiSbkIk5q6dGpD72W0okhWjAOPs54dFOtAKkZvp0Q=w1920-h1080',
	'https://lh3.googleusercontent.com/NC-0R0CD8-BHnENIVQV4aAKZqb6VrNe8-WlQeNa4I1P6cf3WJo_TprIsPNtA3wLV0K3f1RaYyQ3B3l8YqQtU_Ag9vu8vAEW0157ZedxI5ijHTDo9VbPVEKAe_TgSJESLY4-kdBgZSg=w1920-h1080',
	'https://lh3.googleusercontent.com/w09G1nU6BmFVOnFtPMUnI1a6ZiT-UELHKxDpceoJMJ0-pG9lANdURgjeEJ0hWI5GYGe0vxtwfxAAec-lBExl2qiNbOhBbzwdAn8oB2RJt3u7rRqb0uQEbFX99cXQDXJsKpATJ168og=w1920-h1080',
	'https://lh3.googleusercontent.com/TY5231qHt3tZdN6JzsTNFP13wEttnSJFWE_AaJBkefh_D4N6AIPIlEj_MU1_cs3veu5P_At_JY8DbTEofNsh9BUYhiOCZB_7B5e1g_-YzrhOfZu8rBHi4JOYXyyYfIyANShSv4IdrA=w1920-h1080',
	'https://lh3.googleusercontent.com/edswzZhnFFHv6goP4QCrhwwpw1fFLnZB7C1rTU1qR5MtWXItm_iegU9FlQXfVzOF_wtOrdWJH0asGyzEsuEajmin1XWhRh-8ig8rqF8cfz-IEa3qJO1_3g8SUWfSpGdTsObhaSMQUg=w1920-h1080',
	'https://lh3.googleusercontent.com/XdbnFKIaQbSvF4kKf7HCWaDTC8aBF8e4IpeTZ9en7GAi294-Ue3FMnt8LuqsASDbGXn94gCTi4IQoufmdqe6Y4z4AxgBZOJD3ym8Zb4M3i6xk73oGH0zFpkxcjllg6NQDPGxNv_Vrg=w1920-h1080',
	'https://lh3.googleusercontent.com/yHybAA2svw_sSm7cEo0h-7PkTFovb7q5Kgip7hMezDnKDNhz-oaU4nibCTLZY4dOSP24gNqDkWbyE2AxZWl6OLkKYSz7cQs4BBAjnMSFmY0ZDR8iko21u1DNr4ZmeeoZKxoS3JzdmA=w1920-h1080',
	'https://lh3.googleusercontent.com/cfwhayucGUxLZo51JT2GqsdmSS4wjQZqC8ahzP3HJr7rRsKMne9x9TzRKrHEr_Go8oJ2lSRYu92nhZiVOmwEY023nRxfPidV-KYBf-Jr5_kVv-uVX9YLtm-5uD90yktYzdMRF8FjdQ=w1920-h1080',
	'https://lh3.googleusercontent.com/F1YEbmKKPfV47IBefhDLBP4RZSmcuhW1nAZCdPCVL-mNVfA9SC__1rV-eNucQFW2i4VYXkZuoymveubH5kcdhEjqqMi6JG1G2Ja1p7A60gA6mOHwG-Z6MgMV4f9_e6VQHMcLqjD4iQ=w1920-h1080',
	'https://lh3.googleusercontent.com/M85doJtSJ6htt-VJYznSe6zjCzwnPEMmTXmsuCHHfk1eKBNN-RBKAEWmf7w5b0SZ35J2p9x6FUSl_C2fsOPYuVEwUcvJowfuNASFcoZlrwRKOmrc0kdhdGRjNU3l5X6rRNnfInQfSw=w1920-h1080',
	'https://lh3.googleusercontent.com/eAhwLqiUmltAimkc4eU4PY1jSHFKAS0MvfbJOc0dQ7CSbh-A8OGzrGpLlj0EXVrigDa1PFUtjOr3O664wplq4HM-stU72U-S6hbQcZro9OuQOO80VEGPXVLBDzW_y4WwjLlhckSyzA=w1920-h1080',
	'https://lh3.googleusercontent.com/fe3aJXWQkZonTgVNBUosWl3WQ0cYmqqjfU3xf4-uUbayqAi9CH2ZLPXdRXLwOKKZ4FKP-QA6HtZywUCxjFkGhnsakWY4HRLQzQ4S_Vk8EcnPjYNC6Tm2b1gaROG-E02K9dABp0vExw=w1920-h1080',
	'https://lh3.googleusercontent.com/l5BRHwSeaDq4x1aYsZnC66e3CzLzRl3NDgiDt8tcMGFezHHnil_Ji8sgmi9vUKl0Sa3-HmR7x1u0F7FregTM34FgqHN6rbouVqvMOT1_wIY-V7Ee18tGwdfEHCgkMMfsH-8jKDlkFw=w1920-h1080',
	'https://lh3.googleusercontent.com/YVlegWlBzwFU9BQDbHnIg1UWtVYYl4CHF7QPYwoRx5YUyITmbT_qOLH27vOMrjTQ0esexld3cmCJYsyg-8JyNJk4mXtZ6E36cT9YbqqG5don-Qx6SL61lSQEzFkpo8JJefhgiVQ1Dw=w1920-h1080',
	'https://lh3.googleusercontent.com/znR4rMRhFB070yVjXRa3rSTr6GbzhZsaoURynsbnOP3uk00wNRirr22UHZZGEVALJUuqi7dM3Zslylt5NyaYTHhWj5Pc_h_rh5g9WBT4wpJo1Xk_WQpBCHB1HnenldrQ4BO-PLHpLg=w1920-h1080',
	'https://lh3.googleusercontent.com/XECE5H3PSgwIjIhJXFREeKwrJYHCdNcDR871bryPnl23EgknZidoIt8nsppL2mtfZK-3CPLO4JJ_O28G1ufUG-TvvhySXvQ5PN4wH1x46YEnmsLbTjHjmbJQ4UnHAYgvW3ghtesV5g=w1920-h1080',
	'https://lh3.googleusercontent.com/zHvMsL4W1bBre2TBj_9svZ9BOfga6Rn8kjuxX2oFW9puv-WTR29Q8CDE2ZD9UnSvngPg5iZzrdJNesqizPe7PNNZguZSMMpBYnrcwYCqDaKFhTO5IF9uTIzZuZr9xymROpVsZVqmpw=w1920-h1080',
	'https://lh3.googleusercontent.com/383cH6_aR8Ka4w6lG8tU9cilgHrXK9WFndXI4wgYAXGGOo6AeqYbTrFYdSHEzmru135P1L0M9Y7v8yaj38_L1kbQq8nxYEPeNd_7lF6Hec10jJqnK_zCMxSik9XV9dN7F5wNpcY6bQ=w1920-h1080',
	'https://lh3.googleusercontent.com/c4WODCIf3mXjnfWwhnDpoMZs3TmQGN8YzYCWROQpsOjp4x6MwR_hcLxdGYv1uwncqDu5DLsM4NAlJmmc09rUSUeXQntIzK4ZF89Ymwf0GXok4O0K9NjhUdcWgYj989mp6OJx7Jv5xw=w1920-h1080',
	'https://lh3.googleusercontent.com/FoqVYMjfXCNrcTctS-uhY_23n-4pvV7O0AM3aMKpnlWl2YDvcREFbMGKwnWWsvTLGcKKtg8UXxRh52s2vmYvelmCswarYSQstC1_wftqhy7laOy2pzVzjwzL8kXMvJ9v-CRHruCeuA=w1920-h1080',
	'https://lh3.googleusercontent.com/WsxGTYyng-qG5FEWWrodCXsDCBbzL_zJUaaBS7LYhn95oQR261YjBr6nFSB4xcfZTepoXw06R9tOcqt8OZo2-GymquwxiQcwqGYbf86Ds-K7g9GIF4Hs9pJbukjL3XlQktNnhjDqBw=w1920-h1080',
	'https://lh3.googleusercontent.com/XPrDd0RcGmNaux54TuJsPLyt0v6I9m__20Is1zQ-Yxxu68XlOaI2VlYRen5lYJgCB9HHSzxTuBKxyaw8FlAkbyWebGm78hF6kpUz4cAxYFLmHBS_kSGsmfNRqVvM95QGyZ0HntQ_hA=w1920-h1080',
	'https://lh3.googleusercontent.com/8zu978ezY182ArkqZX-6IPLsQQbAdOQkca1UO6BD3TTXlxUl3zD_JWokonrCOqysTiPU_ZR2FddutU-fL_wIFG1KOwGuHkp_F0NmHZEke02w_gfzf8XR8ldkx1ltO1jK04ynEow8Og=w1920-h1080',
	'https://lh3.googleusercontent.com/tl0hmwcadoWBAE1gEjA2r35ocT71aG21-GcbZbc5rlCb6gqq6rbYk_qz3sGN_IqfcHAftF6vWIrZqIYw0hMbll3miSIHJDLe09iQ0IKquwZCt7J08PTGzAL9zzrA7DdRcLAGOGW_3Q=w1920-h1080',
	'https://lh3.googleusercontent.com/zI0LRp8U_j5R_WeYnnQ-0eyvNAo0BiY3vL0wm-3zZcNljZvWuzu2j8rDWvZJNe7RR7FBRb61QGnTJ17POWLqm6sicw1YHouLMp7FOWprXP4IkhWS_mEcd4u_3a8DnB95y67eknVgXw=w1920-h1080',
	'https://lh3.googleusercontent.com/KLx_Ib6rLX9JDM91pZTOM-qIwwKrhroU736dnprywsmhWpwVAg7H8k27M0nogYcdN3dtq2pbYYoE61uYsbA9auAl7Ovs_vEZ9SWrGGwDFbngdGrNjGxJSpzWoHlpiQhknx1DNRPFkA=w1920-h1080',
	'https://lh3.googleusercontent.com/x1SKr_0mvI28BOMXq5VXcnjNC7ckUZHuhz8of2dv0HeWeT51TfSHC5C9CJG9KSIIX4Vc7jfk_2e-0HBnWa3ka-q-7Tw-pZaiDxehcCwhdnbFnDViC9rEEQcYgA3ujKxCVAsryJZY8Q=w1920-h1080',
	'https://lh3.googleusercontent.com/JcOwlQx1bhSTjSd1z_5p-XJuuYiZnpoAXcbe5Ltzm1RkflouzX14gH-XEAje0O_JJDCUlJdd26vzDCGghxz3RqkZJKY786Dch4cEibleHXFJ7kzKCv9_VWkQVsuLb5U1gnqHIjECXQ=w1920-h1080',
	'https://lh3.googleusercontent.com/TNVeFkOl-mN9OEtltTXRnNhOLPbaxL5oReW84I91EIjVY8O0xNNYtju3m4n8N5IMGqPdq2TI1EQtw_HhG4I3lhlDC80MhnBRNTfeWeSOz0sGG0a5ywbX5dwxU-EFJHl_kADuKsh8eg=w1920-h1080',
	'https://lh3.googleusercontent.com/kxsNKcRF63NbcdOheTsiZN3SpUH63tq21-IQaNQ5afCYSRkMOcsMwiLu3WFKrBi07_uM1kFcdQk89Dv_4KLyvytI-Nf9BZ9DA62n-kwe7sQGP1zXAOE_rvug7UqzbcBCNlzzIptkmA=w1920-h1080',
	'https://lh3.googleusercontent.com/QslYcpMKUlJ43S8eHWDhqNZpy-vrJyyne66oLGDT54Dg82cAluwO4GIyScX7ac890OaJJP5Fw2CPduiD65cz0O4KZTzFxl-ECAIHCLwCiwbVaD5vYwmrz9J_mHijFlrAnNo4rmbSRw=w1920-h1080',
	'https://lh3.googleusercontent.com/a4BvnpQC4r5hauYj4GHkKSpiWICuqR4v3mYMjvoZAbXGkQ35pkdi8nXGDEuBntPs3s0yZxFd1NdimQf6L1d_cwLTUFrHQtbEO0sSwuIWnu8PnpaBJkhof4eZ_A9K2i9lN5S1JOXJXg=w1920-h1080',
	'https://lh3.googleusercontent.com/hTHfvr7DINb3jKYZ_GA6oBf_AwOYzOWzaP4bErcooWbUnJIbvERIVfcB8xbFQJFtAFHQNY6R2Dz1X1Rvl1SyKE1d27LS5IBcRXP1ur7bqghR2jPyS_FA1PY1a4pNgWP7GY1CNZPQpA=w1920-h1080',
	'https://lh3.googleusercontent.com/Bdla8QXd75yHIe-wv4sZYdiwT15m-gfejsfgVTWjMA0V3_omH-FxRYdZVdFNawTXAXnX6qJ6oWxjpoeLYcpzbYfoJcFuvYisoALWF3ncrmUGxfLiR9xeIuHGY3k4GOwrjiEsfG-1-A=w1920-h1080',
	'https://lh3.googleusercontent.com/SI4yh-LF1eSvMVFN22rhNPd25uE7bdoa5AK9w5v3ZY1KIQqdHbIVO9vjEcWZTgzMqpUN5r939gN1d6orq6-zzpNLDI-40dCkytAHQdxSZsR0mu6KLPHtL9qGQY-nyMnaPo5cc8cosg=w1920-h1080',
	'https://lh3.googleusercontent.com/xntplOa-817w0wL9oL2uWLXPKA72XE9q0VvfjBFFVN0xgPy-OQBKm5IPuc7T0-djFfqgzkUMUAbUvWFmjoYGcuvnhL8YygirZWvOcXhb6HXAumavoYo_uqX9pbOU77zDrme8UkXpDw=w1920-h1080',
	'https://lh3.googleusercontent.com/3FxdnxHe0-HxXRh9-pECz2to0Trw5YdyevTl1w8aYnWCSuJG8AO3aGBIt_IrXRWeQgigzjB9FTBT2WtKmIeXihUZcznf0e1X0HoTUQClzqBjeaVDffY1lOacPUg41IQmUxkel6oACw=w1920-h1080',
	'https://lh3.googleusercontent.com/YgnGKbcUv9jCHb3RlNMH8vH7zUDuRbycEo2r7bzN5o44s0kdhS4yKzfQkryVJrW2tzHfA5hrC-D7xBfw5ADfXIY1XXahvuFQGkaSts9LR3q6AsXuafnt7Doc31ZK5vfUYYdJQgBqtQ=w1920-h1080',
	'https://lh3.googleusercontent.com/pa-G-qt3u6G6mPRE8Dpxdqw5y3-73hk5GFnHw2seP-5EiMXfNvUspQL56sLs5dxfEvqWHV8rRTjt_jZwGru51UhDfOlUPUGBPYISbv3Yc2Yb1VWmuoYch5ABmZ3kyykXnNWQRORNaw=w1920-h1080',
	'https://lh3.googleusercontent.com/szra0kLutmg0Sk_velvX6N2_4xiaefyHZsfhPISGqnsta5aKfekuLnsc6F1sfpRdgwAAKVBtkvsDPMtBOJeGntUIl_JisZcNwJS9cXJi-vvbfiLpM7S_L2dLW4PMmEr9EeQB2mMuDA=w1920-h1080',
	'https://lh3.googleusercontent.com/uWZ_ioTBN1_RrDmlPuEJE8OzKfbGV9H8Xla23HYYZJkFR8XWHaWiOMT9DNWL0S6vkhVxI2hmFII2LvCEpDSoSAa6_oMVxI0E1GdLWIW9GKKd-Az5jFaZ-IlyeMwGCXNSAW2IeLD5QQ=w1920-h1080',
	'https://lh3.googleusercontent.com/9qiJS7JFCAJrAfqY1_Sxu8hJKtLcxv__F1dXqUOO6PQECfWjhwlpWmMXXOno7ENxW6FK0HqJfMELzT2tV24Kl8zAsuQihZuBjQdDGImRw6rQpkMVbtUsT4GhO7iajSBp7oNjBOMVvw=w1920-h1080',
	'https://lh3.googleusercontent.com/C1JrDAepgxnXLaleDaKv3kUrDCa554_TOr5r_L7JWB4pcuPfg4WzL3g8MfFind_-frsivlHcgWSNVnTKvZ5qFQ0kLELLE7PYrxUrOCOutnilbEovsOKzbhZz5hLYN0T8NIzvOl5fYg=w1920-h1080',
	'https://lh3.googleusercontent.com/NX736wGbCxcEVzFoSN9HTVTPdbT7Q5nlLy5kUzAuuakpJz8m-dXx_QjgxinLAzbBr2Jde05ADzDjnNeUs4L2tRbJpdbFlegroxiVmcnXwtKnrVKnsxaCTgHFMhCcF4vqKCqsdcir7w=w1920-h1080',
	'https://lh3.googleusercontent.com/nH5fqd3Da2cW6GAHA3MqX3dspV6jyMHampIaegeFDiMeqgA9iLoT8l7F1JGi5uaUcHGjgb3mBea0OsdCXD-DUukp9pxm04snP-a_NlxIPmQmrh4D1d94D-Dzq9uBo2BjI1j4kt8H5g=w1920-h1080',
	'https://lh3.googleusercontent.com/7y_yKk_LbaJaQpqKBIvBDXTUSdjaPtD5PboSynF_G5GNr4KOkpGg28WGgDJH2iuYeEipM5sCs1j71NLICmn2VNyKKLYGRijs43hNyOoO-hCfROL_ACPC3uhQrke3offK_NC-IZjCvQ=w1920-h1080',
	'https://lh3.googleusercontent.com/qRB8O4n32ChDrI5_VVjbW4SwU8Cp8LqbiEOteBHkOK3AoZHVTDzbI-oHqqYQ-Ab5D1TpHHwKaMBmElBUARGGrGQT13cxMlu1BD7uLC2E8FnRZUmlhdww84eZ_KJGuSqm0o0ZzEygsQ=w1920-h1080',
	'https://lh3.googleusercontent.com/LPhxaC_XZSOdStzYYMrS7ASnDoaLlSKgH2foQJ1L_glAW-BLgGmlSLYx-845-uliqEamkdLvXF8XW0OebF4l_EgSRCJd2jwMAMrxTunGM9D7ak3h0pqAH4Ja0icD_0v991By-Vr6Hw=w1920-h1080',
	'https://lh3.googleusercontent.com/yKKi5e-pD2CWJDU5wzICiohJjQmEShATDPw5UdR1suTLRp5q5bXXSLtZdame0L9ALfPPkrpC_323s3-ESOSI8NNhCZm_ZAql1vEdQRANJPkwXHlO9fmVaZW7Ig2ILx9JN5KhTJio-w=w1920-h1080',
	'https://lh3.googleusercontent.com/9Vm4gYrxNbmzyNAn0GF-unAbsHXyzryJ6jUiX3NoirTnArHBc1HyY3sv1Ycs-AVkm_uKoO5EbCfErQpPtuje1Vc22VdBT1NIqbK0nc5Xzew1sh2NeNF1uZWhGaghlG_XxjAlYdqXwg=w1920-h1080',
	'https://lh3.googleusercontent.com/mkBlGoHHrTtFD-XokV0KFkt6d4WUu9mS_wAK2rSmD30HwLzrtQiqA8hYiWK1cSe3ItGN6q5vMwqcbYufxip6JvFfKOAqqFGCoXXlGBkqblLhY1BsYgfYh_xS7X6_1_3r4hdVGNrgEg=w1920-h1080',
	'https://lh3.googleusercontent.com/SSz9P2i9gexo-TMaPJWS50hngWmicNy7YfKeJQM9cFEqwya5j3Aq8ku0TLUM7Fiut4if0eiFKhJJP2hGUuUW5NOFu1tG-tbvdTqiWF4DNAmHE4Ah-Y-sxWItbt7VrQriwDFgW4Lvpw=w1920-h1080',
	'https://lh3.googleusercontent.com/L01KLWr7sILBTIexQnvKUaZWxSR_GcUV_eWKufc3H400d3IIdJ6fNV_ifhS07FxDd73Jh8K2Wb07HypMYmtdMIh8Hwy7AYXlds5SqNsaLCWVlyZ7AMmdZFuvKG0Xc4Dl0zUaOFqBfA=w1920-h1080',
	'https://lh3.googleusercontent.com/kWuNeIhITKeFrRhFEHmXqk6P_-_8sAdnEPwKtQjl09C3oLoXGRpJw-T9NSS3Kf3f6lJzwvrIW2E1Xfff2YyaahriItvD1nuRvPhvzEVPTGUCSponlE4U0Y-ay7MSlaWVQVek-P0p2Q=w1920-h1080',
	'https://lh3.googleusercontent.com/0RoQNlo7vyRGM1HJHXPOuJMf3gyxWOIFWJkLtLhOIbN7xT9sS5SUiSrEFyAukShjTxI3iqBpBzJxcJXt8fpJWtVOaym_D7Hkr0OzdO3cJE_hGIF-w0KqGQb5o3wadzRPiqUzWnRczA=w1920-h1080',
	'https://lh3.googleusercontent.com/voBkkMlUd3NzJaH7evqSFIa1vdR-r1zDdj5YJIl-AwDYssEUfbYG6cVvY3kFDxfVkjMDq841fKH6-wICQa-_qJZH1m67tehuhJUq1oyuhEjV_RTAbhh8l7qqrPKfve1j02-4hbxxQg=w1920-h1080',
	'https://lh3.googleusercontent.com/CHP9ZRdivEBwAV9JTrSCACShIiimvnpOP6eLnknmM3X5ml2WjYiUfVwdOQ_FemPlx65Hb7soy2Y7fOIZxCmJX3U6XZRxHev7_LkB3TbxKpEM1ZrJ5AdtoS1dShjTdlGAB1vTHDGbag=w1920-h1080',
	'https://lh3.googleusercontent.com/QUpsUGinkYYwUpXWr-BsU5ZUusf4xDo1BIiUWJ-HP0KvTNHbbAmj5YFYsfyqOUiW1bX_6lE6wcz1UjIHBPUdKPut5P1ZAfAMKSG4Q5GJKrSDzT5ToIgzi5xAvM_azTXIEVGuXasbkA=w1920-h1080',
	'https://lh3.googleusercontent.com/fy6mTYuatbUJm921TDJhNXvFp0GS5sHdTI9Hk7nNSJ1dJM0acJHCImWQdXVNReF96QAfx-ErrhY2GS78iqejsBq88mjBHHA3kz9WttNqUJFzoIqZvYutaAaaBU86UkEEq5nvnsv-Pw=w1920-h1080',
	'https://lh3.googleusercontent.com/C1XZnPiQwuro2dXY-ucoQ0tghtESElzrF2L59zmNYYcOov8YqB05sPu5bCqOLF9ByxfFREe6OUhwEi312yodW1X8K-NPZeVowbQ61SSCdoZr7W-sZjASBhS57MR8kj9z7QYaPvCHDg=w1920-h1080',
	'https://lh3.googleusercontent.com/CuQx2xcPA-hBAYV9D-gRlz-cya88if6108UbU9TnMQ16IuHEeRYglvgHL1aMgI1BQyq6KPctCo0LdHRVsq6muBfpCOJkkg0lURkTsOW4OlgTCYM5owv4cVevRECZG35uKZpV39qsgg=w1920-h1080',
	'https://lh3.googleusercontent.com/4vfM5qhWQmYPjirI1pmpuZ-6WDvFg8pHTgpEP1E_tD0EpyU0RrUAzEF0xUsf6cfCN4UiHFkTYJmYG_gfO0ebrUW5HwMDZUAHGZVN2yz9uyVrquI5ZS1M64QvoAfW4r9IpJ79VCbfqg=w1920-h1080',
	'https://lh3.googleusercontent.com/AtiFdLwZfev9P7Pa0nGkAcaL648gYQPv-lwtoE8sqPP-A8dwxBZ6PjFu0jxgdZ6GhcqgvAao23YVKjj3omaZEDAGWKYYlQ7nf8eDzUC0YnRDIjYXPB_RBGqQfjjbTdKs2BlMLaYt7w=w1920-h1080',
	'https://lh3.googleusercontent.com/IwlRz6k6V_-ST9z_iIUtW3iF37U_Tblf30kyJsJUxVC-0BXIVBRbVsQM0eFdEqFE06w5KRMFp1RYGIESt63ilbAEQTXJyrrYpfgoQz_xD1XpazgI-Dz0LNOR0aL4DyTbCJlQQbz5Tw=w1920-h1080',
	'https://lh3.googleusercontent.com/Jq2GefbGjbkY7wa3qRIsHfddUVHq8Uexq_N8oInvBbGX4YrFUtCutR6zCiSyNtdcmRGyg_XJZ-rXl7Y8XTkDhXwqS1vPfj4An14Mq0w-Yd66QN33XoLhFtsqCbo_kMiZr_XJo-hnCA=w1920-h1080',
	'https://lh3.googleusercontent.com/50MjdJjfbUhjKNmjj8ZP73puDG8kiY6deZMlcfALm58YB2rdo7pe3Txw7D7JRLqG1G7OFwEBGhx5xtP3g5pFVXYRmKEyOWNomzbLEzRhWeWI4u4oXsu0HEI-h_2oWY1k1s9BmgSvCg=w1920-h1080',
	'https://lh3.googleusercontent.com/6WbZhdeTw0-V_86f8Sw6wzA_BrMzq_ahYlwxWAiqRq3WXILy5Evn49ia9WDN4_9q45zFEh33cD6Xd0QrNMQu6wLKGM_oB6FYsARuz_DKw90jH2Y_ePbFe4hi59toVs2eeWnEK4EZtw=w1920-h1080',
	'https://lh3.googleusercontent.com/3U4NJwRxjOfrKT4lWxZwAlsKAdrzkf-_0cdsb5XqAbrcmHCclfyXi_VO76lePrGRhpQ_GKVLztXPxgfdp4__ihaFqZPjQZq9NESfhQIyVe3koxSWCy6t_zawLVinwdvW5-UyEY8DFQ=w1920-h1080',
	'https://lh3.googleusercontent.com/Q-bOy6MTRh128UZj8HH5IulSsC7NiFzUL2k5gXajIV2ewwiUUR9mg-LwAngaHRpMXa1zTopTIz__g17lEDcZ1XoS5f3PbZ1VZpmNVPldP5GlpCD4XTvAM6A0ufP3DsXxPyU2E0yatQ=w1920-h1080',
	'https://lh3.googleusercontent.com/Dan9RY-kxTqPKEmqkY0xsvJOvi0QBlLOl7cNwU-8jMd6JWZ8BSDMH2sDac6ufBtqz7LOkcSVen0KcbecZh4Ra0BlMgvloGBwNY3S_2ixx_Y4vs8oKa8TAtwHECqQI-HIrXoK645Nyw=w1920-h1080',
	'https://lh3.googleusercontent.com/uwOBVrrB2LJNShU9kvfkGCHVwzKlEjN3z9AioX4rDUwZxcs_Jz6w5ablum38b54Iyo9zqScDv0zI2Wc04PqwGWJWTwz_AzUFNqDliGb6arLqGCA_1KD9cCSMkt7taFbgr_JpovrWiA=w1920-h1080',
	'https://lh3.googleusercontent.com/aUZwKY8RmT-UQJFV5T_1ZaLJjf4FOZPg5F7jmN109xtPdZUTnB7me12LXe1lHCsGci_U-Z0WHHHhh4vjRL1t0iBFdvO3IsIHbOXsLc83VIgr-ax7fKlNzyyMmsd_s-PjouIrq4R7EQ=w1920-h1080',
	'https://lh3.googleusercontent.com/UIElTqlCFEq_cVZpcCAOZM8eMieqAajMvzCEKJFWetQGvY1l2DcG1ojuEZDJUsQhpUFl98x6r0AaNLAfILoMA3DrqjP4T5v5TStS5I2J7QGV1szrltRcOYaRCnM0rE0DrV0gKyv2ow=w1920-h1080',
	'https://lh3.googleusercontent.com/A8psnzY05S_ibFLFDb8bteySMI8hq_aFRhR9NcIswQVbPQMlRCZ6-hxxOWGMd7maB54BUOgUwcQ8niB1YGSph3pSwzAq78Kd8kv5z8-xwfKYK7hYkAS_piEabJXuEHaYrnz6LAclbw=w1920-h1080',
	'https://lh3.googleusercontent.com/xUGa0x3nsTSO6pvrb7wCy47bWkbdaOpJTBfZMe5ORUnX6hpKRcEdo_ioaClhpPRYi6kwQXfFAoUQWejqdy3nRzSTN_-V71DIDvOGUTeNggZ-VEgZXRc72bacJdIRp3eSOQz1EZQ2Ew=w1920-h1080',
	'https://lh3.googleusercontent.com/Ivc4VVNz7AvqO-MAiBJ8ieIQcvUeI_XNsiWHqOMJ_r33ULVV9QX59NOuqZ-pTUslTiKWznFs2kPzTrEDwxIbOycleju_Ehkm_KQvyVsVVLuS_gGPrqt5mDGni643raswnKhW3QQCjw=w1920-h1080',
	'https://lh3.googleusercontent.com/Ld8cQEQ2HiRESdQRPAo4NfFjCXNEHIiumZffjCgnvk-k2JH5ptYzTjH3LeP8TKDXIbii_EHWp4OJzdgP9D9wIB4n9zh9g1pOLluFYKBrCHuZTF7qoHoL6asa0SjTk-8vtlt4608j3Q=w1920-h1080',
	'https://lh3.googleusercontent.com/S0u34RsJIJjd1LbggUve4SnKP-neY-KYaYUCbK56QKiQO48jvwTzgx9dsfYGN6jaP6BPHTCC5UjE45-ITs5K0pGddZnTFaxZKWFuuYiHEKpsAbSQO1_NzXzXeNmhPTKtL4CZt8MZTA=w1920-h1080',
	'https://lh3.googleusercontent.com/Qtuu_XTN0ozcc8rgYdoZvBW6gWM-Z_ljB252LncJxro12uNtD8ft7c3Zyk8KzDmTQpOtB8qK7AvhEOCwWkbPpjbjqtKIg_TH0Y9ourWk8H7t9bh3EutY_jDzjKA6yiZd_rKob5J-Ew=w1920-h1080',
	'https://lh3.googleusercontent.com/sFnj6n_Ej-LWHqLKFAmJ_HFTcky8Nw6lcmvTQQBPqRKGASx4FZpL7TTtrAWw8PRfUuf0wSqYQGaWgoG0YVA4F9rAz_YNj3td1rkiP0ByXULBiQMdUuil8-pzw5l_8b9hZ6LkWnbrYA=w1920-h1080',
	'https://lh3.googleusercontent.com/-iTFWw_NTjQoO7wsEXl-VaSCdCfG01EoWpptmC1W3GqftnYadNXiJJpo29IzkPYVRIjYquN5IesdKLNmE_j34lfS62_J6AnIP0j-LRjpa-7ZRaBgBovsGGncPHwtVvo2lkaLyPfoKw=w1920-h1080',
	'https://lh3.googleusercontent.com/3dAEfO1pBeUY4d86rfR3kYu7rq3_juE3YKO_ohxc3n_siI2Y0cJmTOUlE0SRIjnaUi-h4CXhZqu-_TzG4IJGSXuGTPV_evir8LovQctZRq3YuGJ8CPxwH6P7ynb5Em-TIiGMNaylVg=w1920-h1080',
	'https://lh3.googleusercontent.com/wYdY2imrrMx3Biec2BHlWdImSvtQTiMcpcleBNw9DXGWaPIZHJkDLUUbNyVj5ZuYkhbSkuWORzou7zGt0pxROJSaT2Z-0nBtW0uGp9gDkDiBrvKX4ZcsFlvStwd278wnZJIrRfvaxA=w1920-h1080',
	'https://lh3.googleusercontent.com/Yk2AjWs1z720XTXkcmcYdR573legRvihgcwSg6bHZvD8CwzoefeR4Jbox6YbwDab_QAuAONEkX7saBCNUHtPEuUKLnoAzbgtWnr9RhInyINce0eEEETXmjUwL_OzVQRqJk9LqAjFyw=w1920-h1080',
	'https://lh3.googleusercontent.com/cWcyBTLxB5x_2hvSMaujhEjh3bMXVkFDakNLtl0d2fyQm0P6emUJLhqgmwVgSP34twcradcrLIyZ8PonD3qrnDHDpP9FBED24RGoR0NWkTQZimXbRmuLZRJBj5kb9cpu9NeJi6RepA=w1920-h1080',
	'https://lh3.googleusercontent.com/yWzQqc3x_H-7erh8ViLAg0hC65ubM4MsSeQ7MnrDJVLwuDdj1fuo7M0iFdcwgIcsADuqpkF1Q2d4tDELgF2YrVUJoHIVJijf6q4zaV5jvxGTF20c3uR2YSujNCUTPDxKHD-fIGqyLg=w1920-h1080',
	'https://lh3.googleusercontent.com/d3dObcpGKWM-ckOpquRP0T2RRt-I24AGDNZhOApm0D-6og59w6PLOQp9-JmESOrz1uX597zXLRSLChOkh9gMxWtIsZCRCOxvegvZNunlr6gXefTFxKZxXZJhXAAFLAb89DsyeAwJRg=w1920-h1080',
	'https://lh3.googleusercontent.com/EROHK5eoKCzMgXaHrV8U2vz79tRek72XE0xZwEegU2xsnctUQe8P5j5aTtaMScElzXcBIhZaMAMPtEtGX4VYNhqevKjMY6gztz5FiCqgLTuxekVg6XaUtp7x3pbcEwRwlFv5Fwg84w=w1920-h1080',
	'https://lh3.googleusercontent.com/mppVVYNe_SC_Afg-i9qyHEC76JnDQMnVasf70pBercqbDT15NK8IefdgNAfTtnOfiMOnJhHS6KU0BNVIpqzXycppCfOxyV0JLuHnZNPPbxLDIN6WxEEYQD_dfH-G9JsWk9-AO-Wh1w=w1920-h1080',
	'https://lh3.googleusercontent.com/4brzh6NbxyDecUxcSU0AKLE5J7Q-yrJ-ttmM7vt5WOTxl9sgdYXlqaTW1UIVtZRhb-kgHfJqP8HHE50BSienaOqoEH_n0Gn-njCvLGrhG7g_sTo-XwrqSHkdN6rPwl1gJl8B47PkGQ=w1920-h1080',
	'https://lh3.googleusercontent.com/N2FEGSWqCvX89fwpKieQwa3IB-jXf2CRMv6B9himbsFdtg_Wev4r8_8azoss9oZbxfXhNkfS5Y_MTIF4IGZEtsywrQssmcxpHClrFOi6xQcxX0cD2h04CYhgU09F9ttKzFY2bWUnLg=w1920-h1080',
]

export default function TwelvethGraduationShowcaseOfICATDesignMediaCollegeBangalore() {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = "https://cdn.jsdelivr.net/npm/publicalbum@latest/embed-ui.min.js";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		}
	}, []);

	return (
		<div className='content' style={{ paddingTop: '74px' }}>
			<div className='container-fluid sectin float-none'>
				<div className='news-lt'>
					<div className="pa-gallery-player-widget wdth-100p mb-3 hght-480" data-link="https://photos.app.goo.gl/1EjdGF54n6KK7n7U7" data-title="12th Graduation Showcase of ICAT Design & Media College, Bangalore" data-description="44 new photos added to shared album">
						{EMList.map((iMg, index) => {
							return (
								<object key={index} data={iMg} aria-label="12th Graduation Showcase of ICAT Design & Media College, Bangalore"></object>
							)
						})}
					</div>
					<h2 className="h4 mb-3">12th Graduation Showcase of ICAT Design & Media College, Bangalore</h2>
					<p className='mb-2'>ICAT Design & Media College organized the “12th GRADUATE SHOWCASE” at Mantri Square Mall, Malleshwaram on 4th June 2022. The showcase aimed to provide a platform for the final year students to display their creative works to dignitaries, industry people, peers and public. The event was inaugurated by Chief guest - Mr. Shri Arjun Odeyar (Assistant Commissioner Revenue, KAS GM-Karnataka Innovation & Technology Society Skilling section of Government of Karnataka) and our honorable founder & CEO of ICAT and IMAGE groups - Mr. K Kumar. Post lamp lighting the dignitaries walked through the stalls which displayed the students’ works. They were extremely gracious in spending time and interacting with the students about their projects at a great depth, providing them with critique that would garner enough inspiration for their careers that lie ahead of them. Both the dignitaries acknowledged the efforts of all the students and addressed the public with whole heart.</p>
					<p>The noon session was filled with fun activities like on spot games for the public. Token of appreciation was given for the people winners of the game activities. Simultaneously several industry people gathered and showed interest in the students’ works. As an extension of “go green” gratitude, the industry people were gifted with plant pots. The evening was opened with the most high spirited, live band performance by the talented students followed with western dance & solo live music performance. The chief guests for the evening were Mr. Vishwajit Narayan (product head - Reliance Retail), Ms. Ashvithi Shetty & Ms. Adhvithi Shetty (Actress, Kannada Film Industry). The further event was filled with glamour and thoughtful concepts in the form of fashion show which was based on elements of nature theme.  Recognizing the yearlong effort of the students who put their heart and soul in the respective projects, Mr. K Kumar in the pursuit of value-based education, acknowledged one student from each of the exhibiting departments, whose project had stood a notch above their peers and presented a special commendation of excellence, a trophy, as a mark of respect and testament to its timeless beauty and strength. Alongside the winners of sports event competition were awarded too. Over all the event was a big hit and supported the students’ projects. The Graduate Showcase was a beautiful feather in the cap of our esteemed Institution, and a valuable addition to ICAT's pride memories.</p>
					<div className='news-arrows'>
						<Link to='/14th-graduate-showcase-2022-chennai' className='prev'>New Post</Link>
						<Link to='/student-showcase-2021-chennai' className='next float-end'>Older Post</Link>
					</div>
				</div>
				<div className='news-rt'>
					<ul>
						<NewsList />
					</ul>
				</div>
			</div>
		</div>
	)
}