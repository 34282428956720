import React from 'react';
import { Link } from 'react-router-dom';

import NewsList from "./../../Components/NewsList";

export default function ICATStudentsWonSeveralAwardsAtDelhiCGAwards2016() {

    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 mb-3'><img src="https://imagegroup.in/images/news-events/delhi-cg-awards.jpg" alt="ICAT Students Won Several Awards at Delhi CG Awards 2016" title="ICAT Students Won Several Awards at Delhi CG Awards 2016" className='mxw-300 m-auto' />
                    </div>
                    <h2 className="h4 mb-3">ICAT Students Won Several Awards at Delhi CG Awards 2016</h2>
                    <p className='mb-2'>Delhi CG Awards is a national-level annual awards conducted for students and professionals to encourage the talents in the fields of Visual Effects, Animation and Digital Film Making. The awards bring in various talented students/professionals from across the country to showcase their talents. The 5th Delhi CG Awards conducted in 2016 was sponsored by leading companies like Autodesk, Canon, Animation Express and MACC.</p>
                    <p>ICAT Animation and VFX students participated in the Delhi CG Awards 2016 with much confidence and gave a tough competition to all talents from across the nation. Once again, our students won several awards for their outstanding works. During the event on 11 April, 2016, the students received high appreciations and were awarded Shields and Certificates.</p>
                    <div className='news-arrows'>
                        <Link to='/icreas-2017-montfort-school' className='prev'>New Post</Link>
                        <Link to='/ICAT-students-won-various-prizes-at-kalaavaibhava-cultural-fest-2016' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}