import React from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import NewsList from "./../../Components/NewsList";

const EMList = [
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/1.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/2.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/3.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/4.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/5.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/6.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/7.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/8.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/9.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/10.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/11.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/12.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/13.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/14.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/15.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/16.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/17.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/18.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/19.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/20.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/21.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/22.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/23.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/24.jpg',
    'https://imagegroup.in/images/news-events/icreas-2016-st-micheal-academy/25.jpg',
]

export default function ICREAS16StMichealsAcademy() {
    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };

    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 mb-3'>
                        <Slider className="news-slider" {...settings}>
                            {EMList.map((iMG, index) => {
                                return (
                                    <div key={index}><img src={iMG} alt="ICREAS 16 - St. Micheal's Academy" /></div>
                                )
                            })}
                        </Slider>
                    </div>
                    <h2 className="h4 mb-3">ICREAS 16 - St. Micheal's Academy</h2>

                    <p>ICREAS-'16 was a live extravaganza showcasing the one year long creative effort of IMAGEMINDS at St. Micheal's Academy, Chennai. The Art Expo by the students and staff at IMAGEMINDS - SMA was conducted on the 12th of March,2016. Inaugurated by the Vice Principal, Bro. Pasca along with the Coordinators, the expo was witnessed by a huge crowd which included the parent and student community.</p>
                    <div className='news-arrows'>
                        <Link to='/inauguration-at-st-joseph-college-2016' className='prev'>New Post</Link>
                        <Link to='/launch-creative-career-program-2015-bangalore' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}