import React from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import NewsList from "./../../Components/NewsList";

const EMList = [
    'https://imagegroup.in/images/news-events/inauguration-at-st-joseph-college-2016/1.jpg',
    'https://imagegroup.in/images/news-events/inauguration-at-st-joseph-college-2016/2.jpg',
    'https://imagegroup.in/images/news-events/inauguration-at-st-joseph-college-2016/3.jpg',
    'https://imagegroup.in/images/news-events/inauguration-at-st-joseph-college-2016/4.jpg',
    'https://imagegroup.in/images/news-events/inauguration-at-st-joseph-college-2016/5.jpg',
    'https://imagegroup.in/images/news-events/inauguration-at-st-joseph-college-2016/6.jpg',
    'https://imagegroup.in/images/news-events/inauguration-at-st-joseph-college-2016/7.jpg',
    'https://imagegroup.in/images/news-events/inauguration-at-st-joseph-college-2016/8.jpg',
    'https://imagegroup.in/images/news-events/inauguration-at-st-joseph-college-2016/9.jpg',
    'https://imagegroup.in/images/news-events/inauguration-at-st-joseph-college-2016/10.jpg',
    'https://imagegroup.in/images/news-events/inauguration-at-st-joseph-college-2016/11.jpg',
]

export default function InaugurationStJosephsCollege() {
    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };

    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 mb-3'>
                        <Slider className="news-slider" {...settings}>
                            {EMList.map((iMG, index) => {
                                return (
                                    <div key={index}><img src={iMG} alt="Inauguration @ St. Joseph's College" /></div>
                                )
                            })}
                        </Slider>
                    </div>
                    <h2 className="h4 mb-3">Inauguration @ St. Joseph's College</h2>
                    <p>Inauguration of Imageminds Creative Labs at St. Joseph's College, Coonoor on 24.06.2016 Inaugurated by Mr. C. Sridhar IPS (Inspector General of Police, West Zone, Coimbatore). Guest of Honour : Mr. K Kumar ( Founder & Managing Director, Image Infotainment Ltd.) Presided by Bro. Gubendiran (Vice President & Community Leader)In the presence of Principal Bro. Ramesh Amalanathan, Management, Staff, Parents and Students.</p>
                    <div className='news-arrows'>
                        <Link to='/ICAT-students-won-various-prizes-at-kalaavaibhava-cultural-fest-2016' className='prev'>New Post</Link>
                        <Link to='/icreas-2016-st-micheal-academy' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}
