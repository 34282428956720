import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import NewsList from "./../../Components/NewsList";

const EMList = [
	'https://lh3.googleusercontent.com/1ambv7zagABB-n0lujqA27HSx_tcdFvFpfQQf4I8oyLkQMlvNyTyJdsukeE795Xp3FuKHIMkcd4uCc1vMNyJ_2rVr60hGpjBLLzma9eAuiYvMSKXwb7I0qsAE9pC0GtQ9H1LUpiP2Q=w1920-h1080',
	'https://lh3.googleusercontent.com/IM9bodgRPTXMSocUcu8m6e3FGJcES23qpiH503Xy7Lca15nL0aZlaLKwHf5Gdbtapg-bZY-dkzHQtDVLOspLNvwBPOZoVMWb2OMwXEhB83OoBDhGbaMGQxW14QdVmvWKLV08hu97-A=w1920-h1080',
	'https://lh3.googleusercontent.com/hn1C8YzK7ADb4Q-Gj2I03Ha0y1SVehLA6_QTpthPwN0RZWGQqsS64krPXvPY-RN3dYVxozPYlGeLC0ua5iYwkPlh-W7HwbODHWWlQouHEp70MG7_wPm1yQ7mohRh2MxpQgGE9az4bw=w1920-h1080',
	'https://lh3.googleusercontent.com/chD7KSgspGLOrc92IZUfxN3lEEo88ZbmCp1-xzdpVaRq7_QnHGzVzTRjoKSx9pKciwkItKkhT-dGhhpu-VFCJ8gk9Gy38FxQoT97NKBu7_mVeLl5vb2WdL24Wh8wVwushQccJEqGyg=w1920-h1080',
	'https://lh3.googleusercontent.com/ND9Ymj0IbzWUr5-rr-DfzlKzUn-GOIQH8n78rWjqpt4JxUnxR5pYuyUuzal-1rlyCRSUAsFxxnSkcqhgkWGaM2cqx_xH5-qpRto0mD1Am0Lg0VRr_kYg9YfDgKpOt501UTCHf--2bQ=w1920-h1080',
	'https://lh3.googleusercontent.com/V_ltl4X8qEjWYIQG4TYj2UhTIaF3yuC98SHXeWoJug1F7utgsmWZU3u4sgqTCPtG6Idnm1fiFg10ddLsBkEWzfb6R-QMaFQfRQa20rsKcH7oUVpqb22KuzzUOoYxHVlgr4Eg3nBSpQ=w1920-h1080',
	'https://lh3.googleusercontent.com/SSvMhhaFN65PcyzMDavbYApQ9iIS4b0xweAwYPSxlNOS-Xis_KUf102Du7kyTaODMkWRaJfgS9n8oj_hz73xZbwXjonkwxAwMCfbvmhhC_SoWzoeE-KZTW_mYEo_SG2c771M-kyRqw=w1920-h1080',
	'https://lh3.googleusercontent.com/wc7QWutIbk9kEPwqw5JsAcysUe4wiEhyfCABhOr_7C2A25DhTzqzjaduGSjoM249C6CFColZfNmYOpk8cnUo3F6e_mHoG95oIX4uckvd7_yNaY-7adEOfpA59bGyRF2-rUj4A2gREQ=w1920-h1080',
	'https://lh3.googleusercontent.com/ygA9VKErQMRL3gBGjmJAgEDKJd_WfCbXtASfBTN7JxYlJWJ-VPgHGjHEk1n38UuevVI6-xWMkHxF4EBYPPIW-R6Km-oKenvu4mYvNP5fXa-xRqWXkT2iFHmLGOm3X24SK1_iM2YPQA=w1920-h1080',
	'https://lh3.googleusercontent.com/fmAPWERCwI30WYlSOtVBCyLG75GCoVphP3J3EUD-zhfJQ62i8SW7v7zsDOTQ8xR0omnX92X_GAYgsHMdMboZDJ_mD3A-d6spai5AWEd4HTp5GMwFxT_4wq4kFUrkJ02dcpZobnJ1fA=w1920-h1080',
	'https://lh3.googleusercontent.com/50ZaDs6nAjWWCDY3YlFW8DS9QGzyrPTGKRaLxpz5ArvG8OHUxyQ68JR6x3ZraOBrM47IXa_3s1kZjPbSerquUJVl-A3btczpw5VBgC68ucO05KJe4Exzxf6_A05V_EkmIUDOtdTWzA=w1920-h1080',
	'https://lh3.googleusercontent.com/1s9YYDxMZ-Uk3M2ltQUzX9iZ83s843nFb7GOItDGsp4mRO8pLlv2FbOSoKrdk2pqPESTVXnZhN1tyueo5_5ktsJJzF3xHEDNJA7SVeCdn1dzdZQbTV8waew7TmKIGKrFSUaOxuCJXQ=w1920-h1080',
	'https://lh3.googleusercontent.com/SNcBEoiKGUpiv8138fV-lBVbd2G6ie2pf4-P50UsQzx_nSxTGVz5LZAqWjWdKoDwybU9xgY3vNN2dM9sJGjXwbnQ8_qwXXFTUOZnL1iWt5FwNBDM11REDKHHtqxMTNUa-8WjQgXDOg=w1920-h1080',
	'https://lh3.googleusercontent.com/jugVPfCXVRDMxKlDV0mnaKQH6TyEJZur_9hxCe-oEMp1KlqUZ4ALGoIqBHjRHSrQhHMVPSmvknc1zBe_-v8itd0twylNuIFLh92EMrAtyZ7Ox49ybgde9S73eUnHj3xVukUzjtHSXA=w1920-h1080',
	'https://lh3.googleusercontent.com/fh-fQzzJsJDSORnOoJNl5-aO-cjLDVBFtDXWWRtZkAvYnHPbIRYZI4YZs3ijQu98NwK1nHwoKqcSdihA37W4sbKsaZ9nDq1P61b0AtyB_SQXwbndPGrfyGPXK8VRHr8X4vUCOVcCtA=w1920-h1080',
	'https://lh3.googleusercontent.com/551U4_JllrNjX1ScNcQedHeBB9qQT54ig39E_0GluwbBUyju1dN2mU-mcNPYaL_m0sohC2nRvzlxwqxwjFYqGoBOotLlaK2TCaGNSfSChs5TvHbymlBKgyJ-n7DAAkHLPDwiExSrhA=w1920-h1080',
	'https://lh3.googleusercontent.com/E5uNCM8eCv4tNaHcOJcPTS0rPu4Cy4EOCDuiC2HmaOy4RJ6DPJ6RjZt_nARDDMrX1vOqRygg1oUao0_t_hZ0Ob8saLKoJxnviOEf1GutGaNMs_FRFxwGxeKdbDkxeCOsdNOM9L6HYA=w1920-h1080',
	'https://lh3.googleusercontent.com/jji42ym3Fd3YF3pzQIhPVHNq_Hd8EVOKgq9FYiEez4BnxpkHl7m9qWYkRFPdYmU2SndoxokxGBZHDZDjRBN96SLqK4yr-5Y2TISZsxcTjZvu3lvjx_lhpxqbtjIszqL9QYLILV-LCw=w1920-h1080',
	'https://lh3.googleusercontent.com/DNV7Zku3DFWlHYLwotZbln6f0-_CfKlEbW9WTnrnaY1yOQJEK1JyiLcl48MYjiQN2Ru61NdfEDSy5Ri2yR4JbyDyawg2ntN7SkX7y5wUpg4D57rd6ANMMRbMIuWX41Apmpln1Rg6HQ=w1920-h1080',
	'https://lh3.googleusercontent.com/plT4E3E9UiN2atvJQjfCmu-MO98fqocgWMDvSXBJJ2j15iq0sK4y7bh5B-ooPlkusY_O92GkyY-Cz4eVAzOpL3eLcoC61ftyYp8-sXb1vDxUID_SpNPJ54bXx7d5BXNPaBCCjb5mPg=w1920-h1080',
	'https://lh3.googleusercontent.com/bGRMaKwb3T6nViWnjHjvVWpQiQNB9WIc3Co1SL98OmOdqWvLK4zqo9FS_51zWkgkuMyvgUbYw5GaAiCQj3zI2sk5J7Ca_oLIU5S7zgUN7jXIkUanDmBENJ0NvR0Qo5jq8OqisjWaFw=w1920-h1080',
	'https://lh3.googleusercontent.com/A3hZNd97IkVvGmMwcsnvfRfOr0FLswI5zwMT9yDMoJAYjj2DoogV-xd91MWOUCirrMU9GvJDY0VkkL-XekNL2Q3k5kEcXVau-KoXg4A2twTXqYu0xUOiaOA-RA77VggjBXzSfd4HQw=w1920-h1080',
	'https://lh3.googleusercontent.com/9IvBj9cObOGe7UqkXc8h-63y6pflXL16XywOfKxeCybr6R793ltI4HqvtRAIWFLOGuU9j6Ph-gNS3VLiKovXJ0jp27o1OeBDr182ap1-HmiraaXJQVbSEJUdMMhnl5OGdQnsIDQstw=w1920-h1080',
	'https://lh3.googleusercontent.com/i6skX_EsHgxGvkhQqM8ZE4SFEkDiDRsBjCRMOxOeKRt3hSBT4rgfvS7WBt9TExM3DCQCAjRhLcK6MZ2_hKgazOqyj-SyMrUo9mX6qik5v19XL8A89ImTzn3Bl2rlaSjaAUxbVzHv-g=w1920-h1080',
	'https://lh3.googleusercontent.com/cUgC5RZzE7FhGCynJQtlZbr5j3ZVHRkxC0_T0-mvk0XD7BwoS4cOzCSZSp5aicixIkfwxl3uAF1uwcgh-9EWXEdiyDdtHE40JytWLveUR3cAcQbRBID3XXBxEn5Rzt7_cbRR35d9IA=w1920-h1080',
	'https://lh3.googleusercontent.com/58_Qy0VYD_N4D77UA8t5weqwJSX93BCMC8Tv6JkxH9hcZ0a-PsxC8c8ggb7iwm9WFarnqa2AP6RGcmhOnlmVX5h4TIWl-vvKWpaHSNjfLwGjD1442caexyD9_nYFd6inwuR1ylvQvA=w1920-h1080',
	'https://lh3.googleusercontent.com/JZ4N6vRX9H5Ii8QN4mUdH67UGgIxgx8lYjJe0PKJGbeOlGUmp1oKtzvrsi6nCJ8Ox3EHOjdhT8ClAxXjpKRMjwZnAOQVR3o_MMbqX6ChWwTv-ZI0NME502Cc2qGGX1uy9KIiDI5C1Q=w1920-h1080',
	'https://lh3.googleusercontent.com/xYARQWLBc8nJg2SqmzoQ_z3kLZSa6WDHP8JPKrmOUWbxtjeQQiNvXJEREkn6-gF2RAJLTyQi8QxVG_UrzO530home_GkB_8mFPgeFOnArKypIz--W7W7JXD9K9wem46VEOPk0_CjVg=w1920-h1080',
	'https://lh3.googleusercontent.com/1gCQtSoYSiVy7dlm-YjLe3boL6dJem2debd5tbTtrUGuZjBGXwkJJmOIO_cXjSXfpzSvhWCGPY2JVvvg4Uy5GyolbGGImNOdEVWyI7paryhvkAk0QbMpOpziPWSkReYGvbx9U5at6A=w1920-h1080',
	'https://lh3.googleusercontent.com/FHYdb4v_-EiZYTZPuKFrKhrr1VZQurZivm8X5pLfA0yIs6jb3GAbuFiQv99YcVCvKTOAMlsUVu2e-8Ul6APNNtL_pzdO9xtM0ZIAZNpR3tJol-OAyfTqaLgJJ9hya-ri6logRKVjvA=w1920-h1080',
	'https://lh3.googleusercontent.com/ugaCd5ibONZj03kq7jGhcJSdGwys81PM4eUzHizs2cg6gPO1THIpXAMFk4SA4tB_Hsf4KpPQ0EIRLf_F7vVTkykjaAYqlEDBOlHLZHdYSPGOmqhjozbKPrWFplqzzrjURiOPj0gy_w=w1920-h1080',
	'https://lh3.googleusercontent.com/kNJox0sAKib67SczdNVeO4jYhBathn6NOI8NAPEHOjOuxPQLBjIJGqtA7111dyQLQoHjrmUWAj2d-3tySu6hwQSER5wUmDM8AYvL4-g_BRuGYmrNO1Ydrupv2Rzd0v4QCQuCXod1mQ=w1920-h1080',
	'https://lh3.googleusercontent.com/AVhcpYEZmbumtGgGu8hnX6X6AkDFluJ__aR1vSlatLnZpImCqkHAayC0CmINtmq1yrkTwX1PG2x0pnEDS8Na9fUZQ-eOuOGaVEmEBOpFuUZB1gp6TXKuvl1givdjqf7EjxWUpUjbQA=w1920-h1080',
	'https://lh3.googleusercontent.com/nCFeXBnh7lAACx1i93bOWuYWkj8InxrIQ5u4DZYk2Yk_vpvQkGLl6hXQtgFt5odtSjt99jWXMrrknnudA6-zR-N0720k_o-IgPsQy0BSlk5z9ivAW9vwtV85-h9fLMt0qBpYv242pg=w1920-h1080',
	'https://lh3.googleusercontent.com/thbGHKOKAoNxqYh9e7eX5DAVVMMDTmMSXzCui6l46GBHb1NOhjqw0kH1Ix6DX6R82VNB2MHRC_UeGYZH4S9LcV_vpKwRKBpnrMeM9XpNA1wBYhx9WvZ68jPRw_eMrQ9J64S_xBMpYQ=w1920-h1080',
	'https://lh3.googleusercontent.com/L46TehuOdiE0DJMkZslLXf5jomTWtmm0TjCmsOkUxcEij8YvXGlwomxjMs1LyAKAjXv6ZxrtdL8cc6RwDS3dRP9QcbErADf_RpK5BnEMJus13lml2kEIJsSQLUYet_evp9_rzHKaoQ=w1920-h1080',
	'https://lh3.googleusercontent.com/ntx0z8oQu2f7daO8AXHlZevbqw7rb9cEcLRsmz-BiAQNcd54onMhxITF9g3hEbKpmKeEK2klYTvnlRNIKU5qez4QyZuEhDFfQFbWsRQmQtESfK7Zc_UcVPizGN-s8akT3iLYscFeHg=w1920-h1080',
	'https://lh3.googleusercontent.com/6IAP5jqz1KsiJZuTRE7VZ-QNEfvNwenQOxusMUsOZhzLuNlQSOGCOHbVfbBTX_UFUVyC-AhgLlj7oW2ovdxoxr1WVfbszsHsRQ1BYsEDdTepeawgHNc3bJG41bbbM6zD8EQmIOuRDA=w1920-h1080',
	'https://lh3.googleusercontent.com/3hGUGuJxYYKBl-pnpZXT4Qf4UkCd41PRh3yKaGhA6JtbxhwAANymxULDgxtO2pOc4MJNTue0muEMQ2ccXRpfxXtvOVYuxIdp-wt3TWaOpCRJGlb-BAmSvKy7hbWxSLTaoAx_wl0kTw=w1920-h1080',
	'https://lh3.googleusercontent.com/R8FQ-xbpzyovx-0f7Q5Untx7N4BgTejt9FfZ7cGmOVuWmxGZTJO2tOd469AuCtvK4kbosuGXVoIkvolND5FnuzRn3WHhWe8rV1fxmJz5G7I-v3jixQP9IeLF5qlCg7pwsQGVcQT3CQ=w1920-h1080',
	'https://lh3.googleusercontent.com/qawQTzKAQjju2qRqQHYiB_7S13-pPMP6g5WqYQU_KEwI66juxivPSxbOItWbQLCztBgDjqpaHQRN66urel31_111s16PFfE2vXWeqjHP7lKpry44DfG3ZR2vU34__xTEDPmefTgyEA=w1920-h1080',
	'https://lh3.googleusercontent.com/IwYYAzNgoSe0Oj0YuHPwI8DAe3T3Dz0v6my-rWdbcbydNt9-aaLqez9rDFVbgdOWOsQe84AHDwf1HfJV9mXj8pwUa8t9E1Jtx1T_SNTZ2rcR8cUI2MQmbnQdicgndDD5uQ4rmdVDwA=w1920-h1080',
	'https://lh3.googleusercontent.com/IqSauZTHCz_iIvTGtpg4gTau8Ufs0m-m62m1on_a8m50WY0UW3V5GkSTL2zm6SjjyGIKLb_h7IHTJqiI_2V4w8eZ7S7Zi43LFQB0bA-sj_mF6wy0DLjO11lZ_iRVWVhMCKfPUVWAnQ=w1920-h1080',
	'https://lh3.googleusercontent.com/7LWte-4QbMbOhCOxOeTwIIt4AVlesfcDGPuXenTj8MyyUPprFb2z6Om7jUoAQV8DpRVxnKKbyJISwRGdkVoXyndylsOvUEzgywXr93D3YDAwNF5uDF-MU2DcP9AquuB-2PyvlAbf0w=w1920-h1080',
	'https://lh3.googleusercontent.com/7FQ75oYh08JQ5SPHUDrUkp87GiCasBgcywOgaVMDpEBdHuKgQwWGMccHI3irPYyJ6MBZyVBRSebyXPqrv9Cr2IdcQxEilqenGgkxj9S6z3L1fCUx6CYILaBt9Pqe2KMylFAbtzPuGQ=w1920-h1080',
	'https://lh3.googleusercontent.com/JhrsWrPQhj0YNfIPfsMy2Poujsr6Ea91uyW0I4Yi_ioXOlargM1Z1UHUFhHVPRVVIc8kLFz_QkQOYB4P82u1V63sy2hWHbTEPoKwpSys7CqhP2lXZy6SGWsENIEfnpZZhqO8wJaxyA=w1920-h1080',
	'https://lh3.googleusercontent.com/0eePFkJX2A2Vy9QIG2HTZ58Ca6VngI3PWs_P7VkNBDZBEbiZnjdJSLRAgXY46IUkjlZDt7fgH5upk496kglnejJDp2NnJ4bqFEditWcupEcP7zN1NX4DBrLW0KLkaVq0qcWx6ggEQw=w1920-h1080',
	'https://lh3.googleusercontent.com/K3c-bfd5ZTIooq3tndDTowyVR3YuswQzJ9tEh9DDme14os-AbzMe6zTux7_QW6uhrE4ka_q04P3tUVFt28rMOoP8CCXYX-hrIPXs7ydBpXyhOXwZeq3_YJnmv3sgmwF0QMO2XJryUw=w1920-h1080',
	'https://lh3.googleusercontent.com/HsMthCinExOKs3rbbBsAt8ce4L7KjLJBIZpUA1uE7dBY6QsKc7bMn_2mDuCQdLdueaKQox304bhIxBmQTpqnVk5pXMqgtbT5kQFH5hRnTKn_IzF9jLSmgOEvp_WG0tMAYrWFbPHGJQ=w1920-h1080',
	'https://lh3.googleusercontent.com/xl-9T6dFK0vxqDmBxbGd9xsFHN5aBakW2lo2bTTXX31KnKQCYoCwIuAwFaD84bdiSNDpWztVhHiM8R5JaDdTmsVVhYlI8sq6MogVX4qRSqgZDKK54HVnQfNVqV0OHJTe4s_-9X9tcg=w1920-h1080',
	'https://lh3.googleusercontent.com/k9mQu0uPYj8ARr_TuwemHj_jwSpjWTLssWMoXeu1JS1mJjEr87a0f54VFZbH3UatkfDnXRxA_eDgQ2MMwVnDkgxxxPTBuHVo1SY0izvYVQCf93wwA3AixX7fgSY8hA2mqkjm45sApg=w1920-h1080',
	'https://lh3.googleusercontent.com/7ovF2KNaHqRCFXZLLvq4ijMDbZDFB7nJefsmYoVg_89tpBKX4thq4j5o_XUuDO42eJFwqSFAGdrSlmEiICWd7V1fuE9m3EYgMd_nmo5RHLmAtwMV1sm2K3az0tqB-PIi8NYt7Bhbcg=w1920-h1080',
	'https://lh3.googleusercontent.com/xwiS0mUh7OX9dHqEtiyqpWFhgchtib_Awl7nwwYdUdLXM_YXbbQO94vo2nJ7LSc0zo77fSsHQjHSE5kvGtDDoSmOOhFoigjjtf-51bDM8h1dpst63frc-1IbcB3__RR091eEe0a3Ig=w1920-h1080',
	'https://lh3.googleusercontent.com/lXCzTbeTkT1RY1Z4d7HlHEivX1PYNYoZyTcePbkaGex6zO5SalI6DLwzPMEIStquEGsvg6ZFwX0iEWnYgDipmhzpeNcTx3V06xzis49uv_bc7cN0bMkMCpq9nTpYShFScyTlvTor8g=w1920-h1080',
	'https://lh3.googleusercontent.com/l9LPhHs2b3kznrCDnDJA8-j1I2GskHY-HsoDIcRtcaIUq9Hb-fZbh0zwsrgXl4_zlkT5H8qDZZwuTFFOEZH6laW_twblvA3FB5u-FQOKrDAnkNr7sVDdJUrxzuh8fRjfLg369L09_Q=w1920-h1080',
	'https://lh3.googleusercontent.com/-dMUZ9KF2s6upt3tfddZ0Wpl2Ri-bx_RC_4gDbPciEr54frT_l5_plWKMkygBj-tdRaTS0amCfs-x3aN8KORJn0cpCz8EzfAt8tX87YeLcqBF-YQR55s75nwz7RZ-0CKgrLrDQUoJg=w1920-h1080',
	'https://lh3.googleusercontent.com/c82_SbvDmGQvgGrpOXKIDA0Pf7iqK8pMSifKIPLSM81DJah50dxer2iXKoN4KTx-HWGczY_31QUZxAu_yAO0kZwbq1GokBdzavM-OiMOHZ1Th9TzHJHQFKQmG4q19zxduj14UC_QKQ=w1920-h1080',
	'https://lh3.googleusercontent.com/c7b6BIVUsz7lbhtDbReRN1W_DnVvX2X4W7la7jYxsueZ1KWwAJWMpI7cfaO73bs2wnJ1TRpsy0w9gvMxTUel4dlHaKmgpI8HsvNAJbARCmQnu9Ew3WXpGN075hVajd8C4XPX3iRAMw=w1920-h1080',
	'https://lh3.googleusercontent.com/ikLqYEA6bE_0ELeP7U1PUxqrfVghSsQo4mM-FFWkpN8YHKEpM3mpi25o6FQacHBScHm5akuz91zk17iT2L-Zi9-zI7X_lXdjApJ6MM1ZVTZlLK3YRjEgqw2xhJ3VMuog3srD4AONsw=w1920-h1080',
	'https://lh3.googleusercontent.com/Ft_JHCJfY8qlMXnvnhocKgWQGKPL3AU2HO0aznCCZvwrHfcufjDmp-wiw8Z1cMtJaUypIptQC4rf-8xN2yKS45yaYk-iXTvHEKJVlROXLMouC8GTGBx2xT4G0qrsdyyzIULYIn7Eog=w1920-h1080',
	'https://lh3.googleusercontent.com/dBvdw9aO_qaOfVD-eOHIFUHcOL5gRVigjJoEs0QosHPv3NV-1om1bnZReai5-ivBX3gFVtB4rikTUBzSOB5S-hOcBh7R2tefIsOvOdrysUHcqFXoOdi-NMZ1jKgH2DAFxuQABsXvMw=w1920-h1080',
	'https://lh3.googleusercontent.com/7Luc3JE80XB2L2MuFkzincr-QX9CzhpMVrCxS227xts4oeNG08WjXOt_sKwnDRcxqGBaV_SoYHE9VCOZfOg0l-FvNPS1a9iJPGgJR1IxptxZLnpFLlo8U7K4-TGv3ZzglWihNF5O1Q=w1920-h1080',
	'https://lh3.googleusercontent.com/94piEdCzOpqNrm3cf7bC2otLYDr1M_EtLABVboBO-JfeH5cOwrC4ZApFrKRjB1QyG0yP5e9rxubJ7VBTVhqT4JF316foTEfyCumEnE4AeT8gWY0_uU2HhNh1t3NAPYcQpGF5-jkHTA=w1920-h1080',
	'https://lh3.googleusercontent.com/8xPPA8yzXdR6f6Y6w3Q5ETQJadP4kYP_lZbZ4G6QKpQRzQgQbVjJSkco_17nrd4EhjGqcT1zFCqk-jTUDFdER-SLBO0mAOjt1vnXVOU6KEEGoz4fb4yJPG1sfTZN-CgnCD3Z69GbZQ=w1920-h1080',
	'https://lh3.googleusercontent.com/u-rZLl5BonbyhYtDnZsfMEFcHw2z0sTxwUAljX79wf36bRLIv21hL5lwyRnlZPh9vX9GZm5v-tn4a3UuQdIZvkgyDurfEyN41YU92OZY4m-rDlrleHiepouUVolFMTkBtjqgpN3Bww=w1920-h1080',
	'https://lh3.googleusercontent.com/MbTJeYDuAZUXuYjOyoZASMsHLQ-9MIXM1hgfK6Dy2BUrYbGQ5-lruD8_eE8RGEZxkQuJj1_QNDw04E4X4TGke-2Cj2gDhY4NuV4c49ggAKMwL5oHlribVxpglLk70rlgnoxfibbb3w=w1920-h1080',
	'https://lh3.googleusercontent.com/tgsF2Bl24mbuYdLuDt22g4baujpdSvTK0iMXXN49alL8ANNOh079p2WF1yR98ksw_88Nsn51M0IkrWH7gqffKkcx6JtCp-1d3B4lROERfqU6NGC1kaxWgRNkMlgoNZer17Mky6iXPQ=w1920-h1080',
	'https://lh3.googleusercontent.com/FWxRB_NlP8nET4_IhL7DZdJtb40CTf3T08iy9KuhFtuSoAN5EjU6MaPnrbRdtt8phiqwMTgNNMf6ewaHZtmWJTm7wY8YdfJY6nsR8TFGgkY-pOgqT28QA9MJXMyXcYg-m6aABie0kA=w1920-h1080',
	'https://lh3.googleusercontent.com/fWTd7_V5G-NSrBCrKwMVyrYsZLfw5xIH3W1X1KGhj-8Y6y-6v5fu6mmk3H4UvYZTemP_CdVD8WkADIBQVem4V4_vkRa6x0-hZ_nWoAJ5hcwuhxJ6JX4HBREi_JXLQf1mNJAr0MNyhQ=w1920-h1080',
	'https://lh3.googleusercontent.com/2zrujXw7rwcVYk9Ia5GNLAeq6scdvKxxeEvrG-H4TbfwWxjqm1IfSRZuFRMAm08Ia0mmbUerwrUB-xkWhS7jowGwiMp4FaNBlQMl5K3_HtrneIBIKrYLf-bqm6dJ6UXaIVctQzetEQ=w1920-h1080',
	'https://lh3.googleusercontent.com/sUViESFJ7e15IBcozXzaB-0pu1ntNVTs-5eKOVc-adyxM0GLu9KwliDgbJkulrYIORacmiydK7A0HC4QyUsrmAf602r_m9Ezmh5vvrztC_U2mGZifK4k_COuYvZ436ua0Gb_29q18Q=w1920-h1080',
	'https://lh3.googleusercontent.com/_yaLZVx_C660k72IYIsmEf3QezysPnN7yiIplIaorVLt1Pqrvf4PuJht0ktJHbuvBwGcFqcGe-Vih-jw14qepQU8zxekQlAijVEgrXOWJ9PdrFxeqcip_7mRF2DXJ7V0Tj-UN287WA=w1920-h1080',
	'https://lh3.googleusercontent.com/Q0jmXaaSaWATUL7phaYzkcMReCiv1JVXZ4OZJGYifuu8XQUA3QZzY1NLdP8SMcaPjDq5S8kBJdagjlRxqCPlz1iipI1lkGGMTOmKN1D6UUWffJTwoqIMLdV0LxftQtGkcvuh5ydaZQ=w1920-h1080',
	'https://lh3.googleusercontent.com/IXipOgGTxlSbl6RC9Uw0-V8EEcUJIEVNzTpCqb1KiRbYQJIf-AbQ2XjkLvBoLGwyAnMJEHo_LLAZv9RJ5Cxt2dt34cNLOa8ul0yPDK4IdNFdHStk-03_pcVgozksUmbP_QjCWycWtw=w1920-h1080',
	'https://lh3.googleusercontent.com/a_qqsQICeAoDjpCrHEuMif9eD0xF3DOkkET6L7OdI71frsxFOWzRMMS_5FXltKgLuZiAGhD_K1VQbjqKDAxCoOOKaCVJsHDYY0qqYSDiZZYcLRPKNw9B7gwSBOdGgvobLx7ofxqVrg=w1920-h1080',
	'https://lh3.googleusercontent.com/Mg2e1yrSHhntbFgtjCmO_ZI7sKd4otavQ4jOic1h7v4wn7BYn6TsYqACoEOFUtEz6-shl2L_MV54Nq0K2aeyXbIRp9ht0-n42CYuEX3H5cJsBxyPdFsHCYUP1qGy51tqbJmIrfTetA=w1920-h1080',
	'https://lh3.googleusercontent.com/nbpCBWoI5KXklJzhSsniolMw1FMZI3qjemquMA6GgBn-Sn_ZCZoDM9H1b8kP7cpQmJ_Ai21t7lNVApE6bs9iVIf2ZPJXNOu7pV9v8yngD3C72hEaHPemC88XEyb5YjH_RtZlocou5g=w1920-h1080',
	'https://lh3.googleusercontent.com/riRJC8mN_waIib8g568MqukYbqfyeos5LerNo4eoahWwHjeHuu0Dmxqfp3HVvYU0bRulsDmjc879cavGOTmofkJsQIn2XQdx559vebe7m3lGYfMx69n_GdEcEFZW5vCxUFmE445vvg=w1920-h1080',
	'https://lh3.googleusercontent.com/PzQ_yWdO_4AfR5q23ptWRWyK6Ac1jXJRIdt6NBPJYAMjguxtmejAyIl1uFV1-2Eo3bELyNHVMUbOZchSf4kw1K1D6kVsn4XHhVrbUMgAPrFZ0sBO7N-Y3AgaTfUlbmNQ1GOMEAG-WA=w1920-h1080',
	'https://lh3.googleusercontent.com/grhk3TbZcHpnJ78dr5hmAeLZJ4dYAsMip3lHnhtqeOVuXlNWpUSQR3ZDwLpdxBaE06-dqA35nm_QYr0zGv3bNcuAI5gZ3dfcQePkdNYVtH3DkV4T79wQwbfnvyJjCe28H-XT0z59ww=w1920-h1080',
	'https://lh3.googleusercontent.com/n4nz3nLzTA83Dz5oDC1h9LbkSy4m8Lrk0FPyAqapiupyxPYOvunH5svDv-_z46B8w6cUDnSqZrglo5NoYKjLmr35oD9UPNbIm5I46bYLHHzJfCS7-Of_5C6GblDeCOCO2LZBE3g5TQ=w1920-h1080',
	'https://lh3.googleusercontent.com/7tsk1KVbyjq0pJfLpa58bXAMg0Q3nQkGLJpVuxS4GrfJRtkG1G9pN1uzTFnGL3ryXXCrsG1QBZ4PeQrjP4GjJbUMuO4swn9Aaj5qvw-eNbFISnzYA519GZeDvIgvGWXg72wxQ7EKdQ=w1920-h1080',
	'https://lh3.googleusercontent.com/5htPpRqQ8WDl6fm8BtAV57Q3CTvQnkIrhWVacBiLS6hWfGhMsMkNctGDnJlhUi44XKEaK5wKPeli1PdClj4SGZ6BpNb__O7xJ4NypsNSvxAdUc1kIadx3C4d15I4GsZ7KhVVmZtzqA=w1920-h1080',
	'https://lh3.googleusercontent.com/KSGtkT5I3fFuw1zhjG9NnxN0vXNKXyS4cKdepcVCvkgFUG0SwhvPcOCyhCTOSJBBL0QQruw2gYBqS7DeWY0CSZuKV6N5E0uP0bYXEnefLrYzqjF0st56u-oX2EBeEJV20YBuDoubrg=w1920-h1080',
	'https://lh3.googleusercontent.com/cSlPWOkUqz8m03t6i8H8AKqyfsCCS78waOHYA3-oDM8ekeYptcQq5x5blBKtMZ0VVu9412UcljNWVMWuY5VM4bIuTltUQ06mK97aYErxpLrceqIOjRM7JlzXV3t-fld62y6tNxtatQ=w1920-h1080',
	'https://lh3.googleusercontent.com/DTyDgaGcTbMEUYfpL-D4osovK9Ij6z_VmKJi4peiFWEi6YT1QhO6A4SGbzquxDUcj_W2r2T91jKx1WCP03bZZGucCcX1ojvEGR7ctj9O9Sj1cxB3g4ZP_fVgekYsSmMTz-WsEBl5hg=w1920-h1080',
	'https://lh3.googleusercontent.com/AhtxXwZiz6sNlqrXp-uFH0VT0c26o81xk5FXp2mEWxSu7juC7Yx0eaADYZArqTL7DSxDixHetOnKG1z_dTfqmvoiel-LVApuxUUpu6dHiPetKlWgR0pYI-0fQab2qyFTKylZ70WAIQ=w1920-h1080',
	'https://lh3.googleusercontent.com/bNl7QlDW8nZ2kwmiqszk2JBdaCIBvwUu2p2KUUdUlTSMPkkGnXjBlJN0z67lvgxY5Au-HefahBKKRzpi0Ej2KgGAT99D1zp1SGw6Za_JEhP3N5iXRUGYJj0Zf2i1igmNr4E7BHXrPA=w1920-h1080',
	'https://lh3.googleusercontent.com/plD9XROAIxO54KAfLjO6UJza_oT2EVjsYr0JxFNDXhPfmGTKncJGABmTkuVKF3IjABWTFeIRzcknUStzjm21pBw63DnecM5mdtZwCbRnQ9Kk1f_tla2JOQNOWNiT8Xo0479uRGAatA=w1920-h1080',
	'https://lh3.googleusercontent.com/HFO9OACVbkCWepxJ31Y1IEVW7uxQ5sJbEG2Gfz_HdSRiUOLIKYNXtzD7oqTOHushRpJoDcpVCYus7k5TRm9aUGHhLPetnSLaSJYitpFP4bWmHzRxW9zYiM5eVNuMMzeIrN5kchHABQ=w1920-h1080',
	'https://lh3.googleusercontent.com/Ul088g3dE-xBQyeKujZKmX6hRudI-mBMT79_GymBXM4HVSb-3t5lipN8vs_Mz_X_aaNTlZbUDW9M7ZI0gggtEOCmCoJlwkcLsJUPHgTWY7WQIi9c9KBhBnLxIw0c9b9oq-DyN11fsw=w1920-h1080',
	'https://lh3.googleusercontent.com/bWgx_sjviTCf5f0aaNZMxI0qwbYV_Wxt5jiwZcTlom0-Uvg8GI4DIzS_VOSjW8zuZfO4ncT7kMF5fgNtc_IDJHbWcP01zY8sDBwKFD2tBw5qBqyypTXfmpdm0Ogb3RTPxpBFlFXUPw=w1920-h1080',
	'https://lh3.googleusercontent.com/vG4bdlsn467r1sAUahmzImGDWy-xSWm9gegOiiogWGy_gNeJpCwIN2e3R4oinGFH4_KNGyH481PYpNXO9nmIsErjRzKJlGSllEqLEcOIJeFLO8HsDBTpDE7e5d-u3xfa_ev7TFoELA=w1920-h1080',
	'https://lh3.googleusercontent.com/CVzRwWPMVDibN4NZVaRj-rykCJA6oQLZUcCwT12CB15Ul7jg19VYBV5n4YAS7iiIr8NOjFRKzYf8OATaZrV0e64b8pKG4xu8K10DrccZZQqktL2K6wl7_lWri7sSY1jVadRtGtJN-w=w1920-h1080',
	'https://lh3.googleusercontent.com/NY7sHU7MoL7s-3XYVUPJKpVftevYdsiO0-kqdMKCEVtgrJBZTsVs8kB-ltZxXSRz70wld1gBvjfLO3OMlFAZm9v9ttkxyDnWcpGC0zpTgswrfR7vmDmu3X3lpubo3d2jz8CASnkgtg=w1920-h1080',
	'https://lh3.googleusercontent.com/2WQhK9A0WeVfb9YAAzCX4mrgpV-likBPCq6XDYycxuhf7GvTiodobx65AF0JEZcVUZW_SAfcn1kkJEv0d2iQrO_sF0bL0kiQjeTlLJRwnryfzydUho7z74vmw7f9x9qFh0UuUBvfwA=w1920-h1080',
	'https://lh3.googleusercontent.com/lZQBMxmct4kOOEtKTXGuiAZ44PY6vEZbi-7xElPutuIVXqKt7RVQbM_RASJxnLoBcPMkQS3eqknUD5-c3VMEwmJPd1_18jqoEsltE9C0To3caw6nLG9RWyCb8LT3dOT8gisurm34QQ=w1920-h1080',
	'https://lh3.googleusercontent.com/QPkf1KXRDWvzlMnTNhYQSoc2w8Zd88qneIf_o9F2oDd23qbq_xpYgZzK5C-TG1o4cPq6SyM2hDg3eavUGnH83y4rwmFetRR5XZm7H7PCQP2NlAV_7wf50izOBCs4k1CIX6f9APcalQ=w1920-h1080',
	'https://lh3.googleusercontent.com/iivmQ7ATp3AIYtojIvAFKdFTpekIvmVyltA4oA_mLUkGrbwOH1MvwfEcWhXnOd37GbjPKq8OdOMz-y9rEvJ_nBh3tm_fkNaDCvoszcULf73xS_zPZgKfulivwfHwcvCLhE_5WW8i7g=w1920-h1080',
	'https://lh3.googleusercontent.com/ILxYLLVfRQZl_Sd3VCH-EVKEy3EhxIuHbh_zQRNA84bty5Hm6fKia77rq0wkssImwU6aRAFzrfvNS2TnRydLz3Hg0RMYXLvEZpuccAii6WM0-d2GX-Antrz-IqdqY20b_7P7U26neg=w1920-h1080',
	'https://lh3.googleusercontent.com/Krgs_1si3J8y0nZKZhHIpp8aD4Wp85AHf4O0Eaa5zZfv7GBYa-vsCKV5bSyn2zp9kUqJvoT4lUdszBsQEIEhX_XtEM5YqrWtOE5Nia5p-pUByhpuHv4uQMhZXP2-OHMolKiiVRgSRA=w1920-h1080',
	'https://lh3.googleusercontent.com/cvA1UVoy0bLMiYlcpVbdNWoDcMbzbnxoxZfe8pd5_ACcIOGHY6Hr1bhKWKdfHQWyTifp_1ceDwYMh8eSWcFqyQWxXbBGsmfPun0G-RQBZt7hbHl_foFWZvSz_Fnb5JXhkDxwRh5KNA=w1920-h1080',
	'https://lh3.googleusercontent.com/NRfBEH1S7Thc6BEua09gzzxHsl24gGzSsYKjcYoVIRXSO0DUxMZf5zINR9tuVTEhVjc8hmHqs-ieTq_z-PVWGSJNiDT4nCcSQIVHnMg3x85VoJCl9ncfrHRWVicv5fNNbLlnkhWInQ=w1920-h1080',
	'https://lh3.googleusercontent.com/FZ8ma9VIuSIAMxDF6lxbeIWVTPN0yu5TqegENgEKXQXCnWaRXBq7KBbaPBBpOER9RevBygFbczEQmMl-Lff_dUOOB-hTvfG1Xb-O0HYnKqish8A4s7j6IaWxBqFWfhmVB4shtbd5aw=w1920-h1080',
	'https://lh3.googleusercontent.com/PjXI293co5b6FImrkFXw4nN6go4ABsZn3nE2IqaexBRDycdLW2p8UxNngIEwMa5Gnin7B0DPmdSyzIchYTzkWQ9E3sKMSliLLrEJutXvwXlxA2zr2C7gNeBbLnD3q0tVUaOKCh9Sfw=w1920-h1080',
	'https://lh3.googleusercontent.com/pyxFA-VTd8h2o_0FqrCrXVPyaQG6orf32vCu6AqxLNxNcgQg9wbLEvewn87M2kJo7g4JK0iNl5bdsIYiHnH2_RIJELSQhATqYV7onRTLi4Dc7tB06wE7ye7eirpB-Jpawb5ic5he-g=w1920-h1080',
	'https://lh3.googleusercontent.com/zatuazhhI794pxj24eu9SCC3gSutMqFf6GzvUxwBB3wrHmW7eUFqB22jivhm6UtUnfgqd7eSu_GwAV3on1D_kLPUKiQELytLJXoQIQXq8PuQXepmQGILLzNbNsyirFiG9QZVQVt6JQ=w1920-h1080',
	'https://lh3.googleusercontent.com/t1iJXdMEuknsL69XOyDMzVN4H_WWq_twyU0LQFDQvvn-qRiCvonQ0541EzCUDJp7OCfggykABOm3I0rEflsOuGK6R5fCjh7PIYfhH1wdcwzj4lNstoodBtbAODcDIlSqPrd1fuXMHA=w1920-h1080',
	'https://lh3.googleusercontent.com/FAZtYb2qMd4AWiafMXziBDQvBeUpH8Abo_I6UE_8HC5WHvBaOJocl-ZmmKfVhP-V8SkvoDEvVv_uf_x7zp9p9JjfTq-iXRkkyOQ6Barf91pMnDkFYLGAYN94NF_DVpMTbvgiGRqwiQ=w1920-h1080',
	'https://lh3.googleusercontent.com/EGfa0H6m85q68w4w3zHkb0zCpsFsgjVNTfCp6nbodXH5qw86kT-A1PuCtgFKYfB7lDywjyc20pg5Z2WQsaoaL3Ynd0lUClZ_Yt_tgfPNoJ1tbnyDR-nGLtBTG5Xm1fRSF5YiA7FMCw=w1920-h1080',
	'https://lh3.googleusercontent.com/b0aXh9CaSRqv_q3QouyZP3DylmR3bmap2xIMHpKDXTqAhUjzxkpu9Bg83rl13SPtEl1prSld0SiE-SsiCeY9pW21EXItcYzjuJ72-vb1Mje9r9pVHsY2ujyyXewiRyRm0SZkZG9e6w=w1920-h1080',
]

export default function NinethGraduationShowcaseOfICATDesignMediaCollegeBangalore() {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = "https://cdn.jsdelivr.net/npm/publicalbum@latest/embed-ui.min.js";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		}
	}, []);

	return (
		<div className='content' style={{ paddingTop: '74px' }}>
			<div className='container-fluid sectin float-none'>
				<div className='news-lt'>
					<div className="pa-gallery-player-widget wdth-100p mb-3 hght-480" data-link="https://photos.app.goo.gl/qebsSnaRKhXpBBmd8" data-title="9th Graduation Showcase of ICAT Design & Media College, Bangalore" data-description="44 new photos added to shared album">
						{EMList.map((iMg, index) => {
							return (
								<object key={index} data={iMg} aria-label="9th Graduation Showcase of ICAT Design & Media College, Bangalore"></object>
							)
						})}
					</div>
					<div className="videoWrapper mb-3">
						<iframe title='9th Graduation Showcase of ICAT Design & Media College, Bangalore' width="560" height="315" src="https://www.youtube.com/embed/H3rh_y31fS4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
					</div>
					<h2 className="h4 mb-3">9th Graduation Showcase of ICAT Design & Media College, Bangalore</h2>
					<p className='mb-2'>The 9th graduate showcase of ICAT Design and Media College was successfully hosted in Bangalore on 12th april at VR Bengaluru - Whitefield. The event showcased graduating students works of several verticals like Animation, Visual Effects, Gaming, Photography, Visual Media, Graphic Design, Interior Design, UI Design, Multimedia and Fashion Design. The event was inaugurated by Dr. Kailasavadivoo Sivan also known as Rocket Man of India and Chairperson of ISRO. The event was graced by Founder and CEO Mr. Kumar and Technical Director Mr. V.Anand. Dr Sivan went around the exhibit and gave a very inspiring message to the students.</p>
					<p className='mb-2'>The event started at 10 am and went on till 8 pm in evening culminating with a spectacular Fashion Show. The event was open to public as well as industry partners, parents and well wishers. Students displayed their works in their respective stalls that was allotted to them.. The projects represent knowledge and skills that ICAT has imparted to students  over the years and speak for itself the creative quotient of the students . The past results speak for itself, where so many students graduated from ICAT have became pioneers in their fields and lead several national and international projects. The environment and the energy of the whole place enthralled the visitors.</p>
					<p className='mb-2'>The graduate showcase by ICAT Design and Media College was an opportunity for students to interact with around 200 prospective employers who came to see the projects and portfolios... The event provides a wider platform to students and exposure to the world out there. It gives a sense of achievement and also create a sense of responsibility towards commitments of life that lies ahead.</p>
					<p className='mb-2'>The fashion show held in the evening displayed works of Fashion department students that represented different cultural themes.</p>
					<p className='mb-2'>The Guest of Honour for evening was Mrs. Vicky Bamrah who has been a senior Fashion Design Professional in the Retail Fashion Industry from past 15 years.</p>
					<p className='mb-2'>The event was concluded with an award distribution ceremony and photo sessions with graduating students.</p>
					<p>An event of this scale requires meticulous planning and hard work. The Academic Staff, Non teaching Staff, Marketing Department and Support staff along with students worked hard to ensure that this event becomes a memorable success and indeed the resounding success of the event is proof of the synergy and culture that ICAT promotes and nurtures.</p>
					<div className='news-arrows'>
						<Link to='/inter-collegiate-cultural-fest-miraki-2020-chennai' className='prev'>New Post</Link>
						<Link to='/9th-graduate-showcase-2019-hyderabad' className='next float-end'>Older Post</Link>
					</div>
				</div>
				<div className='news-rt'>
					<ul>
						<NewsList />
					</ul>
				</div>
			</div>
		</div>
	)
}