import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import NewsList from "./../../Components/NewsList";

const EMList = [
    'https://lh3.googleusercontent.com/_HWT4N46r36qNV7jH6agN60sTAHI4Rh66N0DAXiZMWpp7pLs7yfGDPkfLVIuXZ6ZB7CCnhNJ1H76i83bRYunJCiOW55AjNcqW362FW_iiqNneB8G5OaKGxnIEc8r18NIxHkVlzyZlg=w1920-h1080',
    'https://lh3.googleusercontent.com/ZF4VZP25Jxnczr6pNFhWXOdptm7gj0gKwZij7WfF31k0cr3bKwSjWWazsAufCJ5xmZpIw-96tGyU4LmPlyp5ZnOgmOAV2hUX6l-kRjuL3T-itv-puK1M7ijX7EThJeiLoxAuLvx1Aw=w1920-h1080',
    'https://lh3.googleusercontent.com/24B64V5S_t2wv8E5tDhXePMR2T-CgL9expOp3_OuEsA7JD8QiUr9ex7EfJpANpqBRwMPL9MUs-UgzXIvG7crJAPST_JO2dP6YpGiBrhYXo7ukvPeQXpYVF5KXZcat0DhFzQiKBMhWA=w1920-h1080',
    'https://lh3.googleusercontent.com/Cl--HviUG0IIX5YK2q9PzZD5Zi2_l1TutMOmsMhLFaqQ3XDGjFYJFRxQgn6kArGyqm8ne1X-Ztqlg-LhLIEvVA8FAwCisnccV3cwZqgO4Si3QHRZhUmJW6FC99fhmxUp8uIlNwYkkA=w1920-h1080',
    'https://lh3.googleusercontent.com/BsfmsWVQM5DP89uWAiWwyUZm4logR09e4Lx7tEFeH0iB9RaxDtm5hgZ2AeyxKmFzwlg4blcIpH4C3YrN1fhPv32GzWqTtEcXgLwHaxPgqJFq9KvIUwnGY9oXgfFuBXl33oSLFZBJFg=w1920-h1080',
    'https://lh3.googleusercontent.com/Jzt-v1_CN9tUPhCyXtXeOgkHaclWcASgIkmpbf2uuhU1r0IEU_yKfeMiIrKadvnv-RrQPzgm_J_zi-Lq94SHKALAH-1qAQLepDX-HhWDuYBTsYEJ_fKytC2ZYS82jpq3fEEnw8dXhQ=w1920-h1080',
    'https://lh3.googleusercontent.com/MEbmvZuum8kgkDPjE7sWLgSemZOW0SrJC0uvNWhGnfWfdJGEkgg1xbwIJ2sf6W3flJXjvVr6b2r8fQae1HjoXsA_2hk3ACNrQWG96feR7otOakyR2JobOuJHRsHdKC1IBEHVNbHSPQ=w1920-h1080',
    'https://lh3.googleusercontent.com/GC4jcm6H9mX4cErTQqVTYP8Lf0x3uE-hZqWpUU4j-Ovsb5KuZ5ygOsTbWFk2302h-_MbH1pofVcZT2ANfIV9Sce4UEQ7_U29tszkYGfkYxhb2jAmtm2pS_EJovaNNE-2t2r6r8kF1A=w1920-h1080',
    'https://lh3.googleusercontent.com/dDa2s5-7dzJoHjOlRulzV5KK8tWOqw84uZXznoV6ktDnoA6SMyqljGjpzet1DSDJ1yOAtiJ8H_d8h8kJDimTnbkpzomDtVqm_ultfA6qopFzw9rpF2fd-UzZBop5Gf9MWgfHMgABFA=w1920-h1080',
    'https://lh3.googleusercontent.com/IDjXxI71Q_E-NfSSHNLsG07CvrPXd_hATLFiLyQFvZ2STcJBl8qqI7DKdMz3ahH-eHNUx_ENlbOPjCGX2c_vAN8_WANGe85MEczkFV6bWFRswwSYtQsDBytDTe29lW8ynSAeB8mIAw=w1920-h1080',
    'https://lh3.googleusercontent.com/lINe24pYOYBJWqj8lqlQx3H7tAjrr5jcVUom6YCG6QFy8eBHb2uqllbD0qf7hQwn0TtIcMbQKsSOyPz2tDBs5PbpKwWbTZE0hP107pSzdWf9JNp17WQKWttaYTVbChy8qHQPrb9Jtg=w1920-h1080',
    'https://lh3.googleusercontent.com/qpuqC5F0T4L627xSuqEVXuNaZlwepdcBwP5J7NIrfSc7bK2mEo484O_Xslm97beSRbTTMbuAmTUiEk5VewpfqpO4R5LPI64NH3maHnux1wGpmPdgkqCWPn7DLxEFYSsF8yN_87AR7Q=w1920-h1080',
    'https://lh3.googleusercontent.com/OhhfLk5HbUQUeYunGCTJkNu74vtesdpw6TtKaR_5pHmn-zvCpei8K1hQyYNoUT3yzOOj-3LYPthIxuB8IqXC-KB136mL_ZRv8xbnQ_a_dsKlIau-LAgrJsWTIpB98jdG7-Z-9Cjf3A=w1920-h1080',
    'https://lh3.googleusercontent.com/4BmmKtL9VBFQCz3_j4hiG_yx2sPRGpcELr6WCLOPGBGfdfgYU9z_17GeE3imUaoWacwgaURQXK2QsxG3QsEOhJrVeKJs3kJiyWq7ug5dkqG1BGSv9KS1kI1ZmuvsrUzitw7cI8RVhw=w1920-h1080',
    'https://lh3.googleusercontent.com/LUKTpr_NPyLPhp3_oXRYv77Jf2BZzAC4ewBhWZyIbcDa0wYDPPnLCstxUPhiR5km82H94840C1MfJCEo_s6zh0h-6KACQEYn9aEvAvJFgkJpWZCkRoae9LcNAAZMetEJ-lbifciacQ=w1920-h1080',
    'https://lh3.googleusercontent.com/KdRyfBBzy1qL289g7S0UHeNWCcAwnpbpK0cZT6RJ5gzkt2OU5Wg6biYjmwfp3eW0FkKP5o0OaX41RkDMf5OLLuXef7BgkZYcOw-INCigddS2mTI9rS92RCRCs9K3vyit08t4a05IlQ=w1920-h1080',
    'https://lh3.googleusercontent.com/P1tHvsqgnt-l6sDtGaoAUHTx5e7xMRHAMdkyhN-pxK1bVpudtIA0uB0e3_iPaL1TnKBXOjDmXDtsiHcKKPaFw83oErLPnooTEnE3nUkXFsYzgLEYo1ckyn8bdBH8qtO9hqcDaTx5VQ=w1920-h1080',
    'https://lh3.googleusercontent.com/7xTqiFIy6ItjcwjFfbhPalgWeGdbNd7HmiAjZJdLjCUM5hYcH78AM_0BvFnJVj-ri7hCQfghArtZHyZ5kvZ_44UbDrxXZYOARMYL8EFRRYip77mEwSgcM11QNsSW4WdWYTlGrA0OPw=w1920-h1080',
    'https://lh3.googleusercontent.com/jG9bk7WZD6Tftzb6PPBqZnOizphmPFIyTL8WwJneZ90_7SU9MpfOf6sYD-W2EzFZOdspeZYlk1yS8ejodnHqL4pfAPnZE9OiSKVA653GGlxR1tC1FM4Qre_oX9w33XbY7R7jN192KA=w1920-h1080',
]

export default function RajaRajaChozhan2DAnimationShortFilmReleased() {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://cdn.jsdelivr.net/npm/publicalbum@latest/embed-ui.min.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className="pa-gallery-player-widget wdth-100p mb-3 hght-480" data-link="https://photos.app.goo.gl/GhvmWfW8bp762zKw5" data-title="Raja Raja Chozhan - 2D Animation Short film released" data-description="44 new photos added to shared album">
                        {EMList.map((iMg, index) => {
                            return (
                                <object key={index} data={iMg} aria-label="Raja Raja Chozhan - 2D Animation Short film released"></object>
                            )
                        })}
                    </div>
                    <h2 className="h4 mb-3">Raja Raja Chozhan - 2D Animation Short film released</h2>
                    <p className='mb-2'>A 2D animation Short Film "Raja Raja Chozhan" Designed and Developed by Students was released at Four Frames preview Theatre, Nungambakkam. <Link to="https://youtu.be/de_ItMyBy1I" className="link" target="_blank" rel='noreferrer'>https://youtu.be/de_ItMyBy1I</Link></p>
                    <p className='mb-2'>Director: Madhanaprakash</p>
                    <p>Students: Thamarai Kannan, Elango Nishanth, Santhosh, Javid Anwar, Sudharshan, Sidharthan</p>
                    <div className='news-arrows'>
                        <Link to='/won-most-inclusive-educational-programme-gold-award' className='prev'>New Post</Link>
                        <Link to='/inter-collegiate-cultural-fest-miraki-2019-chennai' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}