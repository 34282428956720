import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function NewsEvents() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/iac-prize-distribution-ceremony-chennai-2024");
    });
    return (
        <div>N</div>
    )
}