import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import NewsList from "./../../Components/NewsList";

const EMList = [
	'https://lh3.googleusercontent.com/SgyGtA589qPSPERdQm2-9SnSw2o1OVx05sGIc5gwz8Fszm-tMA7AFOfYeGnYdMDhm1ELFsWRiRPRh0Y0J0kGOqy69JAXRBgqdR_-6ZlK5kNP884e3fZROIRKvZ1kEaHRPhN8TgBlUw=w1920-h1080',
	'https://lh3.googleusercontent.com/RjFEuUfFxoThVxqlMorOvpMFTF65YIBEfPrQ6sTbJMMOdU2UyyxU6v1yk9dbnesb826p-Bpg0XicX1zTP6aP9QbjIcJ-8heEvTuTLaY7-lxTfTr_3ZA4zw1WpnohAUtANa72aNOmww=w1920-h1080',
	'https://lh3.googleusercontent.com/8wUNJfze-JKIdlF5DUsQP8aI7eWX_Op4OrSrNfz6qsX2boLdGoc20UXyfJCN2Rv178plgRLadfYDSIj4CZybqB9axi92ncCPr8aFLY7ONkznjIAaNoPQbb_GKVjyIeIxe3x9ACZqAg=w1920-h1080',
	'https://lh3.googleusercontent.com/XQDnAKw3Twx4pQd_R5FUJUfhhob_Hl8bvGfmT8qvMnliOmqS9spwce4GSoNbzBlHPndXzL0Kc2kNPzLKHVWaaAZ_bZnE2vW0IxYMSmCCC3vI2gIrv8EnrWuRHS0N5J8MyNurxnsyCQ=w1920-h1080',
	'https://lh3.googleusercontent.com/tgyo7CHZg3KGTxTml3MQtzvOvzv5mE3srWpTEkRdBZebIYScOaiYT-0XvklBjncnKHeZriArHsdSX_RBnImflvXY_EAlITZomJ7T4_9qZF6B3JpgqDLkvs-Poxe5b7Owc2BWe16NSg=w1920-h1080',
	'https://lh3.googleusercontent.com/zyikNE6d_lP2__w_itm2IYCpQyLsL-5sqyySb0ciSfD3X_3Taxglt_qKXtwI0-TCfDBi67ZXysc2obL8uPrPreRNogwz_9ng39CQGc-_cYilnz_hOgt4_LqBohXu3q_mFF3VMH7L5g=w1920-h1080',
	'https://lh3.googleusercontent.com/9xTpLXOOdbIMlnxsqKikXtVAkNwwWcR8A-kAcnE9gNj-9ehaDseqXi2aVKxU7SNs74AOmBvE0rFAvWE-SqIQQKHYcMBk71Vwg0NA3zlhVBIeG0lggRXHTg-wCqLkAge78I4HHQC5qA=w1920-h1080',
	'https://lh3.googleusercontent.com/fWI6ofykQJIKknNdjlRHC4SVzqF1tXotTOZZevDSQv4YwuPzUKonISPknc4mUv3ZYo2cNYyGC4LX0MFmB2nhBZfdtw3Qjvnx8ZlnhAQ8megOLXYVszWpp7sce1tLxWslTgJi0GXcnA=w1920-h1080',
	'https://lh3.googleusercontent.com/13dmWfGbdfWjB8TVC8mOriavfvnRYfIXhhva3YJWeMoNWzalQ-ztab0zrzpYQ1sW1Wv97EOwDdzUV3pICvt0uBe6_VNN5uCXPMBGrptzMK8J4OgS6f8zXqcGrorWZiB6V3t220D2dQ=w1920-h1080',
	'https://lh3.googleusercontent.com/8BP8Z0goocekRY3oK6vQbz2W37D_AY9mM-4x269W4DSAnOo5OP9c0bI3RQdnXgjqpdaLCIxkV0EcSRX1d4jGF-DqZbvY7ZUkCZYWr-BkR4SJ_Bzm-A7gTFqiMPwXeWLftr4z4WHB1Q=w1920-h1080',
	'https://lh3.googleusercontent.com/k3tWJPjhSYNopgUgmI9RIk0ovgxnT5ATCe7UYTrDxi3nXvjTjqr8cMJI1Sf5w9z0pLbU6TqqduDw9bqrkUErhUOYBVBbN8VSd_dwIKYbK-a1cyDz01v_AZkrN3el4prCBhT7YpaWAQ=w1920-h1080',
	'https://lh3.googleusercontent.com/CWyJGyqrpBbDCBBiEnLIdLAymfU0F0GB-RVZyGeTjZaqnQoV5dTNjxVJXBvYPs4JVv6lElis4y5d9Ybx_SZlMdrACLMPuZzg4PK5_2o57TE6UlsYmf-MqDO5jJPu9Ohf9taU7jSmug=w1920-h1080',
	'https://lh3.googleusercontent.com/aSb7C04EDYrSDjdzf_GvsXFMKh5PYAVFKNZcJj0WfxgEEowTk0tUOQzjBOH8izoh75mipFr3tekx-FROxT5BMzxNaMzP9qBJ_1Y2s-21DZ37NrqCUrB2bp5Cu6EwABbyCqjo_PwaEg=w1920-h1080',
	'https://lh3.googleusercontent.com/4eTP6xkqYt-spvFYlJMKkr74LB6xL9wbita7vogRS-VMc1_UO6PEySFP-2VvFVzE-PhjHW0aoISHXVI61VrP-Lbuc8R87dOthOa_C8a2t73pQTMQt_B1MTNM8KwOu0B-dqUPahCYNg=w1920-h1080',
	'https://lh3.googleusercontent.com/_CVo7-tGcT4ccXk36GB_AzoXAb6JM7enulUdvfmEYHASGrZ3hQ7AXGAO6nUsSx-TnsXmS-zmKQbOZ-S395w4BADMW1-TeIt3qukYCld2Iqoe1bztZKH28V9qV1by4YjuJ_jcrHUvpA=w1920-h1080',
	'https://lh3.googleusercontent.com/vyxVzD-V6-1rCMmpZ82WSQc25YkXG92FqnZFOqG-Al8ukOFJ3s1QQfQpQ52ZJFpc6Eh2hZBchWxDbCxFDezLg7S23vO1bENZfq-oU0Vc1bnTiI1XJnFLpWGXGvF1sPoxLd6empmcsw=w1920-h1080',
	'https://lh3.googleusercontent.com/lcbHU3fQPDAur7lz3jSTczUJjstHrU1_z2XPlM2ooFmzn_hy51qJiVcntWxfwUf9Z8vxRX3fNb_8KW27dopzM46gyghxSWPcxhMzwSSwRgFdC_W2rVBrl6SPYjnUGP2n4s8UW2vRTg=w1920-h1080',
	'https://lh3.googleusercontent.com/45_HlxEJgV506fEVeqGUozgJFpfdTD-_mSA9sdevJo-CiQc-Ap6rfnymcXaynvMlips9FDgghV3-rFKMXYBYUc0-LQSJTtw7rNFF2AcaoNAoa21I5ljT5brWZwqE3IKplYHxHWCozw=w1920-h1080',
	'https://lh3.googleusercontent.com/oqgbdXhlXdfqI9vP72nVYNBA9O6Lj4sod66CJ35ZRouQGrh1Z3614VBaCTSR0t-6SIAA1WI6q5LFxR3-BuEEcEvKhxsUcrRWPTdtCGFAmNw1sfixjGvnJbU-IHt5MZlqvJyuLMd0cQ=w1920-h1080',
]

export default function TenthConvocationCeremonyOfICAT2019() {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = "https://cdn.jsdelivr.net/npm/publicalbum@latest/embed-ui.min.js";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		}
	}, []);

	return (
		<div className='content' style={{ paddingTop: '74px' }}>
			<div className='container-fluid sectin float-none'>
				<div className='news-lt'>
					<div className="pa-gallery-player-widget wdth-100p mb-3 hght-480" data-link="https://photos.app.goo.gl/vWvJ1TkyyRMHN3Qz5" data-title="10th Convocation Ceremony of ICAT - 2019" data-description="44 new photos added to shared album">
						{EMList.map((iMg, index) => {
							return (
								<object key={index} data={iMg} aria-label="10th Convocation Ceremony of ICAT - 2019"></object>
							)
						})}
					</div>
					<h2 className="h4 mb-3">10th Convocation Ceremony of ICAT - 2019</h2>
					<p className='mb-2'>The 10th Convocation  ceremony of ICAT Design & Media College happened on 2nd February 2019 at Hotel Hablis, Chennai. 81 students from various disciplineslike Fashion Design, Animation, Interior Design, Photography, Game Design & Interactive Media, graduated with a great future up front. The observance was made exceptional with the presence of Mr. K. Kumar Founder & Managing Director - IMAGE GROUPS, Marlene Little Course Director BA (Hons) Textile, Birmingham City University, Mr. V. Anand - Technical Director- IMAGE GROUPS, faculties and parents.</p>
					<p className='mb-2'>The inspiring Welcome speech and Presidential address was delivered by Mr. Kumar with career advice to the graduates. “Students must have goals to achieve, and always aim for more,and also give respect to their parents and grandparents” he further added. Marlene Little, in her brief address to the students, appreciated parents for supporting the students at the time of their study and congratulated all the students. </p>
					<p className='mb-2'>Alumni student, Srilakshmi from Fashion Design Department shared gratitude and a short speech on her wonderful journey in the College. “Usually when we get good things in our life we usually forget to thank everyone who helped us in it and I think it's high time for me to thank and be grateful to the people involved in my achievements. Firstly I would like to thank Mr.Kumar sir and ICAT for providing me with quality education. Merlin mam and my faculties for criticizing my work and encouraging me throughout. My friends for being a good competition and remember the time we spend together arranging our display helping each other and even our parents came together it was wonderful. Lastly I would like to thank my parents my mom especially who helped me with all my college work and my dad for helping me financially. It was an honour for me to show my works proudly to other universities for my masters and I am proud to say that I got in just with my first interview to Domus academy , Milan, Italy for my masters in luxury brand management and I was appreciated by the interviewers for my work. These achievements wouldn't have been possible without everyone I've mentioned before. So I thank you all for being there for me in my good times”</p>
					<p>During the ceremony, each graduate was called to be recognized individually with their certificate of mark, which continued with a photography session. All happy moments of the graduates with their groomed graduate suite and the graduation hat, along with the chief guest and the faculty marked a memorable ceremony. Graduation is a venerable tradition, which is dignified and requires proper behavior.</p>
					<div className='news-arrows'>
						<Link to='/inter-collegiate-cultural-fest-miraki-2019-bangalore' className='prev'>New Post</Link>
						<Link to='/icreas-2018-st-patricks-academy' className='next float-end'>Older Post</Link>
					</div>
				</div>
				<div className='news-rt'>
					<ul>
						<NewsList />
					</ul>
				</div>
			</div>
		</div>
	)
}