import React from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import NewsList from "./../../Components/NewsList";

const EMList = [
    'https://imagegroup.in/images/news-events/inter-collegiate-cultural-fest-miraki-2018-chennai/1.jpg',
    'https://imagegroup.in/images/news-events/inter-collegiate-cultural-fest-miraki-2018-chennai/2.jpg',
    'https://imagegroup.in/images/news-events/inter-collegiate-cultural-fest-miraki-2018-chennai/3.jpg',
    'https://imagegroup.in/images/news-events/inter-collegiate-cultural-fest-miraki-2018-chennai/4.jpg',
    'https://imagegroup.in/images/news-events/inter-collegiate-cultural-fest-miraki-2018-chennai/5.jpg',
    'https://imagegroup.in/images/news-events/inter-collegiate-cultural-fest-miraki-2018-chennai/6.jpg',
    'https://imagegroup.in/images/news-events/inter-collegiate-cultural-fest-miraki-2018-chennai/7.jpg',
    'https://imagegroup.in/images/news-events/inter-collegiate-cultural-fest-miraki-2018-chennai/8.jpg',
]

export default function InterCollegiateCulturalFestMiraki18() {
    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };

    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 mb-3'>
                        <Slider className="news-slider" {...settings}>
                            {EMList.map((iMG, index) => {
                                return (
                                    <div key={index}><img src={iMG} alt="Inter-Collegiate Cultural Fest - Miraki'18" /></div>
                                )
                            })}
                        </Slider>
                    </div>
                    <h2 className="h4 mb-3">Inter-Collegiate Cultural Fest - Miraki'18</h2>
                    <p className='mb-2'>ICAT Design & Media College conducted Miraki'18, the inter-collegiate cultural fest at ASI Convention Centre, Chepauk, Chennai, on 23rd of February, 2018. The one full-day event was rightly named as 'Miraki' (Meraki (Greek word) - the soul, creativity or love put into something) to convey the essence of creativity poured in from the participants' inner self.</p>
                    <p className='mb-2'>The Chief Guest Mr. Mohan Raja (Film Director) along with the Guest of Honor Mrs. Keerthika Udhayanidhi (Film Director) distributed the prize for the winners and the participants who performed exponentially well in Miraki Fest 2018. The overall trophy was won by Stella Maris College, Chennai.</p>
                    <p>The cultural programme was a grand success and everyone enjoyed it. It exposed many creative talents amidst renowned personalities. The success of the event was published by News 7 - News Channel Partner, shiksha.com - Online Media Partner, NPIX - Media Partner and Zebronics - Digital Partner.</p>
                    <div className='news-arrows'>
                        <Link to='/image-opens-new-centre-channasandra-2018-bangalore' className='prev'>New Post</Link>
                        <Link to='/icreas-2017-chellammal-vidyalaya' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}
