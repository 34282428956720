import React from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import NewsList from "./../../Components/NewsList";

const EMList = [
    'https://imagegroup.in/images/news-events/image-opens-new-centre-nagarcoil-2017/1.jpg',
    'https://imagegroup.in/images/news-events/image-opens-new-centre-nagarcoil-2017/2.jpg',
    'https://imagegroup.in/images/news-events/image-opens-new-centre-nagarcoil-2017/3.jpg',
    'https://imagegroup.in/images/news-events/image-opens-new-centre-nagarcoil-2017/4.jpg',
    'https://imagegroup.in/images/news-events/image-opens-new-centre-nagarcoil-2017/5.jpg',
]

export default function ImageOpensNewCentreAtNagercoilTamilnadu() {
    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };

    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 mb-3'>
                        <Slider className="news-slider" {...settings}>
                            {EMList.map((iMG, index) => {
                                return (
                                    <div key={index}><img src={iMG} alt='Image Opens a New Centre at Nagercoil, Tamilnadu' /></div>
                                )
                            })}
                        </Slider>
                    </div>
                    <h2 className="h4 mb-3">Image Opens a New Centre at Nagercoil, Tamilnadu</h2>
                    <p className='mb-2'>Spreading far and wide, Image Creative Education is all set to extend its digital media education services to the southernmost Indian town's headquarters, Nagercoil. A fully-functional Image Creative Education centre has been launched on 17 April, 2017, in the College road, Nagercoil. The inauguration was honoured by Mr. K.Kumar - Founder & Managing Director of IMAGE Group and Rt. Rev. Dr. G.Devakadasham - Former Moderator CSI and Bishop K.K. Diocese, in the presence of Mr. V.Anand - CTO of IMAGE Group.</p>
                    <p>We wish the centre grooms skilled leaders for the industry!</p>
                    <div className='news-arrows'>
                        <Link to='/icreas-2017-the-annual-art-expo' className='prev'>New Post</Link>
                        <Link to='/icreas-2017-montfort-school' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}
