import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import NewsList from "./../../Components/NewsList";

const EMList = [
    'https://lh3.googleusercontent.com/pw/AP1GczMEJcInDKAjKNrG88Y85oI9scdfw60c-Xxbcd2wnloiQKWcVGyBG_x1in40hEFc5mI2u03Zl29FlH9FaPBFoiyhGLRTl_92drIYOIwNqpoc7B4YPiY=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczNVxotLS1FViCt4CP3rD3evhANuvpvYw5zR_mgsdRIluVgW5139UuVjlq-lytzxN5QkOL1jSEEW0bi2ybBSDTuq75tI5lQstl51mmFPTgDEz5J1wNI=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczMIEtPd48R29ymRjk6BfkgAQEO21WVhuyRyNIAQgLiOLVZ1v02WdjdLtDFdoTCM7ax1wYdAI8f3fyAMZCr431rYtUnNyUCI2kNnC8IIMoMa-fmwRzc=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczNYOzEOIMKS5buzSxtXemgP2uMo82i9H1vdAbH8ynKJ01teRVZAO0cLZ2GEJIvXJXI8KaD5xpVqx66CaJlAnpVmqg6eF4i24Dcdxl7oQF8ZsICsLRo=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczPOfIMNX8_ZiTM5NnXUnHxEIndM2URQjQFA3VgX7LOh1a2-X1PgMmYJz_hYLNr14bKbexoW-oHFyPJvPS8d0FS27FmsjYPEwnY5rxmhH2LC3CPFa34=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczNp3dJCyALIbWR_tA1oIcwyId5Lpp9glyXFBRGw2-tWOdP9vPoyByommcNDBUwppD-PWhTUVahfiJ6oec_lEZs39t_OX3uA2xmu5ZiC9LQdJD80xNA=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczPRi9uuQgJyLYHScmh599d7eLMpm9Hc-YMici--yvE6r50_lXx5KxJx1VovH9DWxhOk8ttgCjXwUlFOjAs5DFtxPqQ_rnOun4fkLNS3ivDF9SsyUp8=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczPq_KrNwYhOyCmJnAFZSr3sJ2s4WPZm1sne7LpjfSVoPFReK2edPpfYsO0nC-3JWVU9xL9axF4o4EqHpZ9sNZnYtJvMqd3UH2Ed9XTQriEnJUWNe9w=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczO6QfxpYwKWXfEbODEtGKCqywYlig6le1sC_kjfzSXtQRTuRgetAoUFbiDfy5AFp-Mf5f6HQNktxVAUsVZ8gZJTseJ9pRP3W1NrPJ2BLC53MBqbx-Q=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczNkwLj8UStgFZrD6hTmRN9Q8ifBmAruaQ8aPuiz2Z_1OMhHGi19OxRc94U7G14XI2KRZMJLn2dpmklh4ykXb3ABAndP7ZlgTpPrhuarLpiQLW_wz8Q=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczPlkmHoDzPInhnDvyxp72aycujfTJxnS6tCGYBgb6hTAW8DjcUSYFjShefDt6OZfnA0jP0rRGsQrxDB_HzIpQdX1Q9sdMZ3CAey4J790n--sUlp4dI=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczMWJq1WhnNgUb-w_cJB7oaGrNutl9Vj5wQjYcKvBkdjS5rDJqqSw_5N3f4cMoiYg9RUtlyMf0CRLw9GkoCro3G6K4-SvaUZp-Pqrb4Jn7AZ49TdDlI=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczP_5U367tNDnJJLyyX72I-cgug07sOlvG0Wpby202BXjFMB8qkdaxyS8jyrwfXYTlo6y6Z6wbSqzofINQHCxNolTdIGXmRiLXKxd7ED6e8x5ymqguw=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczO1muYNN0be3T1Tv1SQMoVL5Jwr_bb2BzZpFM1vDTTtTkxEQjFVYwnDfi360bAPNPGmShQEqeF2JON-uc83lfgE8LHynbU1g7WV20ZA8w5cF1USfpo=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczNBhzVaCAkwxKsTNMxnrnR_SxVW75otuu5mcz9Rrfp-5Dm8BK7_AeHI9esqiOpoSErjmUKzq0RSbrazUhLKLZQcxXp2GAzJ3S6VIUQYs238BIuICe8=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczOE1UrHS99iEYazf75yxslo_ExYEl9rnjo5nyS7dSzg40kzKPYXF_-adKPnN_1a-YHJpb3h_nCYPFWF5zVa1bZrj5K-auAH6YiXeHBRlnOansCyZ1k=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczPq2qzdWE9TJS6DZBqX6x6v7-MHt-Y4xJM8JVQxmQfppwD3_6F5vo9JJhn4ZGh-nGLEKXQsOubQVbPGpLDB1enGy04p3dJgIYv8ZfXKHUrvrgzHRyg=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczNAHLUr39JrXS6UnOz7GJpDSMyGHeLXehuzSJtpqWhz-u-XEbNxQsugOtfo-Sw7WcMVFXiXMA9ZAyMzgv8ZIpemkUrHFapWNgJUKlKtXzFgfg2SwXc=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczO5naQFvGkDpHaC9W6LMBllHRKNPYZi8d89OkHBnTBVo2KO_nU_BOb_7a6zZTwAVWNYpZz7gatx28Ba8z1ZriI-ZsK-Wlg2bGcnUwOsxMuMA3BIknE=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczOauLK_X-ps44eX-DT4adx5o8nnwfslp-RWi4es63OIGVl5WtvkMVrHJ_ADgFAmypbHx87EcrvK6iollaHg0uNSCcIJYKVLlBnt2lPTUTlLt1OeS20=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczN-0h9nFQHIufQV4oy1jH9KkoeP6SAsVH5CYH4SaSWciXN1ABm4hlIxGd_rhFiVBRCIB6gMvyWQHg4mSuMronwus8bX-BOWLl-bzzs4kuielQrWCDQ=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczMQUMvnqOnaT6-tNPVrd2Z9YtLjUDgTeDn_d3_SoJL-T8Nyb0gyo1VMR7-wDx7hDNWaagx4jbXeOaUN6-U26EBzjw2zw8JOF7cgGvYAlyFtH-mHjF4=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczMuR3We7BIghDpyGawN9Asdq80FaVLa8VyNVCZyich4S_Ciehs6B9_9ty-iG3TTgM5bRdM3o8kn1Ha2YB1K-CfqtvxQ7O1LP9b-tUS24CvylUhU5ak=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczPg2IDn-u_pCJW6_TCgkCKlZ2BQ4HE3gc7Xh1INVdVo34JzqRBrvDRGJh9j-ee80gGr_q3DKUSif5BQ1oJlljDU2-JU5vcllBSgc3IX6oE8SAbjg-8=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczOVStnpGfG7QWe3SP00iR54Z6b4muNSDXPTE-M0-ndWpqz5IJzslfvSoZOCLZ4frdm_LloI0QoAh7c_fRitsXsRJpZ1ZXw2Zp0md5NCx4qGbzZ-FgM=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczOGAtkqpjcHVBpgyHnbNzCiMO8JbKZYDOlqR_utw_V-a-KKLUPSKMDqpC3NV15H3aRNL9KKBUmHbjzBCSAIVpMJlMKLXAaq-m-2hz3MJoERUQEnpiU=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczMdcXgX4CtuEkVQxhqewFMns0xfla7lOVmuJ1eOF92LWG2evyZjf3YZjZPrgjo0Oj0901433vvg6UrmyDJBZCSljCnkrU4jXrgNp70Jz3U0ec1xUQ4=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczMaYOHQEnQpchYJZP_ZKS9p2Hi8sZN8stTG5Xa0DQ9mBeEk5_bjWvWgpvxMYz6c4OJFfkbXZoFUv-wxN6TpbNlGp0RHbMXhXkcGbc99Vs3AqATz-oc=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczP1U5ntNhZwMb_uWjst5pynPpWGseAGAVnBVLoGBn9MWSEFrMoYerMY191AOjAT8RENgLApcYVp7U1_55P3BCpxf75Mctcd8WYf6dQeG2mjK_D_Vd4=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczMGaUopUJIwZmzcc2x9LR9QY7H49X-5XzRTk7YJXP7Nk-ojMKKLY6-iQtwj4hcRA5WT42dMjKy4FuKQ12zthdVZnYJTQy1CoHl4JGernG3EfSzVf6k=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczMC0HWJlkN9d5qKmdG9E1NW67Fjb2C2CbgHDD7PL41BmmTrSoIBHckeEZjp8GxlqSJM1p3KMkQvnJHWYva5pLgVb9Fd-u-AKw622icfZf9hv_uzUdA=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczPwJZJhd1phfZB3eTx0DAJNsVUtrZhtkWUzE5PBciPDKNG-HlKLIEjydXdatgNC3FShlmYtM0aFcE487FEi2E0-gUPLc8BkNtSnySHV3Z_5gTEssW8=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczMX82eZZrQ-Hz1D921g_UHohkH-WET5eMJ5iP8KpLyLA56OumhgmTEo24IT9fVWJ-hZAh9R0iKsIVaTREPBRKpCqRRm9dX86tLTWKCY4LOiMizE20M=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczNBaMFJOwSBfPm-IjkDrl9IcNHpz1R83mqAIP7ZSMYLgiWBXDmL8YJQ5I0gIMOfPVlIErsq43I3KqFTn2quoTErSB7txHvFR5nIaxQ-ct6XiOWlutM=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczPtqaQzLPW1bFF0GTMqT7TIvL9QPKpNFw8Er4H_EielicUC2jA0jreQCPqNrsaVNwvTzYVvXs6_RKkCQh7SE54q-KPwDIx68IlFKAQXF3VRfVlWH8k=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczPALo-L5wllfMA5JwrfZwALlfIOYdh5Cg_tNWpTzCaN2uARUVn7-Il8X-CgmD9bmWa2TeGlIgkxuXEwLgc5Lq2_-eNO1RbRXuvWwz27j1uIXbLpVIM=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczP3KfB7Jk8cv_tqeXLTq_XbHx2QZzqTHphvv3mWP6sj7VTb7G6WEnATHv36-1S1E92RuTj7qVLRoUID5BKtoXhgRcgg2gxLiJbrKE4h-O8FtqVpXB8=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczPzjqDeQkZXq9lImESBJfQeGmbTzZg5gy383bNT1qLuaHbrHKK_PnIxpL4wQpppPrN3gxkO1ZzU-FoppGZpHmVHVvZeGPFtx2tfJQK2AKgYSFQeca0=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczNWcBrfG1-jUvODyCyOecCTjtaJpvpbvuVZRSj6NDgr0XGV-QbLf3kWE8GtEa1cP0y2hBZ194Xk91ozgnkZuX9NothRpM56_UXIdzipaUh-oI30Nk0=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczPVltTlETbWD-4We5RjRcbj-kpFGQHsSiqcvb1DVD0TJRIJXznij14dODvUuaSPMf3n627s8Asg8rrir5zDM1N96dwxjDBfjazkjIeuy06zWyKCyGU=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczMuvLIuN9FcX686ThzL_6I3pGDi6Hn71vcAJP233UwDy1FAcGJ0z57KM822ZJskHhuSFa0lB9cieQcb-eCeKUIv8HUrYcTcLRy8SfbQNuMotQlBc1o=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczNNyLSh_4d2xV53kKJULE8GYzAlfgnPpzlt2CoLV_rxxxaPnDxdpSTDPVscMtP_GdbSPyUBCNzGmQHUC0pHEmqhImit99X26NSeq9G2RhGJ6YdMZpg=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczMED3Cl7CI46mSLXK7lNS3_MouSvZ-APfPwZw0-WRQmzjS3UOgmML9qKY39rgmNd4JelgUFSi4aMe3xLRi3YIf-C1l-C0slM0KoDb9-zVXJmU2JikQ=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczNOku2Jz-lW5kODDYOmJYNflxRBIkzy2AI1knDm4E4wIwYTF2L825JJuMOIj6F3QuqAM5i79DQV_NFPGNjHxPKWVFdqZNWcUN61YWQrX4s8ZaG5hOQ=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczMz6NuTQkBGdokpWV6RAbRmk3-ONGzOfRGTM6CF5usgIzMN206TQ7cq2H4PUuDF8ORs3HnDni2O4JNcqx8XOtjekOJ98luvFeCtu72_XbL5ipslxUc=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczM3C8T7B-3XQLgx-BVeY5JYysGWGY68iMk0ldBm98CRWb2JVumwDvDwrHmG1VhuuedJMv6UEk3H46wUe1PcK4cE2-WKj5tahVBqGAjni1o-9NjhQ0w=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczNRKyyLT_E1hwk0w9LXBt9mTiAzBqODOtoBGtTT2bJexB99E7ytE8j2csW2BJPnGDzDSiNZw2gzge8La5ZUHq5wPsUPbBikUMTJll5lG7dJc3PVrmE=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczPU_ERkhdFXXuDgx3hZB_9QCRjyCmObSTovFnD6EhX2240peWKhyjW3-4R9mcjuIIfCQfDLftFLih00r-1FVB-mANNfSqCT7xZUP-mTNyDBEYWpNFE=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczOS0KEkYIERjZ3Wg5F9RXqUMyqxuS9kF1-oNo0JueQvt7lV96IbGudM8qR9mZQa_dHfWDQUMKJ2K5gC_xSC5vDvW8uRv1fgyhJV2g8h7B0Drapinlk=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczOxptbTrWNbVQV9Fc7mwjJWcUJMhxO8TQ6_rrNDbTecDV699C8djJnB9CYyD_Iw9-_iTV3xRn94LWJtsS8iClfUyfksesJXVd9jjbrLwsu8yEZhq4E=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczNlMadjUwDNopNOaCnfMZKzUq2fGFsSn7GHoLY8OJPd5VO5-xyGhr-bojycNa2tcbI-T7NdPIsew2ufTO_ddi-j6FehCgf1UtNxQHPx9McNt7vDYOQ=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczN50dMhm2HBTn4Bb5iUiNmApmp4n2eZWN5TiIrrUFXcISIGYdpbFDcFIjBZ52H4V7nqmID4WWmztElrb4m8XCwIS36Mp2n0_ygow-r4HM_Nzi14tG8=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczNCkAER_hOlpWvayZwodb7Zomj43vl8sIa3mA4kJ51Rcb3kygoyR9B-0JRXEfXvRkJCkytwuGCQz-4lYjsjjk4LKcdSlAJIqp-yna7aedPwF6wY4EA=w1920-h1080',
    'https://lh3.googleusercontent.com/pw/AP1GczOjdfFrtTQtx3WXd003Rmep4nknKGY1q7ROT4K5Y3yg3FSa_XPV5TkIsfe6NQWEFgZtLD8kL62GbCgZ1WHU-Pz1oL-1VGuky0MaB65eyjGzqIGYt_M=w1920-h1080',
]

const EMList2 = [
    { videoSrc: 'https://www.youtube.com/embed/Lmxc-HjnkUM?si=yBpbfXMESxOu7-l4', },
    { videoSrc: 'https://www.youtube.com/embed/TWdmNRhtC04?si=XtSCbFB0EPnjnaFV', },
    { videoSrc: 'https://www.youtube.com/embed/iP7NQnw2Iwc?si=ILfyH3k3_fcljCRe', },
    { posterSrc: 'https://imagegroup.in/images/news-events/iac-prize-distribution-ceremony-chennai-2024/1.jpg', },
    { posterSrc: 'https://imagegroup.in/images/news-events/iac-prize-distribution-ceremony-chennai-2024/2.jpg', },
    { posterSrc: 'https://imagegroup.in/images/news-events/iac-prize-distribution-ceremony-chennai-2024/3.jpg', },
    { posterSrc: 'https://imagegroup.in/images/news-events/iac-prize-distribution-ceremony-chennai-2024/4.jpg', },
    { posterSrc: 'https://imagegroup.in/images/news-events/iac-prize-distribution-ceremony-chennai-2024/5.jpg', },
    { posterSrc: 'https://imagegroup.in/images/news-events/iac-prize-distribution-ceremony-chennai-2024/6.jpg', },
]

export default function IacPrizeDistributionCeremonyChennai2024() {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://cdn.jsdelivr.net/npm/publicalbum@latest/embed-ui.min.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };
    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className="pa-gallery-player-widget wdth-100p mb-3 hght-480" data-link="https://photos.app.goo.gl/3PY1Btf1LPULPwMv8" data-title="IAC Prize Distribution Ceremony Chennai 2024" data-description="54 new items added to shared album">
                        {EMList.map((iMg, index) => {
                            return (
                                <object key={index} data={iMg} aria-label="IAC Prize Distribution Ceremony Chennai 2024"></object>
                            )
                        })}
                    </div>
                    <div className='news-cont2 mb-3'>
                        <Slider className="news-slider" {...settings}>
                            {EMList2.map((item, index) => {
                                if (item.videoSrc) {
                                    return (
                                        <div className="videoWrapper" key={index}>
                                            <iframe title='IAC Prize Distribution Ceremony Chennai 2024' width="560" height="315" src={item.videoSrc} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                        </div>
                                    )
                                }
                                return (
                                    <div key={index}><img src={item.posterSrc} alt='IAC Prize Distribution Ceremony Chennai 2024' /></div>
                                )
                            })}
                        </Slider>
                    </div>
                    <h2 className="h6 mb-3 noto-t">ஓவியக் கலையை வளர்ப்பதற்கான முதல் முயற்சியாக நடத்தப்பட்ட 'இமேஜ் ஆர்ட் சேலஞ்ச் 2024'</h2>
                    <p className='mb-2'>படைப்பாற்றல் கல்வி வழங்கும் கல்வி நிறுவனமான இமேஜ் குழுமத்தின் கீழ், ICAT டிசைன் மற்றும் ஊடகக் கல்லூரி (<Link to="https://www.icat.ac.in/" target="_blank" class="d-in txt-clr-1">www.icat.ac.in</Link>), இமேஜ் கிரியேட்டிவ் எஜிகேஷன் (<a href="https://www.image.edu.in/" target="_blank" class="d-in txt-clr-1">www.image.edu.in</a>) மற்றும் இமேஜ் மைண்ட்ஸ் (<a href="https://www.imageminds.com/" target="_blank" class="d-in txt-clr-1">www.imageminds</a>) உள்ளிட்ட பல்வேறு நிறுவனங்கள் உள்ளன.</p>
                    <p className='mb-2'>எங்களுடைய ’இமேஜ் ஆர்ட் சேலஞ்ச்’ போட்டியை பத்திரிகைகளில் வெளியிடப்பட்டுள்ளது.</p>
                    <p className='mb-2'>இமேஜ் ஆர்ட் சேலஞ்ச் 2024 : திரைப்பட VISUAL EFFECTS பணிகளில் கலக்கும் இமேஜ் மாணவர்கள் <Link to="https://tamil.behindtalkies.com/image-art-challenge-2024/" target="_blank" class="txt-clr-1">Click Here</Link> </p>
                    <p className='mb-2'>திரைப்பட விஷுவல் எபெக்ட்ஸ் பணிகளில் கலக்கும் இமேஜ் மாணவர்கள்! <Link to="https://www.cinemainbox.com/new-cinemadetail/image-students-involved-in-film-visual-effects-work-9788.html" target="_blank" class="txt-clr-1">Click Here</Link></p>
                    <p>ஓவியக் கலை பல துறைகளுக்கான அடிப்படை தகுதி! - இமேஜ் குழுமத்தின் நிறுவனர் கே.குமார் <Link to="https://nba24x7.com/archives/7216" target="_blank" class="txt-clr-1">Click Here</Link></p>
                    <div className='news-arrows'>
                        <Link to='/photography-contest-winners-runner-prize-distribution' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}
