import React from 'react';
import { Link } from "react-router-dom";

import AboutImgw from './../../Images/about-image.webp';
import AboutImg from './../../Images/about-image.png';

import NSDC from './../../Images/nsdc.jpg';
import ctImg from './../../Images/ct.jpg';

const spItems = [
    {
        title: '3D Animation',
        uRl: 'https://www.image.edu.in/higher-diploma-in-3d-animation-course.asp',
    },
    {
        title: '3D Animation & VFX',
        uRl: 'https://www.image.edu.in/higher-diploma-in-3d-animation-and-vfx-course.asp',
    },
    {
        title: 'Advertising Design',
        uRl: 'https://www.image.edu.in/higher-diploma-in-advertising-design-course.asp',
    },

    {
        title: 'Game Technologies',
        uRl: 'https://www.image.edu.in/higher-diploma-in-game-technologies-course.asp',
    },
    {
        title: 'Web Technologies',
        uRl: 'https://www.image.edu.in/higher-diploma-in-web-technologies-course.asp',
    },
    {
        title: 'Fashion Design',
        uRl: 'https://www.image.edu.in/higher-diploma-in-fashion-design-course.asp',
    },
    {
        title: 'Interior Design',
        uRl: 'https://www.image.edu.in/higher-diploma-in-interior-design-course.asp',
    },
]
const ctItems = [
    {
        title: '2D Animation Design',
        uRl: 'https://www.image.edu.in/certificate-in-2d-animation-design-course.asp',
    },
    {
        title: '3D Design',
        uRl: 'https://www.image.edu.in/certificate-in-3d-design-course.asp',
    },
    {
        title: 'Digital Video Production',
        uRl: 'https://www.image.edu.in/certificate-in-digital-video-production-course.asp',
    },
    {
        title: 'Graphic Design',
        uRl: 'https://www.image.edu.in/certificate-in-graphic-design-course.asp',
    },
    {
        title: 'Graphics & 3D Animation',
        uRl: 'https://www.image.edu.in/certificate-in-graphics-and-3d-animation-course.asp',
    },
    {
        title: 'Interior Design',
        uRl: 'https://www.image.edu.in/certificate-in-interior-design-course.asp',
    },
    {
        title: 'Visual Effects',
        uRl: 'https://www.image.edu.in/certificate-in-visual-effects-course.asp',
    },
    {
        title: 'Web & UI Design',
        uRl: 'https://www.image.edu.in/certificate-in-web-and-ui-design-course.asp',
    },
    {
        title: 'Graphics & 2D Animation',
        uRl: 'https://www.image.edu.in/higher-certificate-in-graphics-and-2d-animation-course.asp',
    },
    {
        title: 'Graphic & Web Design',
        uRl: 'https://www.image.edu.in/higher-certificate-in-graphic-and-web-design-course.asp',
    },
]
const dgItems = [
    {
        title: 'B.Sc. Multimedia',
        uRl: 'https://www.image.edu.in/certificate-in-graphics-and-3d-animation-course.asp',
    },
]


export default function ImageCreativeEducation() {
    return (
        <div className='content'>
            <div className='banner2 banner-ice'><Link to='https://www.image.edu.in/' target="_blank" rel="noreferrer"></Link></div>
            <div className='about-cont'>
                <div className='container-fluid sectin'>
                    <div className='row g-lg-3'>
                        <div className='col-lg-8 col-15 mxw-700 about-lt'>
                            <div className='about-ct wdth-80p mxw-500 m-auto'>
                                <picture>
                                    <source srcSet={AboutImgw} type="image/webp" />
                                    <source srcSet={AboutImg} type="image/png" />
                                    <img
                                        src={AboutImg}
                                        alt="Image Creative Education"
                                        title="Image Creative Education"
                                        width="432"
                                        height="422"
                                    /></picture>
                            </div>
                        </div>
                        <div className='col-lg-8 col-15 mxw-700 about-rt mb-0'>
                            <h6 className='f-16 txt-thm-clr-3 fw-600 txt-lg-lt txt-center'>About us</h6>
                            <h2 className='h4 fw-600 mb-4 txt-lg-lt txt-center'>Welcome to IMAGE</h2>
                            <p className='f-16 mb-2 txt-lg-lt txt-center'>Image Creative Education is the first educational establishment of IMAGE Groups with respect to training services. It was started in 1996, from which the brand has been marching towards grooming industry-ready professionals, who are in line with the latest trends from the world of Creative Media. It offers various Diploma/Certificate programs and Degree programs to suit the need of aspiring minds of different age and need.</p>
                            <p className='f-16 mb-2 txt-lg-lt txt-center'>Along with skill development programs for students, Image Creative Education offers corporate training programs backing a synergy of expertise digital content development and digital media training.</p>
                            <h6 className='f-18 fw-500 txt-thm-clr-3 txt-lg-lt txt-center mb-1'>Highlights of Image Creative Education:</h6>
                            <ul className='list mb-4'>
                                <li className='mb-1'>India's first professionally-managed Multimedia training institute</li>
                                <li className='mb-1'>Two decades of experience</li>
                                <li className='mb-1'>Over 30 fully operational Digital Media training institutes</li>
                                <li className='mb-1'>Uplifted the career of around 78,000+ students</li>
                                <li>Customized Courses</li>
                            </ul>
                            <div className='txt-lg-lt txt-center'><Link to='https://www.image.edu.in/' target="_blank" rel="noreferrer" className='btn'>Visit Us</Link></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="course-cont ice-cont">
                <div className="container-fluid sectin">
                    <h6 className='f-16 txt-white fw-600 txt-center'>Courses Offered</h6>
                    <h2 className='h4 fw-600 mb-4 txt-center txt-white'>Hub of Vast Creative Streams</h2>
                    <h6 className="txt-center h7 txt-white mb-3">Specialist Programs</h6>
                    <ul className='txt-center mb-5'>
                        {spItems.map((item, index) => {
                            return (
                                <li key={index}><Link to={item.uRl} target="_blank" rel='noreferrer'>{item.title}</Link></li>
                            )
                        })}
                    </ul>
                    <h6 className="txt-center h7 txt-white mb-3">Certificate Courses</h6>
                    <ul className='txt-center mb-5'>
                        {ctItems.map((item, index) => {
                            return (
                                <li key={index}><Link to={item.uRl} target="_blank" rel='noreferrer'>{item.title}</Link></li>
                            )
                        })}
                    </ul>
                    <h6 className="txt-center h7 txt-white mb-3">Degree Programs</h6>
                    <ul className='txt-center'>
                        {dgItems.map((item, index) => {
                            return (
                                <li key={index}><Link to={item.uRl} target="_blank" rel='noreferrer'>{item.title}</Link></li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            <div className='cour-desc-cont'>
                <div className='container-fluid sectin '>
                    <div className='row g-lg-3'>
                        <div className='col-lg-8 col-15 mxw-700 mb-6 txt-lg-lt txt-center cour-desc-lt'>
                            <div className='wdth-80p mxw-300 mb-5 bdr-radius-8 fix'><img src={NSDC} alt='Partnered with NSDC' /></div>
                            <h6 className='mb-3 txt-lg-lt txt-center'>Partnered with NSDC</h6>
                            <p className='txt-lg-lt txt-center'>Image Creative Education is partnered with NSDC (National Skill Development Corporation), an initiative taken by the Government of India to offer skill improvement training to millions of young minds across India. With this alliance with NSDC, Image Creative Education is committed to offer its trainings services to more than 1 Lakh youth in a span of 10 years.</p>
                        </div>
                        <div className='col-lg-8 col-15 mxw-700 txt-lg-lt txt-center cour-desc-rt'>
                            <div className='wdth-80p mxw-300 mb-5 bdr-radius-8 fix'><img src={ctImg} alt='Corporate Training' /></div>
                            <h6 className='mb-3 txt-lg-lt txt-center'>Corporate Training</h6>
                            <p className='txt-lg-lt txt-center'>Being a pioneer of Media training, Image Creative Education has gained vast experience in knowledge feeding to people of different age and need. Its vision of making India a Super Power in the field of Digital Media has always fostered it to expand the education services to different sectors of needy. In this regard, Image Creative Education has been offering eminent training services to corporate to promote the skill level of employees and augment the quality of production.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
