import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import NewsList from "./../../Components/NewsList";

const EMList = [
	'https://lh3.googleusercontent.com/QarpzQSKcb6Bmse6Vb-nd29nhZ2nZwxDAJ-0_DkGBP-0hTqs4FU11KMJe4dMxHa5EcN1BGBLDLlDKPhJoTxKOcKqcbKEmKUMYFZFMfmCY5JOZ5_bzkAIdxCouahTKo4Jsng9nHWANQ=w1920-h1080',
	'https://lh3.googleusercontent.com/12bK-e4V-GdnsKngCePPBMTI476XLhqHLSs_XIzMXkUWpMJDrFAkJ9TJO4dyR6BCXm5WxHV4eKx6oEWqmyKWVLFu5caEifejpUmVTqO6rPnP7GB_K3Xr7DUBAK_deY2I70lOBEGveg=w1920-h1080',
	'https://lh3.googleusercontent.com/C0ZVd2vFBg3iwO6Fr8hHdTOxo8wQjfIFfBNBSoyvjiDTJUCOBJp1KpcjYSjjVxrdb1US3MFGfm2LYtVqfNkR_bNxRp4rjV5_Il7nkZitw6V9dG_hPZqzQd6aTw0MViz-gIZ5XuRxtA=w1920-h1080',
	'https://lh3.googleusercontent.com/kQQldumrIoPPb9jergerEIYcDKWR5d-Qc1PUcv2uNaQcwxwkaS8RjDIx96RFhGeJno-yR6a1WD-euT1nbep4Et40YgqOOsXUZNtwhlfHGMWFWfqn984SqE1lR3yYlt3uZzEpQjB8ag=w1920-h1080',
	'https://lh3.googleusercontent.com/5Xm8cn3dOu2OxdJW9VEHuBC6CBFuoE3fML_YEkJmWyEZ1_F4r21Cz5zqEobG-Dz1eX1YbwgZLyXy_9-dl93cCeN5pwi3pGweKTOFDEEp4kzYWrNWk4zkycDnWZ9i_jChuzpU70QMmQ=w1920-h1080',
	'https://lh3.googleusercontent.com/UUAwwroWixCotkTLhLoi0sTFxf1bfVqzx7rqFKcbtEqRSAQR8kWCLSjIuk7adqNsqoWgCK_q8SHhcnBczDEeyEq1oFDO523berwdAsSvbYZQCfy5L0IlrCH9IAQhVkBMiHZKM0SqCg=w1920-h1080',
	'https://lh3.googleusercontent.com/-utHD6XLd7JWq3Vq1tAukwV-bNra-M5ZmuQL-6PhntLlpENfVk4yqXdaTk7YQynKfd9mRtldun3WzbM8jbJEK6Uzo0ySrG5tkjnqklbdDR81Q277wBwpLzH12Xq12EMrgaTwx-dXRQ=w1920-h1080',
	'https://lh3.googleusercontent.com/NjxKDVdtpF5_sDVeM9a7A8oXyETHjmUtBFu0QVr3gsyYGdj3EgN_4EJm-43L1bocxQSimNF49AvTltktYihimlsPyeIRS4aC_1-Sr0s6-11OmxpLiY-W_vc-9rkyddPLx2xuguYzkQ=w1920-h1080',
	'https://lh3.googleusercontent.com/5iSiZ1hyCjrU4WzKkn4fI-KfRHqqxAiU0n9Zg9uSIEx50BNZmoBAX09iuX0qFpzl-7nlBAceAwQFcQRGGSH-BHepYYW9aSegMa_KLtanEo1tWryD9R4HsKwLd-CWJnoRCOLoTZ4Y2A=w1920-h1080',
	'https://lh3.googleusercontent.com/grWguk_lROLJ2tKkS6njro7onZc9hIYw3sA0F0bWMzKfGuThJ6f4KB97HlMF8_Qzy69o7NAKjX7Sz_xcxXqrkd67H2ArEW2Ts8W_c6W62IR5QB-Vp7-F3OqtcZ0s2QvvOwJnxlOABg=w1920-h1080',
	'https://lh3.googleusercontent.com/s04zmtUpob-80VWIZ4KHqHqH2A3wK-BaLU0tyGcbwmNQ6jAutS4n1VWQwfMz95IOyznZmUTd889QRxECVRvHFqxC_OfPPyYq_AgPTJjBoqa7tTEa-veSHyRuF1Jor60lXOZFsqPF3A=w1920-h1080',
	'https://lh3.googleusercontent.com/iYIAwOk2miOspDnELt7ocsINsHsnkEhlCmhNuV1I6vuu8x7Uuo_kmktZN82xp-ykmUodUa8QTK3B07a_Q8DS7AyQ00t1PnPj4jDXoPimFDhe8zie0tIBvoMFJh23ep8WjvOQAHdWKA=w1920-h1080',
	'https://lh3.googleusercontent.com/vydqPucVd6Tlyb-6MA203EWM8U42XE8po1jS6UvTzZWFJJogE8UvUWdkVRzkcHbukmS_4L1KlGsx2lvrH-hL_lqfk3UgV1u7sT1kA2ypxpr6zHj_ztRIXZNBc4H5hjF0xjgerrPg1w=w1920-h1080',
	'https://lh3.googleusercontent.com/uAoLhn6yvIlncGxD4BeBm5X8HgyAlOoxf3a3XCX_vWan8pV7Iq1GnKNdA0zJEulsx0LEeBr_oz9QFxrbN0ljzUnHhXyVGbRpQgyy8c4dvW6XaFwds0cicXPKLNe-0QrDmE5LwORoJg=w1920-h1080',
	'https://lh3.googleusercontent.com/62oBmqk1mr-8OwEY-l1oAyOlKXVvPHAnN8JFL3VH1yNNTGTauDBn9YLiR5z326h1YrOlSN1iY7CcPlLhu7wQs9zGdQAtnv5k8afEn7q3S2UYhZ0hwwCyTJ8wWELya9tn6fcrtFl4qg=w1920-h1080',
	'https://lh3.googleusercontent.com/phFjUs_zna_mfAij0x7RlEXdhnFNDJe5my6hrQeLtVuX5jG5k6f8hBaEBDYvR4bsf9ScKQJB-3ILbAZbeOhlBgSXfjmka-65f3CELmcxH7Dt2Pqkyn3eu_mITzC9bIOhtFJ0r954Rg=w1920-h1080',
	'https://lh3.googleusercontent.com/FmKTMhvN3eyUL_uSm6J3yGBb9ryxaJTW9pw_S7-BTS5ZAArQLBMFrwiN_Xjx771rNA3sT8JlcT3vLfinlrEgjbVh7VrxeXSVOZYsZA1YOd6_jLEaLUdw2hs1I7BksnEUk07zuTwgpg=w1920-h1080',
	'https://lh3.googleusercontent.com/9wxYl6UBXiajLsw-T7pboFXjCuNPOepqdlIbvP565EdkYBnqweGhifJ7TIKzV-Q-Hj34SJIcmmjyZ9BjfZBBPu-eDG3H8D2EHMlzk16pf0ueSE0TGVp1bqY-B-ym_W09Y4zIAyvL8g=w1920-h1080',
	'https://lh3.googleusercontent.com/wUprCyX6Z_dXFV0fIVHqm8e4sCBN3VQ6kaEwhiS537zbO9a_5f15d_S2FJVPawQInneLhfQ7FtTPH6WNamgWB0IlQdqu4AfswzpiTvZYnAuvhpsF_l97jCWjtng7pyUvUktXzKLBow=w1920-h1080',
	'https://lh3.googleusercontent.com/to98rDp3bDPe81I-sLqVW73SDCL0DHfuvsuL2CberXG6W15IFbEVZDQF_COm_Ms0u0Y-_H8g7SOwnnEDyC5L7p9UY1JoKtob5lfEkwEgZMBxbDRrVunbr_mEX7Bsq2R9Ejn3rQctzw=w1920-h1080',
	'https://lh3.googleusercontent.com/sKOyC-a9kB09jjslIqfAMNxqb_iLT7gxoBWBDVl1JIHkV1SXF9tYJ0ag81bX1QOINR2nFBfdZasM1GAT3GlV708mpHTiK-EAC9OFeMNbO6MQElQ9U7ITLwAwA29-5VpiDgvSk4NAtA=w1920-h1080',
	'https://lh3.googleusercontent.com/y4Sh_vLbrOPG6IguJEI1RmWBBU0tRjSP0LZkEc6YZ416JmHnneZIgaAIT339qtseUjlaEgIsL2ZwGw54xjfsJMsq1axBfinT7mxF5OBK6WJ4tKflT52VAhzv5Jk4XDMy3ft9egroAA=w1920-h1080',
	'https://lh3.googleusercontent.com/cccMPMiTMqrgmQtoXNIJUKyivwcPqSRu9uGUSvzqrEY7jszcfFvWaoUY_-mkZld5MebwKqebnis11LeQxtJscRZHLrucl9r3rR6oeWsUd6NZIopzfMlRrMoUnu4MNUGi0Siu685gsg=w1920-h1080',
	'https://lh3.googleusercontent.com/f_vuUllwK-0nVNthNSMciZmQCEX96_961bnTqDuGXBjppa_59FjsreVRdYEi6kpbIUUWFKfXFFz96amEnWegp2N6cfqa51lktYzn-MBmz41eR7IVO7Jm2ZMg11hAVza5x5Z4vNMp8g=w1920-h1080',
	'https://lh3.googleusercontent.com/hURGF8OnGgOq3jnfgFitN4iPjt0S5mPt19hoqcFeP6pG8hd8pRyB1kX0rGsNo-QHOzJE767ULinRVaNsVGE77Qy6WraFvyl9_ty97hsy4ZPP_NnZwjHrC6YokBA_fokMDQf0pnk_iQ=w1920-h1080',
	'https://lh3.googleusercontent.com/gIjvcbEeWzJHdJcKC70wOKU-k_mVfZo2n_gIMaoLjlPfHcvdlZBHwVicgHH0GCYPJGOoc-PZTn3mXwSJefyuiaiIZK5u8jpjfxPbShxOZ6uYAUaWSsUGTFWLi7X-bQ59TAEOiGEx9A=w1920-h1080',
	'https://lh3.googleusercontent.com/FebPtqIBzzQLsjTm7fFSSnpkm3fOkEcMSJ5UM-LxT5ZFG4KItKz6zy9L3sg__62B6e-OW8zQB9LYkjGOyuSy7GeX5xp0vPGeGU-J2X_zFE9vS_JNph7FJaoeZ4J0GyaglatoBCggSg=w1920-h1080',
	'https://lh3.googleusercontent.com/Ti_tO72gkgbDUR_maHGIAGTb6RJSapD-wKqnWJNVZbYdC5qlXjXqCnz_BZNhIOuQF2EjJc52MeavFf1Ne9VCanLjphy6gfT2-2w3_YeMZQTZTY4VIAyGieyKnlgeYq2q0gdPk6WOdA=w1920-h1080',
	'https://lh3.googleusercontent.com/B3FsGzNBNBW0V0jhPN2U5scGU0xAi3ix1ejr1iBfNcog7h6S7MAYX5lbmR6yHVoAzYNAlQ54-433H_fpwoL6tSVpBusL1O2xD-t6lExCfB9lH1tICiP3DJpz37WHLC6QOeHMqwKvMg=w1920-h1080',
	'https://lh3.googleusercontent.com/gkcdcc4ONnNuMXrS-Zj8VZnCXSMFJHTTQNZ29Faoec8cd0hDnc_Vj3cdKq7blNIPEJveAzgHkp_TqizxSAcaZgAXvzPrR2SHolARltuiztkf0D8CArEHZorIRJBBfiNotpJbWcU93Q=w1920-h1080',
	'https://lh3.googleusercontent.com/yIoUF2Vlmi4-8TPSiEI_VDsUCR54OFOja0hx29Ei8NEwQ1xMs8grKgEMmd9zithZxDG-YbaG7MqabALzLC8iqb8icQg0En-GoBwvZY0E2XMWJ3GrJTZUHpDaUZKEyw6PnoKSYPws7g=w1920-h1080',
	'https://lh3.googleusercontent.com/uA5zkqHzw6pDLoeUq4ZlxBISQTmrMI1slI2wiFy6Z7KoZJY_6pjeP-ITPceN5BkZiUWSeo-WFGQaEScdw5FhbuqYmkHH2QKY3Z-hZUV3CgAU7d6HBBJgN6Newvm_8ZlaAAfX8a1WSA=w1920-h1080',
	'https://lh3.googleusercontent.com/31ljjNfeygiEl5l1tum7SRakwhof7sK31RuTX3gZg4F9-gRqAe6duqqON1Hn7y6c9AynujJ2rjC5tR8CdOy15a-UEO7P5BDv9c4r4NncpwJP06cJCx_qDW4slM1El1UQ4_IghOffqA=w1920-h1080',
	'https://lh3.googleusercontent.com/SOMk4czUEIvOtLvjGH9KF_O5Pux-KPyJpVZ2vGYrFzU0ohIVmLQm3qRcxgoXAuwGRiCFhMhw4Ft9g6oFt-Y0w6tDQATsWpqoMqHDcB1YvwHdnmBxgLlSRf67chrGPfzWkuqfK5RmSw=w1920-h1080',
	'https://lh3.googleusercontent.com/vDa4X2t4Q2M9J6zaazJ5G7vbZft3c0YtABg4v3ObxopIbTpI95zWrhIuX4DEuywUyEDlPXw3Z5FJEbtDHZtAmSzIsFTdPX3FJmZ3hfWtlzqX_zWpNkljJcPUct_FPlOYPKkSvDbKdg=w1920-h1080',
	'https://lh3.googleusercontent.com/x5u1k5DSYdCmSAUzx783npQLfT0lA6tguKENw60u5R2j6mGfGlbGcT7xwk-EITq1-dNOoKGUkIioeQco-4WdE3zBPidDKoVA1Y1oLqDtC88RQEFHk-KNCLZeREeDfFn0cnOhXfMNFA=w1920-h1080',
	'https://lh3.googleusercontent.com/8BT7Y0qU5wSr5GN1VJeGGz6FT5G7pPbOi0TAWUkyaF2a5VF04H8GoaqPLHYkLqVDk4MEtgwawC2521UR6wzVJ64bpkOzxTU3ZO4WGXczNCbgahmoKy9traPVPRatZywpE0crHMAF5A=w1920-h1080',
	'https://lh3.googleusercontent.com/_5s2nB6I69WwWikz3ic6FDf7uY_AuyBOHEBbvtjN4TFFbPZsbSnajtvPX4MMh1Cp7ZNEDdeM8W0KO8DZW4VkPG-TdFLwJ9k_Y7PW_axxBwzQ2Ea6GKL68J8pAij0Np8T3t3HYCehZQ=w1920-h1080',
	'https://lh3.googleusercontent.com/Q0G8Fg6108YX4jbstg4ICUI_YSAJHP5XWRvdLuAWTR-h01hOOTezkDkuINkthmPR_npu6f5qXM_FULp6WskmnIHbLsjNwlojiVnI6EaF_KEi96HmP51WMF9HgqXcgV-abqjwSyJFaA=w1920-h1080',
	'https://lh3.googleusercontent.com/lXm3HgKTCaYEsDdcLfym26z5SGKM956FrqihDHuKXgvOlwo-GPsBpv6ZEB7gNEgGqQ9fzWW2u0yBepj1hFxeQ_j3PObKJtyBXeYMF3NBGwtMo1pMUbzMDKkNxDLIMbas7o-0ZvyLoA=w1920-h1080',
	'https://lh3.googleusercontent.com/hmA-QqOEKJUvm_Fl1AUWhRggJdCOGnNKRp3Zvn9dc_G2QXDZkbXFHjb_Z5wfbDqQp3DkrqWjW9BhdS0rzgLGS7enILTZo2JMH_ny_px-mJMI2r-DGHRZ_Nu7t_GGgZGzXzTFSz3M5w=w1920-h1080',
	'https://lh3.googleusercontent.com/N0QMHyWkIPydo_MZPKkVD9MbZfclN-FJjWexarSnz-591GpVjAD3VHSyrEQ5GEi8OwDjnEHSYicPcaFvTbeqwtqi08iYK3NKJtlldwPVSofnR1HWKLSgWt2TUTbQ78Yvn0z3czWB5Q=w1920-h1080',
	'https://lh3.googleusercontent.com/2cjnbGJ2AXyuPa6SR58sBUirTt9iV2OQ2tqesDtSYLbWZCCgRZHtNFbwJT6cXxPP7Vig_QDeHH808N-wte91NMCMEPT-MEwy3U93-LGpXL71s7QE0n8DKiKDB_tAcPl2b_hHIuEhaA=w1920-h1080',
	'https://lh3.googleusercontent.com/mZCw78MoSwL81HkmEo1T9Cg1WfVBZ0sU5b82U3uh4rJKUxgFf9bcyX0KRMA2kltr0BgcZWFBpYB0vyhgizn2AyETU0vYph0fXCw6jrAtA16UXRseqvG4FVQEx-rmeHDh3CUnBTEdMg=w1920-h1080',
]

export default function InterCollegiateCulturalFestMiraki2020Chennai() {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = "https://cdn.jsdelivr.net/npm/publicalbum@latest/embed-ui.min.js";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		}
	}, []);

	return (
		<div className='content' style={{ paddingTop: '74px' }}>
			<div className='container-fluid sectin float-none'>
				<div className='news-lt'>
					<div className="pa-gallery-player-widget wdth-100p mb-3 hght-480" data-link="https://photos.app.goo.gl/VbegGtPF1TuFcEbs6" data-title="Inter-Collegiate Cultural Fest - Miraki 2020" data-description="44 new photos added to shared album">
						{EMList.map((iMg, index) => {
							return (
								<object key={index} data={iMg} aria-label="Inter-Collegiate Cultural Fest - Miraki 2020"></object>
							)
						})}
					</div>
					<div className="videoWrapper mb-3">
						<iframe title='Inter-Collegiate Cultural Fest - Miraki 2020' width="560" height="315" src="https://www.youtube.com/embed/yhg1JGwogKQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
					</div>
					<h2 className="h4 mb-3">Inter-Collegiate Cultural Fest - Miraki 2020</h2>
					<p className='mb-2'><b>Third times a Charm!</b> It's a Miraki day! the fun station for all ICATtians, at ASI Convention Centre, Chennai, on 21st of February. The “Miraki 2020” the inter-collegiate Cultural Festival of ICAT Design & Media College Chennai showed off.</p>
					<p className='mb-2'>The inauguration of the Cultural Festival commenced by lighting the lamp (kuthu vilakku), by Mr. Karthikeyan Velappan (Asst. Director - Bigil), Mr. Om Prakash (Cinematographer - Pattas, Marri II, Aram & etc), Ms Deepthi Raj (Actress & Model) accompanied by Mr. Kumar (Founder & CEO of ICAT & IMAGE Groups) and Mrs. Lakshmi Kumar (Dean of ICAT Design and Media College) who cheered from the sidelines. This time we also had few walk-in guests like Mr.Bose Venkat (Film & Television Actor), Ms.Indraja Sankar (Bigil Pandiyamma),</p>
					<p className='mb-2'>As the usual agenda, the event kick-started with the Inaugural Host Dance and Host Light Music. The stage set in action with event like Light Music Solo & Group, Western Music Solo & Group, Western Dance, Fusion Dance, and Fashion Show. The off-stage event included War of RJ’s, Tyre Painting, Nail Art, Adaptune, Spin a Yarn, Quiz, and Eatomania which is all-timers for any audience. The online registered events that included Animation Short Film, Short Film, MEME Creation, and Photography.</p>
					<p className='mb-2'>Chief Guest Mr. Vasanth Ravi (Film Actor - Taramani & Rocky) and Mr. Desingh Periyasamy (Film Director - Kannum Kannum Kollaiyadithaal), Mr.Rakshan (VJ, Vijay Television) joined us in the evening with great zest and delight, also shared the glee with the crowd.</p>
					<p className='mb-2'>Conclusively, the jury, based on the criteria of originality, performance, and integrity, decided to assign the overall trophy place to Gurunanak college. Our CEO Mr.Kumar presented the prizes to the winners, addressed the participants and viewers for making Miraki 2020 a grand fame.</p>
					<p>Glory to the conquerors and honor the vanquished!</p>
					<div className='news-arrows'>
						<Link to='/inter-collegiate-cultural-fest-miraki-2020-bangalore' className='prev'>New Post</Link>
						<Link to='/9th-graduate-showcase-2019-bangalore' className='next float-end'>Older Post</Link>
					</div>
				</div>
				<div className='news-rt'>
					<ul>
						<NewsList />
					</ul>
				</div>
			</div>
		</div>
	)
}