import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

import address from "./../Images/direction.png";
import mail from "./../Images/mail.png";
import pin from "./../Images/pin.png";

export default function Contact() {
    useEffect(() => {
        const handleResize = () => {
            setTimeout(() => {
                var i = 0;
                var set = 0;
                var cols = document.querySelectorAll("[data-col]");
                var encountered = [];
                for (i = 0; i < cols.length; i++) {
                    var attr = cols[i].getAttribute("data-col");
                    if (encountered.indexOf(attr) === -1) {
                        encountered.push(attr);
                    }
                }
                for (set = 0; set < encountered.length; set++) {
                    var col = document.querySelectorAll(
                        '[data-col="' + encountered[set] + '"]'
                    );
                    var group = [];
                    for (i = 0; i < col.length; i++) {
                        col[i].style.height = "auto";
                        var elementHeight = col[i].offsetHeight;
                        group.push(elementHeight);
                    }
                    for (i = 0; i < col.length; i++) {
                        col[i].style.height = "auto";
                        col[i].style.height = Math.max.apply(Math, group) + "px";
                    }
                }
            }, 1000);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
    }, []);
    return (
        <div className='content'>
            <div className='banner4 banner-contact'>
                <div className='banner-cont'>
                    <table width='100%' cellPadding='0' cellSpacing='0' border='0'>
                        <tbody>
                            <tr>
                                <td>
                                    <div className='container-fluid hght-auto'>
                                        <h2 className='txt-center mb-2'>Contact Us</h2>
                                        <p className='txt-center f-18 txt-black pb-4'>Trusted by more than 2,69,500+ Students</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='contact-info-cont'>
                <div className='container-fluid'>
                    <div className='mxw-1050 m-auto contact-cont'>
                        <div className='row g-sm-2'>
                            <div className='col-lg-5 col-sm-8 col-15 wht-cont contact-item' data-col="js-auto-height">
                                <div className='contact-icon'><img src={address} alt='Visit Us' /></div>
                                <h6 className='txt-center mb-3 h7'>Visit Us</h6>
                                <p className='txt-center'>No.32, TTK Road, Alwarpet, Chennai&nbsp;-&nbsp;600&nbsp;018, Tamilnadu, India.</p>
                            </div>
                            <div className='col-lg-5 col-sm-8 col-15 wht-cont contact-item' data-col="js-auto-height">
                                <div className='contact-icon'><img src={mail} alt='Reach Us' /></div>
                                <h6 className='txt-center mb-3 h7'>Reach Us</h6>
                                <div className='txt-center'><Link to={`tel:04442935200`} className='f-14 txt-clr-1'>+91-44-4293 5200,</Link>&nbsp;<Link to={`tel:04442935217`} className='f-14 txt-clr-1'>+91-44-4293 5217</Link></div>
                                <div className='txt-center'><Link to={`mailto:info@imageil.com`} className='f-14 txt-clr-1'>info@imageil.com</Link></div>
                            </div>
                            <div className='col-lg-5 col-15 wht-cont contact-item google-map-cont mb-0' data-col="js-auto-height">
                                <div className='contact-icon'><img src={pin} alt='Visit Us' /></div>
                                <iframe title="Google Map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15547.571200485938!2d80.2595299!3d13.042495!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd32b51e38c6cdbe5!2sImage%20Infotainment%20Limited!5e0!3m2!1sen!2sin!4v1621941303075!5m2!1sen!2sin" width="600" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
