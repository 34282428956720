import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Imagegroup from './../Images/image-group.svg';

export default function Footer() {
    const [visible, setVisible] = useState(false);
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 700) {
            setVisible(true);
        } else if (scrolled <= 700) {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const scrollToTop2 = () => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }, 100);
    };

    window.addEventListener("scroll", toggleVisible);
    return (
        <div className="footer">
            <footer>
                <div className="container-fluid sectin">
                    <div className='row g-lg-3'>
                        <div className='col-xl-7 col-15'>
                            <div className="logo mb-3"><Link to="/" onClick={scrollToTop2}><img src={Imagegroup} alt="IMAGE Group" title="IMAGE Group" /></Link></div>
                            <p className='txt-white-50'>IMAGE Groups is a hub of pioneering digital media training institutions preparing the younger generation from as early as 5 years to tap the growing opportunities in the creative industries. IMAGE also serves the industry through quality digital media content development and corporate training.</p>
                        </div>
                        <div className='col-xl-2 col-md-5 col-sm-8 col-15 ft-cont'>
                            <h6 className='h7 fw-500 txt-white mb-3'>Company</h6>
                            <ul className='foot-nav'>
                                <li><Link to="/image-creative-education" onClick={scrollToTop2}>Creative Solutions</Link></li>
                                <li><Link to="/about-the-Founder-CEO" onClick={scrollToTop2}>About Us</Link></li>
                                <li><Link to="/news-events" onClick={scrollToTop2}>News and Events</Link></li>
                                <li><Link to="/contact" onClick={scrollToTop2}>Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className='col-xl-2 col-md-5 col-sm-8 col-15 ft-cont'>
                            <h6 className='h7 fw-500 txt-white mb-3'>Contact info</h6>
                            <Link className='mail foot-contact' to={`mailto:info@imageil.com`}>info@imageil.com</Link>
                            <p className='pin foot-contact'>No.32, TTK Road, Alwarpet, Chennai&nbsp;-&nbsp;600&nbsp;018.</p>
                            <p className='phone foot-contact'><Link to={`tel:04442935200`}>+91-44-4293 5200</Link>, <Link to={`tel:04442935217`}>+91-44-4293 5217,</Link></p>
                        </div>
                        <div className='col-xl-2 col-md-5 col-15 ft-cont'>
                            <h6 className='h7 fw-500 txt-white mb-3'>Social Links</h6>
                            <div className='social-media'>
                                <ul>
                                    <li><Link className='facebook' aria-label="Facebook" to="http://www.facebook.com/pages/Image-Infotainment-Ltd/287505814606812?sk=wall" target="_blank" rel="noreferrer"></Link></li>
                                    <li><Link className='linkedin' aria-label="LinkedIn" to="http://www.linkedin.com/company/565628?trk=tyah" target="_blank" rel="noreferrer"></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='copyright'>
                    <div className='container-fluid txt-center'>© Image Infotainment Ltd {new Date().getFullYear()}. All&nbsp;rights&nbsp;reserved.&nbsp;<Link to="/terms-and-conditions" onClick={scrollToTop2}>T&nbsp;&&nbsp;C&nbsp;Apply</Link></div>
                    <button className="back-to-top-btn up" onClick={scrollToTop} style={{ display: visible ? "inline" : "none" }}></button>
                </div>
            </footer>
        </div>
    )
}
