import React, { useState, useEffect } from 'react';
import milestoneData from "./../../Components/milestoneData";

import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init({
    duration: 1200
});

export default function Milestones() {
    const [lineHeight, setLineHeight] = useState(0);

    const handleScroll = () => {
        const scrollPosition = window.scrollY + 200;
        setLineHeight(scrollPosition);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin'>
                <h6 className='txt-md-center f-24 mb-0'><span style={{ borderBottom: '1px solid rgba(69, 82, 108, 0.5)' }}>Milestones (Since 1995)</span></h6>
                <div className='milestone-cont mxw-1000 m-auto p-relative fix'>
                    <div className='mile-line' style={{ height: `${lineHeight}px` }}></div>
                    <ul>
                        {milestoneData.map((item, index) => {
                            if (item.mImg) {
                                return (
                                    <li key={index} className={item.mClass} data-aos="fade-up" data-aos-once='true'>
                                        <div className='mile-pointer'></div>
                                        <div className='mile-lt'><h6 className='mile-year f-24 f-oswald fw-400'>{item.mYear}</h6></div>
                                        <div className='mile-rt'>
                                            <div className='mile-cont mxw-400'>
                                                <h6 className='f-18'><span className='mile-year2 fw-700'>{item.mYear} : </span>{item.mTitle}</h6>
                                                <div>
                                                    <div className={`mile-img mile-img${index}`}><img src={item.mImg} alt={item.mTitle} /></div>
                                                    <p className='mb-1'>{item.mCont1}</p>
                                                    <p>{item.mCont2}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='clear'></div>
                                    </li>
                                )
                            } else if (item.mImg2) {
                                return (
                                    <li key={index} className={item.mClass} data-aos="fade-up" data-aos-once='true'>
                                        <div className='mile-pointer'></div>
                                        <div className='mile-lt'><h6 className='mile-year f-24 f-oswald fw-400'>{item.mYear}</h6>
                                            <div className='mile-img2'><img src={item.mImg2} alt={item.mTitle} /></div>
                                        </div>
                                        <div className='mile-rt'>
                                            <div className='mile-cont mxw-400'>
                                                <h6 className='f-18'><span className='mile-year2 fw-700'>{item.mYear} : </span>{item.mTitle}</h6>
                                                <div>
                                                    <p className='mb-1'>{item.mCont1}</p>
                                                    <p>{item.mCont2}</p>
                                                    <div className='mile-img2-2'><img src={item.mImg2} alt={item.mTitle} /></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='clear'></div>
                                    </li>
                                )
                            }
                            return (
                                <li key={index} className={item.mClass} data-aos="fade-up" data-aos-once='true'>
                                    <div className='mile-pointer'></div>
                                    <div className='mile-lt'><h6 className='mile-year f-24 f-oswald fw-400'>{item.mYear}</h6>                                    </div>
                                    <div className='mile-rt'>
                                        <div className='mile-cont mxw-400'>
                                            <h6 className='f-18'><span className='mile-year2 fw-700'>{item.mYear} : </span>{item.mTitle}</h6>
                                            <div>
                                                <p className='mb-1'>{item.mCont1}</p>
                                                <p>{item.mCont2}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='clear'></div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}
