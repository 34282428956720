import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import NewsList from "./../../Components/NewsList";

const EMList = [
	'https://lh3.googleusercontent.com/8fejqPnvhLwRkkpenU2S35SxdSsC73PsfD7rQOi-9SS2SwZMfNzf1wjztRC_wgxBNXtaniBAA3hylM_9RWmOw4Twd0uI_AogGY4S2wiSOm2NHgTtxaWretkToF2-d-ENAmGUoVaouA=w1920-h1080',
	'https://lh3.googleusercontent.com/VkjfPr7e9h1IrBO-FbSLm67f5EIc1_QgCy1DPubUUiQRsjwy71-oyiUfb1dMuFH0pjuZScKhRl9Mi9Hqv4LQZkjwZtQH34TyFpn6TqlIXjupuwt8dtJh67vZvb9-QEkRsR3ZLuq8vA=w1920-h1080',
	'https://lh3.googleusercontent.com/vgwQbDvyHGh7oFhiBSVHjz3Twq5xy9vkchPmEV9sM5PeBmkXEOazGvTd4jZkPdMRzJ_3T3JUUjEuHWSpm0dWXUfXNFppdkp5k2M6v08NC-VCArkFvm2Ga05TKba8XHlOLRpdvUHFXQ=w1920-h1080',
	'https://lh3.googleusercontent.com/G1SEb2Mie8NneeFhveukCRApeXsilQrv_Ok9WJeDljfNNqzna6bWd52C0_VScIlAvAhi8l4fLiCkmgdtfvfXCAy_S3dXXpkm_EIIQ1zFsT_rVOVPtugDAtN9alWmxmecyQJQ7dSaMQ=w1920-h1080',
	'https://lh3.googleusercontent.com/RbjG8LXcQi-lCE2Ysw3p9o0UoKSnextOR1SCEBnChF_I0xzOr_C7gOLm3Z2XDwwnFXweA41akrKnUKYrvLHErDX9Zj6CT5d5rEl6nVHF57iY59BYAyA13_SOVp4ZnqYdUMLai-o4CA=w1920-h1080',
	'https://lh3.googleusercontent.com/jsaY9W5m3u6rJJRMBwNnObfYjSPT5-BIwExJ0GHk_1vISyA7IZCEQamSozSka1E_Kxcu92O8mveetPqnmn1YTDfQzJzEkCvdFYo_uBdfMEdO3m3rb8Xm57UV3vqXk4ZFFpMLZ9JjFw=w1920-h1080',
	'https://lh3.googleusercontent.com/PrF67hXBc4xBoO1GaZbuxtWKCyzeKAppAEUtjrDUKVd529urzbi4mkdOL3rUGubIXNen9-07XV4yB5eS8e44nFapDTrPZWNS-c7quY8-R7RWkNtY29_4crq-X0ccOw_TdZojtkrjpA=w1920-h1080',
	'https://lh3.googleusercontent.com/k3Ol55qkjTqWFQX2ArJIgFqXXqjrf8WnZnVI4PuBZkrZw0HUPK4-cEATzT7Dhl-xRFMq8T2efteCY3na8gMK58Dx56_UT8gLpqDO_hO316P1PvulC-upXsGr0_vu6Rx8hX77wbsWjA=w1920-h1080',
	'https://lh3.googleusercontent.com/6h1sXkU6mr2gF9NzSAvUvxhaVynIojkvBmRfue4naplfzpKsGQMy37vD2cZ8CG98SGwfqmnuXk-5U032aRTvIC1Es1gL2eT0LqLsWG6fKObV19tuEv-raHrGdzmaJcc5jPbEh_t-lw=w1920-h1080',
	'https://lh3.googleusercontent.com/SabfRCRRGtlhhIqsPnD5J5mwJBNhXpfyIIjUnlBx3kVzEcr819yUYeaY4TjC88L-DSBbDKucjuyl6LRRTs4bYDFvFybybjXI0E4KlM9VEb7Bc6qFQPybFlpM-13iChwISgqwbFEvXg=w1920-h1080',
	'https://lh3.googleusercontent.com/-VTZmA9JpkSzexPLrCFWi84RmbVQVggJb9g5TP4NLfD2P0tSNKUtIZT2yVv7CY5VS7MPAhgTd4HqqEx3xjXu5wDR5DuCEEkuqtrCu5xA-7Okkg7MCFNFGGQa1ZmsKALRgbuFQgsw-Q=w1920-h1080',
	'https://lh3.googleusercontent.com/GKPpot6gcDIZulKebSMHt4GMlfcECFN1mFzMlXgXLaIAbp03aGfoOffgio-CZQ9YlDcjhYXJCD65t9W6w_e7UYypm47GL0vEic_ctqWKofsrSMGtzr5Uo2t0zYR8Kv9_FN_gC0iJ0w=w1920-h1080',
	'https://lh3.googleusercontent.com/Z2B1GjsD4zOwRJmqP99lvX8VIiLM5umKP5x2ZNvO6AEi4PqGCk8HBNeUqVQOC-V2VtO5i7qBr1ZkCuAl78GWWYY23UF4CNR-a_a8NhTFils0CHcw5_A_VSzB7oCLehH8RfehsZVSeg=w1920-h1080',
	'https://lh3.googleusercontent.com/XZ7lcw9eLwrNhGiugR_u2fo4VfYWj7JsJl8DazPW9vRy4e-1AzqoYsKt-GYdL9vsVwYLg8Cbt2aZrbjOBgSCoBvjz_x03OnXzbd8VF9yYKG8avzTO8fgJVolUGxSzn09xLMalNU-HA=w1920-h1080',
	'https://lh3.googleusercontent.com/k8gWmFWQxwOt8AwpgGkcORt712xiaSARR6dOJx-VWstPxw6hhjqFjZKtGHKvXk__CBk_RMJl5GKTGK2nKTShmcusM3-WTyK1LNZ3cUMjNR-qA7cwq7poGgYGtRK-eKzIOvHfRJ29Yg=w1920-h1080',
	'https://lh3.googleusercontent.com/kryskgUDYJzUocUoQi1d6seTBwoupvlRkhymtiroD0mvWpBKtK6gwf0d79jlxOKRkVSeXzaPx0G9_Wc1PZqYQrAmD_8XQK4g5qEYchyVx-q-lvwZBnntRwYwS6kXRc0iL14D0KyxGA=w1920-h1080',
	'https://lh3.googleusercontent.com/S85QcW4tCkgxhrTDFnjlKnIqgRBu59FaD16l6lVkerQz4xvd40Mx6X-MUPSHYrGDZI3vBn6UgTeCw1bCw5C8_9IZcpvn9MiumPwvjXRYjHvk4W-PqgmJYnKASH8mAnXVxo3flWLnUg=w1920-h1080',
	'https://lh3.googleusercontent.com/mqhMmACngql0lj3iLc3mqkduJaqrZ4Ho8bKLIyA7sNQUi8lXxiul_AmlGEtSwg8XotqZfU2tH5eknmEgfVdO2lVcKTmSqgpeBrfTKrUh73DM4Q1z7FmJ_fJlkIAN2hoBESNmLjnSDg=w1920-h1080',
	'https://lh3.googleusercontent.com/khA6FfRNYfmZ5jHQAlcHXShor6T05YofgUql01kgEGvtSHGZz-dIsI5NujUtm6lOGSaT9Ylk6DajJH_FkodV73yFoFlmyCLpUQgQQb8WltFPdGTuNsQ40Ui2RYT6dmja_L1tCzTLlQ=w1920-h1080',
	'https://lh3.googleusercontent.com/vTFhbgcPdcXbFIDqgVrYmOVOJUOXcWaIy1EA9npxBKvWE39n_9TpWJQuxkhCt08fjI5B-sl4Nfn4naZT7Fyz1dmovUPL1fGxDfLKkyNr-Vp_EbtFqornNE-7qF8_jw0hiByOYerb9Q=w1920-h1080',
	'https://lh3.googleusercontent.com/6GT_ex1GUj86k5N1HyVatjPrUsZRkWO3955-vzWYJs_SXJ1aXSfC2EjNosByqLVaM_nAhs1fqvpixoCZan0R_SB2snP61oZeBH1DWF_NoaWuDe5hTAQ573K1QTTQH-IVS1NsdPk8jw=w1920-h1080',
	'https://lh3.googleusercontent.com/qE9I8hSOlU0JCkGvh70HFAabWiunqy82Wcu1bDPyyRHZYrzWvmQ_ZIydtkrAGcE0jHrr4jcWOjlCPQllx8bDFVV-J0s2AUjCxY6NI2-9zBNGXgSxOVqUdldOIFWUoqnSTHKz71s7GQ=w1920-h1080',
	'https://lh3.googleusercontent.com/os4Y46nPHCZcNNbdF03yUyoi9MdLj37YtXXzuQddzFR-iKZy73mZMX_CY4zm9diFRFXZa8pdw-qNP2sMDF5ohA1HN9oH95IsMlrEonSJXW5AR_NsjCQAW8gtcOECWN7Cp1lGVCtEgw=w1920-h1080',
	'https://lh3.googleusercontent.com/sWI9mSZBI5OcKHSnW0VWZocTZHSqPBTMAYRAVFo42UL8grJg7uj5IyfJOO3Zu8-lGHxIdk_alCwoZ6_6qv5pr3bZl7DsMB35sdpDeJEwJxna8zz9wPd1oyVWZ7ElRNaxU4ffemdmgA=w1920-h1080',
	'https://lh3.googleusercontent.com/NA5dqgIUnFwJ2GXB3w2NigUDDwzpFIgOUjUa52sBVIrqshXQ5d9kdW6PFA1jpWJm3zbJdNVYTVH1FnFA6ZMRprTd5cQUFne7XiK5VUSh-UF8xAg4qSgIRoU45q0jsnnaGvYKEH4SNQ=w1920-h1080',
	'https://lh3.googleusercontent.com/pR0AcVYC9eV4R_LsHiuLmOtA_LS3Yo3r4jQiKE3yfvKtNSp66uPUDTB7ikMw2KT3MENx61WOoQLzSMy7w4EQC84LxHZoasuqU9snYvdpCyFAqnbAjoeg988cKlpf1Kw0lFs7dzkcxw=w1920-h1080',
	'https://lh3.googleusercontent.com/4aBx8HC-PcIKiFmTRserh5sr-4YFg_IHL0ue3TJBa9dXPnSZYokDRKDKYUjhDjDwCP7FvVqiSZrqeQNRlH6bX_bWW6DPpq66YQOfFs2zt1v7X5jHfkXbjHOBLptl8aYqyAt6wJihkA=w1920-h1080',
	'https://lh3.googleusercontent.com/QVm2EnFe53vIhPHJGW8hjPx-5OJmAeWcXnc4gudu87urP8zH5Dn1tQxI4e38C0nIdFE2aT3RnHyKBjTNSoFJRPeJc6zNj050fuZP2NynX_CamlQVCpNVIqEGjinCARRKIpU07GAfKw=w1920-h1080',
	'https://lh3.googleusercontent.com/eQxE-tbRbsydUFP9WNS67KaUGozDvqdtNqqVr7GcEt9I65NrawbzM3mchZYasQxQCCmhim_uPjeQRk-VyKuppHqnEHcCJ20MHS3k58N2x4FnJJcoGYzJvN0bjhmw0ippB6QCFUDCbg=w1920-h1080',
	'https://lh3.googleusercontent.com/QSmfiHdNb8XJjZWfA7BmLlWi9gWzhN6kpNnRD-Is6y4i52RcbwVy3nNBZjSP4toNwmzPAxNqJzwYZxnGfHu1YQW7XCUdDpQjBeCiH3IV0msx9rSJoh6TqtjPDl5Qpps0Ai3J9R7ZmQ=w1920-h1080',
	'https://lh3.googleusercontent.com/d-gH7gUnMuAAlJNPXtSQG8FJN6JMpgJuXAMZSF0HDvF1WDiUp2Bt0ykqhV48aBwSSc_YM5a1wn8MscopnZOertGonG4WwNGEDjbttb5yu7IVrJrokR2GIxvZqDkKBAQ7s1faU1r7kQ=w1920-h1080',
	'https://lh3.googleusercontent.com/j0bPXDNRKqvdh8HR6vXdSVCcekK9zhpBSdSUin5Bi8s9_PP_InsWgJvoFRHZJ7av2kv94dZbR1l9JORkwQf3dp5W9gbD7dyKfRoHaSgCC_TCnUxASV44hWyt1-2qGpi7Zwvo38fblQ=w1920-h1080',
	'https://lh3.googleusercontent.com/ZPKiASOq5ACSXKhEC5RzZmy3mdnpC3mA5xrZc91WQLbAd8xHrOFziWhynJAZJG5VbVHj9Vd4s0ZcLEUHFlFogvTSbywiVdI7U8XKDWghIDiQMFAkscSSSZEEORvQSsP6vnHbU4Wq3w=w1920-h1080',
	'https://lh3.googleusercontent.com/ypj9hlbdqY2I7OvI4_a6h3SqD9DRZnHz3cWaXBOxZWgLE4BCq8TdYXboQIGuLboVEi52dyg2TUAJCIs67N5Z5aOXv7p50fBbU2Cp6yHRFPLXQWvAaoKFr3vqFsnEQfcOhK49zvwagw=w1920-h1080',
	'https://lh3.googleusercontent.com/WtIICq3v_b0oS5mSS6XaKaBH93Eorj45XOcdlCeAVBg4RiEfDoi8tCXyBUk5TAYpv1NW8gX9i2INJtZlGdyig-PiXh4aqyZbVUEn8Nwgwwb4GZImb0IWSvekCPuEMESkcrKMm42kXA=w1920-h1080',
	'https://lh3.googleusercontent.com/5uA97_TabTGW2bxD8XOfnk6woMXB3SaKlcVq3ZQnTN6ZceGWQ4sckRdENmJQGQe5ELrCgPea6E-boi0mt7LNzLmP4QGF9gmY6FueqD4Rde2om2H7Wjl9P8iT9eABmmOV1hJA_zf1xA=w1920-h1080',
	'https://lh3.googleusercontent.com/YP0omQdaGEsoT5Pb4gtXaZ0tqJunYJFm_Xyk9Q1SfgQxdPO-E0uLh8-58dV73Qk6bs6OcImTiC-ljltkcW9Ajo8IyKINTKuGcwoufOrEwQf-2Jzt1sxrsxyozA-6c4AdFk8Z4njyzQ=w1920-h1080',
	'https://lh3.googleusercontent.com/BrkCsCT0nNzJ12aLWDPRFBn2nv9-FPJSwIeqF1svUuPU2GKGZunE-iLCYe_H8HR46wvOpF4OLJbTWP-DSt50hmPKnHC2ZYsyNirx2Q3jr6nOB9oTyB7ex3Ap_8pxGHRfHlOBqnGT2Q=w1920-h1080',
	'https://lh3.googleusercontent.com/8j3s_N4RMSLNOB6pY0UVLW9eoeO_0F1IvIcVwUhKn4pKCCL1Hj9PPDGmzgTG2lIKhnwHQRt09e8q2JoruNHiodmiizU1YVtP9MtsIiDTotSgg8RpMYJphBdTE8_EiwgujmBq1WFe8w=w1920-h1080',
	'https://lh3.googleusercontent.com/9VaV3lIVMX4w5sul_daDuw4XbPuwyskARq4Is2Sk0MiQtszDQzijY0ukrtEWEBOB50IYQ7BA8mwlJzJwp0ZDMk9gSMaQMRtWWPl7OgU9gjgD1ZMclImByZF25MsFHD0RWNQa4lxhNQ=w1920-h1080',
	'https://lh3.googleusercontent.com/YEMkkK1C5q5TCjp3iARarsdaDDQgE9DXErDVQOYXXpOP-cioj9UgPRl8eXreoI8eKvc-sK74s-lzMYUQ4zExju7rgz42V8CiRrwhB5Uexyz_bp_0_5hSgV3ubJIKfVsB0VIWG9VOCw=w1920-h1080',
	'https://lh3.googleusercontent.com/-SxK53S4BjwRd-HBrAeNiGhSaxy-fLAF2lsQs71pFWnQ-7KmfwygflPwcVkp_0BclO3ibW_eOLTREMH-H2MIUiVn8tHeN35lZNnRYBQEGP2Oy_ugD0xgGSsrs4vfNg_mCRDwP5ADhg=w1920-h1080',
	'https://lh3.googleusercontent.com/6ddtjeb0pPHTuF1HjzYJaCupo0QsVIfxsipOoe_cNKi-7kgHhjo4gVHrXmDfpy3CLBTDHrPTShrfCYNOhaPjJ6jlN0LcRQGUl4JwdlBlOvj16ER4U4SufWLkUU2xeP4k6F9JIvM95Q=w1920-h1080',
	'https://lh3.googleusercontent.com/W3e387j07a27EQvbOmq9G6rQRt9rfEawTzGBBRlXhIF5LKp3TQk-JrWvTAby9DqnK6G2C9VplyGFqfP9YdyTU9KF8swG0nwbc7_IovFjW8OYRS6Sktt5YYSFFLePMNvQiB6KOJ3sLw=w1920-h1080',
	'https://lh3.googleusercontent.com/NCZgX8vl4W4evS_DBFHsKoMcPGk5alewHWfIWQsamGTgNe5N9MBDiYgKiSMVxo5ujjlfI8EWqhc4NOKcfixDj0Tho76noSfyyQlSC5x4ej7a0CI2k84FPxomVpTKMNMPiuRTMTy0NQ=w1920-h1080',
	'https://lh3.googleusercontent.com/9iGtQjsFX6p3eFNWXwtwWe2w1XZQRz-5aylc_H265ReNq9TzSBtsKaepdv6Bdf2nAgNdYXQf0VWu6AlKCAPw_whBcZsrhDIK-NbzDh7daVi-iH2GB2RTata1hFLva5cJlnBGxFYVQQ=w1920-h1080',
	'https://lh3.googleusercontent.com/dd5zh-xdztpHN2_r55L77nPtUzoci5oC0c0w_NyTsUv7WVuRlhbZvThsZhUfgtolX8p2iXnQ79k_hD0_vNW6705wKrZNrFMYXvzNjJj-EDROH-1pqHQTDs67D2vNBipuVvZoXR17dQ=w1920-h1080',
	'https://lh3.googleusercontent.com/PLwuGoxiZ-89Ah36j0-8lAlk6ks4mXqFc5oeSyjRwyAobgkqJEgKv8qfnZf2hiGtOfKN_klo073HE5NLYf49Ecbw7oWKTbId16Z4SxkCw4JFiMfhYH2gY4xbIuK9B30wgR0rXrbAPA=w1920-h1080',
	'https://lh3.googleusercontent.com/hYqJ6f5yOYaEtdHr9HMYYqokQY9klBMERFDBI6s1CC8X3rTNqEKZGABdSDUDe2e3dUQgLvJzMcy89sz0gU2TvBUfkFPtU1EJJ2Zy1cok5wwKztB0YKdJWJ8ODr7ivvkiWNc3VM03ZQ=w1920-h1080',
	'https://lh3.googleusercontent.com/zh3VdxRSTU_W21UyibPzouCngUOV6g_r2sezL96vkLvYMYKNyHvdz6KfR1cyYZIT1QJGRY4ug4ln-jxbagrV6E6osFNQBoB_zZTX3KYplxr7FJaGhZ0DyhYWw8Fwb8YPm8IBcMM39Q=w1920-h1080',
	'https://lh3.googleusercontent.com/8wui5Zq6h_dyAq8cl3fnwNtZoi3B1BBNKfQ-gcz0Fe40M0FGMPkaXYwl4EYYLxVn4NC8D8jWkDFoORfF1b0dveZOmZWfbiwmWi0mPbniRON5MVEsiy4vjK2mMzGqF2FpYnUD-kNWlQ=w1920-h1080',
	'https://lh3.googleusercontent.com/GSBGMLvOnaVa7-9c0pROPWksGjZ-OIN6wsYn1DLLXW9saUk42DjgpcGtW4NQ217dT22r3BRr5A8OFCOezzAgVsf5wrBy94xR2vlUb0DkcoH9q9c1KwHcB8k7Ze7HqWpHiAjvaicLyg=w1920-h1080',
	'https://lh3.googleusercontent.com/rBC-QIyAiku_uklyFaHRP_5tDsjCh_fsAzrxdqqHZ_6gMHHorxFmBPrlGyvgCBBlZ3o6EZnCOYlQR8KfZhpbBdemX005f4TR8z7MxFCQPNe0qoYpLTa1nF4XyxPeCJLRjeFtoirsrQ=w1920-h1080',
	'https://lh3.googleusercontent.com/YojBHNcIhbNsWCTq-0TuI05WlxbQZcbDl-WzuoRte37TtHNg2AxcH0WU_F6S67xW-Wu6Ug-Jo2TMOBTL8FQ7UvSxDR_5O-3s8ZGyYFwQmreVU0ykN-bMGGv_I0loE2jShzyz3_TKRQ=w1920-h1080',
	'https://lh3.googleusercontent.com/4lfB_Xz4MBiLsOWqyfBK2vmJUB861Dq_P0TgAfantE5eNbyKE0JBG4WVhk1cgsacZRqM7eHjDW-8Ax0qo2vpZGafZiq7HD40nCIk80s6a8Gut6DGeV60RXhH5VgGyzvNtyJ-AxAYQA=w1920-h1080',
	'https://lh3.googleusercontent.com/jVj52gjN5XLHSsy0er8ENBmTzvjuUzdHPedrPaSGR-fFn5OXrtq1eIRJ1b6RyuT5sII9rEejJ3ycOe6-BwwGHRgejCBEVtaKTHPdHOpWDDDwHN-OyCkp31TXkl_LuHhqI_Ngv-L4YQ=w1920-h1080',
	'https://lh3.googleusercontent.com/W6oA51IJB2M65piBHOSL4WGq3dxDa7O0iJTUqZvqurLzbFFp1ApStyY3MJVXQwfWrd9z-3QOgv5AIvDKhUYaFTxXKzcONUnBqInZpv1B5dWrKAiLYqVrno_4O6aLG5lD8u6sPFc5Tg=w1920-h1080',
	'https://lh3.googleusercontent.com/TtkRNUXi1vQqCL3t_wuWuJnlfBt4Ust5vXl5aLAzgLgv6nKt8hNlecEwuWuNNnaUAsAfRm87bU7z1v6GbjRC8r4P2QWxFSbIwXASHVmNr9Hx2ZTe012AP4tH4uuCjPfZMz2d-DImZw=w1920-h1080',
	'https://lh3.googleusercontent.com/Dodv8ve239__h47FXxsNC71BWHI-Nbafup9LpnaTUj5A6CObxAL7by0XAjdljEUAZqxs0Bp8wpMnTjL2wrIgNxUtfrWb_IqFnVU-m14pYcn-t_uEQko2p1OeiBGcZHYFWymil2hncA=w1920-h1080',
	'https://lh3.googleusercontent.com/f23oTQP21QbFwxPcDK51gG7ondk7vzoWTAdg9DDwSXBbRv70SL5-PO9g7wS78VFyG5JdfIv2kW8UXVJ8HDaam063GpYzGNvxbWetjh8yjoJxL7P7LMCxZVJh0ofbTRnHQW1t-bJCEw=w1920-h1080',
	'https://lh3.googleusercontent.com/Dmh0W28GApb6VOwM5IClErbJ2CZr6b4XCCUKjnMLsQYGf55an9FSnvF-rXj8VPTT_8GynawsP7emD_LHna5ZsbMNeDjfjyohCBW0y-0Fp2_G_sH3c_FfJTDmG7Qi8DbYgQumLmZbMg=w1920-h1080',
	'https://lh3.googleusercontent.com/XUWtU8usbbMGqx4kGKb3pcdNJu9gCOVHrCmS6lZr0avg7I3lRBf6Zts6jI4Ggt6Lhq7KbecrJxaEPQyik_92zC8UjH-o-peHC-CADwYkrCAQ5eEuU4EOj01VczY3rvow73kRm-LZRA=w1920-h1080',
	'https://lh3.googleusercontent.com/MxE5CEn-o3NN5K8bJsnjQT5DXPVuO7vh_XuJ8B8_3fOlIvwgOiOjSq1EzkeOLiUSUP1GPw_f1apUKXRHS6d_qGcKjO7YOZ6qmt2I3_F8JCaXfzNbhLERzwUkoMwCL_fRv-azj4DLjg=w1920-h1080',
	'https://lh3.googleusercontent.com/YrbFXDeocpB8GZvIu1-75A0Xi-Tg9ezYnYJXxEwQ40YFqj6GK-YBCGmgHGpGkYZnvOXU6zTHidd5BrqdZwlOxFrL-Pw13z94eDhjKtpEnq3wWBiWOIAPnQE0WVEo1JI44mbKTccc5w=w1920-h1080',
	'https://lh3.googleusercontent.com/-pG6neDQph1BU5rV3EwxvJ5WIt2ct_iH7nCV7VGk04JjS4MxIq1CY4tFu53OjxjPezabj_RqWa3r2LgijSI_ilf5iYCfKWFm3WRElbW7Q8FA4ZrWQga6LstwAAh6bUyG2J1AVIzj0g=w1920-h1080',
	'https://lh3.googleusercontent.com/aaKJGgrvWzeq6cqMJiZjY04VpEPOFhq9fMU8qYX5rFKB6YDkdIFvNWyvzxtwkSyvNVgqeIx5nlc2BmCgk9qRJSV3ynha7OWcVNo1vq7vQP9_-p9GG0d0wmRQuEhs3_MnBAKy7q3oLw=w1920-h1080',
	'https://lh3.googleusercontent.com/J3BvKC0pRblFi8J_gP76iosROWDUKZ6ik9nn3W-S9g9UGY-m2Eydd6jCjjK7Ym9p3D2n90TO-27thFP6r5-5zTgyFIMTOjnJHXv4fTWiWpxQ8dYIYso9523295ZMZRWo3LBE9BhDkQ=w1920-h1080',
	'https://lh3.googleusercontent.com/Fb_fZC7p93qFeL50qzdg5U5UX39xU1xk5XbKjEA4LETlcFfCe2Cj12D7LK887u_JrcG8rgAQ84dsUUma1GOp1QW_hojlCa3mKk0Vs6-3C5gSsEWDpraktyfSVC0MEAf5YZDgX4BcGg=w1920-h1080',
	'https://lh3.googleusercontent.com/vrOK4FcZDuS6DELqqgwetS6d-UELfqmDz1FK2v7judqSBbSygV48AFFy02qrotEjs0PpnzIRUe7QacLzA6UtUJqM7kqI0MYlAG9XHCAOpkFaawPZqAAiBobyUS8Pxqm49mP_LI5l_A=w1920-h1080',
	'https://lh3.googleusercontent.com/3j__yzD-kBYVLVGIcjOupDYokgTXxFMEKzsF_7YUxxvz5fyClYnKP4PBJ6rODbhwIOLjwb69HBJKkA0yn7KA6otN4RLBRXd36LfAXXRccOxG3sLNILo2Wvkz_Fs9Y7npcNG9kr0Vfg=w1920-h1080',
	'https://lh3.googleusercontent.com/1JTVLiqVmr3zznaLmzDe46B_z4vr7AAljHlJcLqf_NDFXul-zazZmyoDxF9qvkqZmZ6gDNC_XcxQ2BE-3Jyy7HtSa07KHC0LXBeam0xEYoBjkHAVK6pQbm0njK32iuDksojFIDeCrg=w1920-h1080',
	'https://lh3.googleusercontent.com/C4pI020Gca08A7uZLaEBUQhcZ6ZwtdFu_NUxq9mgbJLlER1F98qCD64rW6iwHNAhbyuX0RjAp9dHbmDAJinm5Px_DjPS7-6kq5p_OypbDF_lFDa3YWKt40y-CkCTotSr3G8oVp1PSA=w1920-h1080',
	'https://lh3.googleusercontent.com/NtxeNjC_xU9w70MPBWl_w_h8RY7uhez8XoLzWuJd_dk-p-E_-r2U6sshv6jEo7Yy72bP28Ul5g2SUL8CEGI8owbeJTQav_9FOxquUx3OnlbM9Nk6GCqnlzaERNfCGn5kttx8POQaFA=w1920-h1080',
	'https://lh3.googleusercontent.com/33iwDoQHI_PB_BzqOkyL_sDCOrVMLBWcELVY7kaa6f2YK2Z8GVQaTWIXVg87BqI5JdoGFNFePht5lSIjwAyocioRjS5BM6SEnDvNsBZVqvnSBm6Q3ohGKUfwlK0Bs59HEP7UvxnKQg=w1920-h1080',
	'https://lh3.googleusercontent.com/JX6hTcuYByZ4SLSbdH9Ccr0qwCfpPErBLyKdFAx8pm1yLxeeAo94RwzLovohMaMDaTFlqi0F9mGw-4je5qQMOoUjitXtd0kj1a_1hECSzZXHYdRN4i2XtYoSx9OCFs0a7xDb_IpPhA=w1920-h1080',
	'https://lh3.googleusercontent.com/sKlujF9Get7HYL0MQC2E82L-3Pe9xy3wIORNsa-IRbGNMmAuRaVEZZK7F63kMPjVqRshc14rvlzWk0ShTq1VmlnybV7Av4KaGxEW5EcDkPevgDnoxhgyl6TI9PmA7ZH7aJwgDO61qw=w1920-h1080',
	'https://lh3.googleusercontent.com/Q3EiFZQWrLvH1M5h1RRdWtyIiT_kupdkKO8Vv6L8n-2H6UV2uxSFcpvCN5YRcqF8qsTvEHWbZwh5de2C3JttBcp96Z_IHvonbHuci8vjZOXoGeMGucbVghyFjCgaQZSRZfIjObmpqg=w1920-h1080',
	'https://lh3.googleusercontent.com/rbqjlmBJ96c50o8KdopzN7EAfJ1Ul3F5_V4fihY2extd033skTVUdcS8aSl3VJgexGncHrZjITv5ILe3uAcLg0kGD1o8m_Y1vfL6HAoq_I7LPAlQqP2WTt2Hd_LZCrI1A76rPk0e-A=w1920-h1080',
	'https://lh3.googleusercontent.com/2iKsOL0u7fanZ_DVub8-gV2uyjpFHIIGNJSByv0zlNc1Id40NRXMj3MSPHXXUY8Ym3lUWDf9dDSU8hn6zh0Qv7SBah6WY8LmU_ZrxSRchZmVNyMNMAjbGKbKyWVy4jOqbSZ4azx7sg=w1920-h1080',
	'https://lh3.googleusercontent.com/Nd2KVelJacYn3PhN9n-NxoukcmON-i8Jv-7Ito5_BQzVCxgZ4dp1fycp5qlCxICvGzRLTUIQ6Ry0I8BhjjTNLER_5kZV24nCJkuZti6JyBIw_LII_u8lSVSRZsnildXwa6D7UA4jDA=w1920-h1080',
	'https://lh3.googleusercontent.com/DuNH4hJ10oystcQqsNpo2dVJmprioxIOL7VxXUTaTvWdE4ym6IN6LgEnWDdYOKzVZofSfHuSuUQJ-ZcJNxrDhRGJ2vDwutZxS8E1fyh_VvW55g3sYEAeLrpydnr4UsA8prPsRZ2Hmg=w1920-h1080',
	'https://lh3.googleusercontent.com/JCW_jxSFJv3Bu-Pz8QL5nyE5zry2IifCof9YaGmvC703rMR_IxxcfmQi1SQaXAMiPmQvPF4B75kANHathY59UNlN4i0T3-3BHQ6k85XFR8LsOJXOR7HsKeGltlHnE-xJYOlwZcimxg=w1920-h1080',
	'https://lh3.googleusercontent.com/3sn65eYCg1TVF1G0WPERXKXZWCQIFt6TTzat9O2wZvOe3qEoVhsSx2gDdYv2NfAkW4nO2pbEI3cWTjf1VkYPvI6-NQqUsxETr0gp1MdTyN1oYtJLE0OeugSS2q7TWWpYIa_Gt3fRHw=w1920-h1080',
	'https://lh3.googleusercontent.com/_MWh27MweyDBFn4zIG2dD9V6pKT6bjOavpN_1j5dzpHWIhS4eYjfloRM6X9GzR6kVr32EYkWn6CRA5NhX1iGDGdoi__MvI4bGxQKZT4qLsFr5emhhgSOEINVu7AqfSA1neBJu69lUQ=w1920-h1080',
	'https://lh3.googleusercontent.com/n094lpJxtb2_NX1YdZaSpV-xf6QqZLi82Q1GvJcAWZNJzuvk4_jXprSNjjc9QS_W3w8-gvm9zECD2SR-oI_E1o96ucRL7qT-B80p_JekhwJjc50sjbcUEw0E_1vSRE44FkYq743tOw=w1920-h1080',
	'https://lh3.googleusercontent.com/5C-aNT2iHPTiW6acKr224vKnB8Xbu3ue3LKSQAKQBONmEM2Vy3Cp2GfgKlGFkoOys-DapVPLPWuFeJmvhQd1tZU-b_pOlLgvlBsgTkAM6H005Xoa0rlh94r03vtQXHiw0xAhWtdsbQ=w1920-h1080',
	'https://lh3.googleusercontent.com/rDA1ojGAvGrWxSVGTAqbxeRGP8nk-dD1aYdZyTtV5iAp0V3IDXDqApNyAXxokgmf537rQh0XsU75zL73Iom4mRnnatyet2xMTgp9lG2_0L-0SrGnZwjYokuflv2HN6_frPivEB3bvA=w1920-h1080',
	'https://lh3.googleusercontent.com/enrNQnGUA9jzNLR8jTqoDUX-1FWV80fWu0othXhrFZtQREdhswGt8dBf1n_qkt8L0zaB2YgN7XvEtMEPPsB69e3pLuHSJkOUsnl9gGDpEcWRCnqlA77aszbz3IQzsk09yJ-1FnIDoA=w1920-h1080',
	'https://lh3.googleusercontent.com/vRWW5CUJnJrENyfLoCdTD9Kp39JkyCDd6G1OJeqHqC_iAE5-vCjM7Osk1b_UA7vfaqMnK2vI6hrNuJYWNeVRkmTjv-Rnh-qsTjVe8jMhXUmETO6obZELFRsg6Kn1MZb4a3a0EMO43w=w1920-h1080',
	'https://lh3.googleusercontent.com/J4buOwdD6YkgLbygvy_RSfUKDNIP_O-G7SVIayoBxj2kz0NNko2hra10wHpbydsGQ4tX6PDY2NzM44XVMMxTlJfZs-z_FB84Nk-IgzAzZhV9TM3heC3DM6vdq3gni0oa4D3dm5LvCg=w1920-h1080',
	'https://lh3.googleusercontent.com/AqY5ZaNVmYF102QcZI-RkkkljJV8E66Wv9SDimPUGnlLp7rPusSkXDWUhws1mpA1etIsbV703LHBbPqgp5MD8I7Bi5_r8l2ndvJmT56J5XJ2bmasSsNlPLpR_i_BToeK1QvSpkrHCQ=w1920-h1080',
	'https://lh3.googleusercontent.com/Gm472tra0lwhKcsXmsvW9dTTTad4VfcI5GpRLEJ6JoLUlhVJb_dy3-ZaY34_EMJ_kTGRGsUmj1uHhGgW900AIs2gzMpVUxheVzOzgxBN8cDE04kO4gyLeLurL8AuIv4r_FFrD-36mw=w1920-h1080',
	'https://lh3.googleusercontent.com/9Q6-QBBnlTRjPJk-HF3D6cBpwblwulPC9Ac1i9OP6bZySAr6kVRLy03FfzQXQPC_IS8eU8j_vi5zIP44Gu6cWvIqIAWj5qWzC3JD8aqkgUDkqxvHScwAfb2cpGwYTMdshhAsSAc6QA=w1920-h1080',
	'https://lh3.googleusercontent.com/JjLMZEmn3n13JPrfcL2c_7RTeT2e8q_11z10U5rMHKWDX9yrdSRj1sXFD_rLTnP-IMfuI1cvr1VTjuPHgnyCAbYjk9Sp3Tm2ZGbbQXN7SL58FiyML8BnI4P5zk90yB0cQy1YgxNRig=w1920-h1080',
	'https://lh3.googleusercontent.com/iwWdXYTaGBJvRJMldk4pRbHA02Vt0VJvxpWqnnUE5eYGDvdovcGqtw5vJEGGp8j2NqZDjxEiLzDpD8NCZGZK9w1LTmyPejAGvp9kP8FlhL_yjF-TZeiA_4OpjDRZ4ziND3fYtkuMrQ=w1920-h1080',
	'https://lh3.googleusercontent.com/uiJMeO4BZZ2K5MpWz4OitCWoCJQ7_7xGDUctia1b3tx72F3HckHXwFDkUzwEjvy0aUnKObD-lNolNjqpCnwWZDSrsPQoATVPshxdYuGdEaUFVWlmaJt-qNNdNm-AK0ZD7B44dBvl9g=w1920-h1080',
	'https://lh3.googleusercontent.com/edSIIXHAeFGNgqqFJlksDPq46NknCyHyI4uE2mKfs9mODexRwgNJafWENfqeRGkD5sHinmVjKtALk4Jo8uM9kP3BsUJVJREuKHH-u3_9xyrMakl158KhoyeFO_32g62OofNVPLlWBw=w1920-h1080',
	'https://lh3.googleusercontent.com/3fFdTRFLqIsgnZH0Az4Cn46C4ZO1dbZZ021fjsCYvb_XKMqhp0WNfLIA-YEZhESfYIvtkVX6wbYPMuYTYlOUTXa2O1q3KrVugcr6Rr5bxMHn8O9LG4XGklexGgjr8ReTDpXUQIIwCQ=w1920-h1080',
	'https://lh3.googleusercontent.com/1NmKKCHbz148q8rgI_XCpOKXlPqcme3mXSt1qk1tKvxUzyibALNirTgSqkUM8OiXPeHQTD-g9ZDPk7t5MNxy5wNPw1tZ-MJUl1VOM98VTacbwozMOMmtCFzvQoLmDpjfzUnbTDXZdA=w1920-h1080',
]

export default function NinethGraduationShowcaseOfICATDesignMediaCollegeHyderabad() {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = "https://cdn.jsdelivr.net/npm/publicalbum@latest/embed-ui.min.js";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		}
	}, []);

	return (
		<div className='content' style={{ paddingTop: '74px' }}>
			<div className='container-fluid sectin float-none'>
				<div className='news-lt'>
					<div className="pa-gallery-player-widget wdth-100p mb-3 hght-480" data-link="https://photos.app.goo.gl/7ZjXmnpB2Pbj5zu37" data-title="9th Graduation Showcase of ICAT Design & Media College, Hyderabad" data-description="44 new photos added to shared album">
						{EMList.map((iMg, index) => {
							return (
								<object key={index} data={iMg} aria-label="9th Graduation Showcase of ICAT Design & Media College, Hyderabad"></object>
							)
						})}
					</div>
					<div className="videoWrapper mb-3">
						<iframe title='9th Graduation Showcase of ICAT Design & Media College, Hyderabad' width="560" height="315" src="https://www.youtube.com/embed/ELpGLMlLm5s" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
					</div>
					<h2 className="h4 mb-3">9th Graduation Showcase of ICAT Design & Media College, Hyderabad</h2>
					<p className='mb-2'>9th Graduate Showcase of ICAT Design and Media College, Hyderabad was held on April 26, 2019, Friday at Manjeera Mall, Kukatpally. The final year graduating Students displayed their Projects and their best works to showcase to the Dignitaries, Industry People, Peers and Public.</p>
					<p className='mb-2'>The extremely experienced VFX supervisor and Co-Founder of Makuta VFX, Mr Pete Draper inaugurated the 9th edition of the showcase in the presence of the honourable founder and CEO of ICAT and IMAGE groups, Mr K Kumar. Following the ceremonial lighting of the lamp, Mr Pete Draper and Mr K Kumar strolled through the line-up of individual and collaborative exhibits. They were extremely gracious in spending time and interacting with the students about their projects at a great depth, providing them with critique that would garner enough inspiration for their careers that lie ahead of them. </p>
					<p className='mb-2'>Recognising the yearlong effort of the students who put in their heart and soul, Mr K Kumar, in the pursuit of value based education, acknowledged one student from each of the exhibiting departments, whose project had stood a notch above their peers and presented a special commendation of excellence, a trophy carefully crafted from silk, as a mark of respect and testament to its timeless beauty and strength. </p>
					<p>The Graduate Showcase is a beautiful feather in the cap of our esteemed Institution, and a valuable addition to ICAT's pride memories.</p>
					<div className='news-arrows'>
						<Link to='/9th-graduate-showcase-2019-bangalore' className='prev'>New Post</Link>
						<Link to='/11th-graduate-showcase-2019-chennai' className='next float-end'>Older Post</Link>
					</div>
				</div>
				<div className='news-rt'>
					<ul>
						<NewsList />
					</ul>
				</div>
			</div>
		</div>
	)
}