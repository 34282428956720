import React from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import NewsList from "./../../Components/NewsList";

const EMList = [
    'https://imagegroup.in/images/news-events/image-opens-new-centre-channasandra-2018-bangalore/1.jpg',
    'https://imagegroup.in/images/news-events/image-opens-new-centre-channasandra-2018-bangalore/2.jpg',
    'https://imagegroup.in/images/news-events/image-opens-new-centre-channasandra-2018-bangalore/3.jpg',
    'https://imagegroup.in/images/news-events/image-opens-new-centre-channasandra-2018-bangalore/4.jpg',
    'https://imagegroup.in/images/news-events/image-opens-new-centre-channasandra-2018-bangalore/5.jpg',
    'https://imagegroup.in/images/news-events/image-opens-new-centre-channasandra-2018-bangalore/6.jpg',
    'https://imagegroup.in/images/news-events/image-opens-new-centre-channasandra-2018-bangalore/7.jpg',
    'https://imagegroup.in/images/news-events/image-opens-new-centre-channasandra-2018-bangalore/8.jpg',
    'https://imagegroup.in/images/news-events/image-opens-new-centre-channasandra-2018-bangalore/9.jpg',
    'https://imagegroup.in/images/news-events/image-opens-new-centre-channasandra-2018-bangalore/10.jpg',
]

export default function ImageOpensNewCentreAtChannasandraBangalore() {
    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };

    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 mb-3'>
                        <Slider className="news-slider" {...settings}>
                            {EMList.map((iMG, index) => {
                                return (
                                    <div key={index}><img src={iMG} alt='Image Opens a New Centre at Channasandra, Bangalore' /></div>
                                )
                            })}
                        </Slider>
                    </div>
                    <h2 className="h4 mb-3">Image Opens a New Centre at Channasandra, Bangalore</h2>
                    <p className='mb-2'>Image Creative Education has now spread its wings to Eastern Bangalore with an all-equipped centre, at Channasandra. The centre, all set to render its unmatchable skill-training services was inaugurated on 29 June, 2018. It was inaugurated by Dr. Sudheer Pai K L, Principal of RNS College, Channasandra. The inauguration was honoured by Mr. K. Kumar, Founder & Managing Director of IMAGE Groups; our distinguished guest Mr. B. Suresh - Actor, Director Producer & Theatre Artist of Kannada Film Industry; and Prof. Ravishankar. B. V, Principal of RNS PU College.</p>
                    <p>With the blessings of the honourees, we wish the Channasandra centre All Success!</p>
                    <div className='news-arrows'>
                        <Link to='/8th-student-showcase-2018-hyderabad' className='prev'>New Post</Link>
                        <Link to='/inter-collegiate-cultural-fest-miraki-2018-chennai' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}
