import React from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import NewsList from "./../../Components/NewsList";

const EMList = [
    'https://imagegroup.in/images/news-events/icreas-2017-the-annual-art-expo/1.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-the-annual-art-expo/2.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-the-annual-art-expo/3.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-the-annual-art-expo/4.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-the-annual-art-expo/5.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-the-annual-art-expo/6.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-the-annual-art-expo/7.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-the-annual-art-expo/8.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-the-annual-art-expo/9.jpg',
]

export default function Icreas2017TheAnnualArtExpo() {
    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };

    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 mb-3'>
                        <Slider className="news-slider" {...settings}>
                            {EMList.map((iMG, index) => {
                                return (
                                    <div key={index}><img src={iMG} alt='Icreas - 2017 - The Annual Art Expo' /></div>
                                )
                            })}
                        </Slider>
                    </div>
                    <h2 className="h4 mb-3">Icreas - 2017 - The Annual Art Expo</h2>
                    <p className="no-padd">Place: St. Micheal's Academy, Chennai</p>
                    <div className='news-arrows'>
                        <Link to='/icreas-2017-chellammal-vidyalaya' className='prev'>New Post</Link>
                        <Link to='/image-opens-new-centre-nagarcoil-2017' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}
