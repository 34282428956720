import React from 'react';
import { Link } from 'react-router-dom';

import NewsList from "./../../Components/NewsList";

export default function InterCollegiateCulturalFestMiraki2023() {

    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className="videoWrapper mb-3">
                        <iframe title='Inter-Collegiate Cultural Fest - Miraki 2023' width="560" height="315" src="https://www.youtube.com/embed/MRcBWDOB7fQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                    <h2 className="h4">Inter-Collegiate Cultural Fest - Miraki 2023</h2>
                    <div className='news-arrows'>
                        <Link to='/AVGC-stakeholder-consultation-workshop' className='prev'>New Post</Link>
                        <Link to='/15th-graduate-showcase-2023-chennai' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}