import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import NewsList from "./../../Components/NewsList";

const EMList = [
	'https://lh3.googleusercontent.com/yQkmjog9XLARh3jPinxEZtCLwYr8cAyREmo7kDptv3TRLwomAVxsXjm3w-Q1e0yqXjVqrcPBXY9Idp_irCHv3CJ2DE49tcQU48zJHZpG2HOJJeqoSX9kh83UElMuITE52TNxjDbCFw=w1920-h1080',
	'https://lh3.googleusercontent.com/T1M2wKFI9RghwAcujFFUvQcXLgsJQTxTweN1_CRPOTeryxYqy9o4Ri25vao6lqjG3VU0M8nDRkI5UYl3HHNikYA8hFwrQcbRRfPcVa2G4eS-bBeFfbzypfRoy3BC9Gl2V6ZsbE0d9A=w1920-h1080',
	'https://lh3.googleusercontent.com/xX1-bTWK0BN0p5yaSfZvmNsyfgmTZoK7_C26MjNV_dWBQ-o4ZQZCL_ksP1iwKxZm4eLHuv2u_trUjjUtx4FXD7h573uhlD8CjKhhH5i5URQdjH6XpFgC_hkeQrh0DiZILy8V-OYwAw=w1920-h1080',
	'https://lh3.googleusercontent.com/7GqPW9kocZx7FVBaviRX5u-PkcUJwPW5s_N3Jt67iaIGMn4RL7zbW7iVUxf2QMFFApHNNOb3DHTOUfo1COryOdvphyhYePGz_C6efOaJcZwcfu9-k2xzHzSUNsmEHMdpMWX_SVHNCA=w1920-h1080',
	'https://lh3.googleusercontent.com/k-24qh6WEvhEZUxl5PO7q6nem8T2DTR1QOYhNWyWs5CClcbCW64L_rYTFLytF2bFo3_3DO2KSPhvSOR_VecXFvMHV9L3iiXJGccWn8PwaJUtxeilnYeQ1HO_Py6Uj_IZEtNV51n0Ng=w1920-h1080',
	'https://lh3.googleusercontent.com/Qo1gPb5vqPu8W3XavI3VAgwQ5oqqy14sPrzdQ5FHdBAqZcsO6FXCO0a-Kyc8J9ZB3GoR5ljFCV08-Xu-qaSGMomETgmoXq2vMYsLn7cz-SAHBNoyBuhBAOGTe6B82LpNGQtbuGb_zQ=w1920-h1080',
	'https://lh3.googleusercontent.com/uzGjCNxxMe3ymcqWXbQDJGtrF1eE-s_RDeV1trQsl7-l4Cl5TTD74apa4K1f6xMI8DRJfs27RtJA3jm6jZGmBeiBdvptnslIe-sYwV9wGl3LzGbil2Uh5kynC0uFm4mHy_fCwZzXdQ=w1920-h1080',
	'https://lh3.googleusercontent.com/09gllNkzT4inMijfvFSSAwYndUiPdTFRWUtXhXs1WNfl0m-Ugu1lYF_JZOGLPPyc6hM60HmiXMZ0ldUKtywZbTab_fP51fclJs1gc4eMxIZ-gNrXiSqCeh60X7w4d37wBiVfT47kPQ=w1920-h1080',
	'https://lh3.googleusercontent.com/ltOvR0VIaomYUKWLqhUkYN3uMLgy4kxSv5Fuuv_TnW8Ugn__A3KqQXaHy7_oqV0j--qjEQgVeuWPRgqmwyu7MkCW53aGed7LDwMFnFNFkpGwiiD6Vt3H3NQzl7jaK29EhyGcGmu9eg=w1920-h1080',
	'https://lh3.googleusercontent.com/Up_qac3nPD2OA2bZR7OX--Wwbhof654CoCKH7gxzu7hGYmXVKJMu8I1zrb-W368aP-9fiN_jpdm8LCILH8bSZw9aeHTTKJkkqh7isbgYms6fft-3auwAc277N6YewI_DzJ2onkoDHQ=w1920-h1080',
	'https://lh3.googleusercontent.com/S1nLwV7OxFn7e-tM00LjQmhQmX6knwX1vRAUlinR3-1GfIM-To31hDOXR2dABWRYa8upIy6dzH2lCc-mSqq7S_RoW9pZvj5_GJFaAh3Z7wA9IhZAEyErESeK3TqES6-jAAvGkL6gfQ=w1920-h1080',
	'https://lh3.googleusercontent.com/TedyFR4MNPndhUHghrR1JFlw307NeTHOLZyMcVMvhy9k1-E-RessZuSrX4k_9zlBkTY2R8PbOYnOiWIyFMeU4r9fJig8oL_eSiN-JTPk_TnjUeCSX7lV8xUGqvMWc1-UClrKuRECEA=w1920-h1080',
	'https://lh3.googleusercontent.com/d-84K-l8PNd35FEXVG0PoAYS1iqxMNIro0XtsAVxgVyP5KLk2sPw6cVY_P05HJbnfHQOFqw2ByKWnNwdrBeLHGgcYzcLGH8uFxDKQnJCcWgMrcEzRJ9wxdp16t2_iKMy7V4byXFX3w=w1920-h1080',
	'https://lh3.googleusercontent.com/elG1jIkEky9A7i_Hkq00u_MdlR2jv9dr7KC4t0OJ7HTNr_UAasLVngKjoQCGyJgH6WCS00tHRQtLVxXfG-NeYgwA4-ST1iCsuJlvn_CRPcph9UnQtwcs3B4ckFVgqsIjp7XJgcxChA=w1920-h1080',
	'https://lh3.googleusercontent.com/ZQhvdCgpMq7lr1W2KRcFvHBNG-Zs2BgBxspXy921uVnf39sKYkdXHuPsze2McGMnMZx1S_ZsFpCtC_HwS2CWHkpfJvzGWLfj7Oci_2zJnCN42vvkDLBubxYFgtGRDNU51IEZgGSRmA=w1920-h1080',
	'https://lh3.googleusercontent.com/2gFexwx5-ZHTiSJBIbej5Q9dwrddJ06fHp5Nnwwz1h_rcnTU8zwz3G9wddwyB9NX0k9PKhIhpBLMeuv2_c9tjoW3C0ZHEUVnJyG5QnvpfR7NdQl576ncPq_RS5PqLXDpWImLKlVO-w=w1920-h1080',
	'https://lh3.googleusercontent.com/E4zL0zB9Azes8QEjaKmZmLKS7AgLwivJOzR0Ilj1NoP2k-C2Hzj1Fahgr-8NuOnYZv2O94UAoRF4muXMZREiO7_JJSwjtZo7OER7P0ZaMGToOGrXsrHaNgxpGPNlYxK4hGhFWX4HEg=w1920-h1080',
	'https://lh3.googleusercontent.com/moyOFPDlSLJMN7dv9VTgeGbL93hsI54sa79p2EyuJZv_IthSzpE51U644DHj3kgmKQIEPHvUUomOpfCHjGygpEsA0KJPM8rrY1HuRb-SqIQ7t75zGYQkI3dciNoOxAr7Mnwr6VcA4w=w1920-h1080',
	'https://lh3.googleusercontent.com/mLDBfQhRhRt-D3pP7rRfqkSqusSz28zYYLJePa_L6OhhhwXPZTR0GciqLwOlYaAAZEMtxnRkHRBXBROgfmp-1b0SZ8Svr-R1OvDfU7wxhSA7katn6LWtav5Gmk9CvE52zddgOIauHQ=w1920-h1080',
	'https://lh3.googleusercontent.com/HlnIIlevHefsG27fI22art2dfs485RQQEXtl0Vc-XRMvtprtPNlm_skywxjH0umuJcW_hjEbLz4RA1oh5MHDPNCO3q_U9s1_NZbUASOnwGUwEqqI_yzYahYkaN0Rs3Wd-e967CeZZA=w1920-h1080',
	'https://lh3.googleusercontent.com/HZlNvAbdBYnb19zZw1WwAJhW9mmwbpHO9lJUnKE9II-q0GTqUA-95vx8ytb2IHNLKJfzhjpp2AQ1zYnGrBATAS04dcMEyews0wnK-i6S_hefiPFbz9r2I0yqhfwliDONyuJl_-_LWg=w1920-h1080',
	'https://lh3.googleusercontent.com/yBYYsGmsicnN_7SdNKHxT6eQ-RpW7xgWL76bKr_PebgxOdhBR5eCWI1Omp_VGrgipnoRa7oOJLqyE3u2dUT1VK1R5pJkduHWPzrLfYGqYCWBMYA6YcZPKprq-yzAdUyKcaI0DXtRCg=w1920-h1080',
	'https://lh3.googleusercontent.com/0Ll4TL6YnmbRctD8hqiaQ7zWUP8LG3s-_570fFJ3oixDfoev1Prepe3TmZKqC09-HdyTJe6wq2qifEfdLtYo31IxM0uqVdbpBoiOTMw1yK6xHr37Bm1UNLO4rOLw29ToL2OFCvB8WA=w1920-h1080',
	'https://lh3.googleusercontent.com/ZM4ecTHwb-5c250zhNNj6__iD-Q5uzK8gh-hkxYDJCn05Hk7oBeOo0A9F-5EjFRewTPtOyV00qCPbRAt-YD0BXMcmUP-8rwip4O8du56El-iD1aDSmWDH0-KStTfDIdq16HUSyu6rg=w1920-h1080',
	'https://lh3.googleusercontent.com/gQ-MrHtNL9lGrQJ60zxqU0DyoxNhhY6DfjG8TvBW7rH02sOeF8pPJHeoZxJ8fuZAoAFUXxuW7nAyYeYvhAP23g_tfS_8ZkzUKvEEFppoawpwBxpbPfOCY0EfEifIW6NlAEnAtAUyNQ=w1920-h1080',
	'https://lh3.googleusercontent.com/CPUishbkmREddFcgdwGLkZUYKVKCQKRT4xJTcVxDSxmQHv0IjEoQF96qzFL_m9VT4fYcX377O1z7QLWSdfgOCGX4hEPy8kv8nz1i8VAhqnJwW8V22jd3qEzRmwzD9E0e0fzVItG1vA=w1920-h1080',
	'https://lh3.googleusercontent.com/tn7Air6yq0WsMaiNqSyIMrHf9tq35UHCMMKPZK6jJf_aYjC-uGNxNJctuSEAen_pC2Lnqskr1ArLjJAERj9LQjKnwRs70yzRGT9ldGlXKBL2OAI0NGVauDEIhgqQjTgbMZFQj9yhnA=w1920-h1080',
	'https://lh3.googleusercontent.com/_zgazmtfGsmhp31mvC2i4Ik7SJHumX4RQW2ZZnk0Ti7OfRkObyOUT9vzgtMO0LH5pyhvL_hWx9-amVd4bFZzptems38ChPnlubRN0HyiZvsJQzYcRMtTBFVfoayK9Kmf0veZAoDCag=w1920-h1080',
	'https://lh3.googleusercontent.com/U7-x6RWdvFQI5raQCPGdmBGkToDyg6_pieUfqlUehR7Z3sD7KtDJP7NLc321R9GilqAplmltsE_CD2wYD84TTfq2EumRKD9q06zdRvBp9z_FEBbqVdxl6LvP3xswMwxuKrAEt0iT4g=w1920-h1080',
	'https://lh3.googleusercontent.com/yKyxiaa5TiK85PjXLMRbqAVjO1O0vOZ6VVIxNBKokl2akkzFgbZm1A2vMqWAN8u7t2yX76AsJ3ElLmokd-PjMjgehgIrT-FOTQGGmXJ46IyUMpNjedMzvP2bYF098Rwq8wowXXCv6w=w1920-h1080',
	'https://lh3.googleusercontent.com/XWb9lAs58ggwhuyPTcp3OmeMKRyBAeAZYgm5ei_r96L9hgddRDcHoE3qDdaBppMMWXuRQo8j9npcI0hl_nvbYoTp0mq7bGinSwASMR4SVeMNiUBmSchMrFkiBh8GAZc5sEjnz4L0Ng=w1920-h1080',
	'https://lh3.googleusercontent.com/11NhTCnj0yjF7axET8tV8BAR9-6FppU9szqiF3GrwVCmaxR9QXt1r7WftZuyIlwcAjaEBHMxL86gHoKhz1IlpxEZ6kYE-d54Ave99uMB0KWP6_gyN1GCzt38hEYx40nCij0-VVYPng=w1920-h1080',
	'https://lh3.googleusercontent.com/Lsi6jabphuM_q3Gto7j3XyD0jHQGzjxlQxj6K0l1moQnPgCCK4rsyL8BNn0iuqGbuFdS8R9PhE6p0ncCBmIVYuseLXq4kLj3-iPDh_ldNoxfP5IZ69vRJ8mAIqFgFSYNdCbVBo8ryw=w1920-h1080',
	'https://lh3.googleusercontent.com/0gsSFHT3Qk6Fs1GxYc_XfxrJF6WbJDiPZ8htNSEQd94nRH76TjSLpc62n5oZz8o4p-fktpS_-AGugfGUEnM9-o-pyHiGzHha0QfaA2oy8Aa4ZtrxAaRdzLSnG_PZF_Sx3eJ0wbUuIw=w1920-h1080',
	'https://lh3.googleusercontent.com/dgHYrjSRFObjtYLtxSJ_5ujNn6W3KTTLG6SMoO_YA5q0Ci1flnLTf8iWDtRM4JoWo_dOuJF2Ct3dftzkXd64gDamMdPPXJLavNLHw8nTqPbvjgbOzfqFDtZBq-5LoN9uTA6AmigpKw=w1920-h1080',
	'https://lh3.googleusercontent.com/U8Zw47sPvtANyEtoippN_Q7MwXs7uFGpYaQSq8b4HylgSnvzLcT7q_yqYJQvkmCX8Yk5CBZV7XbZhm6YTvsCfZF8hfPcdJAqdrokTtQOw871GlldQ8h5sekMbKNpe02trza3LSVaZA=w1920-h1080',
	'https://lh3.googleusercontent.com/Q8dLRygD1_VCKqUReFBJMyuFF5geDlovfYyi8AfMsfkr0ttpbAAi3AMM3_h-DByYH0wiaVpFd2Mbs4V64R3_-WsG2xUeg-68vwQAwFaTF29VqcoKIWKZHX09Oo6XyxqP1N5WD_ljrg=w1920-h1080',
	'https://lh3.googleusercontent.com/N5gwIeuGfJS71fMvcegfGy08VdY7SpYb19K1BRHH6am0_tsQaO7Q7xQ86ARHRzllyLMO1DCZF7I5SgeQi8pLSxYF7jUuJy3eGkV7cLiLsIPMz2OFABBudME0jrSHC9pMdd_-jvB5Zg=w1920-h1080',
	'https://lh3.googleusercontent.com/2pA-tOD1vm_x69me5cD4pGzV-dBtEo12QQ3C1KzxQtP9PRUJP4tr3Xxb7mq_gGKj2TfsZCt-PCZ3y1fBJlzDdIFG0VDv2XW76bHdMEnXr9fs8FqF8SDdJBUEeGRA5hexSMw3-0-rxQ=w1920-h1080',
	'https://lh3.googleusercontent.com/Y4vH03-O1um1sQ4q_xmTOws-73-LRb6VB2jEp0HlM-eh8J5X60aRXukiOLoqGTScmy2ig6Lr1QwWDWsHxwTV4NnMYZ3Gy_-2HcZpAx-rvUxrngBNQ2DBljKbIaoalO6BHeCT-orXLA=w1920-h1080',
	'https://lh3.googleusercontent.com/upGdYkDErDgwSD02Bz_OU4q15oCUkEhaU7zLJmlfvpWL-QiryIAbAdl6BS07yAwsWP2FN8XzLjjHAk_ZUb65FitqYKbtprKtt7jsBBU5APg02xAPso_HmcJvnFK6AHdz5yQeEA0iUQ=w1920-h1080',
	'https://lh3.googleusercontent.com/n7pCN6yemeGzADV2u1hl83MhEbp8pT2uE-GI84JyD7bEh7kNyYPkhgHiSqHT504oalOQlmrNw0FAnaipKMdTa8VWECbZuzjhARp_5bFzfc18AqG_NMF8D0iTUf62gz7OX3yIYWCZbA=w1920-h1080',
	'https://lh3.googleusercontent.com/A60Vd8fDa6IV9XYZMgFsJic-YuHpU6lU1BQ9yWKpjaBaCdr8KAELZ9Y6rXctxw3VmOd8BAhbubThjDAgPcQMi5g89SyfI3VE4LsQYdth5PgnbH_0pc955sqpp-Mb6WuvJRK3tGLaxw=w1920-h1080',
	'https://lh3.googleusercontent.com/w8MtgGY4kD5U_-DQaxtrJJcJ00y1tlSYSQ6xBmmoo1WvV3sNMDy7MOi5P2yOnlNtRpqO9y4WxrktkoAj5j39qpleeagjCxAfh0R9jnvKVoPEbLwbtNknKy5ZRmguNhTAJ_FZ3yTElQ=w1920-h1080',
	'https://lh3.googleusercontent.com/f_aa5TqlJlS0BEbNcqOI-_tWG-ZVzgDJesR2_b5JfTBsh0mW6c9tkyVKo4bzyyr5mYx2nN-rFVpNp6w6EeqMyDL9vzaMxuTypc-8OyjqzTphfpR9pFv22wXUrZ4B7EMTW8gpYwnSDw=w1920-h1080',
	'https://lh3.googleusercontent.com/XJSNfGYunE-B6WVVeF0B1E90_sfoDw2A5XsObn9SPYZHwOc9JFPpnWA3apyIPlmWzpFVyE6YUO1OSGkUlsNcCUbPg7kqGZiLZjEqHBU_K-ynDfWvHKBms0tcIZvVzxNtWoxY0D6Dew=w1920-h1080',
	'https://lh3.googleusercontent.com/D_xszgZB3B2v4wSGff3gU2X91UxUiY22k8EINyKACUcStC4VE0_G9yadLI0-mEUGm6bdZb2on24FGTrxwk0WvgU1ZTm13as_FH1aublVYJHP55UhNOiesSeYw45nED6KU8F7b1JOTQ=w1920-h1080',
	'https://lh3.googleusercontent.com/lbqWw_pRnPjK_pT2NwZY00YApaBgnaE3qXpIyj-qcJH89YWsz56Xq2LCyOITY6craaeEvist46O8MDZ1dJ4Gx6fAnps-CRS-qKUaue7awKtLrFzSkBY1qDwIzSRk4y1grAAyp9DF9A=w1920-h1080',
	'https://lh3.googleusercontent.com/OKa6j1f1zyNxoUkzK6WxYs_DdjpBZGAHGBtTQab9QF_2p64XaXAi3sIv5ZDe0HzxGaiIEP32_bN6scm_SxET2GidHLismfnJnznYjEoyQk5I1ePXy_0mZWSgS-766mhKp6DwCXNf9Q=w1920-h1080',
	'https://lh3.googleusercontent.com/-8DmZO75FrJg0YRn04K75ZdimUKC_pcg5mkBBemBtncPEQn9Qcy0G97-oyRKz9sLWTkoXJd6bFepvDig-SuxS4DkjhCjaHupXhnz1YHS4y_YNsFrlk3BmMieojOEwyLTAQiSbGPB3w=w1920-h1080',
	'https://lh3.googleusercontent.com/XioH4-QmNauR5QbBj4qBYnXMWqXYWN15ielbtKDlxRXa_Ndi27AZflVhHvCq1XoGnsrNkob77GwmQIjFZXm6ZvWZ8f7wdMr23FyCw44UwC4dvpYSI0ObJHjVRCovCruU4lysHjB9Hw=w1920-h1080',
]

export default function InterCollegiateCulturalFestMiraki2K19Chennai() {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = "https://cdn.jsdelivr.net/npm/publicalbum@latest/embed-ui.min.js";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		}
	}, []);

	return (
		<div className='content' style={{ paddingTop: '74px' }}>
			<div className='container-fluid sectin float-none'>
				<div className='news-lt'>
					<div className="pa-gallery-player-widget wdth-100p mb-3 hght-480" data-link="https://photos.app.goo.gl/auiByPDm761AumbF9" data-title="Inter-Collegiate Cultural Fest - Miraki 2K19, Chennai" data-description="51 new photos added to shared album">
						{EMList.map((iMg, index) => {
							return (
								<object key={index} data={iMg} aria-label="Inter-Collegiate Cultural Fest - Miraki 2K19, Chennai"></object>
							)
						})}
					</div>
					<h2 className="h4 mb-3">Inter-Collegiate Cultural Fest - Miraki 2K19, Chennai</h2>
					<p className='mb-2'>Yet again! With sparkling lights, music and fun at Miraki 2K19, which caught the audience that gathered at ASI Convention Centre, Chepauk, Chennai, on 22nd of February, the “Miraki 2K19” the inter-collegiate Cultural Festival of ICAT Design & Media College Chennai kicked off in its 2019 edition.</p>
					<p className='mb-2'>On a day event, “Miraki 2K19” witnessed at the highest level of the exaltation of diversity in the cultural artistic mosaic that the educational institute is proud of! Groups made up of students from all different colleges sought to demonstrate the best that each has in the most different cultural manifestations.</p>
					<p className='mb-2'>The inauguration of the Cultural Festival commenced by lighting the lamp (kuthu vilakku), by Mrs. Keerthi Shanthanu (Television Presenter, VJ & Actress), Mr. Maran (Film Director), Mr Ashok Kumar (Founder, Aksha Animation Studios & ICAT Alumni) accompanied by Mr. Kumar - Founder & Managing Director of ICAT & IMAGE Groups; Mr. Madhan - Operations Director of IMAGE Groups; Mr. V. Anand (Technical Director of IMAGE Groups) Mrs. Lakshmi Kumar - Dean of ICAT Design and Media College played a key role in her invaluable support.</p>
					<p className='mb-2'>Every year, during the month of February, within the framework of different college celebrations, ICAT Design & Media College, through its Direction of Cultural Diffusion, organizes the Cultural Festival “Miraki” to show a selection of the artistic manifestations and to reflect on the management in the line of culture and arts. Thus the event kick-started with the Inaugural Host Dance and Host Light Music.</p>
					<p className='mb-2'>It was hours of action in the on-stage event categories like Light Music Solo & Group, Western Music Solo & Group,, Western Dance, Fusion Dance, and Fashion Show; and the off-stage event categories War of RJ’s, Bottle Painting, Adaptune, Comic Strip, Spin a Yarn, Helmet Painting, and SuperHero Quest Quiz which always received with pleasure by the audience that made up the stands of the cultural event of ICAT Design & Media College a grand success. There were also online registered events that included Animation Short Film, Short Film, MEME Creation, Fan Art, and Photography.</p>
					<p className='mb-2'>Chief Guest Mr. Jayam Ravi (Film Actor) enjoyed the event with great energy and spirit, he also shared the same with all the participants boosting their overall confidence.</p>
					<p className='mb-2'>In the end, the jury, based on the criteria of originality, performance, and integrity, decided to assign the overall trophy place to Ethiraj college.</p>
					<p className='mb-2'>Addressing those present, our Guest of Honour Mr. Ram Kumar (Film Director & Writer) who also presented the prizes to the winners, acknowledged the effort made by the participants and stated that it was a moment of learning and reflection on what the contribution of students to the colleges could be.</p>
					<p>Glory to the conquerors and honor the vanquished!</p>
					<div className='news-arrows'>
						<Link to='/raja-raja-chozhan-2D-animation-short-film-released-2019' className='prev'>New Post</Link>
						<Link to='/inter-collegiate-cultural-fest-miraki-2019-bangalore' className='next float-end'>Older Post</Link>
					</div>
				</div>
				<div className='news-rt'>
					<ul>
						<NewsList />
					</ul>
				</div>
			</div>
		</div>
	)
}