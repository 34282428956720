import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

const menuItems = [
    {
        name: "Creative Solutions",
        id: "crs",
        subMenus: [
            {
                name: "Image Creative Education",
                to: "/image-creative-education",
            },
            {
                name: "ICAT Design & Media College",
                to: "/icat",
            },
            {
                name: "Imageminds",
                to: "/imageminds",
            },
        ]
    },
    {
        name: "About Us",
        id: "abs",
        subMenus: [
            {
                name: "About The Founder & CEO",
                to: "/about-the-Founder-CEO",
            },
            {
                name: "Milestones",
                to: "/milestones",
            },
        ]
    },
    {
        name: "News and Events",
        id: "nae",
        to: "/news-events",
    },
    {
        name: "Contact Us",
        id: "CoU",
        to: "/contact",
    },
]

export default function Sidebar() {
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        window.addEventListener("resize", function (event) {
            setWidth(window.innerWidth);
        });
    });
    const sidebarHide = () => {
        document.getElementById("sidebar").classList.remove("active");
        document.getElementById("menu-btn").classList.remove("active");
        document.body.classList.remove("fix");
        setSidebar(false);
    };
    const [sidebar, setSidebar] = useState(false);
    const sidebarHandler = (id) => {
        if (sidebar === id) {
            setSidebar(false);
        } else {
            setSidebar(id);
        }
    };
    return (
        <>
            {width <= 1080 && (
                <aside id='sidebar' className="off-canvas-wrapper">
                    <div className="off-canvas-overlay" onClick={sidebarHide}></div>
                    <div className="off-canvas-content sidebar">
                        <ul>
                            {menuItems.map((item, index) => {
                                if (item.subMenus) {
                                    return (
                                        <li key={index} className={`${sidebar === item.id ? "active" : ""}`}>
                                            <div className="nav-link submenu-trig" onClick={() => sidebarHandler(item.id)}>{item.name}</div>
                                            <div className="submenu">
                                                <ul>
                                                    {item.subMenus.map((stem, index) => {
                                                        return (
                                                            <li key={index}><Link to={stem.to} onClick={sidebarHide}>{stem.name}</Link></li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </li>
                                    )
                                }
                                return (
                                    <li key={index} className={`${sidebar === item.id ? "active" : ""}`} onClick={() => sidebarHandler(item.id)}>
                                        <Link to={item.to} className="nav-link" onClick={sidebarHide} >{item.name}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </aside>
            )}
        </>
    )
}
