import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import NewsList from "./../../Components/NewsList";

const EMList = [
    'https://imagegroup.in/images/news-events/rampculture-image-fashion-dept-students-participated-avant-garde-fashion-show-2023/2.jpg',
    'https://imagegroup.in/images/news-events/rampculture-image-fashion-dept-students-participated-avant-garde-fashion-show-2023/3.jpg',
    'https://imagegroup.in/images/news-events/rampculture-image-fashion-dept-students-participated-avant-garde-fashion-show-2023/4.jpg',
    'https://imagegroup.in/images/news-events/rampculture-image-fashion-dept-students-participated-avant-garde-fashion-show-2023/5.jpg',
    'https://imagegroup.in/images/news-events/rampculture-image-fashion-dept-students-participated-avant-garde-fashion-show-2023/6.jpg',
    'https://imagegroup.in/images/news-events/rampculture-image-fashion-dept-students-participated-avant-garde-fashion-show-2023/7.jpg',
    'https://imagegroup.in/images/news-events/rampculture-image-fashion-dept-students-participated-avant-garde-fashion-show-2023/8.jpg',
    'https://imagegroup.in/images/news-events/rampculture-image-fashion-dept-students-participated-avant-garde-fashion-show-2023/9.jpg',
    'https://imagegroup.in/images/news-events/rampculture-image-fashion-dept-students-participated-avant-garde-fashion-show-2023/10.jpg',
]

const EMList2 = [
    {
        posterSrc: 'https://imagegroup.in/images/news-events/rampculture-image-fashion-dept-students-participated-avant-garde-fashion-show-2023/video-1.jpg',
        videoSrc: 'https://imagegroup.in/images/news-events/rampculture-image-fashion-dept-students-participated-avant-garde-fashion-show-2023/video-1.mp4',
    },
    {
        posterSrc: 'https://imagegroup.in/images/news-events/rampculture-image-fashion-dept-students-participated-avant-garde-fashion-show-2023/video-2.jpg',
        videoSrc: 'https://imagegroup.in/images/news-events/rampculture-image-fashion-dept-students-participated-avant-garde-fashion-show-2023/video-2.mp4',
    },
]

export default function RampcultureImageFashionDeptStudentsParticipatedAvantGardeFashionShow2023() {
    const sliderRef = useRef(null);
    const [currentVideoIndex, setCurrentVideoIndex] = useState();

    const handleVideoPlay = (index) => {
        setCurrentVideoIndex(index);
    };

    const handleVideoPause = () => {
        setCurrentVideoIndex(null);
    };

    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };
    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 mb-3'>
                        <Slider ref={sliderRef} className="news-slider" {...settings}>
                            <div><img src='https://imagegroup.in/images/news-events/rampculture-image-fashion-dept-students-participated-avant-garde-fashion-show-2023/1.jpg' alt='RAMPCULTURE - Image Creative Education fashion dept students participated in Avant Garde Fashion show 2023' /></div>
                            {EMList2.map((item, index) => {
                                return (
                                    <div className="videoWrapper" key={index}>
                                        <video className="videos" controls muted poster={item.posterSrc} onPlay={() => handleVideoPlay(index)} onPause={handleVideoPause}>
                                            <source src={item.videoSrc} type="video/mp4" />
                                        </video>
                                        <div className={`video-play2 playpause ${index} ${currentVideoIndex === index ? "hide" : "show"}`}><span className="play-icon"></span></div>
                                    </div>
                                )
                            })}
                            {EMList.map((iMG, index) => {
                                return (
                                    <div key={index}><img src={iMG} alt='RAMPCULTURE - Image Creative Education fashion dept students participated in Avant Garde Fashion show 2023' /></div>
                                )
                            })}
                        </Slider>
                    </div>
                    <h2 className="h4 mb-3">RAMPCULTURE - Image Creative Education fashion dept students participated in Avant Garde Fashion show 2023</h2>
                    <p className='fs-italic mb-2'>Date: 30, September 2023</p>
                    <div className='news-arrows'>
                        <Link to='/comfusionism-miraki-2023-chennai' className='prev'>New Post</Link>
                        <Link to='/AVGC-stakeholder-consultation-workshop' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}
