import React from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import NewsList from "./../../Components/NewsList";

const EMList = [
    'https://imagegroup.in/images/news-events/CSR-activity-help-kerala-flood-victims/1.jpg',
    'https://imagegroup.in/images/news-events/CSR-activity-help-kerala-flood-victims/2.jpg',
    'https://imagegroup.in/images/news-events/CSR-activity-help-kerala-flood-victims/3.jpg',
]

export default function CSRActivityToHelpKeralaFloodVictims() {
    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };

    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 mb-3'>
                        <Slider className="news-slider" {...settings}>
                            {EMList.map((iMG, index) => {
                                return (
                                    <div key={index}><img src={iMG} alt='CSR Activity to Help Kerala Flood Victims' /></div>
                                )
                            })}
                        </Slider>
                    </div>
                    <h2 className="h4 mb-3">CSR Activity to Help Kerala Flood Victims</h2>
                    <p className='mb-2'>As most part of the Gods Own Country was heavily affected by the flood in July-August 2018, thousands of people had lost their livelihood. So, relief fund and offering basic needs to the affected people is a responsibility of everyone around, felt Image Creative Education. With a felt duty of Image Creative education to groom the students as socially responsible beings, Image has been conducting Corporate Social Responsibility (CSR) activities every now and then. And this time, it was a dire need.</p>
                    <p className='mb-2'>Image Creative Education conducted a CSR activity at Pathanamthitta in order to extend helping hands to the affected people. They collected a sum of Rs. 1,05,000/- from the students and staff of Chennai, Bangalore and Hyderabad regions and Calicut Centre. The Image Corporate office donated Rs 1,00,000/-. The centre management, staff and students of Image Creative Education, Pathanamthitta contributed another Rs.50,000/- also. Five needy students who have lost all their belongings were identified and Rs. 1,55,000/- was distributed to them on 26 October, 2018. As per the  direction of Mr. K. Kumar, Founder & Managing Director of IMAGE Groups Rs 1,00,000/- was donated to Kerala Chief Minister’s Flood Relief fund.</p>
                    <p>Thanks to all who donated whole heartedly!</p>
                    <div className='news-arrows'>
                        <Link to='/icreas-2018-st-patricks-academy' className='prev'>New Post</Link>
                        <Link to='/8th-graduate-showcase-2018-bangalore' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}
