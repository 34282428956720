import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import NewsList from "./../../Components/NewsList";

const EMList = [
	'https://lh3.googleusercontent.com/4YwPW84Ucv7WhL3WPaPqJOflWeLU7sMeFtN1_YGQ7b3eZ4CI0y65mv61uepXrERG6XSbuJiS_9gHtD-V6VVQ3RBS49LEY5RQl7AaILQzHN0DfP3hV6PVZ1P59HUd0UqtemWi3HeNYQ=w1920-h1080',
	'https://lh3.googleusercontent.com/zAQ6ueOl4QLV_AGfrpuptDkkViTtuoKCkuLtBLZ6DUPIHNhA29Q1HDSLgTOWps0-9uR5SrJUQtCSFSztBe8e0bo5rBZtnl66HcqMXRkoL0ZtGqTKKiABXeqqPbjr1R12LCvnC-JtQw=w1920-h1080',
	'https://lh3.googleusercontent.com/HbE6YuWKCzII6cVqOi9SUH0dmPxUsybsan-w4RNPCcAEes4xIphBfv6a_mG3ELmdNy_iy6j_lW2DhQOdHxUIf3IXgmVqD6fud4y4mJLjx0oHT13qSQLGvOUIK6tRrL9xgYfdXqovqQ=w1920-h1080',
	'https://lh3.googleusercontent.com/Lte3g-T1UIEjUGlYJdK_ng5JjZOmh0z71SIXCHHyxckIKeVlCR5x0XrSg1MDfkXzf4l13qsH3MIZmwijtUUR4QDqUa3L8LpaULIgsW2SlgrvjV7TgAm5YHLqala2ZeEo8RFmyiBAqQ=w1920-h1080',
	'https://lh3.googleusercontent.com/PmY0MyjsDrclGNJgzuIKKXuZahlLAnrRpOsnoDJ66f36zgpl03mYe-ld_Sox8N9V_GP8PoG_YvR7LZ42zDgaWc7V2uZBqJSmv6QtwN4rjbehs9QGAELuQ23ud6eLStCHGTDPgTR11g=w1920-h1080',
	'https://lh3.googleusercontent.com/xy311H86JE8rFP_8AArCmazkQLHu6492XZ6f8vde7x6cbcjcDv9Lz9fL23QvIenHMFkCpCseurYBUO-KDu2rBosTpDpiZJRKB-lYkaWUnRnLpPvTkz8CSFg1a72KPtct7glHuPRabg=w1920-h1080',
	'https://lh3.googleusercontent.com/oYJM22YqYvPKSsgDRKjJrnmdut9TWyL-THxosD1ZRkPW4Jeck0gr3j2rbULf-YUFG52sS33PkA104XWQtgvPEPWpFmi3NvmiMgJUHSQHmUgC6P957FnQ1hYbH_jz4NjVtEtGH7S-WA=w1920-h1080',
	'https://lh3.googleusercontent.com/Zt8Y4es1ZyaSUz4f1VFalvj470Gk3umc_1jsPGqlkGGaS1thcZ2hsDExILsK_Ji4bAW_TW4XgCTzYoKgpsU1ZjvQdPw8qTHGdcbZZUM3nfC_XdQzcEjRxSibT_QO-cSLzttjI2986Q=w1920-h1080',
	'https://lh3.googleusercontent.com/A2ZR-0UkDcCysu8LS1_s44TKoBkwQOCqCYs_Q6SfMJT0u5_v6IAqsvEWr9RFvqUPf0OcdrQqp1fObtRp2IxH-UjDPTZMI5S8m0YNoBLE94li10UuTvrUgcWaqcxxXLHN03OFedtovA=w1920-h1080',
	'https://lh3.googleusercontent.com/p-yNPDzSJVUI6-zaqRsf3Rw7NQWLD4cX9gs71SZKvgj7SnGZSXHxbGSgB8Qpya1r_M0VSWww7hpd1aiVtUSutWhZ_sZapfQKkEw5WHIQHstkii_iylRYRJ2_fyf2ymbVqMLsGjSKtQ=w1920-h1080',
	'https://lh3.googleusercontent.com/LumYx47ABYxsIRG-X5Cl3Gzy1F1AUyypDrZpM_u6W48lplcWCDiOu40YlAQ156DKlF6-e3zINwqOXiV7SaimPrMZ5fn7_W8W5R4b2cwaKAPiGllIC1yJZru5PrWZrpqwChlc1GIvng=w1920-h1080',
	'https://lh3.googleusercontent.com/ITqBvGrlUWQaGyCEahAkIQOGpykNiQaUNjTnhbhMTimjiSAAj4cPWR6XexZgAjvPSiUOrXyN4zZvUnIicy-PEfT1edvBHp4_dEvmjGI64E0y__sigJ1_s5NHTAx73qxBRi-8PO5Tmw=w1920-h1080',
	'https://lh3.googleusercontent.com/6RS72xqpdQaQhm9ay-6oOrZJ_rcDovsydpOGiskPWD9G3VoYDhq5BGnknQwBn6rQrfJgix7wPIJwKUO_3CfoHRa_emsUOjVOOjmM7X5hb8G_OvAT6jjijfQEHKBnrNhCcQiPHQ5L2A=w1920-h1080',
	'https://lh3.googleusercontent.com/MiuTFg5HZECRHzOEJo_JJDsTQ8Qfv3qGZ3bSvyYWVoqa_14jilso7vDuYgzj1V21xaFi5A2E5ZgQwcfvqqLXMSvCaQMy6HKizEA5SvjKCA0LXmvh_77AEqrImoIpOw4Dv_vkn-o1SQ=w1920-h1080',
	'https://lh3.googleusercontent.com/8bQYq0Rvf4rDXVjsA2ffxBQrxW53mvxjh7u9U3-JHWP7Aa4_2NsoRTF4WTQbBYb5JCL6N7DAZMzMeiStQvpk6y6ZZJg9uYb4r9ruCmP734AE61a5PDwmJyKMUq95tQq3jkA2fjWN7g=w1920-h1080',
	'https://lh3.googleusercontent.com/LxsZYqcc-CjxnxaHSuWg2RDup4EslRhcR_qUdqkU1_ZPnCHEVuza32wXE1U18xAXubTl3aOD0-s_sFhK0zQAXNOiJ04czwln21j0EIsVanNih2N47JTcwpUmIcZnT4NCbunaP58nHA=w1920-h1080',
	'https://lh3.googleusercontent.com/HSYTnHylQaCQ5w5tGdeOVCKhoNLRuTgNc_TbhkyY90x1HEo77xWw_L-840Ag2ezX98JCvgU2EKbsEPHaiVNd7w0MuD2Bv5wVXanokRatnfhw1GjrhP8GAgo98g82ycGs2vp5g_XkXg=w1920-h1080',
	'https://lh3.googleusercontent.com/uX-n2lMkGQ7ZoH4bqxMGzZt3g_A2J6sJcR18J9MVFQp06cID-nFXLqZNGYyVBLMRt3WZppd9yzUu498E6J--nY9ezVXfKQs4SlhsGYjX0EAkWQtM7KIl0lN_UTU4KgZKcwbcB9jcbw=w1920-h1080',
	'https://lh3.googleusercontent.com/b4Nqz28M6kHki4CLq_37kxPyLQm4CHS5HPVR2vztbOOnEsEXa_H5NXFgWl4P69Ra7EtRIV72Fa2CvJGeE_ML3rLbQYBNS_96t2--EFnMsDRKPs9nGv98XzK7H2F6EG-YicKBmiN1Cg=w1920-h1080',
	'https://lh3.googleusercontent.com/LXluwgJxR942WHJwBxPBfAWtDdtXU2onq3gyf4_493N6siBKNM5Nw9ndOjqNpZf6QY8_KCU4IvaY3aXAO9-Xb0iI-IXNzoYXT-oZaA-t7AtwkbHg0rXVta84-fXX3Wj09QrdsSw50g=w1920-h1080',
	'https://lh3.googleusercontent.com/zr_o2iDMhnMJ7a9xAAeEBwxXBZv225ok2PWVFpB6-A5cnVlB_LpJdcKMKbfrCTJFSqKp3pqTJh4qCDzrjv4IRU2ysHXWTvpyPHmQ1v0gPORebZKTDUBCCa2uETFBJ9SeLNJAfCmzlg=w1920-h1080',
	'https://lh3.googleusercontent.com/QRWRTjNYmJqtdmDGnu0Vbmzyek3w56LhWGHtam94523xtmjXmONRH9lejcG39bLzYnCNgQoPekLO50FeO2eobP9xlSXlBKB5M_EDqIxD69_d-LMVj9AaYvNz2dZEQitPh_gMYwONbQ=w1920-h1080',
	'https://lh3.googleusercontent.com/SFMMaCZdCMG_TNaeakbk3J12G2OeME7UEs4Ai513keh5N0Q7A3FTsUVaxZGwRXmAt4mNWZliTo6LqegwOA8fnR_6m6PGbjxsJX4fv4H_2z9HLhN-kux10p4CCS7LQQM5_tE708NlTw=w1920-h1080',
	'https://lh3.googleusercontent.com/JYtZrUUsyNqvGKkIcXq13K4UK3661AcS5bQN6rctvV4gfvKibMbld9Xd2QMs12CkNlxXRkvhmF-O0t-JfRWO_mio2NUHM5G_QDc4LcLOwHyd6-ms0GGPIIn8YF3XfNfaZJ-lNgD3Rg=w1920-h1080',
	'https://lh3.googleusercontent.com/-bIwv1A3RIphNo6lxScQZbCgm_v8d1bbvZmcqtVGvqC-uCt4ouoWHmDdszMu8HcPvYV1GIlF7treBgmbq4qXFqQFIySNADdsfPRUWbKAT2ESb2tOCUXrP4VQF-XdMPSw2B4Oxkj1oQ=w1920-h1080',
	'https://lh3.googleusercontent.com/4YDYnY91kTwysNfrL4nvf_eAmEWaFLUAWZYKQ-7TlDKlwL46_oqepcBMhmOFOF1q3Q4cijrFdGWFmekl2_0QOmrR_aC9RIxjYrYwk42gy1_NVXlDhzXcQh3mviRVQdQ3xyNzOYIWOg=w1920-h1080',
	'https://lh3.googleusercontent.com/YLnurHc8oztGzfjbqZVqW5zRCmIDHUArNYdh6R-gptcNo2MEB6To4uHCMQPucbClYcq1yC1Ubu5SErtflN_zE5WoDCSp1hzhXOF8kYg7H6AOx6pUvIomx9o3zS3sWSKi39vgYgxtqA=w1920-h1080',
	'https://lh3.googleusercontent.com/OLjqZoH7qjLufGBw3qVK-1-BOI_xNsnxie6wtHh-wtI0IT-GBkT6vysaf8No7JkIfrQU7-MFbJYImdublwBggDlcRGR0lCy1nfdXpuWIXVWs3SXDAsf-bAywP1TCLjRWOo4Q0J3Jdw=w1920-h1080',
	'https://lh3.googleusercontent.com/H5lUmsR7HHgXVNFa6QxhcK3qi7wXlqeTgQr6KDxJK4oQWymAreUA_Un_TVHss6zBbhQtHZcEo0Ig7xVLaN7X5W7utk64cblOxdWzN8Su_tCYBuFioPnygSfeYNr8rgu3jEP9Dre84A=w1920-h1080',
	'https://lh3.googleusercontent.com/-RtKs9JwpbUlIBP73_8QLI37bXkfY0zuDPEka2QUj8TCQDRmE2ZqASGLGWJ_TBi93TmNYs3851UhlbztWj0YRUeoN6gN_0wJHAwz8a6AFnNSOxSGN6ds_2YFn6_0veWerNNukL6ORQ=w1920-h1080',
	'https://lh3.googleusercontent.com/WYm4tjvDINrZx32mBavJbU5BSkcFhUs6yKxMXnj-T1UmrO02GWAH_SjWiB51ag39nMcTa8jz9oFr7L23maahCTO5J9yX6ZMWvqiAtc9HzNukznWehRAADEP8i7TmFWbWj5tw25N-HQ=w1920-h1080',
	'https://lh3.googleusercontent.com/O7hd21njts5XOLMXlxQ5IUHCLcttkqqy5YcP0W10srOO1fAIfL3Elc3nFE6ppUJPS8C6mdeK_CTgk1dysMKlyyGdZczBfUI1-URCGnkDqVlz9wJDqotdW8c5GzpMQgSNrURctig_0Q=w1920-h1080',
	'https://lh3.googleusercontent.com/CsEJQUFlrcKZy3-KKBTg582oBMYl7JiZ6M6F0WkBzLveEyAYEUVAj4ligmn_dCtJ5y7r2PtUUnSsPWx_0tYwhw-UrO2XqnSrHOCA9vg7Nj1q6H4PmL91s764EMFc2xtgeGc6zMD37Q=w1920-h1080',
	'https://lh3.googleusercontent.com/_1DkmFBbqFGXYzmhyrW4MVLdgqIMEup7OTQNqYSykAFbfD-PuP7DgwGhO8axkl5wQG5GeZEhrzJlh85qbbXyoRHg8CUhNDwBxPh5zs0LwtyI8f1JB0VrLdlMdB-jMSshFdKL6nE43A=w1920-h1080',
	'https://lh3.googleusercontent.com/m1HaYXskoxsQlF6xJCcsm_wS1bhRKe8K7fwhopeoKoDKNygJRc-v7HvY0XJS8IC_UB9VqwLrDd3VdHdm3g3MQCvRbV-Dfqj1MyG9FrfmSlSzvIt9O22Zmuvu0GZwjat_5f8pG1UYkw=w1920-h1080',
	'https://lh3.googleusercontent.com/qTLlpaM_2-iE8Slz-3jrJnaHG2NXrnnMeJ-aILx44YJui6_Bclf_Eue6yLGQ29il60SNb6qiPF-sQOBdGzEo1Phjn9r768LnU56glw-JjYkffrmzjdxMWXitXPtwpHAgwEVQvAkCFw=w1920-h1080',
	'https://lh3.googleusercontent.com/Eaog3N98iPMSTyFnHbavnvEbGA8WdSgm24AD7sQu6VODq5npNM6_KZac4KnFSt9FykvMA6yfQP-kncsIR0A0Ab1m8HH1uEdaHFi4GIwTIfZQDljU7aA27hztSUonEvej-ASAo1_6Kw=w1920-h1080',
	'https://lh3.googleusercontent.com/R5mL5_2JwXWvE7lAtO2OEs13klDWzhnQjHN-l01Z41bK3Jemw7ir5jbQ540wIshA3W0JvGAlosHWygK_qCHI9Y3lnCu9oGUvYJThPL8gM2xV4bl01_UGAF2QYO-9AojR5PN25KMbHA=w1920-h1080',
	'https://lh3.googleusercontent.com/NNsNLWc906d_uomuOXIJyQUbfN461jSF0iuvdo0rha3Opan4RoODdBcGhSF8Vrye1LURK09EMiRdJwzCH76XffR4YKhOSOavLgorZkZnO3hg5MeJ7GDcJCTrQ8RncWNNxQbIMkZDaQ=w1920-h1080',
	'https://lh3.googleusercontent.com/K0qd2MT8_pH3QVzJj5RxRpw1FRVkalnPubidTBt7SMmTI8MFRC_9EuxOezfvbv-GTSZ9pLMHjVsXO7p5GPJnb9ARvgqNCKfPpGAZh6Ckxvy-MR-c9_G9YnBexNYF0PiVU6AepvF7Sw=w1920-h1080',
	'https://lh3.googleusercontent.com/clHm-RzO4F_-x6hE_Hao_KWUL65nHmATG11k-7Ja1zSyheVnzwvcqB-0LLgzjNV0SCwlttMkQTNTApe5rndqDxpiwIUmLV_KjAYQBgkPG1TDTmHQp9oQ3VEBMwubLFZROV_4P3berA=w1920-h1080',
	'https://lh3.googleusercontent.com/O_UEihRW6EjQvvc0c6z0Sph_KeenOyGLvRH1HPQnTK2PRICB-YPoAJRY6D34tp6NZB4S-vaSx-xZvqeBYq17RyL9Lm8M2lA2W7esmZRUzfZFpHVODL0IN7UxasGIqF-80renv08-pQ=w1920-h1080',
	'https://lh3.googleusercontent.com/jif5IDJQIXHy4GAAYUwRZePdnOqPIGLtLhbRlFfiqpeLzgD055GD6S7LKv5JC7e5thUBjA0FxfPo_9j5ou1L1Byko_Edgoju3rHNznPXTQQywyQpgwBXR7S9V2DllFZvt7uLH56aBQ=w1920-h1080',
	'https://lh3.googleusercontent.com/wrtLLE1UrbEcGgWGHazjHwDv46Vx0qhZu0hHr2tnWDmUIRWhMaAHivSCI870z3tqs1HSCFYbVU4YI2hBC545JBQhOE5ZLqVjFIJl4C7tzGXVLamK8gNERGr2yNhZm_S_tCRTkyxvwg=w1920-h1080',
	'https://lh3.googleusercontent.com/gP3eKv2kl1IvmK-htSka8eB23czVmS0f3mblvQJ-XYcvcFY5j4mddu1ij0pxtigW7pUq1yX6oKAEj1zV-m7s1EdMN0PQ-8ee6wR42PPfH2Uk_pq3zLxNYDJw2D2qOmlrmCX8V1My9A=w1920-h1080',
	'https://lh3.googleusercontent.com/GIyfuWrYQe_8G_k7uccwYcPWYFGymtatha2Nd3LJS_Cca4j91j_kGAUt4PXLQXDVt2U5i9MoZRdHO0ks74Ag9IFH1ZtFrdK9PUQAhS0ZiJFtEwchjTnVd-yHE5DQhpyhgiq6th8N0g=w1920-h1080',
	'https://lh3.googleusercontent.com/N48a_IMzTSM3qJ6kyGHk5i9GlPuRNzt99XCI6M1jvvLIC7BC0mQ6FtJOAJ-OMDbtzYK-xKmle0zbsWZtWSwNH8ctlu0Gh2snGW9IvSV-Nl_5gkIGQDb8voKfOtumXiVTjtmNuwT9jg=w1920-h1080',
	'https://lh3.googleusercontent.com/pQ7EJzD3mPHi_Iq_sVZbmlm0VuJO1ohesf0_Jb3yh47nPVQ5IEZ0C1ym6g8I9wQ9yBwmkY0vjG3k0FKo4QhA4PlwlTDyAaQdaEBca7rSx6hWKhuwbG5vyFW6ZU-uqxz-4nBPS0-GRA=w1920-h1080',
	'https://lh3.googleusercontent.com/7bCgm0Xd2nuXOcEQhnaKP77TxeBn7kwZQygXtJB3mzTcwA_u0j6IUr6-YdCIknU-WJuz5PPoJ-TWieqxBux8ocG9cOdgU2wzE1bMyy6sL6IN5NVyFWfjUVRlgzANWtA7uUynsNJN6w=w1920-h1080',
	'https://lh3.googleusercontent.com/UuLLIL7Ce7AfyuOif6UoNQL0RQwcVNFGsflqlIuHyDVPCiTRJKhjT1rAI_OyEm-lG2ctvo0tseUsgEgqzOhd8CAlTjcmhk6sehZYB06HqkXxrZvp7KxYvDY_eXXXznm5u1_2WlhM4A=w1920-h1080',
	'https://lh3.googleusercontent.com/MsV3ddnH75EKnpXD0plNHP1RBjijAtbfuMb6FVyg465TQSN1kpe7HkQMkZ9Rp6VI-hElNR7-naxsTY6Lq956IPSpAhxENdLl7hxJdp6G_AUZT69YWNuXe1-MJ0_fBew4DQ7RgSbawg=w1920-h1080',
	'https://lh3.googleusercontent.com/lZ21kUynKdV3NB5FqgUCss7VnDgm5wT1idc_wub1vIVeR4d0dI8Pnjg-iQeym0ST_XrJY_xpdZjxWSEQsCUdoxxxS-GouiAjZTOJ_oE_ADHCJoFDepcdZ0IxLriSplwKEnWjGOgtiQ=w1920-h1080',
	'https://lh3.googleusercontent.com/YX99PtrSQlCbameFOhBNr69pInR-cU29DUvkwmfx-rwi9E6qubZXq6jvrO5q4pkd3oWYUtWGMRf2m9IPCMP6Sy0v1-1r7Bmc7haysVm5BfhU2K2GHaMU-JvkUjLiQ_mbVtEjU2qKAA=w1920-h1080',
	'https://lh3.googleusercontent.com/Gv3kA_sN98dE_8NHjPTXHNCPMKQgymBLvyHmYIVkxbBTHxCi6YbORQL_RvV7B0PIzxFWExvreJNO-dIvuJdcjVlCECXuu69eJC_KYYPic7V5-jdgo8HuOo2Ro1L86LJYLf_NNNBIHg=w1920-h1080',
	'https://lh3.googleusercontent.com/tseeAHqr2G-WCOm36VDCFZrOgpJrwK2XoWSsMIQBqHLKQAfIlFu4OqfaWzqT_Ig24rP_c9VHkGA0Lzm0zq0qDrtKtRKuBLspwOvsk0LJjkcSGlEjwpxEN2FpEeZYavIPc7WzsTVc0A=w1920-h1080',
	'https://lh3.googleusercontent.com/40qCEFbMyEeSqEHGInlcFe9OFcbAyojh_bFpZ7Uikv_QnJj2YTm5a9WJ88sBlrQgR18E4XQIwSDEg_BlfT0vdwT8-ljuovwWKMokPnZ48qT9tvQul5mnVfPeyxBBQwTbQXlq58k9og=w1920-h1080',
	'https://lh3.googleusercontent.com/LnVjv_Am7K5Ak4BH-C0AMKUkj-an2G3zFuDyNjFwkTUuy0SYtzlVCu9Rrn8vwSmXZx5J8H7Wssaj-Gk5llnB7OvE0sHsyU3kCSegxVkLzGUnQ4oWl_OI83MLVLyy_rq2MBDGNWjGsA=w1920-h1080',
	'https://lh3.googleusercontent.com/wPX3dVQMgpnmD1jvCn0NQPWJ-Bp9YLfWYn1G9L2QStLDCMlnW44SOte78wXE8UfiJ88j8kwSpVK16mjJUZ76x5zW3RKfj2K6WuzZo1-md9JOFmZs-GOFkY877y64oUNIAsqOufnnXQ=w1920-h1080',
	'https://lh3.googleusercontent.com/71Rmj5xn9FzXdIZxFlB74NDaLvdqTLDoI5pyUdaug_PZQESA1rvUgMGGNJokQrtOKMMKSNUiQEupz91K2226nhCDSlyi1cm0IL5N3XZnNHP5jSYa0kMTZtf6bIuGnA2o75ITBCP6ng=w1920-h1080',
	'https://lh3.googleusercontent.com/D6aN1HYUTdeNMyLoVWiqiubbybJq_Da9xhFdijGRnF4hezd2IJomEvIJloFlyBTC0lbX4yJTAdNX03l2Ec2MJMEzrzUITRB9mW__QKA8TMJG_YKemw6NNn7DGUd1ifJhL-ezAuGn6w=w1920-h1080',
	'https://lh3.googleusercontent.com/MWivfxeINaJhrBliAvU7n5tT4rl_Txd5SE5bZ8DYGMYu7HoSbeGtSyNpDSNH8g-hH-M1mWv3UX6nzDnfoGKiu8fFvf1yy6WkT4o0Vm_pYHoS1qbAB6UGyM9PrqCAMYL3itABQ3xwuQ=w1920-h1080',
	'https://lh3.googleusercontent.com/Pf-NFNHrq4oRfBSm377g0M5cGs79EDLiHnWB-aNxz_xE7Y5wBG5jyAR3hDO-Yyj1xD_9b-MBdbnrKoWk29H7qNMJD44GCTWvDcoWHCDJhnvlmAmEaBvgcBXj2xL-A7ELXGZlZi9axg=w1920-h1080',
	'https://lh3.googleusercontent.com/gUxQVN2ZteQl9ot9kicRxGCvUY-1qXDl0y59uIHMnnMfx2Woq2DkHbk5fN13c8OSeSsMX6h0vahKMJh4siieBRdpZNe0QVBmGR3ddpR-cAvhCJGWSiQhYRHkf8UM2Qz72oC2VBShJg=w1920-h1080',
	'https://lh3.googleusercontent.com/der_bTcZbbI9LaEgeElV6k4hLlgB07UYJbf7W6bdXREtjsAZrG5LmX_41mJoiQHg2cRuzsy__Ry2yjyFcW1W8keDmS45ehPQhRB_WEKuXvz9bbmjmO0P6gOlj_MML6uDRH-H7ARzKA=w1920-h1080',
	'https://lh3.googleusercontent.com/gWiNo22QRJKH4is_phVVhCy_hcRSEYlx91kFvUTiPsamQVGCiOdCm8obijmcU4-CQrQFNjexc0xK4tHffR87DvNN6_OuPKY4n2ZGsHnd7nD_aUVx8BvrSztZ27bqJBEUHIQvMXZuEQ=w1920-h1080',
	'https://lh3.googleusercontent.com/m3r2GZOGjFybAsUoxDuX1WMRn0WA2eCQYGwvKmVGObVfj0weSgPOpeVxvLxJG-bogR5MgXLweWWSt70JLZkVOc4zXBb_xmwYsVRTx6LG2dXr61mtjIOXuJyeRRQtBxGFFsy1FES2Yg=w1920-h1080',
	'https://lh3.googleusercontent.com/Dv-GulF1jNMNaCC-X9GsjbRPZna3kePCNsl8m8BdpPc0XVm2UXL53CEJhnRBsBZmliE9VFGl7KEg1lRUojpxzZXE9L1PSNdMs2iw--5RXuNpD950HtP87eZVOjznZry8utRjIvARhA=w1920-h1080',
	'https://lh3.googleusercontent.com/cxMe0fOqcuAMRhM6XnFdnGlEHkXmCiSGmI-sERBbpcX2eX_0SsfFXkz0egOhEEcta_ZDcmNfWkQ-iSDnbO_mrnhmWxDM8Jc3O3MrIVQHcPSzHIIFPVq5HHKMIH38oFTUc8wxOfgHDQ=w1920-h1080',
	'https://lh3.googleusercontent.com/RED4ZHpigKlPKZSM8rJZsHcZSIpjT138WqgcDdltun-mG3rOqC4GANp4FuLxpr4emcDkkCJUxFLghMPcch345QCWXchTxyQkSnh2K6zfEy1Ot9P6mp4InndrpozQAUoJzuaRVDiTDw=w1920-h1080',
	'https://lh3.googleusercontent.com/vcXRONot29FDWS_nK5fwXGPAwJh0jz-XJqqyY5LgpQtHCJewGQQB5dffM6oAxSbGGZ56kk64pzBtBMkO-mSkTQ82FPuEHkUgR94rnqC5yWh9DYbkaW1vl4JTh0c3Dj_OlAuUkbXs7w=w1920-h1080',
	'https://lh3.googleusercontent.com/nfHWvuKNhxkbnJBaFM0c5f5Lsrcpb3Ro1Z4T-KcW-utrJIyNmVhEgVBvvOU_bGVn9sXpHY7KFgsBJP_uwwESGxAAUFZsfuRnIYsOmbgz_IqjEIM3s8SAs4EYHDvlMRjiXfLFrLhF4Q=w1920-h1080',
	'https://lh3.googleusercontent.com/kSuVxSWwzzTYypnlYoKK_CcQWRKBZbGIPlg5VlFM05wAOetGn3b5abGX8lBqyx4YPzdCxsgglm6MZm-Hu7vTpz4-b5X9bPyU008lvYnNlIFoxPdaM6tTzc7pqWpfZfnHVXQCPA_Zbw=w1920-h1080',
	'https://lh3.googleusercontent.com/UxbbWteD_f4nJgpc4mHz5tK8o9dRgvNzJ0gYGOY4rdlds1o2pPB_XUx5gl1da-u-GA-E_j65DY6H5mpzHL2vtWjU_lTqNAZ7oHNvs4lcqzLVdUi225PnGK8S-rt1jDIMWD_cIPOlUg=w1920-h1080',
	'https://lh3.googleusercontent.com/VBlBxm8KJnRYUAlnqsZNWVeY0BRg7BXKaZH_lhLO3A4KYJsaAJocDEoPZcqPqHPxyEIqLnNV1cJq2KjjnNTSFlj-hnwSxBJeRcIR0obd1cKGW0GzjlrXAUZYv7YWDgZp97cPSVfW3Q=w1920-h1080',
	'https://lh3.googleusercontent.com/xPoonDQqJhgIwkXg-FjlJ52kJc3ktqrAtAgtxfH_12BBFjy2xJxGuM4S_1nlkvKLAlTYjC8qDyQ0ZHo6ThIVlZ62YoEv6TSMpFiJCrivPtmQ9hJ-JHISVLnMHYgS-3jaMd9nI3y-oA=w1920-h1080',
	'https://lh3.googleusercontent.com/CXXGqRBoWej4-gPjAEPpxJstImMr-nvBcCsmnJX3mX38mo0td9_2eaYqC0hgHQ6xScFGqeRhQFrv9dJ_3z4yQS-KIsK4CFWbCoJrFrvtrrGYsfwPZnHLXx0vb3sMjBI_Uh96ujhuRw=w1920-h1080',
	'https://lh3.googleusercontent.com/jun7GXmHIUQkNtXHXvjGDvD1KW3NUYBOexXoR6xmbH41z_fULVLFYfvGtbegSeddgM2D3ZohXg4nFOzV-MtsvQeK7FB4o9vKcWd0deHsif3Gsck0UqKdLhHpCaWOqQhsVfYXuzp92g=w1920-h1080',
	'https://lh3.googleusercontent.com/vohIrwFvdlQ1y6NGFRtXnjjo0FaxP8LTY5uaVcNkdVx894xUXTu7VHwqiGfsg9sKJF3AKKwc1797_a-RncJW4P63lApcbrtFFwHizO0ad5hHSE77ri_YibuhtPSJXOD6L-LabnbMDA=w1920-h1080',
	'https://lh3.googleusercontent.com/gxua1WNHoafeyQG7x1tpotWjgWc4Z1st5cUvtTyH65NYhlbIU4DzAxQRRYvxZWixGVk-pTqISkG2WY2W1QhFz8-LCiSjx5gVsISArUkCktAE0te8ABpPBbQPVBy_izFfHZmzy5_8xw=w1920-h1080',
	'https://lh3.googleusercontent.com/0YCGnmZKpK6EI0RTuguPHyZyP__Qs02DEFaJH4XiKp4lNnxjxRxK7Zv3dsimG07WFeYzNql_AWteMkV29E5KnXSFtwmKgrKqk-VBbGBac-QL-zVm9d0w2b3YRwuZkA5cJRGKQFXwkQ=w1920-h1080',
	'https://lh3.googleusercontent.com/8-6bc8qw-kC0J-snLDnjIbrbGDgtVJJsrcDQ38Ug8a0sN6jB-g65P7M8wOeB7SdyoUKOIut30Q0lcsM8vnfQKgIl_UNk3-ogwx0JYvuOJNrEjkWyAxUsY55tpvlm0bLS_uXRAxz-FQ=w1920-h1080',
	'https://lh3.googleusercontent.com/rDms-RvRQxZfwAB2mQkSV4CymfzyKWJViXQorLsXYZ4Kgk0IuTCA1jifurSI6XyE4mZnR9oAmvJ9NcEwcr10geKNbA9sLDrUQ8tVQaOgsNGLtpqBJVCwBGww2UpS2E6HcmGLIWt0GQ=w1920-h1080',
	'https://lh3.googleusercontent.com/1rY_-jNi4sozp2pReEPmC3lSbRnjthAX0B5migq2GxSrxxTm1tQg5s90q308ohJjjpRQ77fwigmCsAjjv2O6j6ZoZsmh55-cz-49erWj313Xoc-Fs7nnGMdUdt5nqXM39JmvJlYvhA=w1920-h1080',
	'https://lh3.googleusercontent.com/DbGEJu8dpL47DSxcOZX2z3OO0SgmIGBnuiywX4WX5SzptolHD_meV6dfgcv0zJkgGnHW5ZbYfoSviNLxmgyEJSSiAseBETCk3W6wU4bqRWybY-nbLVn0n62N_8i287XALPS1ZOxbaQ=w1920-h1080',
	'https://lh3.googleusercontent.com/vV9cETjd1faSJB_WyjC3amIxcf91_Kgu2UuditQSSkgaSAFoSIP7ulIViu1TkQ5AXYnXYY-yy-uLDmJcInYNFbPX_oF5YYpSnBJtplNaEUblvy-qLKlhurXNDufF4kKjeInoyTbGHA=w1920-h1080',
	'https://lh3.googleusercontent.com/ehGL7TDpFwifDKNa2xBGBhe1Z7gL1HPHTiYjh4CczdNf-3AlxQqT2fWiRarg3v8F4O7sX7RgSA2jyGTbIpF8ES8RwzhsUgv8w8RU7JDQKVU5PPckjPuC2L0K5-_Z5jjfD5Q0IiGfJA=w1920-h1080',
	'https://lh3.googleusercontent.com/PzR1HMpzr9hOgVbtjM9DTW62cZnfuCs7xl29msTKZBMDcr2OmBJ-EIOd9UAp6Tlm9zPl5XJoI5q_ERIx5SFrdyJFDwH3kSPVjQr592Sv2E9EPgRfDpNNx0BvmhQpVdJOadCcjI4PKQ=w1920-h1080',
	'https://lh3.googleusercontent.com/jYH4czuAa-rZWcKJQx711SHlImf7cEOprBtS_Bfg9zeQzvj44j9QNAoA3nQgT1QB-PebtQtrJuutzQ1vM2KQ1SOIbGvZoklGQ3cySyvC7PZ82hc3yn8if1K_4adBuwhtYF2KxD9lIg=w1920-h1080',
	'https://lh3.googleusercontent.com/fbuJ7p2jw20qeKH5Yk0ihdt5QnPhHQaRlhptUsUqRwHsf7fEhEJjuCkXxDQiBwz9tPKQ7jtd6mXNsIz4qflOxVgopxVkieUhm5KGtOGitkt3G5nlt1bj89RHNhS5ZHcbDGMjlWby-w=w1920-h1080',
	'https://lh3.googleusercontent.com/BjsKOLXPWqEwjP3qk3cv4V3caI-a2atw_bSyvWMIGm7TISMflKNTodrZL2COBILS20J7QdM_ER7p3uWMAibbW9jIPu3RoOb_3ko2ye3plDp3D0RKSI4zMRwP6CFraeA2pl-_sPpjug=w1920-h1080',
	'https://lh3.googleusercontent.com/w-MBPy76NvulCYubtkAKrnkbOdE9vF64a244ZAl-SP31uxAn8t0014xjdonaKqZqR0eBCK-9QFbeZYg-OtmiXwlpWAVU37H8CLdbrDNT4bWv5RSc7JFNHulbvLC-2KIJtM7AsNOQHA=w1920-h1080',
	'https://lh3.googleusercontent.com/MZvik46dUSD6yM93x3qUU-xYXMtSoYc6H5wEQ6wq9LHtAGdHmL9s1nYKnRyCPzTTvo2gncVi6Rc4-1Iu33qsKrUR-sl2fV9GM0eSPsBLFqoo0kA9KktJt5sPJ7RWBo7aAKRNaq5XWg=w1920-h1080',
	'https://lh3.googleusercontent.com/DO6jD8VP2GJP2y8JMQWZN2avZBlWHu17tfrNwnFX8GrpDtMvpzCP7z9RU5hRgjmOT0JDoQXydl5opwsPqETRBJPkWZZRXh-5rePv2uebw86LF_ZapWYrlWfvBBPeXCKprkaXy_7jsQ=w1920-h1080',
	'https://lh3.googleusercontent.com/Qxd9gec3tGruNO0iI4tBhQlJs0XgnSfNE3GC1ZQaFwCj7Pr720bcJgd6BOYEftMQq-ZonCsyUmuEKTcltEYJWtOMIjpJ1iC7AbJMfIE71-4qRgdn8s2Y4GLEvwcCATWVor44AZZbrg=w1920-h1080',
	'https://lh3.googleusercontent.com/qotjll-5PmyOAPFaM647XYMwdERZ2tzrmYwvYsiYT8sgUjGo1FrmZrLHHPceVwaR5abivWN95phOwVer3NWbMyVmeFxklRzPMnfLkhTUz90Loq3FSuksJll4CdgwAy_GYsloCEsYRg=w1920-h1080',
	'https://lh3.googleusercontent.com/pNW_00L7Zi7H_xI6LQ406tGw-yn5jS2x2FfXwJEJYMyG4AZTny11srhwf712SMIuX-Pkind5BHnO6DhZiNjsGxh4A_jNdWsIZqeDdF1_oks5T5_xxmdufOL4JZYNpFWVj-MDfQEhzw=w1920-h1080',
	'https://lh3.googleusercontent.com/ZFGjPX7j16l8MZZogBK6QzlukHZdnc-ludv2pd8DJbBczBoJVswdIGYUvMHGLAhaYswju3oikvVr_g6hq4Y8j5pOU_wdCfkaC5Lc4IANOnpvBKrzS56zcTybD6K_FMELwwaiG5sy1g=w1920-h1080',
	'https://lh3.googleusercontent.com/-8HxylgUWqkmb5jItjvc_JfxFRLK6autL7hKLFVtTQgbGREGNVfplprHgix4Ny4x6-BoMtkdLcblp7TM1h8LOOnZdzXidWRdYL3LzIE3a7Ws7Q-80XgQO02oSIe1_B8NZWeBIIbhuQ=w1920-h1080',
	'https://lh3.googleusercontent.com/HLJHgK1eTfV-HaHQQNs82CxEstXjeXUgNcNGoMBoWvvxjFStTvC7mbL-BCDlaQkkT97JDjAnUdCDAowQMeynl3RzWswHMgWpMRNK4IfHrZR1WGoZVF_-x78XOT3SL21XW5JSExhErQ=w1920-h1080',
	'https://lh3.googleusercontent.com/L5JsAUYX0Dh9LhPRnIgze85UEnfCFY2Y9EyY8OiQOBCdlYChO1hhUBpF5fJ3pwfJyLXs2GFfUnn6509GUJF_tucSqyp8m_S0rIsXdxpb7T3L8Iewb6PiXcldD72A52qZbPWBtZk12w=w1920-h1080',
	'https://lh3.googleusercontent.com/eDuakbjws5GVldTD26RP9CYpTvd6tTSJSfrGDLCWSrMVYl9HCtjQw1OX2_pWREkM67bRff28O0tqn-oefHoowpYIHYIQUf6COhD6qhEoFrVLzNj2gr1SkDzVOWVPSeqG3YQdmbZywg=w1920-h1080',
	'https://lh3.googleusercontent.com/EzZjv8EJpbG68mOy6EEm7BEVoYRlz_3ky_Wzwrfc55npfdbzn432azRjzWGWYZFEsfO6JN7oHqBuOpUnMhAwK21A1_cs57J0zsVZV3H2H6qKBKA-e7b2H6lDKueU8OeLjH0vE0AGuQ=w1920-h1080',
	'https://lh3.googleusercontent.com/TVUfmkkwJ9M44w6ApNtUNEtPqqdf_4njf_mW0_udM5arSLv6gsKXyDXcXgbsoWtQUTj_Jvy9Q6G0mXq1kBMDHIBO3KR9aa9FeqSZjjIfqgnDUnm6K9Di5Y-0E2qEXwEbyQ9k6yAHnA=w1920-h1080',
	'https://lh3.googleusercontent.com/WsqcfnOXgPU-f9v4QgYs9vzmOO3ZfsTreLHXmtae1LnDSUk303hAc8pY2yaxUGSSuliMh97GQtOc3sKCk_so57AX6Vm3a1xFxAi6x3k4cG5SvipAIJMWucsiYoAzyP7OYxNtxXuYqQ=w1920-h1080',
	'https://lh3.googleusercontent.com/vgkN8Dmpu4IrCoSmgAa8BLt8JeqeBlO1aFf-PKTwhNTkQAYtSIxYwOZtIxgRoX71AfcuvJOuu0SEbmwBnyNLXBYIg-J2VCYQbugghENkTk_ix2h6AVNMPtxy4NdX4iPCE87GDX7MbA=w1920-h1080',
	'https://lh3.googleusercontent.com/lnjjxs8lsw5ibrPF3tjOCCy27H9ES_ikG_mloBHceS58BRNlWcvalwiiSxEyPnbXclgTwNyiIuhP8qfiQAbP3BOsikztauD90wp2qqIsPoz5agCBoF-bMcNBt986uFCkVkvzUQlVcg=w1920-h1080',
	'https://lh3.googleusercontent.com/xqfoIblierVJK0xAr85MXd0fHqWcd5IZaV1gbzB-9Wn97_2gJZ1NwkPLtjt3ML3npFE3PcZlCMxOu9QOJrVj56fMmWs7SFNPagqVhfA_NOWQhP31Uq7bSZ5HedxJYRLXUy28YBye9g=w1920-h1080',
	'https://lh3.googleusercontent.com/nuMww8V7rXNLfD-u3MSl5TdjsQXlVu2IbAnc35QW_3iECdR9zha4rydnD3kStvAUtxyt_Y3SSkK81DlJbwHKnDLfUQ8IU9QNnTI9_ylynKfxz3RwfKLy3OrdeM7yooXDxHp1TxRBjA=w1920-h1080',
	'https://lh3.googleusercontent.com/fzTUgBBiNAGUnB7eRqfMangAJLv-WY2B8g1wkdgK7wmDXjULPc9VFgk8r2_gpuTRXDme7Z0z-Chjitiy8UnByosyKMhT43lt6O8aS8Jl7m3o9gb-0kGmCzT5i_tnYd3t-tWMAoYShA=w1920-h1080',
	'https://lh3.googleusercontent.com/A2ElzMd34111F1GCBemkh5l0IMpeWg9K7-dqiZ1CbRBXCSMrqRVVrJuTRu1FZHWpIl8C6ho0Mv_0oX_028BRohX1EuiCaNWQhaJaxM66-AM7slwKD1Bxc2qKmXp4sEegke6XexDI5Q=w1920-h1080',
	'https://lh3.googleusercontent.com/oh8_cmOT8oMr-41d7BayCgJ4Qv93Cy2DG1QcjLdzpWtqH36KWsmJpNwrw3jM45SniWfH2n8ieibwacSOi8yxKaVnno0uonbZCneRnyA2wik4ysBe79HEvNug9e5_3xjC1B7V7XlFlw=w1920-h1080',
	'https://lh3.googleusercontent.com/wZoyeQXHAKf0s8hwABumEcOjbKhxyGSESTvgF7ZobPvHhaBhHVZ4dkDD_Raidz3hti2tXBW-o_fB1KcA60AoUjD-HIL6uJvWneK8NSa5EzlFvkBPsqMq9MMxykBO3HvgJ1qvyRpKHw=w1920-h1080',
	'https://lh3.googleusercontent.com/40jgB761xryXzYLAyZaajEJ_J72LFug8DLbXh_aqe5C6txQNpOd4SkfrRmZMkCprLLGm8Of7z5pGVlml-A4cGlyyNal_vyb6Fl1YM6Q2jHzU2WsLGGim_rpgSx50M-kxs48J1BhsPA=w1920-h1080',
	'https://lh3.googleusercontent.com/sNMxycK50NFrTd3ipwHSumIqCxAuA9-1Fnb2GcEtyDIZukwXaUMWkL0I-jLqhJEPO0Np1t6F_O57GWXKvsJeruYSFom-3tcEINEoPpK3TzZu-Iu_Uxn-7S4vKyWUwLhkxJWwS6tqHQ=w1920-h1080',
	'https://lh3.googleusercontent.com/3PLJy7hEW4toCgoDGQIowU6w7FTHy2FqHbjfvyXxn3z39CIWjflnC_9bBR_gVvqVXY7z2Xtm-lRcfdFBz1f_eVjQyz1FN2lZedubnOrTdfhefW1FqocGayh1ByS0mrAIKz60vPALNg=w1920-h1080',
	'https://lh3.googleusercontent.com/PmvoE6JCvlgcRWZIcxnzjuO2EhB2HUhujBRUbjg8dHkkObyg6CkYYG5m8tsJX0-UDPMWeFFZ0ZeRI_RO_gTH1ThShRVp-czyG-mg9zwgLoqr_fnSF30zKvOwm7e-2XFJ_c1vMnZrSw=w1920-h1080',
	'https://lh3.googleusercontent.com/_PFQpcENJwg9BNNkNWbYVYY0e0GgScO-zfJl_-MfF_DHvIipR8ZY-AkrOi_pTNDgfir6OtpHKto8EttooLVm-6f9Rm0EWVWHm4SJuy9HWLLpY8b7GCCcg4lvglYuDT4Nvg0qt6khgA=w1920-h1080',
	'https://lh3.googleusercontent.com/eb4vxzw2yZ_iWKqx5hzsTqtQWJZT11rf0iZR7jYIyjAYektXN1EhZK0tLTK4oku6KiFcM0Y1qN1PAA5bmC3hJhfmmYNUo0Q5V1jj2bZqscAatVpmxJTV1U_k5Zv3ShM1UBq-BMKruA=w1920-h1080',
]

export default function EleventhGraduationShowcaseOfICATDesignMediaCollegeChennai() {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = "https://cdn.jsdelivr.net/npm/publicalbum@latest/embed-ui.min.js";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		}
	}, []);

	return (
		<div className='content' style={{ paddingTop: '74px' }}>
			<div className='container-fluid sectin float-none'>
				<div className='news-lt'>
					<div className="pa-gallery-player-widget wdth-100p mb-3 hght-480" data-link="https://photos.app.goo.gl/epPQyAEmJzzirzUy5" data-title="11th graduation showcase of ICAT Design & Media College" data-description="44 new photos added to shared album">
						{EMList.map((iMg, index) => {
							return (
								<object key={index} data={iMg} aria-label="11th graduation showcase of ICAT Design & Media College"></object>
							)
						})}
					</div>
					<div className="videoWrapper mb-3">
						<iframe title='11th graduation showcase of ICAT Design & Media College' width="560" height="315" src="https://www.youtube.com/embed/B9X3SnHf6rQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
					</div>
					<h2 className="h4 mb-3">11th graduation showcase of ICAT Design & Media College</h2>
					<p className='mb-2'>The graduation projects showcase represent the most important production of the academic life of the students in their final instance. The great day of celebration of the 11th graduation showcase of ICAT Design & Media College, took place on 5th April 2019, at the Centre Atrium of Express Avenue Mall Royapettah, Chennai, in the presence of many Industry Professionals, Academic professionals, students from different colleges, parents, teaching faculties, friends and special guests. The event was inaugurated by Padmashri Dr. Mylswami Annadurai (Chandraayan Fame), now the Vice President - Tamil Nadu State Council for Science and Technology at 10.00 am, honoured by Mr. Kumar - Founder & Managing Director of ICAT & IMAGE Groups along with Mr. Madhan - Operations Director of IMAGE Groups, Mr. V. Anand Technical Director of IMAGE Groups, Mrs. Lakshmi Kumar Dean of ICAT Design & Media College.</p>
					<p className='mb-2'>The 2019 11th graduation showcase of the students of ICAT reevaluate the space of critical encounter of Art, Design, and Media all through the Nation. In the case of graduates of ICAT Design & Media College, showcasing over 200+ students project under 12 different departments - Animation, Visual Effects, Photography, Advertising, Gaming, Interactive Media, Visual Media, Multimedia, Fashion Design and Interior design; these projects that had the possibility of taking new shapes in the future, through proposals that reconciles two aspects: artistic creation with academic research. The showcase also comprised of 156 Fashion Collections from the students of the Fashion Design department.  The performance of each individual departments was of a great diversity which relates in comparison to the traditional and modern art styles.</p>
					<p className='mb-2'>Students, educators, and parents have been involved in every stage of preparation. The performance and talent of each individual students impressed the public and the Chief Guest. The showcase event was witnessed by over 100 employers in the industry to see what the young minds are capable of and find their suitable human resource. A show full of colors and breathtaking presentations was the result of a team of educators and the management team who know how to offer a quality educational service to each Student. A team that cares about every family and every Student whose development is a priority and unique. The showcase event was an eye-opener for the public, who joined to see the creative works of various genres and to understand the creative career pipeline in a better way.</p>
					<p className='mb-2'>The creative, artistic part was came to an end in a fashionable way ICAT students Fashion Show ramp witnessed by the special guest Ms. Prarthana Venkatesh - South India Queen 2018 and Miss Telangana 2018 along with the management team. She emphasized the importance of the transition from graduating college students finding their path towards work, the participation of families and their involvement in the showcase of their wards project was great support. She also delivered a message of gratitude, encouragement, and thanks to the whole organization, highlighting the quality of the work done by the students transforming into professionals and finally yet again congratulates to all the graduates. </p>
					<p className='mb-2'>At the end of the show, parents had the pleasure of taking pictures with their wards representing the joy of celebrating with the family. Congratulations to the parents for all the support that fosters student achievement and success, and to the teaching team for its dedication and professionalism.</p>
					<p>Customized awards which were weaved from silk cloth were distributed for the Best Department Display and Best Project. The ceremony ended with the overall best stall display award and best project award for the one student from each department and memories for the Graduation students. It was a unique day of its kind. To the graduates, we will say our best wishes for more success in the future and to all those who made this celebration as pleasant as possible, our heartfelt thanks!</p>
					<div className='news-arrows'>
						<Link to='/9th-graduate-showcase-2019-hyderabad' className='prev'>New Post</Link>
						<Link to='/won-most-inclusive-educational-programme-gold-award' className='next float-end'>Older Post</Link>
					</div>
				</div>
				<div className='news-rt'>
					<ul>
						<NewsList />
					</ul>
				</div>
			</div>
		</div>
	)
}