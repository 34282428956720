import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Imagegroup from './../Images/image-group.svg';

const menuItems = [
    {
        name: "Creative Solutions",
        subMenus: [
            {
                name: "Image Creative Education",
                to: "/image-creative-education",
            },
            {
                name: "ICAT Design & Media College",
                to: "/icat",
            },
            {
                name: "Imageminds",
                to: "/imageminds",
            },
        ]
    },
    {
        name: "About Us",
        subMenus: [
            {
                name: "About The Founder & CEO",
                to: "/about-the-Founder-CEO",
            },
            {
                name: "Milestones",
                to: "/milestones",
            },
        ]
    },
    {
        name: "News and Events",
        to: "/news-events",
    },
    {
        name: "Contact Us",
        to: "/contact",
    },
]

export default function Header() {
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        window.addEventListener("resize", function (event) {
            setWidth(window.innerWidth);
        });
    });
    const sidebarTgl = () => {
        document.getElementById("sidebar").classList.toggle("active");
        document.getElementById("menu-btn").classList.toggle("active");
        document.body.classList.toggle("fix");
    }
    const scrollToTop2 = () => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }, 100);
    };
    return (
        <div id="header" className="header">
            <header>
                <div className="container-fluid float-none">
                    <div className="logo float-start"><Link to="/" onClick={scrollToTop2}><img src={Imagegroup} alt="IMAGE Group" title="IMAGE Group" /></Link></div>
                    <nav className="navi float-end">
                        <ul className='float-none'>
                            {menuItems.map((item, index) => {
                                if (item.subMenus) {
                                    return (
                                        <li key={index} >
                                            <div className="nav-item">{item.name}</div>
                                            <div className="submenu">
                                                <ul>
                                                    {item.subMenus.map((stem, index) => {
                                                        return (
                                                            <li key={index}><Link to={stem.to} onClick={scrollToTop2}>{stem.name}</Link></li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </li>
                                    )
                                }
                                return (
                                    <li key={index}>
                                        <Link to={item.to} className='nav-item' onClick={scrollToTop2}>{item.name}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
                    {width <= 1080 && (
                        <div id='menu-btn' className="menu float-end">
                            <div className='menu-item' onClick={sidebarTgl}>
                                <span></span>
                            </div>
                        </div>
                    )}
                </div>
            </header>
        </div>
    )
}
