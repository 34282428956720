import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import NewsList from "./../../Components/NewsList";

const EMList2 = [
    {
        posterSrc: 'https://imagegroup.in/images/news-events/12th-graduate-showcase-2023-hyderabad/video1.jpg',
        videoSrc: 'https://imagegroup.in/images/news-events/12th-graduate-showcase-2023-hyderabad/video1.mp4',
    },
    {
        posterSrc: 'https://imagegroup.in/images/news-events/12th-graduate-showcase-2023-hyderabad/video2.jpg',
        videoSrc: 'https://imagegroup.in/images/news-events/12th-graduate-showcase-2023-hyderabad/video2.mp4',
    },
]

export default function TwelvethGraduationShowcaseOfICATDesignMediaCollegeHyderabad() {
    const sliderRef = useRef(null);
    const [currentVideoIndex, setCurrentVideoIndex] = useState();

    const handleVideoPlay = (index) => {
        setCurrentVideoIndex(index);
    };

    const handleVideoPause = () => {
        setCurrentVideoIndex(null);
    };

    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };

    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 mb-3'>
                        <Slider ref={sliderRef} className="news-slider" {...settings}>
                            {EMList2.map((item, index) => {
                                return (
                                    <div className="videoWrapper" key={index}>
                                        <video className="videos" controls muted poster={item.posterSrc} onPlay={() => handleVideoPlay(index)} onPause={handleVideoPause}>
                                            <source src={item.videoSrc} type="video/mp4" />
                                        </video>
                                        <div className={`video-play2 playpause ${index} ${currentVideoIndex === index ? "hide" : "show"}`}><span className="play-icon"></span></div>
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                    <h2 className="h4 mb-3">12th Graduation Showcase of ICAT Design & Media College, Hyderabad</h2>
                    <div className='news-arrows'>
                        <Link to='/13th-graduate-showcase-2023-bangalore' className='prev'>New Post</Link>
                        <Link to='/14th-graduate-showcase-2022-chennai' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}