import React from 'react';
import { Link } from 'react-router-dom';

import NewsList from "./../../Components/NewsList";

export default function ComfusionismMiraki2023Chennai() {
    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 news-img mb-4'><img src='https://imagegroup.in/images/news-events/comfusionism-miraki-2023-chennai/1.jpg' alt='Comfusionism - Miraki 2023 - Inter Collegiate Ciltural Fest, Chennai' /></div>
                    <h2 className="h4 mb-3">Comfusionism - Miraki 2023 - Inter Collegiate Ciltural Fest, Chennai</h2>
                    <p className='fs-italic mb-2'>Date: 20, October 2023</p>
                    <div className='news-arrows'>
                        <Link to='/photography-contest-winners-runner-prize-distribution' className='prev'>New Post</Link>
                        <Link to='/rampculture-image-fashion-dept-students-participated-avant-garde-fashion-show-2023' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}
