import React from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import NewsList from "./../../Components/NewsList";

const EMList = [
    'https://imagegroup.in/images/news-events/icreas-2017-chellammal-vidyalaya/1.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-chellammal-vidyalaya/2.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-chellammal-vidyalaya/3.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-chellammal-vidyalaya/4.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-chellammal-vidyalaya/5.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-chellammal-vidyalaya/6.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-chellammal-vidyalaya/7.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-chellammal-vidyalaya/8.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-chellammal-vidyalaya/9.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-chellammal-vidyalaya/10.jpg',
]

export default function ICREAS2017ChellammalVidyalaya() {
    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };

    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 mb-3'>
                        <Slider className="news-slider" {...settings}>
                            {EMList.map((iMG, index) => {
                                return (
                                    <div key={index}><img src={iMG} alt='ICREAS 2017 @ Chellammal Vidyalaya' /></div>
                                )
                            })}
                        </Slider>
                    </div>
                    <h2 className="h4 mb-3">ICREAS 2017 @ Chellammal Vidyalaya</h2>
                    <p className="no-padd">Place: Chellammal Vidyalaya, Trichy</p>
                    <div className='news-arrows'>
                        <Link to='/inter-collegiate-cultural-fest-miraki-2018-chennai' className='prev'>New Post</Link>
                        <Link to='/icreas-2017-the-annual-art-expo' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}
