import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import NewsList from "./../../Components/NewsList";

const EMList = [
    'https://lh3.googleusercontent.com/jMeuC1QLbi2LZP7Efgo3SRQJV1EZZ2y8vuuJIurwWFzwPnMXXzdmHrSEpboY5eY_MI92_UCT73keuWRMK8Ks-0nSmpCWWeV_jXLxtKSI0yPTQ192G8ExcG5GyIeYtm2SQb2XhkLOLA=w1920-h1080',
    'https://lh3.googleusercontent.com/YCmqBUCetL0wXe3Dhlb01b0C6opKAz2dvYrQe_Pbe9VLoPQXe0ITv4huZnRO3AWsZZ2QTBr6jvR4s1K8JtuSgOHKoTYxWIE3g_EE_3UbVZA2_XeVLbz8Mowthu6VlzNutighbrnniQ=w1920-h1080',
    'https://lh3.googleusercontent.com/K-9xKoCGtOKBOtCa91rwJTjhf0ufLZZ3qOmXunbqD47ptd_dsX2pO8pbXsKsh52pnDdTLHrugFSzOVVe53SYHPdyhnC2TrZxptN80PdR96zfuCU9raG64ruu23OisC9tuptKELxPpg=w1920-h1080',
    'https://lh3.googleusercontent.com/WBnCCIzJdnIvNf4P7zu9y0cEvKuNEshvsisJHD9VdAAqJsZKmhQzcKiw0nf_kyWYf4KX2yrYg4-nGkl4uF5YplYnVteuggWhbgKnF2iGJMPzQrojoypogQf7HhfsSKcgkHSSXroROA=w1920-h1080',
    'https://lh3.googleusercontent.com/7fN8iqLGAElfd9v-YsEX2pbBpsBbAJ9eZkmTcQiFrFuBprVHk9T928kyRPPPc-Jx2Hdj-NY01KVPJjN-alIaiaeVmXniG5027c-g_GhIKCVsdsHGxbAnH1YwMTF8nJp9hB-bAmEIrw=w1920-h1080',
    'https://lh3.googleusercontent.com/TGEzuLO8fGB-sRvLtcBFIZOf6nK_Dut6B6nxtk_H8oU6FyFv5sffjzm-32NaLb0b17CmB9qCzj2WkkUDkVciO8ihA6Yx-iyVM01TpVUmNopC1C2GLTQfvRMwdI2xNXNT5FPakUH0DA=w1920-h1080',
    'https://lh3.googleusercontent.com/vTlwi79Fwpn1b5nXDad17j5oaFPIoxeObxzMX4xXi2hMr_eSUAWZeFfVElnpgF3Sd_MAeawKWMRpghDdEPzxYJymxYA9iCkJK3Q72Cb0TBSo1sVPg1XdhTeqcHMMGDm1E1YrBo_9YA=w1920-h1080',
    'https://lh3.googleusercontent.com/UmYdGIXJ7j6IZo0T8KCHfXGYDCXiEKkxtvBIXljn4jboxCE_TQwilF2U3XfcRES7BGiwiyxniuqhf-gOFlStfzN1xmt3JNcvuRcPLq6TJr1FjnAfngv7M9FXMnrze0dhxMrEJMQI3Q=w1920-h1080',
    'https://lh3.googleusercontent.com/YVxVwdUvOFP-OgChfOzxymemEpoFj53U1hxJPT1vITzF1r6BaaYR35R3Sg6vomObG34JbExZngCijHREH9MV6zK6cXf49wx34jEBAXnZoVGgepXfs_EoZs2K5oO16phsiAOjsmiiiQ=w1920-h1080',
    'https://lh3.googleusercontent.com/Pv5Il-H_EOFHxkHEIVCbyzV6dGzgziaAB5yIAfmBIU91x2n4DAFVm-_Ni6EaTaj2O8E9amkO3subjwMQLwfHrZa2kZhX0HFnRLPFM7jvWktynAKDQP-A4M1IiDd92dHhlM60KxCPWA=w1920-h1080',
    'https://lh3.googleusercontent.com/pCROTizVb_WnAKr8NIWQK52dHKiAIiwv1BFBp9nk4XZlevzUB41yEkVT4po0q6A-qsWNtOWKRdzjo0izBSLvRTNXWI9yAG6pnxXFqMjx_vfCYIk_1RAfQl4QpyRvmyBVPBoQQiY1bw=w1920-h1080',
    'https://lh3.googleusercontent.com/R-yK_x1bmZ93uVs5j7TrTBiXrTNxxRvRu3dhj2aP05zwxpkjxC5QfWarRf2AKaAsLjAMzqpP_y6hU8oLydYlE2Aoqyq0OiqGgppPLAH4BAB3h-Yt0_imA9mjNqPP8bQI34WWKv3iuQ=w1920-h1080',
    'https://lh3.googleusercontent.com/3eT7_6r65AIKheCb--deZP1BA5AYM18l-ztCeY-0BWP7jkNE-LiYeN-vNyxupWpvYvy2M7s-jY3B_GglJbq_nq8gshny5EFoWnjpR51fhzd5JPqwxAW1owP0579cCevZx9PUOOJ2Cw=w1920-h1080',
    'https://lh3.googleusercontent.com/xmmhVV0gKAUshghCgtby-Itm9P9N7S87HA4EERn7IfL1a0Z2M77GscGzOEXvdHzc9O5ZShqPv8TtRY6Pn9YrZoWbKhuYTR8CHtzk5gt2N4KzS-FKo1GFg5g5mhxv5l6CIBn9Fq7_Zw=w1920-h1080',
    'https://lh3.googleusercontent.com/1ID-gZHpeseuubU719Tw7Pj4hA2vAvNyPchDxCRmzhcnadKuJvTO9oW462PJR6ERkjM3ShGdIAzblOeWo6ZM91CcJXWVJlv7yz1XtPUnyPtgpt_XCqlFHszrCNvf67QIZws_g0A9Hg=w1920-h1080',
    'https://lh3.googleusercontent.com/Rn2m5S5b2W2qSS8p_kTLVAQg-XHWy4KVHWh_3nnPwFi0s6dBuTXZjAmNtUC6c0jxRLeQ44NYsBDjEwlSjgexXtl48LgSW_0R_PvX1xSaz--gzLL1o1I4qtHJWX1HMBF44ObetJSr1g=w1920-h1080',
    'https://lh3.googleusercontent.com/WKU2fZL6qru4ajm9oPwVv95JnAyY7RsVuVYeMkBrlyzWrzEBGMc8NvaPQDEbNDEf5ZCyqi3_cLtpkgZ5ooAXma4k1DNt6qi4WgFQd59_snyJLCYHYvM4Mfg4KoePdGsCGTWZ4TdPdA=w1920-h1080',
    'https://lh3.googleusercontent.com/Hj77fe8j4gcoorEK4w5wV5Hg9IamLryRQWop8POOB2XN7igXIuo1A8gUlwAL64CC9rKscPX6FGgamDEEdUw_V2zoOhaxt62Sv5IZd8llnNSF4vzzXYvBNqtcVNO6MM5H_EOem8jhUg=w1920-h1080',
    'https://lh3.googleusercontent.com/YS4xUOBireub7FyMMYTS2CCuxOxQMCuKCI4sTJQZfXNvSKpAEV4nsn5bV3lc8WM_12Yp8kOVJwiialAhvPQozwBen-Sco9eI1ihB4Q2LNGlfRmJf1IARGlfEmq_5WAWhZCf9ip-2uQ=w1920-h1080',
    'https://lh3.googleusercontent.com/_z0kVkf3bcM5RTdzyJVGnF9meMmDtFxPXFy-6bdvcQmp0fTdY7ZVF7MqTZUES3mIgikJXCdFVepPnHhOrIqEuHc0XL8vSxBSy8JIwFEo4CdNxZzuWdLrF9BraDXbRRsOFGQbuHMnVQ=w1920-h1080',
    'https://lh3.googleusercontent.com/4jckIT7UacsrW96iPkQ9t6vsnrXC9B-WHQ8c4HNThncq3Puy1X50gY6iObfRjDhxfsBQtCFvY7le-0YIqq-UaStoaa5NvA36NPF626UOh26W1zFwEe3UMRB1PVxIgdoJrLdxbEFvuA=w1920-h1080',
    'https://lh3.googleusercontent.com/2U0zAY1Q7Az07fW3op6h5_I7PvbbAvdDIy6xVh4f03SrKCcz_P9I1Fo-rAg4OTexM1AUH20RghJYT78Q2V_7dk7yEapEdxC8R7ckzGpxYmWeGLy9HpoPNb2dj08ib8LD1nIOzsiQiA=w1920-h1080',
    'https://lh3.googleusercontent.com/Rbt9uyH69U9mgvqOAavGovuxtXouj6LB2yH7slWQKD-X-jaYeGfx664bGDC9dJiHV988W1_oV3YQYaNeV8tWG2pyMXaFFIYQqEZNdW-BzTIL8EVIfcXhgQ39LroBZMIXX5w13zLc0A=w1920-h1080',
    'https://lh3.googleusercontent.com/ODYGzaYrEhNoWich8VT1a6m5B45kThBpK9-9u7iwHrZKLRhomv6PW7_kRd4vbtHCMajB9q-wYXwGIDT-9_ux4p8CkuG_K-a2B8sjHh0MCtDldFhHceLHHd5aYaHBpF-ZJqAw8sjjag=w1920-h1080',
    'https://lh3.googleusercontent.com/Tj5ZjBE8kyHlrWgLN5dJQyQzj8ln571A8bl3Ikjazk3Q3LBOxtV6IB2P_oy6M1tM2bq87thAAXuhBD4Qa0uCbDZ-sVV2JLv5VG3qdDPsJOfHm6uUUYYBkHvcbg128EEsdVRfpqffnA=w1920-h1080',
    'https://lh3.googleusercontent.com/-PwC2ic07otE9rgpcYQ3O4qGhikfUAv-UPM1e6qO8bBe3MAqxSxqOKQ0pU1T30qlxgxJQtYhPQxYp3i8FOLZn6QdamNQ3VuloohVPRqg82mwh88cKjRqztpfq9ZkJUEGPlWIIoy5fA=w1920-h1080',
    'https://lh3.googleusercontent.com/GLu5TMi_WbPPweflMr0w5gmHrYtGMMsers6GJPCnBL3jCNdB7ztooru1GP-UxHkGT6mM0uqVQQUVbYDmPItAGtw4MQyjz2U49fe6iYZfeABzORDQxgG33ibjJ6RQiPS2BQy7T5rSOA=w1920-h1080',
    'https://lh3.googleusercontent.com/Ia620UOqrHZjKUxlU-ipIq2Sg4g58WzEJQOanuq_7muAyjHbp2TXTmb2zrnex4Z29dDOtIFTKENqja1hSxUEH-kyr_tyUOX60kYpP0Ks6jgMh5Wm-_JL-TxJu4HVVq6WThUYbvM0Xw=w1920-h1080',
    'https://lh3.googleusercontent.com/YV9clFWScRYSYLaayuEUtzEGv4YfNsQAx3-4ll7cMmKl1SrzqU-PWPQDw5P2g8binw_S6_lCzFlrj3Z3NMREkyfGA4EtNQNhczJTp6HXKbZjt93p4yX2hFgvpzyMuVziWWHCsnTjPw=w1920-h1080',
    'https://lh3.googleusercontent.com/DbgYWDTxxRV-Gx9pLTTa_EUjFBBYc0Zk391bgXZ0wRJvH5vKDRoyqSAxjb_vHWq2fMjWSPOwkZQRdJdLLb0ROutTH6ivtqIS6Q0iSOhGy0UYKQ82WbSu4Rs238ndLqwag1P4oyRSnw=w1920-h1080',
    'https://lh3.googleusercontent.com/PiW7IK3JVAKX4-eRXiB_MNGf2_s0RvsZHKzFhXOeJJgzD2e_2i5OVv4v90wbfDNldgZM2PgB6JNEb0Hsit7O2Hc3c5IxlgkdWPvwiChAJvO-RyW-t8fhAxLEq_DvEOnvGHlEXACUVQ=w1920-h1080',
    'https://lh3.googleusercontent.com/_m2-TKpcV6ZI_QfYZTQaiqNI9_CohgN-hF39_iKtISrUkM-ZkVV3NPZkUn6m250W0VJ-5fxx3hR1Si_QPG_n7QYllSqxjBAtygAWF5zxhjCzC6VmnN_T7Kh5YHP14c6R-Qxy9NEv-A=w1920-h1080',
    'https://lh3.googleusercontent.com/-p_sMoiaFgwBp5qr3peaa3sCVM0vbGGmIxap2YQ04eWtkD0xem4PoYN2oRG9YnA9gXS82_JpiK7Oq29VBr3ZgUGAxwFZVGIQAL-2losX_ceyPG71bENtLylM1zIE0ujl4I2u-8mE6A=w1920-h1080',
    'https://lh3.googleusercontent.com/1bSBL9-BOwjsTA7Y7dQDCV6bqM8Nuj4r32HRCTyPgONknoCwSoz3tvHYXfzYPMSsjqtPFG7UilHIs1LQr2BZHGDlpf5f5IM3857E0Ah-nctspMGaJuuqMDbM9hbkLmq5Yvn88pE0vQ=w1920-h1080',
    'https://lh3.googleusercontent.com/Q55eU4cmQHSSIbiOYidRf9NgGlyxjEyToZys62Ie-g2Gu9dCKhYk89kxpzcc46rIf3Z3_qt5LKaFHemCtC8Z9QzT7BZdtmsLMUESg7-ry_MwPcMY-zYdh3SnFV7aYNeCVXMo_6LryA=w1920-h1080',
    'https://lh3.googleusercontent.com/K8ULOpq1rKuqUVXJnK1sEm2ljQ9a14ZtMfk-_OV2wihu1Wljm0gFf_SgscvOUmadpRQC0MpcQQfq4K11Y-0rx1Q0KxXWbzHa5PnhgBEFo0qRczI7FK1T6Z8YZ3GiHol0ThiX8pRYeA=w1920-h1080',
    'https://lh3.googleusercontent.com/0Xjx7Kv8FG-Qy7Jzb8pAPAs73162D8pk91ccwaLEz_1xYrtc-AaZ_x0Z4D7CBLw2RJk2hkuOmkn28EDHG4oxdkgdZo038LUhk-wiNpnHuK20yvUWbgtAKxkTyntBMIe0ssjK9K6_pQ=w1920-h1080',
    'https://lh3.googleusercontent.com/RFBr7VNk0mN5TMCzWI6EJS9CKMT2uxg-hUZ0vDDSN32NIXAe9_oR-pKZB7Z_q43wN-8IW4SvS2rW0lOFlNNwRDhu3iS9GLUhQwqZ4KEsXwjstFRf4kKxzqsEeoWQo-AoEtkbfIyhFw=w1920-h1080',
]

const EMList2 = [
    {
        posterSrc: 'https://imagegroup.in/images/news-events/14th-graduate-showcase-2022-chennai/video1.jpg',
        videoSrc: 'https://imagegroup.in/images/news-events/14th-graduate-showcase-2022-chennai/video1.mp4',
    },
    {
        posterSrc: 'https://imagegroup.in/images/news-events/14th-graduate-showcase-2022-chennai/video2.jpg',
        videoSrc: 'https://imagegroup.in/images/news-events/14th-graduate-showcase-2022-chennai/video2.mp4',
    },
]

export default function FourteenthGraduationShowcaseOfICATDesignMediaCollegeChennai() {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://cdn.jsdelivr.net/npm/publicalbum@latest/embed-ui.min.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    const sliderRef = useRef(null);
    const [currentVideoIndex, setCurrentVideoIndex] = useState();

    const handleVideoPlay = (index) => {
        setCurrentVideoIndex(index);
    };

    const handleVideoPause = () => {
        setCurrentVideoIndex(null);
    };

    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };

    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className="pa-gallery-player-widget wdth-100p mb-3 hght-480" data-link="https://photos.app.goo.gl/c3JFceuyQoh2t1ww7" data-title="14th Graduation Showcase of ICAT Design & Media College, Chennai" data-description="44 new photos added to shared album">
                        {EMList.map((iMg, index) => {
                            return (
                                <object key={index} data={iMg} aria-label="14th Graduation Showcase of ICAT Design & Media College, Chennai"></object>
                            )
                        })}
                    </div>
                    <div className='news-cont2 mb-3'>
                        <Slider ref={sliderRef} className="news-slider" {...settings}>
                            {EMList2.map((item, index) => {
                                return (
                                    <div className="videoWrapper" key={index}>
                                        <video className="videos" controls muted poster={item.posterSrc} onPlay={() => handleVideoPlay(index)} onPause={handleVideoPause}>
                                            <source src={item.videoSrc} type="video/mp4" />
                                        </video>
                                        <div className={`video-play2 playpause ${index} ${currentVideoIndex === index ? "hide" : "show"}`}><span className="play-icon"></span></div>
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                    <h2 className="h4 mb-3">14th Graduation Showcase of ICAT Design & Media College, Chennai</h2>
                    <div className='news-arrows'>
                        <Link to='/12th-graduate-showcase-2023-hyderabad' className='prev'>New Post</Link>
                        <Link to='/12th-graduate-showcase-2022-bangalore' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}