import React from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import NewsList from "./../../Components/NewsList";

const EMList = [
    'https://imagegroup.in/images/news-events/seminar-visual-effects-enthiran/1.jpg',
    'https://imagegroup.in/images/news-events/seminar-visual-effects-enthiran/2.jpg',
]

export default function SeminarOnVisualEffectsInEnthiran() {
    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };

    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 mb-3'>
                        <Slider className="news-slider" {...settings}>
                            {EMList.map((iMG, index) => {
                                return (
                                    <div key={index}><img src={iMG} alt='Seminar on Visual Effects in Enthiran' /></div>
                                )
                            })}
                        </Slider>
                    </div>
                    <h2 className="h4 mb-3">Seminar on Visual Effects in Enthiran</h2>
                    <p className='mb-2'>Mr. Srinivas Mohan, VFX supervisor of Endhiran explained the numerous difficulties he and his team had faced to produce the effects. Endhiran had used most of the visual effects technologies in a single movie. Unlike other Indian films it adopted a completely different pipeline to create the extraordinary effects.</p>
                    <p className='mb-2'>He further explained about the new technologies used, namely Laser body scanning which scans a live human character and reproduces a complete digital 3d version of him/her which can be later manipulated by a 3d artist in post-production. The same technology has been used to extract accurate texture information from the target object. He also spoke about dome lighting, an interesting technology which allows you to extract lighting information for the target object, by taking over a thousand photographs from different lighting scenarios, under a single dome light. </p>
                    <p className='mb-2'>Mr. Mohan gave thorough insight on various other new technologies which were used in production, such as, motion control rig which has the ability to record and reproduce the exact same camera motion, so that the artist can create various layers for later compositing. He also gave a brief about the history of visual effects (optical effects) and how it was done in the past where there were no computers. He emphasized on the importance of pre production and suggested that the students should form a sound base of understanding the pre production techniques because it will help them to develop their work better in the production level. The most important advice he gave the students was that they should aim to be a "creator rather than a computer operator". </p>
                    <p>It was very useful for the students that he shared his wisdom that to make a sustainable career in Visual FX , a budding artist needs to know the concepts before he learns the tools. He further explained how this learning methodology proved useful to him for creating his own plugin during the production of Endhiran.</p>
                    <div className='news-arrows'>
                        <Link to='/ug-pg-convocation-2012' className='prev'>New Post</Link>
                        <Link to='/game-grind-2012-chennai' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}