import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import NewsList from "./../../Components/NewsList";

const EMList = [
	'https://lh3.googleusercontent.com/Vf5zAzxIIRJIm5tAAizJHrvtlDeYUosaarCFDp-mAufshbDw1OJmRYpdGLHInvVFaA1yx5iiuMjATqQmBQgEoq-0jDMA7vSnnikHpPRHPcT98-fh3HRfOnvDWWiZKxNaKvwcDLvo_w=w1920-h1080',
	'https://lh3.googleusercontent.com/vAPR34UBAUNd_4NmBgc_GOdByKfPSul4svSGZ1NiUn14wv4nY2Pe5kJMyP71qU6oQExjEEznQSDtWS3xCefsKtiN1Tcn33pFeglHjmj6bjk0gv6rMUjjqFx6Bs-ybD4dj2bcUBrRcw=w1920-h1080',
	'https://lh3.googleusercontent.com/3fHwlq_Rn7gpeAkxUubMv5-v2iA4o-xxtIxzB1yPdGq5rU_xMEocz6ow9yiQZtLHVpUWHGB6Nf-cqay_m7zaLvUmKIaTTnooZ4hBrIq1J_P77Tk5-vRAeYaLHziCETEd6VTpyoF-rA=w1920-h1080',
	'https://lh3.googleusercontent.com/DMEsFkDrMQ66Gfk5Rj32Mt4tTeh1XA3yutykCTdgqfcH32Sip5df13bbc2VyClOyG6DaIgFcbG2DMPTkEzLJwpQRtvKNwZ_AmchjGdGsPCyGh6LOi994XXbA0LDnGpqXaJX0bPo63g=w1920-h1080',
	'https://lh3.googleusercontent.com/h8mGybQVIUdYiXTnaVTuEbtloKAbAaUzeRYzjdREpwhUbLg6NW8lQXF141HzmW32EKUjSFKBDfXpZZcrFg5e5k_y849e85RM_8pVcXbHEjrRpFMnnDJ-Wu1IyXoawBlF8Ov8CiADAQ=w1920-h1080',
	'https://lh3.googleusercontent.com/Af3CsEiYSDddtWxffTOZCbUKNS18vbVJsSBfWhgYb_vqZhLjYcgqQa-b6nKYKvEKrEkGVcUIDd8NFuqKTcR20sW-6tpZXVXK2PVLHJNbz0QnxjLeR8uBshvUSh09QJ8hQ8SaM6XGeg=w1920-h1080',
	'https://lh3.googleusercontent.com/clo9pQFpZEqgw2MyUNMOSseUVZuNOR9AwocE0WmWncDqIC8p55zg-fvWurVaDpZK_n9jrxN-Boe3bCPVq9dwRMekaBRIUyYqGRRQCyZf0cY2PNSdMP4-bbAz69Pf5Qe7W4tQBiJM2Q=w1920-h1080',
	'https://lh3.googleusercontent.com/W_AODH9JYMvcVqEl_yUEFvzyj-eBk7GZT_-8O1lbFwfJakDCzqELlcZoOrCVB22LENVO2cd4SPbSIfl_bVPD7zlQz2y1Dw5FMukz5zsdPm2hkXAgG8TxlsJDANNK3PaRyYBi4wPImA=w1920-h1080',
	'https://lh3.googleusercontent.com/3jsaa7hMsbAXs67xHVd8EtdChwKVh8y1szhJyxGbD7taBo44NR1hXLY-WH2-ngs5gneHWP7-q6Rf41wLkrsudohxL1fyc4YaOT3-YQQ-qm4GLhIlI-A7AM0yfgMgXy-kh1m854X6ow=w1920-h1080',
	'https://lh3.googleusercontent.com/ZgRsiY92CXKd4tqa4aIAgvJdmE4qZxF1R7_Lnw_bo7TVB5-w-n2YgGYXss-iInwB1eXqbUFsiyPxb2e36F67gWfx0lwdfYjBUAf-tzlR-6nGYwhd9zmEayggLg4ACFce4kB8kT4ViQ=w1920-h1080',
	'https://lh3.googleusercontent.com/DwEK2eO7cNAHOwRxkeCxAyvQIbL7mmL77Sd6jqqfWthSxOp7ge3p8XYF9XaDjUDUpgyKiSlYY2hw8MfEEJwUdKp90Ey9ST9MNrOxm0_QPDlxSG-fdT75Gf4aqkCMNKOB4iF9lmrtmQ=w1920-h1080',
	'https://lh3.googleusercontent.com/IulP-MtI15MkUa8964Dhg1JaquDhAO1AkBCgwrnEEOEWdl_N5dANz-Y7AZ5OJ2HFdQrGHoJKxqifFkfpcAb0cBsxRScrhnC9B405ZQgTloq4Vnf51feJE2yp-vxQxnPfKk_WkSW-9w=w1920-h1080',
	'https://lh3.googleusercontent.com/U9lUEBFpVVsHXIwYB-5WLjAMJm2MpxaBV-sDm34_sIvjFExzr1oOGSO0HtrIYDxezqq4AWDy5MWhkQozesBew2LZA2usMB_494t6yWnRGT-so69tExl5sd8tDoTm6V3xqt87yhp55g=w1920-h1080',
	'https://lh3.googleusercontent.com/Xzv59i7OOtnZsdRpeMYpgW4AGFwpuvmSH7fAQtK_9-aHbR0VdN1StK7kr7EIEUAW37CeW4ucVnbgvATrUGPp8_QSK7Rybd8OBhBHw1r72hfQJVV7_zUynj869OhiD21VoDjNvVPRyw=w1920-h1080',
	'https://lh3.googleusercontent.com/P3RSvi6lHy1S245NMRalqV-CV2ZPRyRfIkJKcIGp0G5TYM1JYBqR7dyeK50ZdCZ_vPdQwSQq7-iCWE9S_w4ju-n05lCk6HS7hgJ0s5CY6yp_jv7zdEZH7a2wo8AIpz4mNjziB21S9g=w1920-h1080',
	'https://lh3.googleusercontent.com/8Bw7fpGNUlYT5RmbO_T34xwaMD4sdJbUfYXzAAtMaO7PJHS0V-eM8ofoeADGsRevOURvqB_VVfDRKS4AKELNLraDKOtmgasyoUgrzFqHWIfBx_AW_JXjLf0v-z9UQXQdWRtHovE6hw=w1920-h1080',
	'https://lh3.googleusercontent.com/jXAWaDuXFgUG5L-izQ9CjrHadmicrtcZysjfuVYImA3so0CwwcB1nbKdR05nZioqcOHpSGilnpzRNDUyVezRMJmAObIxl671uQnijp2iEDbV1JEueUu53SP2_XxsKqJrJ54-RzNm3g=w1920-h1080',
	'https://lh3.googleusercontent.com/e-32fV-6eOIJ5y8a8YbXe-fCdkE0ihPQ91Xmy7CM8hE1v20WxahkxZqpy7MUNzqqTFaZcr0mjWiPg0tdwvuARm5s8ucma3ClZEJfOXz1297-ZicFePDDxPQhvLMeGx3an6hoXoMDvw=w1920-h1080',
	'https://lh3.googleusercontent.com/mwYMxSzz2DM0p9SVKz97Sh1iA9ieXU64V4XShNjeNnaKrAnRQ4g9EtV8b0EG1a2CmRzZgHqHQs752ufMJIvJPRmmT0fZLWEIYJZuFjUFqw5wHeM9w6qIpqmBwntfVKWkof9h-67ZKA=w1920-h1080',
	'https://lh3.googleusercontent.com/0BCtTrgwI7nzFV1eXL_LwK8ub5o1ve6rj4SP85K0ERmpCh7YucJt0OEw32jtpTjy598SvMS01uoeAFxckG84c348pKddlv35xYQkCf9sPq__za2Ey-ibodvPHPM7jkGH677YageUVw=w1920-h1080',
	'https://lh3.googleusercontent.com/darkKObPrtbwFiQod-wRuAEEEn7uMKu9y5OAxVMIrHvvugGx8-m2yiX7W_ld9Vr05-f0Nzue99lvTugNiYo0WJ4VzPVpO2UU0kk3OQq5U5mRPTgeGL0eqc24zh-OcG3U47goh1kTCQ=w1920-h1080',
	'https://lh3.googleusercontent.com/v4t4ikBbfMV2rg7GulT6LpiHQq-tcS8n2-uVB_H5FDB9OcPu2j5L02bzTHKUpwsMKyUKBbnP0voYcsF2mtyQjsF68CnI49S8WJTpNfpQocUOzNxBCI96S6vskY-C_UF0Jk-FJXgLHA=w1920-h1080',
	'https://lh3.googleusercontent.com/VtycZfiaZ-9YxAHlsdJLI-NY_M9N4fs8HHyjX9AGwInfkDy4kA6T8vvomCxdW1x1zkVVMCYokHxmfxxdAo7bZkElhNFCEnj-LXw-YNKM99Q7F0k5IOP_onEVe5IJVOn0lwlb_iYnWw=w1920-h1080',
	'https://lh3.googleusercontent.com/bi0cEPcIV1U1hKzNVcqfpS4nKpZY4fjuzjbjNZImuwkAZw7PYyC-fUsQRqT9xCqevpoi4kfgNG-T1nV0g82cjryLLH0s1-Yh-2aiHcD63bpVoplapLWLAz92_sDnxqtjLiFXMtzeyw=w1920-h1080',
	'https://lh3.googleusercontent.com/GmP792u0g4E5432Y4N3DCmEp7_2zrOPvrc1tVb8Zh56YDrjH6hoJpVotUID12bFagFzWjdl2RooWZ31frjk_cdPlC4xlLYsVMQHqjtBxxx5lAGmCLSucBePXqUpjg2ZVSnKvIq_u6w=w1920-h1080',
	'https://lh3.googleusercontent.com/lOnQR6-9cX3xi57-SB38xX-4DFZXOMRp_JR9es4IFhynNYObutQ9MdsmkljcCn7vH9GzQcSoYlJxun7iYdp-2uI1V_OMr2oiZh7YOdy_cdKs-JRoMqKvecOLVbGWVk5JD3S1oUWWkQ=w1920-h1080',
	'https://lh3.googleusercontent.com/COhVjyJ5jFtGjikpEZZhk6BbeIIqppBOMuIBUVevjCu75hn_zaMBGpGTsiKLAPerY80Y7Ej3Bxc0xlk0eTvyt8wxkgH3gftPyW5pipdQXTc3iPi8BUTR7HQi1kqsmK410i5St5E8iw=w1920-h1080',
	'https://lh3.googleusercontent.com/UvdF4fFpILb3nzy1NbNauPnyLDEO2iQDB5LKmqdJG6rKk6xBsu4Pvfyany3TsCiDHteLY-_JvqMZtKmlnGJZNtmu96LOeMzEWcMNM7SeSPANTcSyqgjX0QLhHjiWjxY_K45M4sYZmw=w1920-h1080',
	'https://lh3.googleusercontent.com/5oktO_BEhjMXIhYWwV27A675umMjtgFyzT5xAzjXc8ufM8QoL-LdCpJNEs65dbI7aPifUKF40WTQaE5YW7IkPvt2HWR-M8eLIjP8gyYuGdc4BCpGhj9OkoAn0slqZaFzQzong3Kz7w=w1920-h1080',
	'https://lh3.googleusercontent.com/7rNA7PrsYdbiHQZRJQGmAKzXcOM5CaseuopXEXfbIB08wP-JXPgjCuBa-VsEyH37cbV1CqvYHeIXIzOlUq1KgksHtkdPZCqYeOYMA3loSxuVwKT7EGe14rgQDp2-2D0StW005AHU5g=w1920-h1080',
	'https://lh3.googleusercontent.com/spiDpHaXKS5E8E5Kd4A0h9uVpADnhW2bkFz0CX7UKukY9xknnV-2lmLVUzlsnWPYwREwp-mxThZjulndwrfS-figbeoFkW9d21X9wZ7wwf-Dlvh_8oTh4qxNNeTFJ_HzpBlH5WZlsw=w1920-h1080',
	'https://lh3.googleusercontent.com/C4tQmI1hC9_O54B34owk4mncN8BI4ASPVH6W1jcZpczD_5cjuzHIs7OTsq96vnBBk4A4VRM7CDh8dSRPbY6mt9UecaGHHqJSVUBVlG3MBtdqFa_so-egHHFw3dvTfxrQeCtDOdTqIQ=w1920-h1080',
	'https://lh3.googleusercontent.com/HtgpkP2zZ7VaMSXJ2xGZbvYuYu0nOIqSqiVLxLvuZPE0gTWDX6jDQLZCisvXBqjVg_wrsqVAFu1D4x0Wkv51fJ2_x7VKS93K2zeqqk4txbEeyJHip1CUPzMUMPV0Yk7Rfbdcx5HPtQ=w1920-h1080',
	'https://lh3.googleusercontent.com/inkwYiUxWqDTVkP1rhZco8EkmZCUjeg3HzuMOpG1z-yS9dJA-vF0K3zPhYP7MdwJSKSHWJzb8rivcw_kPaMciC3ZKLIX70o6dCmW5gCMICmlMrHbrVvPKyv3IReudTSrrzzPB1D0wg=w1920-h1080',
	'https://lh3.googleusercontent.com/tIGnt85z-0lbdaFFvxqFzi_cpelZtBYuD9cxCHA8UZ8BY26IHRScD-c30NC5NArKRKUPlVEJ_vBGKpbgEvPZBKEivXkpTPzIDrS46iYehONhMitRcJ0j_JpUHVEEliZtPZQo1ekolw=w1920-h1080',
	'https://lh3.googleusercontent.com/KMXkRfNau1qP3m0idgYyDEBBKyXmGGLrqRSmA5Dnezka0VEj_vBXMgEzy87d5FC26vV9QbWx_3HJ4p57kUZIXdCxEpJ9p05tZ3MkE7CbCkt689Abr3gAXcKZGFi0v7C8RgREy6j_wg=w1920-h1080',
	'https://lh3.googleusercontent.com/KRHUsGahh-5gKbzazZhKjqpktTLHgZts3yWt3B8SDeS0zcnqhUGOnuUeXUEpGq2gOOyU-jBF97L4HILa40nF6IrWfPR6OsFtbSnzuFEuxKEnFCSnfT0fLR5AItraUp6xU5yn3wZQ8w=w1920-h1080',
	'https://lh3.googleusercontent.com/JVDIbmPmQLk_yhuDPh3HAygORX5mriF056H_HQG0autS9LOLaUvzcp_sHJXYH_xJ4pHT582TN5_5cir1yhlYswzN70Xjt1BRsxUcPXluFef6MwSw7olqeCpjcfNGNmyyvBMakzQyaQ=w1920-h1080',
	'https://lh3.googleusercontent.com/KKzr77sz85mJgoprm65QAJxFC1SJoZD2Sk5de52U7-jhZVkhH3ENgUY7Q095zmFrB8E52D7CfHLyiCK-bJ-IJnOpRVlL9k2IUZPuGGOJ49tunxkEOzaS9iKTRb7VzVn7lHUk7xUrUw=w1920-h1080',
	'https://lh3.googleusercontent.com/mh5jGothFLQWhfaBLZVOPH5whQV-rghaFszwG4-mihRbDfPXZmkNVyhlWqbsEmxqOh_315gTTw6OzUFa5VjRmkikXun-qLqSrohDBr6R8Om6wyIJL97wpV3CLuWiYxS0GuNHkVDgRg=w1920-h1080',
	'https://lh3.googleusercontent.com/uFQXJuRj52NnFispcez2RqXhNZveu0SPaOCfby30yML6lDSDO-6cEHLbwOxvZvTEM7rjsMzWsLwldgx0IVYKY3N3RAIshoscYR0kEm_c_5kBXVL89xet9WIqTAZr_-23_CIxzxCo7A=w1920-h1080',
	'https://lh3.googleusercontent.com/GRyADiT71Di_SR045UWmxNhO_TKBmtqesc5vfclGQis18yoSgPtynHvBHOHkAIvfKcTD3PYHh9vZFfXpnxgElPb9ZmflGtcW7c4kEu0xDr7_nVmGt9WgWEJH4i8fWiPNeeYn3yzW-g=w1920-h1080',
	'https://lh3.googleusercontent.com/6eWV4IPIwlbtXq0MS8zMwVhigOBYTO0CzI4IgOCPNoT66yCBpA976Jj3w-rQ5Od7qIoD5TGCsvxczw-mN5WUfujIwVJq9IePsaWoaA9RzaWf-ed-smDBjuioq5aiKSeTd3VLSIInag=w1920-h1080',
	'https://lh3.googleusercontent.com/sQpn6wOO26jzRDVB9jYG6nTOZE4zW6l0cWtnR3ELbvAq8BD0Uu84miEeVGd8vhhIUv324KxsXG-7AL3uvn04POYxLfRO8bA3xrLlrS0nizEbcXweK8GRoldh5gyfmcGwEX3zHJsCOQ=w1920-h1080',
	'https://lh3.googleusercontent.com/F4bN9NwFvHuh9Yn6dN8Cgs9JPaOC2P17GPiPdZvLX4qNvOtLEurGlfX8bS-WFUf8rga1zahqeRhWS0NM1oeC5E7U2Gaav97RLTgRR6tByV7nVurbVyEpGSed-fC8KG4mPbCdmsnagg=w1920-h1080',
	'https://lh3.googleusercontent.com/itmwB6y1e_5kf5LtJpPTp5V90BXqBD8hzcEcofVobCHQ_RIHIxHbK6ppaARigU-AF8gjYbOIf2okiAcmXfrVH7-nhRsPjjktzFhnEU5e1v8wbE9-DkPJCrg2EIpEpbXf1RCBhAuhvA=w1920-h1080',
	'https://lh3.googleusercontent.com/doUibtWlgtRTTOUMgsM_Q9Y1jVAVWMypINehlNvc17b9v1c0Z02DqFF6iy5SEkqSRiC-SgR1frcPZAdDBfOdsFNgcbakOVKdLrItWr7yYOtYwUOlv_mRXCQcpelmRZSHS8VR80-Osg=w1920-h1080',
	'https://lh3.googleusercontent.com/ynoOqD-9IAj5bhZVUuH8QFqac-UXF2weVoBOTBuRS4PEDDTeL5MujSEt3XnGftT4E2yJSn96w5d2ePqaWbrnST7b3nq9Rv7kpby3lhf2RqbdzN1h_PsG5YJvNDLkY4RCH-As7Am02g=w1920-h1080',
	'https://lh3.googleusercontent.com/lgs5RPO0r3-9jlQxO0FzohhybNgYQn3-eBzEV-RDK0GL4_3MjWyo-zqJFEljamK3AHuw949vuSchBpt8xFs2R6T578kScL-NN7MpslGcTxKw12i5KpuH4Kk9fYfYBUW7WuwkcNTpmg=w1920-h1080',
	'https://lh3.googleusercontent.com/phqU4xeZlyf7aCZKomgj4ifW5Bbp1iZZN3LL2VyW1MjWR2u9xlF3uWwXEe_pcRS7LLHfhEz-uioovvrFMRfVWSMJEfboqmaIoFaXi-HZDzJQxVK2XTVyMdxHXxo10asnS7kMv6kUDg=w1920-h1080',
]

export default function StudentShowcaseOfICATDesignMediaCollegeChennai() {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = "https://cdn.jsdelivr.net/npm/publicalbum@latest/embed-ui.min.js";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		}
	}, []);

	return (
		<div className='content' style={{ paddingTop: '74px' }}>
			<div className='container-fluid sectin float-none'>
				<div className='news-lt'>
					<div className="pa-gallery-player-widget wdth-100p mb-3 hght-480" data-link="https://photos.app.goo.gl/GkcwX4Ru56dmFEv19" data-title="Student Showcase of ICAT Design & Media College, Chennai" data-description="44 new photos added to shared album">
						{EMList.map((iMg, index) => {
							return (
								<object key={index} data={iMg} aria-label="Student Showcase of ICAT Design & Media College, Chennai"></object>
							)
						})}
					</div>
					<h2 className="h4 mb-3">Student Showcase of ICAT Design & Media College, Chennai</h2>
					<div className='news-arrows'>
						<Link to='/12th-graduate-showcase-2022-bangalore' className='prev'>New Post</Link>
						<Link to='/11th-bcu-convocation-ceremony-2020' className='next float-end'>Older Post</Link>
					</div>
				</div>
				<div className='news-rt'>
					<ul>
						<NewsList />
					</ul>
				</div>
			</div>
		</div>
	)
}