import React from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import NewsList from "./../../Components/NewsList";

const EMList = [
    'https://imagegroup.in/images/news-events/AVGC-stakeholder-consultation-workshop/1.jpg',
    'https://imagegroup.in/images/news-events/AVGC-stakeholder-consultation-workshop/2.jpg',
    'https://imagegroup.in/images/news-events/AVGC-stakeholder-consultation-workshop/3.jpg',
    'https://imagegroup.in/images/news-events/AVGC-stakeholder-consultation-workshop/4.jpg',
    'https://imagegroup.in/images/news-events/AVGC-stakeholder-consultation-workshop/5.jpg',
    'https://imagegroup.in/images/news-events/AVGC-stakeholder-consultation-workshop/6.jpg',
    'https://imagegroup.in/images/news-events/AVGC-stakeholder-consultation-workshop/7.jpg',
    'https://imagegroup.in/images/news-events/AVGC-stakeholder-consultation-workshop/8.jpg',
    'https://imagegroup.in/images/news-events/AVGC-stakeholder-consultation-workshop/9.jpg',
    'https://imagegroup.in/images/news-events/AVGC-stakeholder-consultation-workshop/10.jpg',
    'https://imagegroup.in/images/news-events/AVGC-stakeholder-consultation-workshop/11.jpg',
    'https://imagegroup.in/images/news-events/AVGC-stakeholder-consultation-workshop/12.jpg',
    'https://imagegroup.in/images/news-events/AVGC-stakeholder-consultation-workshop/13.jpg',
    'https://imagegroup.in/images/news-events/AVGC-stakeholder-consultation-workshop/14.jpg',
    'https://imagegroup.in/images/news-events/AVGC-stakeholder-consultation-workshop/15.jpg',
    'https://imagegroup.in/images/news-events/AVGC-stakeholder-consultation-workshop/16.jpg',
    'https://imagegroup.in/images/news-events/AVGC-stakeholder-consultation-workshop/17.jpg',
]

export default function AVGCStakeholderConsultationWorkshop() {
    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };
    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 mb-3'>
                        <Slider className="news-slider" {...settings}>
                            {EMList.map((iMG, index) => {
                                return (
                                    <div key={index}><img src={iMG} alt='AVGC Stakeholder Consultation workshop on TN AVGC-XR Policy 2023' /></div>
                                )
                            })}
                        </Slider>
                    </div>
                    <h2 className="h4 mb-3">AVGC Stakeholder Consultation workshop on TN AVGC-XR Policy 2023</h2>
                    <p className='fs-italic mb-2'>Date: 22, September 2023</p>
                    <div className='news-arrows'>
                        <Link to='/rampculture-image-fashion-dept-students-participated-avant-garde-fashion-show-2023' className='prev'>New Post</Link>
                        <Link to='/inter-collegiate-cultural-fest-miraki-2023-chennai' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}
