import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import NewsList from "./../../Components/NewsList";

const EMList = [
	'https://lh3.googleusercontent.com/Gx810lRKTAhZ3ZNXBUJPfe8WPanOsDWN3CL5K38KBPUg8639-ZIIDitrFaI600jCSV6Kq4gq8UfVRJlkzM3nBAnQaAG7IbGxbGKZD8qo7dI_Zv0X4MO786N3A6wImyTcc_JHOzP-uQ=w1920-h1080',
	'https://lh3.googleusercontent.com/jKCuWGw1q8s6mENSGHhNOIF9bT0SR2mP5kSp7c33DZt4lAcGIoc05YJrqQm9TbzCbRm6f792Uhgfgm9ACfF4lAApO1ERfdSL-UNbU1wRySQrMlc2IHs5yAc9P55NP-HRXMZEmXTb8g=w1920-h1080',
	'https://lh3.googleusercontent.com/vEu6g7OrmecpALX6DIB-1LQmJFXqSzBZgL9d1Zzr6BNSIRrxo8ragZzCJy2O2o-WWKmW2qE0m-o4K5ezj73fc1As6cgfK19x9dP7_ksxkB8PCNoZ1xdYGymylRxSMqhiKNWquQGjUg=w1920-h1080',
	'https://lh3.googleusercontent.com/lF1COvztDOrVzHaIkx6n8_FMrUMkTsQEL8EaO0-yYF4JNlkIsfPNg0GZtrN1sNQiNh0vA9Gugoho-FXk-5g_i85ru5a7FAHgtiQfrZmWjzkGuTmd3MhG40iOGfBm3zbUgAKYrkYrcg=w1920-h1080',
	'https://lh3.googleusercontent.com/VfSahFnGdBQUdnJqUSUmt6ZhH3G2xfLSRl9kTcNsdTLdG3v1BZvKRGc6t9ThqjVVISPo2m7r_qBe_AMZ3Z3y83M-pOfjSYh1RKfVn0gGy6tgjc9rs8-X4VkR_fbIGHVVT6viVucKdQ=w1920-h1080',
	'https://lh3.googleusercontent.com/rwFWk8uVJS8DMW_GIRCWLjWx7GnHTU_tydIo1EAcfaFTvlILbWebUhDGD0DU0mC9B2OSDASrtfFhAQXL1RyQhpEeoIVMQe5vk1fDOsHY625qETezaO8-Af059UWhIS6YbKMfZJhZkA=w1920-h1080',
	'https://lh3.googleusercontent.com/OyPsy7kSVSQtea_0iqE44LWaibBdGzwgwRpP7oX8JlufkaBU5loEfV5hOK-KNghaaPf9YrAiHZ660sf072UCg3aDt2eT5ydMY4t5aksyxH9LTDFhF0FhdIQqvbut-e-32aV9R6g0KQ=w1920-h1080',
	'https://lh3.googleusercontent.com/MkbKeSORRgmkshy3LyOT3lgSEavP6BcIM2wbN7ICZMaWwiB-Isdvp6swmLu_w0rhR7Cb0cEdAEYKr6M48H0uhEDfWlDdBlusc5sxyb05FBxr4nsZPSI1dBeDC5ZGtlH3g476XYxprA=w1920-h1080',
	'https://lh3.googleusercontent.com/rUnqWrZekqqehETga_sNqWRecbG2DudFQabrX9PKMo4avzDkyN19qdiqMM6RQfbPLvW8JWrIahQnZ3X8RhPXpDKSkSGIiaTJspZZvlku2_Rpa_REEPo0kJCi_zIdbW5oiWo0zHZyjg=w1920-h1080',
	'https://lh3.googleusercontent.com/F4B3HgaojTrTqwvL8PBkc6mOUUM1PpGYlG_1X3IlwNw56Xe6gR8mBfx2aFJmI7Uxx0pAVaXAZUpV1F42LqgsnkWS6ZFjnhA_s6YEds67SowaK8UFC5Q5eeLVGaG4v14SNuZ8LULH0w=w1920-h1080',
	'https://lh3.googleusercontent.com/brxfxFgEyHJAuCjjW6s05FG5A_x9cxgzQoux5YkvbmiQx5rHXMJkpVq2F2Lgr5atUewXH45J0E_fScgVlUnwmHui_6yrVK0y9FsCcSA8m2XyI87AFFcJa27_ZSZ5Xr-OEhIU0_CMTg=w1920-h1080',
	'https://lh3.googleusercontent.com/8FtjEsDk5Uz4U--HUqSMt_F1Pe9URkkhK597TNCndvZVcHLy-RKXOcbhBx4Jjqpk3SEypahheUSy-ot1a5QUiWj9o4NHEnhV0aFe9jlFmICCBjbhLSwoUsWOcCXYDt53JSuE2_b68w=w1920-h1080',
	'https://lh3.googleusercontent.com/XL6jXfQTKNov9R9pMQ17R6AaeyBhaAWguWzV0LqCkV5PQk4Jvu_27m0XTY9JIzFf0iuuqbYwo4ffebUyEtJKS8e28xErmQPO5FfdmKymRvw8IgqDbJKPy4CvV3uZ3kVX8NGdXJhkaQ=w1920-h1080',
	'https://lh3.googleusercontent.com/_8HQez9fVJmnL-aCRLFeudMD8KvHQYnyStlB_-qc5c61W6EJTrPHsFv6vHfkyUY5rmOravvStfNCIWchDeimNCDEfijWAj-77yIC9fGXSAHETt0XGp9mUWVFXIrBWTmllY-0tBYLbQ=w1920-h1080',
	'https://lh3.googleusercontent.com/d94iaGPXrJCxR-0HCzDT0jROcp_uf4rWGJNCtyoZdRHM_FF4SFZaChkyaksykxBSB2FiXi-BHPEHUJt4q56rWFFTEl6DDC4vUpiCJIYrAC063ob-9-EUYe8Wf95vtruaX9Lx-W6nZw=w1920-h1080',
	'https://lh3.googleusercontent.com/rL-i4h32HKcjbuE4xwWGZ4dfJQ4lPJTHyOt4bsqpIMPw4S3ZlxhsLiVlQtlZO65YQrwHsnf4dCN0dvXQ0syx5XhIFMw2PLD9LlA1w-zb8zdD_FN8aL-DmneL0u1Nuk8BSrU8QyHU5Q=w1920-h1080',
	'https://lh3.googleusercontent.com/Lb0fBdcniChDvAYMp1J9PB93q4WiY1NzgzwgCPsl4HMxhMdKXcbdoz7Djmw3qJcVtab5zU1tK_k-Dg44hVdjL-Cyf4tHZcm36HX39acvGvsNiOdubVx1rZAea8p947RQ5WwUmMsLkA=w1920-h1080',
	'https://lh3.googleusercontent.com/CmJKuuIh9UdrNGroQtpOzO3rcy9J2FJCN7t6rKI67RNDisxYyzIEO6eRzitBTtrL4SYkluS-xQvJBgH9GBpRtkGt6eOi54lOT4VSNLQ7FcsnxRPqp07-UCmcMQCIcY2EEy73HL1Y4Q=w1920-h1080',
	'https://lh3.googleusercontent.com/XR2EFv0GP3gtxgQLjm1Dk7i2lnkzUi-D-MaHBTXniCfdN5A0Yd4nd2TPlnewTj04e3vZraiJb3HReiJJXX9i1DdMbBgI2OsK_EKtBiqBZBCsuy8Pkesh8RLVEutlS3YErwQXWbRm8Q=w1920-h1080',
	'https://lh3.googleusercontent.com/D9LkDPpSDBWnVVs2qj2NsSPfq4oOQONbixwXZXi7Vy9jqOQZsGpWks7Bp3SkDwr34qY3jDkM53O5eulkfZSZbKJWPa1Y_k1ZGR0K67DFsr63FKS5Bspvgq3CGriJH3NUpW9p77Obww=w1920-h1080',
	'https://lh3.googleusercontent.com/ca8MprbXlE5uKzU3YtUBh8QC936H8dUMH8gCZ5aeKcvtW5dtaWttsG2JQEZtHCz4mzi6P3ifyLPMAaqdYiEFonX3NSW3ilZC2JxVtYzMRVlLaTImV2nRLdhub7UBKHsTy7XMHbofPg=w1920-h1080',
	'https://lh3.googleusercontent.com/-cWmUyWjjXyleiGwCg_y8unP1XOA6RPfLf_jOVjhxRcpRQZ4akq7bbDBeEr3693jfwt2oGRuLRiPNlD6bG2188cpIRf-x3HIAViv_StLHYJtpH4r0UTM45pp4TKvGAe98GAVbWyrhQ=w1920-h1080',
	'https://lh3.googleusercontent.com/ahYOQ-vV16zf1RzsxeERkabkv4PoxLZPnh8khdm5MLS9zQtu-6xlJrTJntjXWaqL-1VilIh5nOhGHRz9UP8XPlAvogO7iQqkAY-3lGvlFGcHl1w2MWZA9583nhJ96Zo57rEBgSh9Yg=w1920-h1080',
	'https://lh3.googleusercontent.com/S-siR1WhpXlo-S03Wy5_BV1zm9k1UinMKe5qN8CtA9hxHVksaFm8WkgmuNnFrp3noXxShpb7YwHmvXtZAQ5kLnV0YFVzzicsJ3IN9DIo8mNQarbJy81NkMKqRvgk--Gium-eW1i6nQ=w1920-h1080',
	'https://lh3.googleusercontent.com/CTHD2YzvzPpc2xbfJkRjixREnaxrgsGzM6O0WUeiPzbfV72gRNwnZgUJkRpApPng9SEjs3AX7gAvOwkFzHzKilMRSUjZuTsTZKKHCAPR1sJkjGZJCV4gej-8Wfm7YE7tamRBcpTcxA=w1920-h1080',
	'https://lh3.googleusercontent.com/umjHXqqCYl70M_ES4U1slkDNPBuej-OQHUtUgIhTkyetbKsgjKz946--H1g7xQsqgyfLCyyat0_wJPFOL29LyVcbLVDMSlV5jlGZXY2VOwPd4X-qGI77mgo43lZBEx6_S_zddsUIow=w1920-h1080',
	'https://lh3.googleusercontent.com/bPOSHzQ6LDWxepd6XoLVjcux8jT4XfAsn1G-SC6MzqvxppaVbsqAHP8aW8Dxu9ogHus_tctqSWPlg1vIZ1S8L0MCki4ZpWNlj3yBKVbfuPTKVWg7ojGJE9CtJka-hKQ-QsuNHkmj7Q=w1920-h1080',
	'https://lh3.googleusercontent.com/9ulmUKTix662rIvQ-G75_wZAcV5edRyvJXRQ3mVPc-iUdUecSyNghjzY2SAN0vUNexF-0ioEmf7NBYUdTOgm4NvvQG9WEn5Jo9uGWk8pAB6Xx69-4cmSaNZDY8TRmpn72J6xj-XIWw=w1920-h1080',
	'https://lh3.googleusercontent.com/_mvVgHp7we60hxX7xljdKP2a6ZGudNT7UDPx5jW7d93wh0cBFrxPYJnCCa116kaPaTYWwoHo5EI6SRhwn8psJZHi1HQVC7gcctxM3Sef70z5OroxLC6Frf1CMPBjAGJc-_RYUnBQpw=w1920-h1080',
	'https://lh3.googleusercontent.com/f_0DvhQdw4q6H0UNBC0chPa8P6fg2xI-6qVuuVpPe4LhEnDlQWRuxA1XsOdxTPIgDzPHqHd4EdkmkOqcC44mWudRWbCfkVQznWvqmmlA7S2JQJ07jZD_tO2WtOmtsQ7YzIGBXbnsEw=w1920-h1080',
	'https://lh3.googleusercontent.com/EYBrFESILCRaQHUBKQxuazFWOV3kypuSwE9MoUotVjZ94c7Ba2wM9ATg6k9n7BFGdKQHvx4jNfw0MFiBmRrr_tFxUvVNlC87uDtC20WlMlpCMlBf-pWQqlLSmcsZXfx5EVpj3jZqSg=w1920-h1080',
	'https://lh3.googleusercontent.com/sXi9Cu5ay7N4ZDdDpeyOSRq8yX3umcNPr6o-xWWUTVBolfruQelkmqPIeb4-PE2XEcgO8DrL7tI14b2pOwNKhj7sF3bG7tHgPAANxfodCbAzHs4yHhNpgCoNqo4KdzTbYcME7lcgEw=w1920-h1080',
	'https://lh3.googleusercontent.com/mAXBQGCIWsWDwKT5AM1LvKZC4M-yNSZDk-dXD8h42FSvPMm_mWXiI5Bh6UuzH6fCx6K-n2RTCcr1aNH8x_YbkCE8D0pAAm5zdWjrEazOjAgomNmzFUJQo9V-7p5iTEFnIgLqu4Gkog=w1920-h1080',
	'https://lh3.googleusercontent.com/RHhIfU6uFsCgSBYW3zs9HvR5kZQoocXgFOgG8qBNA8VEmbyS01seIA7tm-B7Lk7zrplod5-RJdl5JMqMHoDjsJtc6at8egwiZtBeKxX-hVL0Xdo7c8rK81nwC8QF7BFRpGhkvFIFXw=w1920-h1080',
	'https://lh3.googleusercontent.com/aKXAyU1RsPHy7gKHTYBeYOpwkNcEYbuiiI6Lo1JXP6MqFfobYrgwAlY_Q1QtS1MzLJzcrwr3Uj0O2FeGQwnCmlTX1U4ev9KXOYZRv62flA7hI2TF9xwuN8pqqiFFIBgG8QwxUwSNLA=w1920-h1080',
	'https://lh3.googleusercontent.com/VpReddk3lRzE_b4mEFkk2qYmKwgLIoQbPZO4syUVEZjXX18en9qkoxxnO6AgKSjFxjPJXOFtsBy6shRdtmHuMuXiqcyoJ6oTKk7uDRAscXl7ArROTjSRaLvnXGnpymUjd_XYVbH0nQ=w1920-h1080',
	'https://lh3.googleusercontent.com/-u4ZahYmo8WNcE7XdoPT0hnpNkrLyUaGDUTnv61cvRjmbsD6B6EsOpY80mFF2ZoZmcXN013cetLunQl3_xwqItH6ny6itjjfT1qx9U3wFuvYVV7tkJds9td-jj3j08CXswDQCeynKw=w1920-h1080',
	'https://lh3.googleusercontent.com/YtyxoO7xDsUQuBMnHc6znn62VcHNtksyIgNiQxyb8SMqbGVyDZpWSdYvGs8PWf2T8fzYkLKGF3G6qWPh5cahuQgZSCOZI6RCNvlXrjDlg7OfTXK9_xaN8xFprJ6dqcY_lRG7TYZIIw=w1920-h1080',
	'https://lh3.googleusercontent.com/856_F-Hc4QjJIcXU-PYkHMY4uogEAfKN45aViAJOo6tcRR8pHbc67JhgOvv0vzKQbDF_zJoYmHPdntn_Liu9PLr3MUXEOun1xcU5hmqfvTmfZB7GMGPpguEg6mX0i19Zp6LKBBfClQ=w1920-h1080',
	'https://lh3.googleusercontent.com/4bVYLOtJjp3tgrBBT7dvnTGOfJlJiF3rTR5ELzFW4JuG5rNhZqlWN_fZ9d56AjIEWfC-2eqSZM_T61Ak3wcPhayRv7JlBz1XCvIuWdJzM5EDnVpY1wIaNjAeAPaCIzNHq2lJ9M5RrA=w1920-h1080',
	'https://lh3.googleusercontent.com/bCLsNYAf-fw2swSKeysd3_tcjtE02E6ZFH4K5DMhU82ZP4hRgcNgBOldza-b4xK5MdPL0ATAS1lgZozOVMbgPLyh6v6vhJPTZbu0vCSbMSJoN6OIyJTxudq-IUmWra-e1ZVRr-2xCg=w1920-h1080',
	'https://lh3.googleusercontent.com/ZArKg0RQNnMwf5jP2Z9MawKRUdfbqaeKHYotpwWBb-bzAOfw5hMIyKVxi8sWNaarmOBqhPf6b5iTXT6CBkQq0Sp0l5yMfhGJfSfYKMjUbaYpBAgEvR_gJ__A6CQAxIrgJ5LVKpJV2A=w1920-h1080',
	'https://lh3.googleusercontent.com/BDAZGh-ZQHEpwhcyVhd3DN_nu0YIvNgcjWAIUCGuVp0yH-reFvmY1T6SNjw1eRGME2tLYG0w_QZgd5zBwGQ8cxpXiiTN_MJ0OMcwWZQYRKaJ3VcY0svd9q6x1rhmKB9GWqkgymfp4g=w1920-h1080',
	'https://lh3.googleusercontent.com/KHybWOPd7v2FZE8g5Vd015M6LPjMmZHPY7G8U5xj9rvrS7roknJThYXIGbbTxIbuOJPgiPUKTwoNlbQ6KOlR2Zq3fqSdXliavzRBnaZmlLgPc3E01rTALazpw5O36kF1FA5iwGmj3A=w1920-h1080',
	'https://lh3.googleusercontent.com/6bB3CZXIlc1fvVgmVYZEzPBOvwbywUWMxF9knCoLZxhcTNBwAOJnqIBzqaWacZ3QqYi19kbHnvVl_vu8kJT2hAPd6okafBXE7Z1WpSx5e2HU-uC63wrFOt0OHr5Wnl5EPQz2Xeqm0A=w1920-h1080',
	'https://lh3.googleusercontent.com/HlRzfM76rPKotlBsCl9vFExipIWvLEhd9olzPzUaw6lYUN-EqTDatzVebyESpMfjIMA_6d-flYbpYCk3jg6nWPBD-UCefmuq4eIlvWjypF2dPeMi8I8uRX22aTztYia3kR3wsxU0og=w1920-h1080',
	'https://lh3.googleusercontent.com/f67B2u-6Yt-i_idrVJAWcPizQvsRWv9fzFVQGfNCk8sF-EzeWFwvhi8BSkpy65TLBvJL-cDz-dWYn0YeGEAZT1TIRdTTGsgusxwuBq9yYm7cyW9rpELcKpBcBHDAmZYpUWkg2MQavg=w1920-h1080',
	'https://lh3.googleusercontent.com/jCjMTheWzg-ikFagfScnj6jgiqSKcs5o5wc5H_5w7uQlowi0WkO1uorZV2xCpVl2wPKgz0dN2RWhelUrxvkF7DH9FB0-c6XPR4gkX8ut8x5YMNxnJXon8PUhxVYiK-KgaMHuYSzzKA=w1920-h1080',
	'https://lh3.googleusercontent.com/txZWtOHE_cmvhtlhjtBpEXCWMYwHuR91RScAPgTueEgQIoAkmz5nDxaAaUQusCp9JGUtqsLeGimnTIndKs3AREiPNkhm_P_8eUp7I8SxKTwn4iIcC5DCYxsiciJoZx1cdrnF3NkQoQ=w1920-h1080',
	'https://lh3.googleusercontent.com/yt48-aq5wtHqyUA-qZvPJ0_4HsDD_7tbXCrxnnP61Jy2xXqwypCkRsOGIuaMPTnljGOOG0sFiwn-Ug-aapej_Uny-zDTXfs6-DifqjyLLIDLDw-iRJYLGrLeLuaVDENchj7KKCzooA=w1920-h1080',
	'https://lh3.googleusercontent.com/VZ2HNHBnjo9QgVq2HaeEYv8GLyKCuAiZuSZ9MspTMQIXJOs7oLlrDwZ0CPir8jNhr46aCmnVC2tlqnAV9F4PzZ7NXSkp0pjA8rex8QgOspZC9Q43E-Jx6Ly0nWkJm00MypEzrGSOLA=w1920-h1080',
	'https://lh3.googleusercontent.com/YBBbkA3HOrvAM06iedQjdXXGXRJfOin95xTlIccVfslnYKOHNCX-WP2HcPPFCslsQTsfLT3dSAwDikN2iqV0CbAjyjGgJiwQo9vbuNRoAEG43O0HwvquGFjDcaIIDqeFF0nR1oHDLg=w1920-h1080',
	'https://lh3.googleusercontent.com/YhWZOVGkA08rhBcbuYiv23ZHQ_LNTc5ZZdpbqZNSEc3XGeXajqSlAQtoypmvHsq5WZb4HaliSMT70-Ib5bZE57p94twvIgJJ8T31XqsshawErjB_MZH5XmiROzq7DkxVRiV4dYvS-Q=w1920-h1080',
	'https://lh3.googleusercontent.com/f87JGEvfh7ge-gNmdVopoOfTrPzBI6WqvwOE26MmXy5boC4RqTXtFvSt0zuKKD88f46y5_0TAeeYWQh7mBvdvGgwcAGaySbvMB62gcKp3sG82rYjnIsZodXSerVAxncyGY9YgRT16w=w1920-h1080',
	'https://lh3.googleusercontent.com/upgsUBF1JX7KXQxaVaka5PnmZ-xsjyXk2kS9erSrNpkb5ctV7cTi9nv_oOxM9hnFNlQiBW4vRTRoDEZxUDhyHwUZ4UJw6u9jPVf4zCJ_AFU7GxKPvuWkoynkvWZ6laJvoQ4vMsEPeg=w1920-h1080',
	'https://lh3.googleusercontent.com/qpWl7m5VW6etRe75_4sLy90mpOzib68z8BiXNGcohHrAxh3ls-Zt4dABTSpO9eoySJx5_7JmtpflRar3qJAV4iaECZkKe7kfk4umHd0VOsUwk4OwzGfVIUgAe8od4o6uVn3f4sS2ug=w1920-h1080',
	'https://lh3.googleusercontent.com/DspgJCCHomODcMZBr1tigyAoX9jTs3p1PQ_0lAkiglc2BlQHP1X7Bxsmqp0URHa2xlg9FxySZEh_66fcXJ6o5XKFRwIENmoLvxsq3JcICK5axH7s70orkJ8f1stZhdPXu8Kbs-dQVw=w1920-h1080',
	'https://lh3.googleusercontent.com/Gwc8z-c1F20ijumlpgIAUd713aqwqVdKmpo_OkVrglYDzkFfer9IrwL45qzeKQI13bu_EK9u5OlTqFwFOt7NqKjnZvVpODjaUR1fUJEq1VW-W-u-MJTNfUZSLPhRO2yv-bwZbdZLJg=w1920-h1080',
	'https://lh3.googleusercontent.com/3Ze74KQkbdbwYxzToYtIr1qFhL1OsbGd63v-yfaNOYcEUtOyRKl0BfepJIPruFRQ08Q29KU15w5kE-paa46fv4GHi-jsArCzBRcxn0P9vZFbmci1mNLCt-RBSQTS5E9BgoyRAkKbdQ=w1920-h1080',
]

export default function InterCollegiateCulturalFestMiraki2K19Bangalore() {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = "https://cdn.jsdelivr.net/npm/publicalbum@latest/embed-ui.min.js";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		}
	}, []);

	return (
		<div className='content' style={{ paddingTop: '74px' }}>
			<div className='container-fluid sectin float-none'>
				<div className='news-lt'>
					<div className="pa-gallery-player-widget wdth-100p mb-3 hght-480" data-link="https://photos.app.goo.gl/KMchzxUR8oui2CJdA" data-title="Inter-Collegiate Cultural Fest - Miraki 2K19, Bangalore" data-description="44 new photos added to shared album">
						{EMList.map((iMg, index) => {
							return (
								<object key={index} data={iMg} aria-label="Inter-Collegiate Cultural Fest - Miraki 2K19, Bangalore"></object>
							)
						})}
					</div>
					<h2 className="h4 mb-3">Inter-Collegiate Cultural Fest - Miraki 2K19, Bangalore</h2>
					<p className='mb-2'>ICAT Design & Media College, Bangalore has hosted its first ever inter-collegiate cultural fest MIRAKI 2k19, on 15th of February, 2019. Dedicated to the celebration of creativity, the theme for the event was Magical Stardust.</p>
					<p className='mb-2'>More than 150 colleges in and around Bangalore had participated in the cultural fest. Miraki has provided the platform for the students to showcase their talents in the fields of dance, music, designing, film-making, quiz and fashion show and so on. The competitions were divided into three categories namely Online, Onstage and Offstage events, in order to accommodate as much as possible participants from various fields.</p>
					<p className='mb-2'>The Chief Guest for Miraki 2k19 was none other than the famous Kannada film director Mr. T S Nagabharana whose gracious presence has boosted the confidence of the participants. For the evening cultural events, we had Mr. Aniruddha, the noted film actor of Kannada film industry, as a special guest to support the confidence of the participants. The judges for various events in MIRAKI 2019, are eminent figures in their respective fields and industry. Moreover, they generously offered their support and time to evaluate performances. In addition to this their presence boosted confidence of the participating students.</p>
					<p className='mb-2'>Miraki attracted a significant number of audiences from different educational institution in Bangalore.  All the events ended successfully and in the festival finally ended with a prize distribution ceremony in the evening.</p>
					<p>ICAT Design & Media College is proud to host this creative and interactive event and is committed to the cause of integration amongst youths, therefore, desires to continue with MIRAKI as  open interactive platform for youths in the coming days.</p>
					<div className='news-arrows'>
						<Link to='/inter-collegiate-cultural-fest-miraki-2019-chennai' className='prev'>New Post</Link>
						<Link to='/10th-convocation-ceremony-2019-chennai' className='next float-end'>Older Post</Link>
					</div>
				</div>
				<div className='news-rt'>
					<ul>
						<NewsList />
					</ul>
				</div>
			</div>
		</div>
	)
}
