import React from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import NewsList from "./../../Components/NewsList";

const EMList = [
    'https://imagegroup.in/images/news-events/icreas-2017-montfort-school/1.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-montfort-school/2.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-montfort-school/3.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-montfort-school/4.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-montfort-school/5.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-montfort-school/6.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-montfort-school/7.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-montfort-school/8.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-montfort-school/9.jpg',
    'https://imagegroup.in/images/news-events/icreas-2017-montfort-school/10.jpg',
]

export default function Icreas2017MontfortSchool() {
    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };

    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 mb-3'>
                        <Slider className="news-slider" {...settings}>
                            {EMList.map((iMG, index) => {
                                return (
                                    <div key={index}><img src={iMG} alt='Icreas 2017 - Montfort School' /></div>
                                )
                            })}
                        </Slider>
                    </div>
                    <h2 className="h4 mb-3">Icreas 2017 - Montfort School</h2>

                    <p>Icreas 2017 - The final Art expo of Imageminds at Montfort School, Kattur, Trichy was conducted on the 4th of March, 2017. The expo was Inaugurated by Prof. P. Manickavachagam, Associate Prof. in Fine Arts, Dept. of Architecture, NIT, Trichy. Ms. Nasrath Beagum, Branch Head, Design School, Trichy was the Special Guest for the occassion. The presence of Rev. Bro. Dhanraj, Principal and Rev. Bro George Fernandez, Vice Principal, Brothers from different communities, special invitees, parents and students graced the occassion. The year long creative outputs of the students at montfort were exhibited during the event. The mug prints and wood prints done by the photography students of std 4 and 5 gathered them huge appreciation.</p>
                    <div className='news-arrows'>
                        <Link to='/image-opens-new-centre-nagarcoil-2017' className='prev'>New Post</Link>
                        <Link to='/ICAT-students-won-several-awards-delhi-CG-awards-2016' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}
