import React from 'react';
import { Link } from "react-router-dom";

import AboutImgw from './../../Images/about-iminds.webp';
import AboutImg from './../../Images/about-iminds.png';

import imnImg1 from './../../Images/imn1.jpg';
import imnImg2 from './../../Images/imn2.jpg';
import imnImg3 from './../../Images/imn3.jpg';
import imnImg4 from './../../Images/imn4.jpg';

const prItems = [
    { title: 'Foundation in Visual Arts (4-5 Yrs)', uRl: 'https://www.imageminds.com/products/foundation-in-visual-arts-age-4-6-year-old-kids', },
    { title: 'Painting and Illustration - Paper to Pixel (4-6 Yrs)', uRl: 'https://imageminds.com/products/painting-and-illustration-paper-to-pixel-age-5-7-year-old-kids', },
    { title: 'Foundation in Graphic Designing (5-7 Yrs)', uRl: 'https://imageminds.com/products/foundation-in-graphic-designing-age-6-8-year-old-kids', },
    { title: 'Digital Photography (6-8 Yrs)', uRl: 'https://imageminds.com/products/digital-photography-age-7-9-year-old-kids', },
    { title: 'Digital Filmmaking (7-9 Yrs)', uRl: 'https://imageminds.com/products/digital-filmmaking-age-8-10-year-old-kids', },
    { title: 'Experimental Animation Techniques (8-10 Yrs)', uRl: 'https://imageminds.com/products/experimental-animation-techniques-age-9-11-year-old-kids', },
    { title: '3D Modelling (9-11 Yrs)', uRl: 'https://imageminds.com/products/3d-modeling-age-10-12-year-old-kids', },
    { title: 'Fashion Design (10-12 Yrs)', uRl: 'https://imageminds.com/products/fashion-design-age-11-13-year-old-kids', },
    { title: 'Mobile App Development (10-12 Yrs)', uRl: 'https://imageminds.com/products/mobile-app-development-age-11-13-year-old-kids', },
    { title: 'AI Application Development (11-13 Yrs)', uRl: 'https://imageminds.com/products/ai-application-development-age-12-14-year-old-kids', },
    { title: '3D Game Development & AR/VR Technologies (12-14 Yrs)', uRl: 'https://imageminds.com/products/3d-game-development-ar-vr-technologies-age-13-15-year-old-kids', },
]

export default function Imageminds() {
    return (
        <div className='content'>
            <div className='banner2 banner-iminds'><Link to='https://www.imageminds.com/' target="_blank" rel="noreferrer"></Link></div>
            <div className='about-cont'>
                <div className='container-fluid sectin'>
                    <div className='row g-lg-3'>
                        <div className='col-lg-8 col-15 mxw-700 about-lt'>
                            <div className='about-ct wdth-80p mxw-500 m-auto'>
                                <picture>
                                    <source srcSet={AboutImgw} type="image/webp" />
                                    <source srcSet={AboutImg} type="image/png" />
                                    <img
                                        src={AboutImg}
                                        alt="Image Creative Education"
                                        title="Image Creative Education"
                                        width="432"
                                        height="422"
                                    /></picture>
                            </div>
                        </div>
                        <div className='col-lg-8 col-15 mxw-700 about-rt mb-0'>
                            <h6 className='f-16 txt-thm-clr-3 fw-600 txt-lg-lt txt-center'>About us</h6>
                            <h2 className='h4 fw-600 mb-4 txt-lg-lt txt-center'>Art Integrated Learning for Schools</h2>
                            <p className='f-16 mb-2 txt-lg-lt txt-center'>With a responsibility to prepare young children for the future needs of the digital space and for them to succeed in every step they take, IMAGE launched Imageminds in 2011. It is the first-of-its-kind venture that imparts creative education to school children. It works in collaboration with schools and sets up its own Creative Labs to give the youth of today their introduction to the possibilities that the Digital Medium can offer. The Imageminds course works as an integrated component of every student's yearly syllabus, with the option of providing credit for their completion of each course.</p>
                            <h6 className='f-18 fw-500 txt-thm-clr-3 txt-lg-lt txt-center mb-1'>Highlights of Imageminds:</h6>
                            <ul className='list mb-4'>
                                <li className='mb-1'>Is established in numerous schools across India</li>
                                <li className='mb-1'>Kickstarts the creative journey right in class 1</li>
                                <li className='mb-1'>Makes creativity a practice</li>
                                <li className='mb-1'>Fosters new aspirations in students</li>
                                <li>Introduces limitless career opportunities</li>
                            </ul>
                            <div className='txt-lg-lt txt-center'><Link to='https://www.imageminds.com/' target="_blank" rel="noreferrer" className='btn'>Visit Us</Link></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="course-cont iminds-cont">
                <div className="container-fluid sectin">
                    <h6 className='f-16 txt-white fw-600 txt-center'>Courses Offered</h6>
                    <h2 className='h4 fw-600 mb-4 txt-center txt-white'>Creative Learning Programs</h2>
                    <ul className='txt-center mb-5'>
                        {prItems.map((item, index) => {
                            return (
                                <li key={index}><Link to={item.uRl} target="_blank" rel='noreferrer'>{item.title}</Link></li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            <div className='cour-desc-cont'>
                <div className='container-fluid sectin '>
                    <h6 className='txt-center mb-6'>Nurtured more than 2.17 lakh+ Students</h6>
                    <div className='row g-lg-3'>
                        <div className='col-lg-8 col-15 mxw-700 mb-2 txt-center cour-desc-lt'><div className='wdth-90p mxw-550 mb-5 bdr-radius-8 fix m-auto'><img src={imnImg1} alt='Imageminds' /></div></div>
                        <div className='col-lg-8 col-15 mxw-700 mb-2 txt-center cour-desc-rt'><div className='wdth-90p mxw-550 mb-5 bdr-radius-8 fix m-auto'><img src={imnImg2} alt='Imageminds' /></div></div>
                        <div className='col-lg-8 col-15 mxw-700 mb-2 txt-center cour-desc-lt'><div className='wdth-90p mxw-550 mb-5 bdr-radius-8 fix m-auto'><img src={imnImg3} alt='Imageminds' /></div></div>
                        <div className='col-lg-8 col-15 mxw-700 txt-center cour-desc-rt'><div className='wdth-90p mxw-550 mb-5 bdr-radius-8 fix m-auto'><img src={imnImg4} alt='Imageminds' /></div></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
