import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import NewsList from "./../../Components/NewsList";

const EMList = [
	'https://lh3.googleusercontent.com/GwaYoIJr7roKXtHdvpEvIIzQ3X4PmC3PC2Cg7J4q1kdGvUzjeJw6fF7vZiCi6Ftn27CCnTpVsmV2b8NeGeE1oiLRjkZ-NaiMfiOBZzTxumBF9IU8seEBte-0M22bhs9K9aFCbzFVeA=w1920-h1080',
	'https://lh3.googleusercontent.com/eyKHnPh0j9emfF1p8I7YGtAhmkN6CmRiX0v69jkKkPzkAvDX5i5i3gBMM_ESl8qqqHsfjvuT1I2l3ZVreOW9X99k653TZIrS9fPSbi08QB7mFK7gVZMc-90s5dC0DlMVy253FK9u3w=w1920-h1080',
	'https://lh3.googleusercontent.com/2y1Hr2Z74YgYH1FkQ6GXiGpmjFc6RhYj9lmYUy7LuJ-xNlqZ8coLmecQ-ozQYyz6_jJ6vyWc69RT3XL80T1vM4Npb_0mhvtfolEDTxnI6LQIc3TQpkSSh9_b_x545IaN92855hJxaA=w1920-h1080',
	'https://lh3.googleusercontent.com/yfVI2xBBtYtay3e7V2E0JuM3plNqrYJBq3mSlAMI0F0mVmzC1IEJCmk6cQ8odDV7t2uWAT-okC-rOTYdZfB9VyIU_5Jy5KQZgj2byOUD_mfhS4V2EzagTUEU2JcvadYGcxZXMNpoKA=w1920-h1080',
	'https://lh3.googleusercontent.com/_lzpj91MHXn9qlmct9rW6eV-tsZqiJUOl_PPYmiFfSrtHbULvc14sr6QlEJthAWzpoMKpkEUZJdiisF8n4KPJShw8VujtSjfuTQe_q7yA6wzX4izWzsCg6Z9vuZ3lB9snVqKvDEX3g=w1920-h1080',
	'https://lh3.googleusercontent.com/4rAaFh_A6O83Hu6LxD-4TbD7B7Z8B3sY5Nz55iBLG6epO544hQdfzqORBToXfdl5E5YzWZfII6vcDcT_Ju-ZutLcSb6r3qi4ikKnP36ieyWIj6NsWx23AMWWDE_nT7Gv4mflKco5oA=w1920-h1080',
	'https://lh3.googleusercontent.com/Y12qhHmBE4AmU_xbt7PX2sJRZ8LQK2T30zbarlhZzoeFJJMIUk9Mt8q-EjH0eMEyVFXopBNFAz4BuaiRvmCCU7CjfwPdbfqXTIvX5skkr7K9HKyL8snoy45y0GRcBaR2ejKbzB6aeg=w1920-h1080',
	'https://lh3.googleusercontent.com/SLuYjQmsp0zLdy6NKnjeql0UcjnFAAuA0c2h70FlAozFO_KCQ-rvtfOfgjfgS2C6TC7BhC2qzIpxW3weUgaA4E174YAUEXKeIi1bYKMhmKFHPJdtNZl_W0TnFEJUcTTJKhL2i-aywA=w1920-h1080',
	'https://lh3.googleusercontent.com/nBXZwRX_yyyUuOiCKCHxtOBB5_Uoy5HfVPXSKSRvTJBk6lMAFICI7F6rBevoAojjbTW2Gi8R3ZWTm34WamFFVyB3DnC0270lx0PAuDNtLsjOUUmydorCDHuHoyyw0O3-vBGtNbwsQw=w1920-h1080',
	'https://lh3.googleusercontent.com/P7WUmQjND91fSzpGQKotXr-U5KhweumlFsRMRsYT5Hok1lGdGxcOmzGIrZvmkJedgCjWnCrJC7mLgM6S-kd9rezn210BWI6uZAuw-ZITAdfNOZ1LKmd0ZUA8QA_6SwHwle47cWyUag=w1920-h1080',
	'https://lh3.googleusercontent.com/Ma-00kZdAB2hGvAgBM3S-6zyGEh2XUlMnoHCKASkXZyrSWuxrwL6SqLZnJvcKMlgQ8iST_sI5W5XJ1XTn7FpNJCtfbVvfL0zHm1ARaPmq5wuKW5cluCk8SvRLDckGeqmO3nJzejWfA=w1920-h1080',
	'https://lh3.googleusercontent.com/AXzWSph-e8EsT_wTLFw9ZE9o1c6t6-RonrgZtUdKLWunAAqEc-42QIUBLJASIrugx5-QTpg1_Bxw4q4xOsnH3mnJY3FYr7HK5x86tmf_FduzDgc3jS_x2MtFAD6VxPeXVnlq87-BcQ=w1920-h1080',
	'https://lh3.googleusercontent.com/NCRm1AfXRJ7pcXjqW4740d8ldiEpF7i-1eGHbM_8LI3i5cHUWYjBgnhDA9uEcYCJWipGyHFBgBlWlKtLPggw8VlrMHNOpWUJYuYrmI10oWr5WYCAjZL5RLwhCv-woRpu432NHgcOKw=w1920-h1080',
	'https://lh3.googleusercontent.com/1B7LM4alWG2Uf9ngfjICIuJ6FqlLg1n0-HQAvQCczAoeleJ4aTfZLXuqkHfFpfD9f_JiZCdDgj_gHJZ66pCzCUmMCG_aN-UQmO8dFDBaWi4c8Ag7dKW6jz32iaDgz935UscKNioYeA=w1920-h1080',
	'https://lh3.googleusercontent.com/BUircGoEJEr_6y-BTWBwzM-xtvnzW8s7a0KtwJB8dVXIwy3gdGt4XKyt-8v3Jnayr_-rj7IgModDwZt-gpMjcqdlugE3PM9M6FD85K4xnPWixzfYb3e3P6oElwnTdFPssL12DRlXUQ=w1920-h1080',
	'https://lh3.googleusercontent.com/-8eCBV_pig1YUQ4Qk4peY4pi2XF6xxHpS1twQUceEY3AFmohJAKbiOFFekE9ii0PIMo3A1jAzy7A7Ub2hVHpXwe9-uBrrbijvqRhVVrsmw1bK1FtV9Zqds-3MYxh0KVtv6ZDKuiX0g=w1920-h1080',
	'https://lh3.googleusercontent.com/YNYmEsntYS6RbhzapK2Lx50l-PFrAIKtnb1KWxtwyyhAzDMP2VEMLj91k5xZe5K46ZEj32zOiSM3na2qUjqiFleeteP_5AhTdPrytSlkmbWSDqgDod8RPFbdKeVQz97eE_tDNLjEUw=w1920-h1080',
	'https://lh3.googleusercontent.com/y-j7R47aE18bdnsEwu5dZQjnhcVTap50fNlp4OOTaElw5jT4ncfqdP0iW_qclxhxE38tf8298mVYk4_k5wVlCKy3vdZZAHhcMZCB29nlJMHGb3BFkakE1X0EhBuBWhGjc7b4qUGPmQ=w1920-h1080',
	'https://lh3.googleusercontent.com/2d1EWMtEYps3VZHay7PdF1Mwqd9sAJ-Z0n_YMew2UfFheOMwQdhehn-VkbBnIp27BBpKxyv1kS1rqBe-vJXzWNoIUom2EmyMt3eJCscYiQUejO5IcxSTlxvJgcxR13sP-425fqZ9FA=w1920-h1080',
	'https://lh3.googleusercontent.com/m0ovViDAI_us5OPGRwjosuPpahDDd4r7Xi1cvyd83U2Vk9yG6rue81HtJnsqf3q7vKsZ4KpT4WipbwqM5IeJlkt1yunmDjfp2yIu1J8neyXDKQoXzDZ1FwW8cgfyCeS8vqbe3-qizw=w1920-h1080',
	'https://lh3.googleusercontent.com/C0x8b4lW_AfQ8Oq5c_Qq9yT0hTaQ85f7sfoi2lGcdE5MsFYrlWnRSjW82K_GXuTb4y8F2dU20GEYSuOL399Pg34MBdBLVguhL421Dc6Mld02-DfpA6SGI0UKtlS-CJFSYLk4B-pysA=w1920-h1080',
	'https://lh3.googleusercontent.com/Z6I_O-Uc1ujZ0THkLp7Ml6z5hU2jdZxWXWz66hRqjo6WeUwF8z4zDrvQt_trZMv3ZpPXzuZfk_Mk8ae_TsaK5-izb5F_aEPmq_y8saDp5m5XIoaEyBctG2nUlqLSE_4FoEerESpEAg=w1920-h1080',
	'https://lh3.googleusercontent.com/PEmBMXwtqrIY_ffk2j_fvACT6GyNB15GYemW4ReQ6Tl35mTIdDraEUmaeIrvgbQmab3oE7G08srjB5pOaoNJefHNBKq4Q8f9dZkB_cVTYRk_X7xibsP7gxZDhx6KPsbI-q0EZAvrmA=w1920-h1080',
	'https://lh3.googleusercontent.com/njRN3wtymatARhQHDyolzx-OpyEGnnTp8ETs9AeYlpy5lmF_VKMEE7qw3SegnIekUz9_eJlOvWnKSeP0RqEyCOEz30U4430ffw5pmXmShqH0VU5Q14z4wzZwCl2XGN1AOR6DrEJQsA=w1920-h1080',
	'https://lh3.googleusercontent.com/9BP3QzS1TWpXW7BbfbC2Rb9uLVI_Gx2FhrOv-ozzr927DtG4n7YXjL0YQS1ixeibzICwIB9X9db1gbzLKEu0yaZDVkR5heUTCYZzFvttwmddkqG5rFQTOwJg8uv3H1P-F_RG5pnd1w=w1920-h1080',
	'https://lh3.googleusercontent.com/6gwToFG0HPyzZXf1zfa6l2dtV3XxgFQscW30vRHbQZqf8Jc67DDydJ_8rgFOk-8HHBuKdUPtWsI3N5qxktIr3QRin9qh0HqirW2xjyzkBa5fgpG9-YEn8VlNIlRlOOvfj0BWJ5338A=w1920-h1080',
	'https://lh3.googleusercontent.com/p7S3aZJBnQTfXcxDEItydj2LUfO6jgrRMjM5EySKRMUCjUNT_tRohhwajTtlEVnMyAPFdkw6XC4GSuzwm2DY0ks1z5JO_yrujadRBMmjkb8Bc8S64ExbnUgmEbIBtiz55TrCdDBCCw=w1920-h1080',
	'https://lh3.googleusercontent.com/CJdY_D7d5AMJWizDZkIv19GvK_w6cXZJtEvs-1WOxb70JHCPjRWeDKTXev_HlGY8blI6VV2aLqX-e7oLn4DpzOzFyIw_mS-LPVV-EY4Gf84Dswpdl4rrpnLGcHA0Ti8_uAdrOb1wgw=w1920-h1080',
	'https://lh3.googleusercontent.com/y2ECYDRbX_MLz42i-XRSpWSdya2-NcNO9Y9olGckuPz3xipUYbshnlzlEyIxA4-Qmb2GmpYM6lcLahbQXG9gEXaFUkJ0UVAJG56Fou0sRLlXocgR53QUO6_I8hhG4uGrC8er2kmpSQ=w1920-h1080',
	'https://lh3.googleusercontent.com/ZaYy69_ykQ3DftFu9Iq8LeiiDFOd9ErtuWCeEyWhTsX877R8wVZxbrKpZfwR_uAEUKWBA4RjULqf7VBp_ks9Z9aIgxc0JvTIufvNKT1vT2Gv-8JCz_oaODELk4559uFFvTYVBHijHA=w1920-h1080',
	'https://lh3.googleusercontent.com/nByp4CjbIREplR2SogE5Lc_SDeTdCSXOjdAOryxbEt1p_RgPJ-MD3nlrunuHcD_R96U6XgS9dWvaxR6dFSeBwn3iPVwqxnlNkBBMCHQMp-acK9TRC3A_vHzb8x8csdRFUEKFc8M95w=w1920-h1080',
	'https://lh3.googleusercontent.com/va19TJTtAWwZDvfxWEboYzHhFM8Zlq31uU-fdwp3-kdVYT_OmrSQYiXOfGIfvq1dY3jN2fyrolJD744G69XWzSYdm0dTrXMkXYxFB_OZB4Dm0rPT9A7jD6NY-yp7hJDXylQ9Cpw-tQ=w1920-h1080',
	'https://lh3.googleusercontent.com/Z4ymtj7g802gtF5LnK3DhCQVhbGbtyxzPWuEt7EAOx19LQ7vtHRFZDpctfSet52pIWC0UEE9JV2EXMIOWl9q5pmDYCLAalLMD0En0L7CcsPwwceU4iSQ5lqpi1i48CC7p_AnIwXhXg=w1920-h1080',
]

export default function EleventhBCUConvocation2020() {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = "https://cdn.jsdelivr.net/npm/publicalbum@latest/embed-ui.min.js";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		}
	}, []);

	return (
		<div className='content' style={{ paddingTop: '74px' }}>
			<div className='container-fluid sectin float-none'>
				<div className='news-lt'>
					<div className="pa-gallery-player-widget wdth-100p mb-3 hght-480" data-link="https://photos.app.goo.gl/VFrtMz4zsFHaHqfp8" data-title="11th BCU Convocation 2020" data-description="44 new photos added to shared album">
						{EMList.map((iMg, index) => {
							return (
								<object key={index} data={iMg} aria-label="11th BCU Convocation 2020"></object>
							)
						})}
					</div>
					<h2 className="h4 mb-3">11th BCU Convocation 2020</h2>
					<div className='news-arrows'>
						<Link to='/student-showcase-2021-chennai' className='prev'>New Post</Link>
						<Link to='/inter-collegiate-cultural-fest-miraki-2020-bangalore' className='next float-end'>Older Post</Link>
					</div>
				</div>
				<div className='news-rt'>
					<ul>
						<NewsList />
					</ul>
				</div>
			</div>
		</div>
	)
}