import React from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import NewsList from "./../../Components/NewsList";

const EMList = [
    'https://imagegroup.in/images/news-events/won-most-inclusive-educational-programme-gold-award/1.jpg',
    'https://imagegroup.in/images/news-events/won-most-inclusive-educational-programme-gold-award/2.jpg',
]

export default function IMAGEMINDSWonTheMostInclusiveEducationalProgrammeGoldAward() {
    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };

    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 mb-3'>
                        <Slider className="news-slider" {...settings}>
                            {EMList.map((iMG, index) => {
                                return (
                                    <div key={index}><img src={iMG} alt="Won the 'Most Inclusive Educational Programme - Gold' Award" /></div>
                                )
                            })}
                        </Slider>
                    </div>
                    <h2 className="h4 mb-3">Won the 'Most Inclusive Educational Programme - Gold' Award</h2>
                    <p className='mb-2'>The most prideful moment for Imageminds is here on winning the 'Most Inclusive Educational Programme - Gold' award at the EduTech Asia Awards 2019, Singapore.</p>
                    <p className='mb-2'>Among the numerous entries from all the countries across Asian countries and Australia, Imageminds has won the Gold Award in the 'Most Inclusive Educational Programme' category, which was awarded for our specialised support programmes that promote equity and inclusion at a whole school level, responding to the needs of all learners.</p>
                    <p className='mb-2'>Winning the hearts of many educators, who were voters for the award, Mr. Kumar Founder cum CEO of Imageminds received this prideful award in the EduTech Asia Awards.</p>
                    <p>Kudos to the entire team of Imageminds! It's time to celebrate and spread our wings far and wide!</p>
                    <div className='news-arrows'>
                        <Link to='/11th-graduate-showcase-2019-chennai' className='prev'>New Post</Link>
                        <Link to='/raja-raja-chozhan-2D-animation-short-film-released-2019' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}