import React from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import NewsList from "./../../Components/NewsList";

const EMList = [
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/1.jpg',
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/2.jpg',
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/3.jpg',
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/4.jpg',
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/5.jpg',
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/6.jpg',
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/7.jpg',
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/8.jpg',
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/9.jpg',
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/10.jpg',
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/11.jpg',
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/12.jpg',
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/13.jpg',
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/14.jpg',
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/15.jpg',
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/16.jpg',
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/17.jpg',
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/18.jpg',
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/19.jpg',
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/20.jpg',
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/21.jpg',
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/22.jpg',
    'https://imagegroup.in/images/news-events/ug-pg-convocation-2012/23.jpg',
]

export default function UGPGConvocation2012() {
    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };

    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 mb-3'>
                        <Slider className="news-slider" {...settings}>
                            {EMList.map((iMG, index) => {
                                return (
                                    <div key={index}><img src={iMG} alt='UG & PG Convocation 2012' /></div>
                                )
                            })}
                        </Slider>
                    </div>
                    <h2 className="h4 mb-3">UG & PG Convocation 2012</h2>
                    <p className='mb-2'>Donning a black gown and a gold-laced cap and being honored with medals on a convocation day was a memorable moment for ICAT student on 18th Feb 2012.</p>
                    <p className='mb-2'>The function proceeded with welcome address by Ms. Suchithra (Head of Student Services). The occasion was graced by Mr. V. Srinivas Murali Mohan, Three times National Award Winner in Special Effects, CEO of the Indian Artists Studio, Chennai, and VFX Supervisor of Endiran-Robot & Shivaji - The Boss. Also present in the event were Mr.Jithesh, Lead Game designer in UBISOFT-, Mr. Kumar - Founder & Managing Director of Image Infotainment Limited and Mr.V.Anand-Technical Director of Image Infotainment Limited.</p>
                    <p className='mb-2'>Mr. Kumar, Founder & Managing Director of Image Infotainment Limited, in his felicitation address during the convocation stated that ICAT is the only digital media college in India with 84 undergraduate students graduating this year in the specific fields like Animation, Visual effects, game design and game development and & 65 post-graduate diploma students who have completed their 3D Animation/Visual Effects/Game Design/Game Development/Advertising Design programmes.</p>
                    <p className='mb-2'>A lot of hard work and dedication at all levels has gone into bringing this day to completion. Among the Graduates are those who have taken pride of place and will be conferred Awards in recognition of their efforts. This marks another milestone in the annals of ICAT.</p>
                    <p>Delivering the speech, Mr. V. Srinivas Murali appreciated the graduating students for their contribution towards enriching the academic and the community environment of the campus. He said "Fifteen years ago, when I entered in to this field the opportunities before me were not even a fraction of what each of you have before you. A new brave world lies ahead of you. For that we have to be fearless and take on the world."</p>
                    <div className='news-arrows'>
                        <Link to='/image-advanced-learning-system-launch-meet' className='prev'>New Post</Link>
                        <Link to='/seminar-visual-effects-enthiran' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}