import React from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import NewsList from "./../../Components/NewsList";

const EMList = [
    'https://imagegroup.in/images/news-events/photography-contest-winners-runner/1.jpg',
    'https://imagegroup.in/images/news-events/photography-contest-winners-runner/2.jpg',
    'https://imagegroup.in/images/news-events/photography-contest-winners-runner/3.jpg',
    'https://imagegroup.in/images/news-events/photography-contest-winners-runner/4.jpg',
    'https://imagegroup.in/images/news-events/photography-contest-winners-runner/5.jpg',
    'https://imagegroup.in/images/news-events/photography-contest-winners-runner/6.jpg',
]

export default function PhotographyContestWinnersRunnerPrizeDistribution() {
    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };
    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 mb-3'>
                        <Slider className="news-slider" {...settings}>
                            {EMList.map((iMG, index) => {
                                return (
                                    <div key={index}><img src={iMG} alt='My India My Pride - Photography Contest Winners and Runner prize distribution' /></div>
                                )
                            })}
                        </Slider>
                    </div>
                    <h2 className="h4 mb-3">My India My Pride - Photography Contest Winners and Runner prize distribution</h2>
                    <p className='fs-italic mb-2'>Date: 04, November 2023</p>
                    <div className='news-arrows'>
                        <Link to='/iac-prize-distribution-ceremony-chennai-2024' className='prev'>New Post</Link>
                        <Link to='/comfusionism-miraki-2023-chennai' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}
