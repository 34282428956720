import React from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import NewsList from "./../../Components/NewsList";

const EMList = [
    'https://imagegroup.in/images/news-events/8th-student-showcase-2018-hyderabad/1.jpg',
    'https://imagegroup.in/images/news-events/8th-student-showcase-2018-hyderabad/2.jpg',
    'https://imagegroup.in/images/news-events/8th-student-showcase-2018-hyderabad/3.jpg',
    'https://imagegroup.in/images/news-events/8th-student-showcase-2018-hyderabad/4.jpg',
    'https://imagegroup.in/images/news-events/8th-student-showcase-2018-hyderabad/5.jpg',
    'https://imagegroup.in/images/news-events/8th-student-showcase-2018-hyderabad/6.jpg',
    'https://imagegroup.in/images/news-events/8th-student-showcase-2018-hyderabad/7.jpg',
    'https://imagegroup.in/images/news-events/8th-student-showcase-2018-hyderabad/8.jpg',
]

export default function EighthStudentShowcase2018ICATHyderabad() {
    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };

    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 mb-3'>
                        <Slider className="news-slider" {...settings}>
                            {EMList.map((iMG, index) => {
                                return (
                                    <div key={index}><img src={iMG} alt='8th Student Showcase 2018 @ ICAT, Hyderabad' /></div>
                                )
                            })}
                        </Slider>
                    </div>
                    <h2 className="h4 mb-3">8th Student Showcase 2018 @ ICAT, Hyderabad</h2>
                    <p className='mb-2'>The campus of ICAT, Hyderabad was resonating with energy of intellect and nostalgia as the faculty joined hands with the students to guide them through, one last time before entering the professional world, fine-tuning their projects on the 9th of June, 2018. Colorful decorations paved way to the venues, distributed across the five floors of the campus where the students portrayed their creativity and zeal with blissful confidence.</p>
                    <p className='mb-2'>To start off the day, an inauguration ceremony of iMiens, the 8th Graduate Showcase was organized at the seminar hall of the Hyderabad campus. The Guest of Honor was the eminent Telugu Filmmaker, Satish Kasetty, who along with Mr. Kumar (Founder & Managing Director of IMAGE Group), lit the inauguration lamp and flagged off the event. Classical and devotional performances by the students and faculty alike set the mood for the wonderful day. </p>
                    <p>The event had a good footfall of parents, well wishers and industry professionals. With the 8th edition of the Graduate Showcase, Hyderabad campus has been successful in continuously creating a platform through iMiens for the students to showcase their talent and build their confidence.</p>
                    <div className='news-arrows'>
                        <Link to='/8th-graduate-showcase-2018-bangalore' className='prev'>New Post</Link>
                        <Link to='/image-opens-new-centre-channasandra-2018-bangalore' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}