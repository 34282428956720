import React from 'react';
import { Link } from 'react-router-dom';

import NewsList from "./../../Components/NewsList";

export default function LaunchOfCreativeCareerProgam() {

    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-img mb-3'>
                        <img src="https://imagegroup.in/images/news-events/creative-career-program.jpg" alt="Launch of Creative Career Progam" title="Launch of Creative Career Progam" />
                    </div>
                    <h2 className="h4 mb-3">Launch of Creative Career Progam</h2>
                    <p>Launch of Vijaya Karnataka - NSDC - Creative Career Progam powered by IMAGE Creative Education was held at Hotel Ramada, Bnagalore on 24th September 2015. The logo of the function was unveiled by Mr.Mahesh Venketeswaran, Principal - Innovation & Engagement, National Skill Development Corporation in the presence of Mr. Nischal Kumtakar, Vice president , Vijay Karnataka and Mr.Kumar K, Founder & Managing Director, Image Creative Education Pvt Ltd.</p>
                    <div className='news-arrows'>
                        <Link to='/icreas-2016-st-micheal-academy' className='prev'>New Post</Link>
                        <Link to='/image-advanced-learning-system-launch-meet' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}