import React from 'react';

import CEO from "./../../Images/manage/ceo2.jpg";

export default function AboutTheFounderCEO() {
    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin'>
                <div className='row g-lg-3'>
                    <div className='col-lg-7 col-15 mxw-700 about-lt'>
                        <img src={CEO} alt='Mr. K. Kumar the Founder and CEO of IMAGE Group' />
                    </div>
                    <div className='col-lg-9 col-15 mxw-700 about-lt'>
                        <h2 className='h4 fw-600 txt-lg-lt txt-center txt-thm-clr-3'>Mr. K. Kumar</h2>
                        <p className='f-16 txt-upp fw-600 mb-4'>Founder & CEO of IMAGE Group</p>
                        <p className='mb-2'>Mr. K. Kumar is the pioneering orchestrator of Indian Digital and Media Education. He is the Founder and CEO of IMAGE Group, which includes:</p>
                        <ul className='list ms-3'>
                            <li className='mb-2'><span className='fw-500'>Image Infotainment Limited</span> (since 1995), renowned digital media content provider and corporate trainer in India.</li>
                            <li className='mb-2'><span className='fw-500'>Image Creative Education</span> (since 1996) (trained 78,000+ students through 30 centres), India's first professionally-managed digital media education institute to offer skill education in creative design and media fields.</li>
                            <li className='mb-2'><span className='fw-500'>ICAT Design and Media College</span> (since 2004) (Groomed over 9,000s graduates so far through 3 state-of-the-art ICAT campuses collaborated with renowned Universities), through which Mr. K. Kumar introduced first-of-its-kind, regular, full-time UG and PG programs in creative streams that many Indian Colleges have only introduced very recently.</li>
                            <li className='mb-2'><span className='fw-500'>Imageminds</span> (2012) (now at 19 schools and benefitted nearly 2.17 Lakh+ students), the School Education Wing of IMAGE, ensuring a holistic development in children by nurturing creativity and innovation as a practice through Visual Arts.</li>
                        </ul>
                        <p>Through his visionary ideas, planning, guidance and motivation, Imageminds received the <span style={{ color: '#cc7500' }}>Most&nbsp;Inclusive Educational Programme - Gold award</span> at the EduTech Asia Awards 2019, Singapore.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
