import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import NewsList from "./../../Components/NewsList";

const EMList = [      'https://lh3.googleusercontent.com/nimKOs0aAFQpGfmPH5lb5MR8QpL70uLNQ72Y7Zl_j1pGKgnHO0aGGvFdB7uMbFDUgCbE_cqvYOgzO2kV0QBvfREK0si8CdnP9V7caxzmdC2CF4CBvNudUh0BSzGmbnddwquEJj7Wsw=w1920-h1080',
	'https://lh3.googleusercontent.com/88nBYV0At7uu4tm0dVp4s-lKazTX8rljmCfgbYW8KRk6d_7kW_mcLZfdFmjU0leF3JREZWAxcEqOaHyaZigqyer3wf7XKXXjKfjQZZgyra6nDIFG0BYTU6pNp4KWmkyjQ0JLl13fpw=w1920-h1080',
	'https://lh3.googleusercontent.com/-MaKRyEbe5hMpZMN6BJfchagricIqtlLPLj3YWIFArpMzPQss_HpbvUuM7IvRUbVXH-Zl1Insj7uX3nhzTeoQtE3loT3ENP5bcKB7vxwnJ_bkJThQ9ePm2gHsuOwUdDjJrjogNvUMA=w1920-h1080',
	'https://lh3.googleusercontent.com/cPlVmLbAtHtdlngedvdpOeGUUjZNjsXlY1Qk4BA53nnkJdc150ggjJ3jcWTuCFYejUk3Ne3J5yK7OTEP4-y5wVDuAatsIEB7U9GaEAZItvgjiV_DEL2h5fansf7OxZHdyMMvGx8gLw=w1920-h1080',
	'https://lh3.googleusercontent.com/sZ5yPf-GRovdi9rrtlS640cX1yytEJ8OANbMbHtNJFIJdagI72SAbeYcj9wt5cPe82AItxJqwtaWVqKQEOjd7WXUCHFA984H4bAUsMKj76L-uj-ntyf3BlkHpvoWx0RIcjOMAUt23g=w1920-h1080',
	'https://lh3.googleusercontent.com/UDsCPcLqMgzK-OYNlMuFBaEP-ifCKHWrZIATFf6v_YYwFparzpyJG6yqBCSDhwuXdnFa3DFWnOtw9kcBZL06ncGJiUzGWxjj2Nq8n0I0zAy9K4uB212N2bxANL_WupbKFnWnQptOOA=w1920-h1080',
	'https://lh3.googleusercontent.com/vyGb_rq9rqcdl55gZ1nrXdPZLpdYMEjlOxXb15YTyMCzYEWf9vqDJO4K9EU6iCq-_g7ExNUudE0AuGVe2A42Jqr6nDwXdrfZJUxv2YZmmHyQZVJQwQazjoq12Aj1v_xN9cCg76uqtA=w1920-h1080',
	'https://lh3.googleusercontent.com/cMFDbAS8S45pwLNBG-u0pb5ckLLWwv8jeIGWMuhu4Oe7kjJL3tUPCdUOmdEY3HyDUR-N9TFtzfJQMLSfR6ymzKBK0F5cwOx20UPkkkNE-gaDBJMzD4toykZxy9SxTtxJ7dIY3aJIWA=w1920-h1080',
	'https://lh3.googleusercontent.com/Cjext6z8PiraRJ_qpgIdp9_9_AJ494H9jnHdMEns8pUzPC4R2qtyKBC2eFYSzJZ7FLBt7O-hHR6v-wmcRVpcxzyxUfGDW5OfMKKM8R92dey7g-XsJMSsvASW4EmAWLTpNlSwsoT-Gg=w1920-h1080',
	'https://lh3.googleusercontent.com/rUm-mMF5iVHxML267MF7J935uC8wO46zEMTqITTYHdgBA93EpvgIPHcggEGPTwIlL09CWlGfg7-5AncCz5YUawd0tyjvrNfl8y9nsLnEop_xaNqEWbVzC44vBesC68eoN6nnwcAxRQ=w1920-h1080',
	'https://lh3.googleusercontent.com/hg7H_3Pi-W0BEBCeBO7d8K0mK2h9nQJjhdkSbfuTebjWduQwUf_WNbcdHd4gI3Nl7tVeEiDAoQLNf3sK8RifC-ZWq35rxY_1m7pJmmxfvSincV5hcOYV20VtECeCjK03ORpNt0Ekbg=w1920-h1080',
	'https://lh3.googleusercontent.com/WYaWFX5Oj8d5i1bjxbkeAPNbU40p32X6mF26Ael0aV0RZ7WutEDKT6R_Fv95wzaSLn-oB6W3BT_hRYbC3aiyI9kAuysrJDNdItpZg7WT23ukVaPQXEq7d0TZ2mCtqcS2kOBSbPpxbw=w1920-h1080',
	'https://lh3.googleusercontent.com/6-wSMuAu-qMEFHtdPiFLk6P4AHNUoZijhozzaIdrnN2FXaNsD-gfDHADtKHeOsjXwGPwBSuDRVPG4CdfH9D_oHDo4LQbaFJOryCXNcul1IJyotn5Ccf5eayjiAcInmGod5j-UCCtpg=w1920-h1080',
	'https://lh3.googleusercontent.com/UIlHQ-7zzmcQnZjWgzeP-UNAYa0Zd8JwVm7j_yOalKhBbltH1v0GD4q7-jncdyHLAHyZY6AxjIlCUle20omwZDt7U7oEBoqj7sWC3057ORiHO6h438h1Nk_yYFFQWlCsnjQ8xJ849w=w1920-h1080',
	'https://lh3.googleusercontent.com/Rd10RLXnZo9-a9nAJB50gvviZ6m13ANXD1mCGww-rvZx_nXQDHUWs8UWdzxKhR9fJP3JZECfgzV12LiSC_52Aa4X7ITewkvb2CGkp0eXnLovhlERjBTMeuKtUxxlHjrJnqZnFgJnFg=w1920-h1080',
	'https://lh3.googleusercontent.com/mwXZh3i4niWQVOeBkXG0iKziyofsxPtF61_jW71RrMPHHP8dnX5lbIKCsm1AO7htjenmi4oB49zrPSx9BYRLvsGzyF11WLyRVLWT3EPEN09Ky-JHF5jgJXr0FzucE1obskSCzt28kw=w1920-h1080',
	'https://lh3.googleusercontent.com/CnHdByGeEmFsK_GziTCDrIK_9kxkr1rsMThl2g2LHWx60bl1yo2R2ZuUuP2z2z0jmaX4sFJggvYg3Qckio6aI87Gdz-8J-sieleTEfGZh4YD0Ca6hpZNmsbWgBqS40ZCIoZsk7ujPQ=w1920-h1080',
	'https://lh3.googleusercontent.com/nu6ENuZr9HIJ_WII3S-sgTfC7hmML9aKFIDLXTxvcedMsEmBHGNROOx895vrM8Io4A5IgaRhhGGNQhVU2oFR3AFWcgZGaJad9kXo_MG7AByV0QKLUIt-YXBCo4AZm2LIE0ueA1ejTA=w1920-h1080',
	'https://lh3.googleusercontent.com/mRMjcUIwL91p4K9I4rQoGeqpXgUtl03kOpnBz-p-KHx-6sp6EimS-NcwsJJuUIUr2ojaToPcgwSmLtqPynEh_r2n5dEIlD0EPh_jWlyBm0CYxjL5N6T2YdGcuIpGGiitrc0jnBtbzQ=w1920-h1080',
	'https://lh3.googleusercontent.com/IQStNFmJ1ZzUfpJK-QjtaSKpFDmO-2jl7qAiHpWbdIwtIRb4sCbrrsIBo_yPp_W0GkpIWLZJb9zy2zKF7Ea2glbAApTti1NIVsxepXzbxSJY0pX3V6HUL9SYP69junia6j6bQCylqg=w1920-h1080',
	'https://lh3.googleusercontent.com/JyljvOJjghiDNoeUa0DHofuV_1k6t043n10yZSHATG1X-H0Mw-N5GG3Mwj1tFkZkw4oIxEictf9oz2TYm5X2D4v2wLn1gDgOKqWom6BDihXJ_A9PlO-NKvT8uEisyUJzAhNbeHLwXQ=w1920-h1080',
	'https://lh3.googleusercontent.com/rHGM0lAAAWQAd0MyKyKTNe2snV5WKglmdhwWWNQToUj00qEhTCtXXKHS939xDDXs_jf_BtsW8D4AyezOtBdmGIJie01q6mwqrDFRa7Hln1y6OuCSJ8-GDHdaRQ1llJk9IviZNiwAVQ=w1920-h1080',
	'https://lh3.googleusercontent.com/ywa9soMcNzPgwUcVTQ2F9XWFcsY8JSbg3q2gLKfApVB2bLObWm8MR0tOita57Q5ufHWgK3sLGg10atYKWBlpUZMgoJi9Uzkrk7qHqcPPDcfL5DgfQN_cgGNiNZ7h8EojC0UIKSUWZQ=w1920-h1080',
	'https://lh3.googleusercontent.com/nvaKzFKIyrIbhKMU3DXTBCf7GcO4e1APaGuOLhGQTfkTwfnXyecdYymiJmfH51g85YpHMkXrzj4wpHR2hMqgMHqIhaIV-CgN8Mq-OuRI7PBebBR-deks9Nv_RVKz3VSi8qI-5MyF1Q=w1920-h1080',
	'https://lh3.googleusercontent.com/mp5wxzpd0MagxVttjLozdV-k_TWBBHzxBmXcsTGShALBbpO2eqgMYMDKvYff1ELU2lDaBjQw1rFg49sJs7aEUfGgyAwSStj5lLatzYYC2gcevDggz51Uuv-DElC1mLPpt9zFMJ7mKw=w1920-h1080',
	'https://lh3.googleusercontent.com/7_rzFMoiNXU7cmQ8zK6K4XS5ZlxphNYpS8WD8geWwLpOjTT7xibK04d8ndLPTem5xq0ncghlorsC3mZ4lX4TlG-GV9hw19VFY0FaEDtnSuttvsuDEmbEI_jYGSLcpInbghTmrCttGA=w1920-h1080',
	'https://lh3.googleusercontent.com/jUbatSWRNvhkbVrhj882z8qZxu9zqGGCuzpT4Xixqc3LxZ_qlTJdpcBCq60SgdRsy67kliJzAHGcE8EnTXwFd1zzf9AilHvGNNFGFN1tcWgOZtEzIjeqjdYECPMxt43Wyc8-RJQT9w=w1920-h1080',
	'https://lh3.googleusercontent.com/bHNs75Ql4N6WS33c8Ege_T68TcfYuf8WAYZmT4ZbCfuti_fQYlkRRJhH0Bk6U-VBpKBdFb8_lzRv3SWgdBEHE3CT4WPBrJ2D0weJx_j9z1RawBzH3WBxoKzngl4_KLA_8Ig9JzWYSA=w1920-h1080',
	'https://lh3.googleusercontent.com/CVKmjjUp1YkHhdPIJyN7w21G6QRKhO9QIT5o05Hppczz6YyucElyWBpJA3YeDneHgNeLQOFhLW8J7-snI5AJQoQQqIogO-ys-eX0ndmulnO819DgOB7W3C45UHYak_BTrU6PMT-2ag=w1920-h1080',
]

export default function InterCollegiateCulturalFestMiraki2020Bangalore() {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = "https://cdn.jsdelivr.net/npm/publicalbum@latest/embed-ui.min.js";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		}
	}, []);

	return (
		<div className='content' style={{ paddingTop: '74px' }}>
			<div className='container-fluid sectin float-none'>
				<div className='news-lt'>
					<div className="pa-gallery-player-widget wdth-100p mb-3 hght-480" data-link="https://photos.app.goo.gl/VmFm9KFjkR3kkKWR8" data-title="Inter-Collegiate Cultural Fest - Miraki 2020" data-description="44 new photos added to shared album">
						{EMList.map((iMg, index) => {
							return (
								<object key={index} data={iMg} aria-label="Inter-Collegiate Cultural Fest - Miraki 2020"></object>
							)
						})}
					</div>
					<h2 className="h4 mb-3">Inter-Collegiate Cultural Fest - Miraki 2020</h2>
					<p className='mb-2'>With the spirit of the grand success of Miraki 2019, ICAT Design and Media College, Bangalore hosted Miraki 2020 - the Inter-collegiate Cultural Fest on Friday, 6th March 2020, at the St. Johns Auditorium Bangalore. This year’s theme for the creative event Miraki 2020 was “Sci-Fi: Future and Beyond”.</p>
					<p className='mb-2'>For the auspicious occasion of Miraki 2020, Chief Guest for the event was Mr. Vasuki Vaibhav, lead singer and composer in the Kannada film industry and it is needless to say that his mesmerizing voice gave an energetic start to the program and also we had the honor of having our Founder & Managing Director Mr. K. Kumar with us. His presence and guidance encouraged us and the participants. The honorable guests inaugurated the festival by lighting the lamp and then the inaugural host dance was performed by the students of ICAT- Bangalore.</p>
					<p className='mb-2'>The stage set in action with events like light music, western music, folk dance, Fusion Dance, western dance and fashion show. The enthusiasm was no less in the off stage events like pot painting, Mehendi and tattoo painting, cook without fire, Adaptune and beat-boxing. The online events like photography, animation and live action short films and poster creation were also a great success. Distinguished judges from the respective fields were invited for the competitions.</p>
					<p className='mb-2'>Miraki is a platform for young and creative students to showcase their exclusive talent in various creative fields. More than 100 colleges in and around Bangalore had participated in the fest. The students of ICAT Bangalore made the festival colorful with their creative decoration ideas and host performances.</p>
					<p>Famous actress, model and dancer Ms. Ashvithi Shetty joined us as the chief guest for the evening session and we also had Mr. Deemanth R, one of the most celebrated models and fashion personality as our esteemed guest. Our CEO Mr.Kumar and our distinguished guests presented the prizes to the winners. Mr Kumar addressed the participants and viewers for making Miraki 2020 a grand success. </p>
					<div className='news-arrows'>
						<Link to='/11th-bcu-convocation-ceremony-2020' className='prev'>New Post</Link>
						<Link to='/inter-collegiate-cultural-fest-miraki-2020-chennai' className='next float-end'>Older Post</Link>
					</div>
				</div>
				<div className='news-rt'>
					<ul>
						<NewsList />
					</ul>
				</div>
			</div>
		</div>
	)
}