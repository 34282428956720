var milestoneData = [
    { mid: "01", mClass: "milestone-block mile-2 mls-1", mpoint: "book2", mYear: "1996", mTitle: "'ETV' network", mImg: "https://imagegroup.in/images/mile/31.jpg", mCont1: "Developed & delivered cutting-edge, real-time 3D visualization project for 'GEM Granites'", mCont2: "Deployed video transmission system for 'ETV' network", },
    { mid: "02", mClass: "milestone-block mile-1 mls-2", mpoint: "book2", mYear: "1996", mTitle: "Launched Image Creative Education", mImg: "https://imagegroup.in/images/mile/30.png", mCont1: "Launched India's first professional Multimedia Training Institute: 'Image Creative Education'", },
    { mid: "03", mClass: "milestone-block mile-2 mls-3", mpoint: "book2", mYear: "1997", mTitle: "Nadhanubhava", mCont1: "Developed World's first Interactive Encyclopedia title on Carnatic Music 'Nadhanubhava'", },
    { mid: "04", mClass: "milestone-block mile-1 mls-4", mpoint: "book2", mYear: "1998", mTitle: "Developed Virtual 3D Cricket", mImg2: "https://imagegroup.in/images/mile/28.png", mCont1: "Developed real-time, virtual 3D Cricket Simulation game & Web Games for 'Sify.com'", },
    { mid: "05", mClass: "milestone-block mile-2 mls-1", mpoint: "book2", mYear: "1999", mTitle: "Ramayan The Interactive Epic", mImg2: "https://imagegroup.in/images/mile/25.jpg", mCont1: "Released Interactive Animated Storybook on CD ROM - 'Ramayan The Interactive Epic'", },
    { mid: "06", mClass: "milestone-block mile-1 mls-2", mpoint: "book2", mYear: "2000", mTitle: "Launched WAM Curriculum", mCont1: "Launched India's first specialized curriculum for Web & Multimedia(WAM Curriculum)", },
    { mid: "07", mClass: "milestone-block mile-2 mls-3", mpoint: "book2", mYear: "2001", mTitle: "Released French Version of Ramayan", mImg2: "https://imagegroup.in/images/mile/25.jpg", mCont1: "Released French Version of Ramayan, the interactive Epic CD - ROM and content digitization for HMV-RPG", },
    { mid: "08", mClass: "milestone-block mile-1 mls-4", mpoint: "book2", mYear: "2002", mTitle: "Hindustan Unilever", mCont1: "Developed and deployed Knowledge Management system for Hindustan Unilever", },
    { mid: "09", mClass: "milestone-block mile-2 mls-1", mpoint: "book2", mYear: "2003", mTitle: "Launched IMUST", mImg: "https://imagegroup.in/images/mile/23.jpg", mCont1: "Launched speciality Animation Curriculum (IMUST) that incorporated Art and IT Skills", },
    { mid: "10", mClass: "milestone-block mile-1 mls-2", mpoint: "book2", mYear: "2004", mTitle: "Launched ICAT", mImg: "https://imagegroup.in/images/mile/22.png", mCont1: "Launched India's first Digital Media College: Image College of Arts, Animation and Technology(ICAT) at Chennai", },
    { mid: "11", mClass: "milestone-block mile-2 mls-3", mpoint: "book2", mYear: "2005", mTitle: "Became an ISO 9001: 2000 Company", mImg: "https://imagegroup.in/images/mile/21.jpg", },
    { mid: "12", mClass: "milestone-block mile-1 mls-4", mpoint: "book2", mYear: "2006", mTitle: "launched India's First Under Graduate Degree Courses in Digital Media", mImg: 'https://imagegroup.in/images/mile/20.jpg', mCont1: "Obtained Validation for Degree Courses in Animation, Visual FX, Game Design & Game Development from The University of Wales, UK for Chennai and launched India's First Under Graduate Degree courses in Digital Media", },
    { mid: "13", mClass: "milestone-block mile-2 mls-1", mpoint: "trophy", mYear: "2007", mTitle: "Won NASSCOM Super Pitch Award", mImg: "https://imagegroup.in/images/mile/19.jpg", mCont1: "Won NASSCOM Super Pitch Award for Original Animated Content", },
    { mid: "14", mClass: "milestone-block mile-1 mls-2", mpoint: "book2", mYear: "2008", mTitle: "Became India's Largest Digital Media College", mCont1: "Became India's Largest Digital Media College by establishing state-of-the-art college campuses at Hyderabad and Bangalore", },
    { mid: "15", mClass: "milestone-block mile-2 mls-3", mpoint: "book2", mYear: "2009", mTitle: "Rated as India's No.1 Institute", mImg: "https://imagegroup.in/images/mile/17.jpg", mCont1: "Rated as India's No.1 Institute for Animation training (The Week-AC Nielsen)", },
    { mid: "16", mClass: "milestone-block mile-1 mls-4", mpoint: "university", mYear: "2009", mTitle: "Collaboration with Annamalai University", mCont1: "Established a strategic technical collaboration with Annamalai University to offer B.Sc. Multimedia", },
    { mid: "17", mClass: "milestone-block mile-2 mls-1", mpoint: "university", mYear: "2010", mTitle: "MoU with VIT University", mImg: "https://imagegroup.in/images/mile/15.jpg", mCont1: "Signed MoU with VIT University for content & delivery of B.Sc. Multimedia and Animation programs", },
    { mid: "18", mClass: "milestone-block mile-1 mls-2", mpoint: "book2", mYear: "2011", mTitle: "Launched IMINDS", mImg: "https://imagegroup.in/images/mile/14.png", mCont1: "Launched IMINDS (creative education for school children)", },
    { mid: "19", mClass: "milestone-block mile-2 mls-3", mpoint: "book2", mYear: "2012", mTitle: "Launched IALS", mImg2: "https://imagegroup.in/images/mile/13.jpg", mCont1: "Launched IALS (Image Advanced Learning Systems) training modules for iPads", },
    { mid: "20", mClass: "milestone-block mile-1 mls-4", mpoint: "university", mYear: "2013", mTitle: "Collaboration with Bharathiar University", mImg: "https://imagegroup.in/images/mile/12.jpg", mCont1: "Established a strategic technical collaboration with Bharathiar University to offer B.Sc. and M.Sc. programs", },
    { mid: "21", mClass: "milestone-block mile-2 mls-1", mpoint: "book2", mYear: "2013", mTitle: "Launched imediajobs.in", mImg: "https://imagegroup.in/images/mile/11.jpg", mCont1: "Launched imediajobs.in, India's first Digital Media Job portal", },
    { mid: "22", mClass: "milestone-block mile-1 mls-2", mpoint: "book2", mYear: "2014", mTitle: "Launched C-TECH", mImg2: "https://imagegroup.in/images/mile/10.jpg", mCont1: "Launched C-TECH - A unique program combining creativity and technology", },
    { mid: "23", mClass: "milestone-block mile-2 mls-3", mpoint: "book2", mYear: "2014", mTitle: "Partnered with the NSDC", mImg: "https://imagegroup.in/images/mile/9.jpg", mCont1: "Partnered with NSDC, an initiative taken by the Government of India to offer skill improvement training to millions of young minds across India", },
    { mid: "24", mClass: "milestone-block mile-1 mls-4", mpoint: "book2", mYear: "2015", mTitle: "Launched PMKVY", mImg: "https://imagegroup.in/images/mile/8.jpg", mCont1: "Affiliated as Training Partners with PMKVY (iInitiative by the Government of India)", },
    { mid: "25", mClass: "milestone-block mile-2 mls-1", mpoint: "hat2", mYear: "2016", mTitle: "Celebrating 20 Years", mImg: "https://imagegroup.in/images/mile/7.jpg", mCont1: "Celebrating 20 Years Excellence in Creative Education", },
    { mid: "26", mClass: "milestone-block mile-1 mls-2", mpoint: "university", mYear: "2017", mTitle: "Affiliation from Bangalore University", mImg: "https://imagegroup.in/images/mile/6.jpg", mCont1: "Obtained affiliation from Bangalore University", },
    { mid: "27", mClass: "milestone-block mile-2 mls-3", mpoint: "book2", mYear: "2018", mTitle: "Launched C-Tech AR & VR Tech", mCont1: "Launched C-Tech AR & VR Technologies Programme - a course on futuristic technology", },
    { mid: "28", mClass: "milestone-block mile-1 mls-4", mpoint: "trophy", mYear: "2019", mTitle: "Won EduTech Asia Awards", mImg2: "https://imagegroup.in/images/mile/4.jpg", mCont1: "Imageminds won the prideful 'Most Inclusive Educational Programme - Gold' award at the EduTech Asia Awards 2019, Singapore", },
    { mid: "29", mClass: "milestone-block mile-2 mls-1", mpoint: "university", mYear: "2019", mTitle: "Affiliated Jawaharlal Nehru University", mImg: "https://imagegroup.in/images/mile/3.jpg", mCont1: "Obtained affiliation from Jawaharlal Nehru Architecture and Fine Arts University", },
    { mid: "30", mClass: "milestone-block mile-1 mls-2", mpoint: "university", mYear: "2019", mTitle: "Collaborated Alagappa University", mImg: "https://imagegroup.in/images/mile/2.jpg", mCont1: "Obtained institution collaboration with Alagappa University to offer UG and Masters Degree programs", },
    { mid: "31", mClass: "milestone-block mile-2 mls-3", mpoint: "university", mYear: "2020", mTitle: "State of the Art ICAT Campus", mCont1: "Opened a state-of-the-art ICAT campus at Hyderabad", },
]

export default milestoneData;