import React from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import NewsList from "./../../Components/NewsList";

const EMList = [
    'https://imagegroup.in/images/news-events/game-grind-2012-chennai/1.jpg',
    'https://imagegroup.in/images/news-events/game-grind-2012-chennai/2.jpg',
    'https://imagegroup.in/images/news-events/game-grind-2012-chennai/3.jpg',
    'https://imagegroup.in/images/news-events/game-grind-2012-chennai/4.jpg',
    'https://imagegroup.in/images/news-events/game-grind-2012-chennai/5.jpg',
]

export default function GameGrind2012() {
    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };

    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 mb-3'>
                        <Slider className="news-slider" {...settings}>
                            {EMList.map((iMG, index) => {
                                return (
                                    <div key={index}><img src={iMG} alt='Game Grind 2012' /></div>
                                )
                            })}
                        </Slider>
                    </div>
                    <h2 className="h4 mb-3">Game Grind 2012</h2>
                    <p className='mb-2'>ICAT, Chennai presented a grand Gaming Event "Game Grind 2012". It is the first game fest which opened doors for the public, to view and analyse the skills of students who have chosen Gaming for a career. This is an initiative taken by the students to bring their game designs and prototypes to enlighten and educate current generation, by sharing insights on the development of games.</p>
                    <p className='mb-2'>This event also posed challenge to the gaming clans and was a proving ground for all age groups from all over Tamil Nadu. The game enthusiasts got an opportunity to meet professionals from leading game companies as well.</p>
                    <p>Game grind 2012 was a game event which was organized by the Game Department of ICAT, Chennai, on 18th, 19th and 20th February 2012 for school students. More than 150 student and 40 Professional gamers participated in this event. </p>
                    <div className='news-arrows'>
                        <Link to='/seminar-visual-effects-enthiran' className='prev'>New Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}