import React from 'react';
import { Link } from 'react-router-dom';

import NewsList from "./../../Components/NewsList";

export default function ICATStudentsWonVariousPrizesAtKalaavaibhavaCulturalFest() {

    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 mb-3'><img src="https://imagegroup.in/images/news-events/kalaavaibhava.jpg" alt="ICAT Students Won Various Prizes at 'Kalaavaibhava' Cultural Fest" title="ICAT Students Won Various Prizes at 'Kalaavaibhava' Cultural Fest" className='mxw-300 m-auto' />
                    </div>
                    <h2 className="h4 mb-3">ICAT Students Won Various Prizes at "Kalaavaibhava" Cultural Fest</h2>
                    <p className='mb-2'>On 10th & 11th of March, 2016, Sri Venkateshwara College of Engineering - Bangalore organized their annual cultural festival “Kalaavaibhava” in their campus. As a part of the program, they conducted various inter-college competitions in which students from across the country participated.</p>
                    <p>ICAT students actively participated in various competitions, including Games, Face Painting, Tatooing and Photography. Among the participants from around 20 colleges, ICAT students secured the first & Second positions in different competitions. Here are the details of the winning students.</p>
                    <div className='news-arrows'>
                        <Link to='/ICAT-students-won-several-awards-delhi-CG-awards-2016' className='prev'>New Post</Link>
                        <Link to='/inauguration-at-st-joseph-college-2016' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}