import React from 'react';
import { Link } from "react-router-dom";

import Logo from './../../Images/icat.png';

import AboutImgw from './../../Images/about-icat.webp';
import AboutImg from './../../Images/about-icat.png';

import ctImg1 from './../../Images/mile/21.jpg';
import ctImg2 from './../../Images/au.jpg';
import ctImg3 from './../../Images/bu.jpg';
import ctImg4 from './../../Images/ju.jpg';

const ugItems = [
    { title: 'Animation', uRl: 'https://www.icat.ac.in/chennai/bsc-bachelor-degree-animation-course.asp', },
    { title: 'Fashion Design', uRl: 'https://www.icat.ac.in/chennai/bsc-bachelor-degree-fashion-design-course.asp', },
    { title: 'Game Art & Design', uRl: 'https://www.icat.ac.in/chennai/bsc-bachelor-degree-game-art-design-course.asp', },
    { title: 'Game Design & Development', uRl: 'https://www.icat.ac.in/chennai/bsc-bachelor-degree-game-design-development-course.asp', },
    { title: 'Game Programming', uRl: 'https://www.icat.ac.in/chennai/bsc-bachelor-degree-game-programming-course.asp', },
    { title: 'Graphic Design', uRl: 'https://www.icat.ac.in/chennai/bsc-bachelor-degree-graphic-design-course.asp', },
    { title: 'Interior Design', uRl: 'https://www.icat.ac.in/chennai/bsc-bachelor-degree-interior-design-course.asp', },
    { title: 'Multimedia Technology', uRl: 'https://www.icat.ac.in/chennai/bsc-bachelor-degree-multimedia-course.asp', },
    { title: 'Photography', uRl: 'https://www.icat.ac.in/chennai/bsc-bachelor-degree-photography-course.asp', },
    { title: 'UI Design & Development', uRl: 'https://www.icat.ac.in/chennai/bsc-bachelor-degree-ui-ux-design-and-development-course.asp', },
    { title: 'Visual Effects', uRl: 'https://www.icat.ac.in/chennai/bsc-bachelor-degree-visual-effects-course.asp', },
    { title: 'Visual Media', uRl: 'https://www.icat.ac.in/chennai/bsc-bachelor-degree-visual-media-course.asp', },
]
const pgItems = [
    { title: 'Animation', uRl: 'https://www.icat.ac.in/chennai/pg-program-in-animation-course.asp', },
    { title: 'Game Design', uRl: 'https://www.icat.ac.in/chennai/pg-program-in-game-design-course.asp', },
    { title: 'Game Programming', uRl: 'https://www.icat.ac.in/chennai/pg-program-in-game-programming-course.asp', },
    { title: 'Interior Design', uRl: 'https://www.icat.ac.in/chennai/pg-program-in-interior-design-course.asp', },
    { title: 'Multimedia Tech', uRl: 'https://www.icat.ac.in/chennai/pg-program-in-multimedia-course.asp', },
    { title: 'UI Design & Development', uRl: 'https://www.icat.ac.in/chennai/pg-program-in-ui-ux-design-development-course.asp', },
    { title: 'Visual Effects', uRl: 'https://www.icat.ac.in/chennai/pg-program-in-visual-effects-course.asp', },
]
const mscItems = [
    { title: 'M.Sc. Game Technology', uRl: 'https://www.icat.ac.in/chennai/msc-master-degree-in-game-design-and-development.asp', },
    { title: 'M.Sc. Multimedia', uRl: 'https://www.icat.ac.in/chennai/msc-master-degree-in-multimedia.asp', },
]

export default function Icat() {
    return (
        <div className='content'>
            <div className='banner2 banner-icat'>
                <Link to='https://www.icat.ac.in/' target="_blank" rel="noreferrer">
                    <div className='banner-cont'>
                        <div className='banner-logo'><img src={Logo} alt='ICAT Design & Media College' /></div>
                    </div>
                </Link>
            </div>
            <div className='about-cont'>
                <div className='container-fluid sectin'>
                    <div className='row g-lg-3'>
                        <div className='col-lg-8 col-15 mxw-700 about-lt'>
                            <div className='about-ct wdth-80p mxw-500 m-auto'>
                                <picture>
                                    <source srcSet={AboutImgw} type="image/webp" />
                                    <source srcSet={AboutImg} type="image/png" />
                                    <img
                                        src={AboutImg}
                                        alt="ICAT Design & Media College"
                                        title="ICAT Design & Media College"
                                        width="432"
                                        height="422"
                                    /></picture>
                            </div>
                        </div>
                        <div className='col-lg-8 col-15 mxw-700 about-rt mb-0'>
                            <h6 className='f-16 txt-thm-clr-3 fw-600 txt-lg-lt txt-center'>About us</h6>
                            <h2 className='h4 fw-600 mb-4 txt-lg-lt txt-center'>India's No.1 Design & Media College</h2>
                            <p className='f-16 mb-2 txt-lg-lt txt-center'>With a clear vision to groom professionals in Digital Media, IMAGE established ICAT (IMAGE College of Arts, Animation and Technology) in 2004. ICAT was first established in Chennai, offering full-time PG Diploma programs. Then with continuous monitoring of industry needs and trends, ICAT has been expanding its wings by offering various UG programs, PG Diploma programs and Master Degree programs to serve every need of the media aspirants.</p>
                            <p className='f-16 mb-2 txt-lg-lt txt-center'>All the ICAT campuses are adorned with multi-tier training facility that consist of air-conditioned classrooms, digital labs, gaming area, art classrooms, studio floor, post-production facilities, seminar hall, library, canteen and a host of other facilities. ICAT has been continuously offering best talents to the field, because of which it maintains excellent rapport with leading companies in field.</p>
                            <h6 className='f-18 fw-500 txt-thm-clr-3 txt-lg-lt txt-center mb-1'>Highlights of ICAT:</h6>
                            <ul className='list mb-4'>
                                <li className='mb-1'>3 state-of-the-art campuses in South India</li>
                                <li className='mb-1'>100% Placement</li>
                                <li className='mb-1'>Prestigious collaborations with top Indian and International Universities</li>
                                <li className='mb-1'>Industry-standard curriculum</li>
                                <li>Student-oriented pedagogy</li>
                            </ul>
                            <div className='txt-lg-lt txt-center'><Link to='https://www.icat.ac.in/' target="_blank" rel="noreferrer" className='btn'>Visit Us</Link></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="course-cont icat-cont">
                <div className="container-fluid sectin">
                    <h6 className='f-16 txt-white fw-600 txt-center'>Courses Offered</h6>
                    <h2 className='h4 fw-600 mb-4 txt-center txt-white'>20+ Creative Programs</h2>
                    <h6 className="txt-center h7 txt-white mb-3">Bachelor's Degree</h6>
                    <ul className='txt-center mb-5'>
                        {ugItems.map((item, index) => {
                            return (
                                <li key={index}><Link to={item.uRl} target="_blank" rel='noreferrer'>{item.title}</Link></li>
                            )
                        })}
                    </ul>
                    <h6 className="txt-center h7 txt-white mb-3">PG Professional Program</h6>
                    <ul className='txt-center mb-5'>
                        {pgItems.map((item, index) => {
                            return (
                                <li key={index}><Link to={item.uRl} target="_blank" rel='noreferrer'>{item.title}</Link></li>
                            )
                        })}
                    </ul>
                    <h6 className="txt-center h7 txt-white mb-3">Masters Degree</h6>
                    <ul className='txt-center'>
                        {mscItems.map((item, index) => {
                            return (
                                <li key={index}><Link to={item.uRl} target="_blank" rel='noreferrer'>{item.title}</Link></li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            <div className='cour-desc-cont'>
                <div className='container-fluid sectin '>
                    <div className='row g-lg-3'>
                        <div className='col-lg-8 col-15 mxw-700 mb-6 txt-lg-lt txt-center cour-desc-lt'>
                            <div className='wdth-80p mxw-100 mb-5 bdr-radius-8 fix'><img src={ctImg1} alt="ICAT is the India's first Media College to be ISO 9001:2008 Certified" /></div>
                            <h6 className='mb-3 txt-lg-lt txt-center'>Quality</h6>
                            <p className='txt-lg-lt txt-center'>ICAT is the India's first Media College to be ISO 9001:2008 Certified. It has also been rated as India's No. 1 Animation and Media College by the week, A.C. Neilsen.</p>
                        </div>
                        <div className='col-lg-8 col-15 mxw-700 txt-lg-lt txt-center cour-desc-rt'>
                            <div className='wdth-32p mxw-200 mb-5 me-1 bdr-radius-8 fix d-in-blk'><img src={ctImg2} alt='Alagappa University' /></div>
                            <div className='wdth-32p mxw-200 mb-5 me-1 bdr-radius-8 fix d-in-blk'><img src={ctImg3} alt='Bangalore University' /></div>
                            <div className='wdth-32p mxw-200 mb-5 bdr-radius-8 fix d-in-blk'><img src={ctImg4} alt='JNAFA University' /></div>
                            <h6 className='mb-3 txt-lg-lt txt-center'>Accreditation</h6>
                            <p className='mb-2 txt-lg-lt txt-center'>ICAT offers Under Graduate programs in association with Alagappa&nbsp;University, Bangalore&nbsp;University and JNAFA&nbsp;University.</p>
                            <p className='mb-2 txt-lg-lt txt-center'>For a brief insight on ICAT, <Link to="https://www.youtube.com/watch?v=yXVzVf0ClzA" target="_blank" rel="noreferrer">click here</Link>.</p>
                            <p className='txt-lg-lt txt-center'>For more details on ICAT and its course offerings, <Link to='https://www.icat.ac.in/' target='_blank' rel='noreferrer'>click here</Link>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
