import React from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import NewsList from "./../../Components/NewsList";

const EMList = [
    'https://imagegroup.in/images/news-events/8th-graduate-showcase-2018-bangalore/1.jpg',
    'https://imagegroup.in/images/news-events/8th-graduate-showcase-2018-bangalore/2.jpg',
    'https://imagegroup.in/images/news-events/8th-graduate-showcase-2018-bangalore/3.jpg',
]

export default function EighthGraduateShowcaseICATBangalore() {
    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };
    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-cont2 mb-3'>
                        <Slider className="news-slider" {...settings}>
                            {EMList.map((iMG, index) => {
                                return (
                                    <div key={index}><img src={iMG} alt='8th Graduate Showcase @ ICAT, Bangalore' /></div>
                                )
                            })}
                        </Slider>
                    </div>
                    <h2 className="h4 mb-3">8th Graduate Showcase @ ICAT, Bangalore</h2>
                    <p className='mb-2'>The ICAT Design and Media College, Bangalore, hosted its 8th Graduate Showcase, “iMiens 2018”, on 30 June and 1 July, 2018, at Rangoli Metro Art Center. The honorable Chief Guest Mr. (Vishwanath), CEO of Ken Interactive, inaugurated the event by cutting the red ribbon; following which the students to graduate in 2018 from all departments showcased their best works.</p>
                    <p className='mb-2'>Students and respective faculties from Communication Design (Animation, VFX, Game Art & Design, Photography, Graphic Design), Interactive Media (Game Design & Development, Game Development, UI & UX), Multimedia Technology, Visual Media, Interior Design and Fashion Design put their best foot forward and made the event a massive success. The departments also had special presentations like “VR Presentation” from the Game Art and Design department and “Miniature Set Models”” from Graphic Design, Multimedia and Visual Effects departments, and “Life-Size Cutouts of Characters” from the Animation department.</p>
                    <p className='mb-2'>The event witnessed strong presence of professionals from several reputed companies and industry giants like Technicolour, Xcentric etc- insert company names here. Many industry veterans also visited the showcase and gave the students their valuable feedback and career advice. Many of the veterans spent a long time with each student, giving them specific instructions on how to improve their work and what really works out well for them. Many students were also able to make valuable connection with professionals in the industry.</p>
                    <p>During the event, various awards were presented to students who performed outstandingly in their academics. The event was not just successful in its presentation but also paved the way to the future of our students, who are eagerly awaiting their turn to make a mark in the industry.</p>
                    <div className='news-arrows'>
                        <Link to='/CSR-activity-help-kerala-flood-victims' className='prev'>New Post</Link>
                        <Link to='/8th-student-showcase-2018-hyderabad' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}