import React from 'react';
import { Link } from "react-router-dom";

export default function TermsAndConditions() {
    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin'>
                <h6 className='txt-center mb-6'><span className='fw-700' style={{ borderBottom: '1px solid rgba(69, 82, 108, 0.5)' }}>Terms and Conditions</span></h6>
                <div className='mxw-1000 m-auto'>
                    <p className='mb-2'>The following are the terms and conditions that govern the use of Image Group's Website <Link to='https://imagegroup.in/'>(www.imagegroup.in)</Link>. Any user accessing this Website is subject to the terms and conditions and any other applicable law.</p>
                    <p className='mb-6'>Please read these terms and conditions carefully before registering and using the Website and registering for any events organised by it using any device.</p>
                    <h6 class="h8 mb-2">Use of Website Content</h6>
                    <p className='mb-2'>The content given on the Website intends to inform users about Image Group. All content provided on the Website, including text, images, animations, videos, digital artworks, audio files, and so on, is intended for personal use and informational purposes only. They are not intended for commercial use. Thus, the content may not be duplicated, sold, stored, distributed, printed, displayed, published, reproduced or exploited in any way for commercial use.</p>
                    <p className='mb-6'>Image Group reserves all rights to change, delete or append any content to the Website, without prior notice to the users.</p>
                    <h6 class="h8 mb-2">User Action</h6>
                    <p className='mb-6'>On using this Website, a user agrees not to use any obscene or offensive language; provide any false, misleading, abusive or harassing information; provide any personal information of someone else without their prior written consent; or use the Website for unlawful actions. Image Group reserves the right to take necessary action against any individual/group/organization involved in any such malpractices, as per the law. However, the choice to take action or not lies with Image Group.</p>
                    <h6 class="h8 mb-2">Accessing the Website</h6>
                    <p className='mb-2'>Any user of the Website is not encouraged to use robots or any automated devices or programmes to download, save, reproduce, or distribute the content available on the Website.</p>
                    <p className='mb-2'>The user of this Site must take precautions to ensure that the process he/she employs for accessing this Site does not expose him/her to the risk of viruses or other forms of interference that may cause damage to the user's system. Image Group shall not be responsible for any interference or damage caused to a user's computer system that occurs in connection with the user's access to this Website or any linked Website.</p>
                    <p className='mb-6'>Image Group shall not be responsible for any kind of damage that affects a user directly or indirectly caused by the use of or access to complete or part of any information given on this Website.</p>
                    <h6 class="h8 mb-2">Registration</h6>
                    <p className='mb-2'>On submitting the details in the Enquiry form, Registration form for Image Group events or any other form on the  Website, a user agrees to:</p>
                    <ul class="list mb-2">
                        <li>Make his/her contact details available to Image Group.</li>
                        <li>Be contacted by Image Group through telephonic calls, e-mails or SMS regarding the purpose for which they registered/enquired.</li>
                        <li>Receive e-mails and SMS regarding the latest activities at Image Group.</li>
                    </ul>
                    <p className='mb-6'>However, Image Group reserves the right to make decisions on contacting a user.</p>
                    <h6 class="h8 mb-2">Event/Contest Policy</h6>
                    <p className='mb-2'>While registering for any event such as the Image Art Challenge organised by the Image Group on this Website and submitting their creative works on the Site, the user agrees to</p>
                    <ul class="list mb-2">
                        <li>Create artwork only based on the topics denoted in the event for the participating category.</li>
                        <li>Manually create their original artwork using any art supplies.</li>
                        <li>Upload proof of age (ID document) while submitting the artwork.</li>
                        <li>Create unique artwork that is not a replica of existing artwork or AI-generated art.</li>
                        <li>Ensure that their manually created original artwork is not damaged in any way during the competition, as short-listed ones are required for the final round.</li>
                        <li>Provide their full and correct names for certificates.</li>
                    </ul>
                    <p className='mb-2'>Failing to meet any of the above-mentioned will ensure the rejection of their artwork and disqualification of their participation.</p>
                    <p className='mb-2'>The participants of Image Group events such as Image Art Challenge are made aware of the following, and they agree about the same.</p>
                    <ul class="list mb-6">
                        <li>The process to submit the original artwork will be shared with the shortlisted participants through email.</li>
                        <li>The final judges will decide the top three winners in each category and honourable mentions from each state and union territory.</li>
                        <li>All participants will receive an online Certificate of Participation, which can be downloaded from the contest page.</li>
                        <li>The jury shortlists the top three candidates in each category and makes the final choice.</li>
                        <li>No appeals or questions will be entertained regarding the jury's decisions.</li>
                        <li>Upon submission to Image Group, the artworks are transferred into the ownership of Image Group. Image Group may employ such artworks for promotional purposes on social media platforms, website, print materials and more.</li>
                    </ul>
                    <h6 class="h8 mb-2">Refund:</h6>
                    <p className='mb-6'>PARTICIPANTS of Image Group's Events / Contests,  Registration Fees / Entry Fees once paid will not be refunded at any cost.</p>
                    <h6 class="h8 mb-2">Taxation and TDS:</h6>
                    <p className='mb-6'>Winners of cash prizes in Image Group's events / contests are advised that a TDS (Tax Deducted at Source) will be applicable as per the Income Tax Act. The exact TDS amount will be deducted from the Winner's prize money, Winner should submit their PAN Card and other necessary documents to provide the TDS Certificate for the deduction of Tax.</p>
                    <h6 class="h8 mb-2">User Information: Privacy Policy</h6>
                    <p className='mb-2'>A user may voluntarily provide his/her personal information, such as name, e-mail address, contact number, etc to receive further information about any event organised by Image Group. Any such personal information provided is purely given with the utmost conscious and self-interest and is not provided by compulsion. By such an act, a user agrees to receive the required information through an e-mail, text message or a phone call from the officials of Image Group.</p>
                    <p className='mb-6'>Submitting any forms shall be deemed to indicate that the user has read and understood the terms and conditions of the Website.</p>
                    <h6 class="h8 mb-2">Information Security</h6>
                    <p className='mb-6'>Image Group ensures that it will prevent any unauthorised access to the information collected online. Any personal information provided on the Website will not be shared with/sold to/rented to any third party.</p>
                    <h6 class="h8 mb-2">Usage of Information Provided</h6>
                    <p className='mb-2'>The information provided by a user may be used for:</p>
                    <ul class="list mb-6">
                        <li>Internal record keeping</li>
                        <li>Improving our services</li>
                        <li>Customising the Website</li>
                    </ul>
                    <h6 class="h8 mb-2">Cookies</h6>
                    <p className='mb-2'>We use cookies to analyse our Web traffic to identify the Web pages that are highly accessed and those that are not accessed commonly. We use this analysed information solely to customise our Website and improve its usability. We assure you that the cookies do not provide access to any user system or user information, other than the ones explicitly provided by the user to us.</p>
                    <p className='mb-6'>If a user wants to decline the use of cookies, he/she can make the respective settings in the browser.</p>
                    <h6 class="h8 mb-2">Access to Other Websites</h6>
                    <p className='mb-6'>This Website may provide links to other Websites, which may include third-party Websites. When a user intends to access such links, this Website's 'Terms and Conditions' and 'Privacy Policy' will no longer be applicable. The user is responsible for reading through the Terms and Conditions and Privacy Policy of the respective third-party Website before any user action.</p>
                    <h6 class="h8 mb-2">Disclaimer</h6>
                    <p className='mb-2'>The terms, such as 'we', 'us' and 'our' cited in this content refer to Image Group. The terms and conditions given here apply to the use of this Website. By using this Website, a user agrees to these terms and conditions. If a user does not accept any part of the terms and conditions, he/she is advised to refrain from using this Website.</p>
                    <p className='mb-2'>All the terms and conditions given are subject to change without prior notice. Users are advised to check the terms and conditions regularly.</p>
                    <p className='mb-0'>If you find any abusive or plagiarised content on this Website, please report the same to <Link to={`mailto:info@imageil.com`}>info@imageil.com</Link>. The reported content will be validated and if found abusive/plagiarized, it will be removed within 48 working hours.</p>
                </div>
            </div>
        </div>
    )
}
