import React from 'react';
import { Link } from 'react-router-dom';

import NewsList from "./../../Components/NewsList";

export default function ImageAdvancedLearningSystemLaunchMeet() {
    return (
        <div className='content' style={{ paddingTop: '74px' }}>
            <div className='container-fluid sectin float-none'>
                <div className='news-lt'>
                    <div className='news-img mb-3'>
                        <img src="https://imagegroup.in/images/news-events/ials-launch-meet.jpg" alt="Image Advanced Learning System Launch Meet" title="Image Advanced Learning System Launch Meet" />
                    </div>
                    <h2 className="h4 mb-3">Image Advanced Learning System Launch Meet</h2>
                    <p className='mb-2'>Image Infotainment Launches first-of-its kind Advanced Learning System to offer Mobile Learning Courses Using Apple's iPAD.</p>
                    <p className='mb-2'>The Chennai-based Image Infotainment Limited, a pioneering multimedia and digital media training company, has become world's first training institute to offer its courses and training programs through image advanced learning system(IALS),a proprietary app developed by the firm using Apple's i Pad tablet. This brings the convenience and interactivity of mobile learning to its students who enroll for its diploma in digital visual media, Diploma in Advertisements Design, and MSc in Animation and Graphics, and Msc in IT and mobile gaming programs. the institute provides free IPAD, which would come pre-loaded with IALS, to all students who join the courses.</p>
                    <p className='mb-2'>Talking to media, Mr. k. Kumar, Founder & Managing Director, said that with ever increasing usage of tablet devices among the students community ,the institute developed a proprietary Learning System.</p>
                    <p className='mb-2'>"Our R&amp;D team worked for more than two years for developing IALS, which is the first-of-its-kind application developed on apple ipad in the world, IALS is designed to enable our students experience learning beyond the walls of the classroom. The application comes loaded with all required image learning material and videos, user-friendly navigation system, video-based tutor learning series and other feature like gallery, etc" he added.</p>
                    <p className='mb-2'>The deliverable s of iPad courses (IALS) include the advantages of live streaming of lectures every week, students can view and play archived lecture sessions; and students can learn and prepare for next day classes at home or on the move. </p>
                    <p className='mb-2'>Mr Kumar pointed out that the iPad learning will not replace regular classroom programs totally,it is blended learning system, "Instead of attending regular classroom sessions six days a week, students enrolled for the i Pad programs can make it up with just three days a week," he said.</p>
                    <p className='mb-2'>The intake of Image Infotainment, which has 30 centers in south india and three colleges in the cities of Chennai, Bangalore and Hyderabad, is around 4000 students per annum. The institute expects that about 1000 students would enroll for iPad courses in 2012 and 2000 students in 2013.</p>
                    <p>Making a turnover of 20 cores, the firm has invested about Rs.2 cores for developing the proprietary app. Depending upon the response, it plans to make available its newly developed application tool in retail too.</p>
                    <div className='news-arrows'>
                        <Link to='/launch-creative-career-program-2015-bangalore' className='prev'>New Post</Link>
                        <Link to='/ug-pg-convocation-2012' className='next float-end'>Older Post</Link>
                    </div>
                </div>
                <div className='news-rt'>
                    <ul>
                        <NewsList />
                    </ul>
                </div>
            </div>
        </div>
    )
}
